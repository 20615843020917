// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import { DEVELOPMENT } from './globals';
import React, { useState, useEffect } from "react"
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import Form from 'react-bootstrap/Form'

import axios from "axios";

import globals from "./globals"

import { login } from './redux/ilUsersSlice'

function WarningBanner({error}) {
  if (! error) {
    return null;
  }
  return (
    <div className="alert alert-primary" role="alert">
      {error}
    </div>
  );
}




export default function Login() {
  const dispatch = useDispatch()

  let history = useHistory();

  if (DEVELOPMENT) {
    var initialUser = {
      email_address: 'horst@tellioglu.at',
      password: 'DJTsbjJUKG64'
    }
  } else {
    var initialUser = {
      email_address: "",
      password: ""
    }
  }
  const [user, setUser] = useState(initialUser)
  const [error, setError] = React.useState(null);



  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };



  const handleSubmit = event => {
    event.preventDefault();

    const { email_address, password } = user;

    const doLogin = async () => {
      try {
        const response = await axios.post( // zwecks visibility of query string in URL
          window.location.protocol + '//' + window.location.host + "/il_ws/il_user/login",
          {
            email_address: email_address,
            password: password
          },
          { withCredentials: true }
        )
        //setError(null)
        if (response.data.success) {
          dispatch( login(response.data.login_model.login_user) );
        }

      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.data.success == false) {
            setError(error.response.data.message);
          } else {
            setError(error.toJSON().message);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          setError('The server is not responding. Please check your Internet connection.')
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(error.message)
        }
      }
    }
    doLogin();
  }

  return (
    <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6">
              <Form onSubmit={handleSubmit} noValidate>
                <h1><span style={{fontVariant: 'small-caps'}}>IG Admin</span> <span style={{fontSize: '42%'}}>{globals.majorVersion}.{globals.minorVersion}</span></h1>

                <WarningBanner error={error} />
                <div className="form-row">

                      <div className="form-group col-12">
                        {/*<label for="exampleInputEmail1">Email address</label>*/}
                        <input
                          className="form-control"
                          type="text"
                          name="email_address"
                          placeholder="Email"
                          value={user.email_address}
                          onChange={handleChange}
                        />
                        {/*<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>*/}
                      </div>
                </div>

                <div className="form-row">
                      <div className="form-group col-12">
                        {/*<label for="exampleInputPassword1">Password</label>*/}
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={user.password}
                          onChange={handleChange}
                          required
                        />
                      </div>
                </div>

                <div className="form-row">
                    <div className="col-sm-12 col-md-2">
                      <button type="submit" className="btn btn-primary">Login</button>
                    </div>
                </div>
              </Form>
            </div>
        </div>
    </div>
  );
}
