// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import { TESTING } from '../../exports'

import React, { useState, useEffect } from "react"
import axios from "axios";

import { Redirect, useHistory } from "react-router-dom";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import { countryPlanRegion } from '../../country/countries'

export default function EnrolmentDeclaration({
  planOptions,
  primary
  }) {

  let history = useHistory();
  const [declarationItems, setDeclarationItems] = useState([]);

  const getDocumentPath = (plan, iso, type) => {
    let region = countryPlanRegion(iso);

    let regionPath = {
      row: 'World-',
      cs:  'CS-',
      gcc: 'GCC-'
    }[region];

    let planPath = {
      yourincare_19_v:        'InCareLife',
      premierincare_19_v:     'InCarePremier',
      yourlife_row_17_v:      'yourLife',
      yourfamily_row_17_v:    'yourFamily',
      premierlife_row_17_v:   'PremierLife',
      premierfamily_row_17_v: 'PremierFamily',
      yourlife_cs_18_v:       'yourLife',
      yourfamily_cs_18_v:     'yourFamily',
      premierlife_cs_18_v:    'PremierLife',
      premierfamily_cs_18_v:  'PremierFamily'
    }[plan];

    let fileName = {
      tob:            '-TOB-2022.pdf',
      policy_wording: '-Individual-Policy-Wording-2022.pdf',
      policy_summary: '-PolicySummary-2022.pdf',
      idd:            '-IDD-2022.pdf'
    }[type];

    return regionPath + planPath + '/' + regionPath + planPath + fileName;
  };


  useEffect(() => {
    getDeclaration();
  }, []);

  const getDeclaration = () => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(host + "/ws/enrolment_declaration.json")
      .then(response => {
        setDeclarationItems(response.data)
      })
  };

  if (! TESTING) {
    if (! planOptions.plan_group || planOptions.plan_group == 'no-plan-selected') {
      return <Redirect to="/ig/enrolment/primary" />
    }
  }
  return (
    <Container className="declaration" style={{maxWidth: 'unset', padding: 0}}>
      <h2 className="mt-4">Declaration</h2>

      <p>Please scroll down to see the whole text</p>

      <div  className="declaration-items" style={{height: '20em', overflowY: 'scroll'}}>
        <ListGroup>
          {declarationItems.map((item, index) =>
            <ListGroup.Item
              key={index.toString()}
              dangerouslySetInnerHTML={{__html: item}}
            />
          )}
        </ListGroup>
      </div>

      <Row className="mt-4 mb-5 text-center">
        <Col>
          <Button type="button" onClick={() => history.push("/ig/enrolment/confirmation")}>ACCEPT</Button>
        </Col>
      </Row>

      <ListGroup className="mt-3" variant="info" horizontal="lg" style={{fontSize: 'large'}}>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'tob')}>Table of Benefits <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'policy_wording')}>Policy Wording <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'policy_summary')}>Policy Summary <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'idd')}>IDD <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
      </ListGroup>

    </Container>
  )
}

