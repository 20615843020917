import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom";

import { format } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import EnrolmentPrimaryShortView from './EnrolmentPrimaryShortView';
import EnrolmentDependentShortView from './EnrolmentDependentShortView';

import { getBMI, formatHeight, formatWeight } from '../../exports';


export default function EnrolmentInsuredShortView({
  primary,
  dependents,
  setDependents,
  dependentToEdit,
  setDependentToEdit
  }) {

  let location = useLocation();
  //let history = useHistory();

  return (
    <Container>
      {location.pathname != '/ig/enrolment/primary' && location.pathname != '/ig/enrolment' &&
      <Row>
          <EnrolmentPrimaryShortView primary={primary} />
      </Row>
      }
      {dependents.map( (dependent, index) => (
          <Row key={dependent.id.toString()}>
              <EnrolmentDependentShortView dependent={dependent} dependents={dependents} setDependents={setDependents} dependentToEdit={dependentToEdit} setDependentToEdit={setDependentToEdit} />
          </Row>
      ))}
    </Container>
  )
}

