import React, { useState, useEffect } from "react"
import Select from 'react-select';

import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

export default function GroupEnrolmentInsuredConditionsPregnancy({
  persons,
  setPersons
  }) {


  // currying
  const handleChange = (personId) => (event) => {
    const person = persons.find(pers => pers.id == personId);
    const modifiedPerson = {
      ...person,
      'pregnant': event.target.value == 'yes'
    };
    setPersons(persons.map( pers => {
       if (pers.id != person.id) return pers;
       return modifiedPerson;
    }));
  };

  const personPregnancy = (personId) => {
    const person = persons.find(pers => pers.id == personId);
    return person.pregnant;
  };


  return (
    <>
      {persons
        .filter(person => (person.relationship == 'primary' || person.relationship == 'partner') && person.sex != 'male')
        .map( (person, index) => (
        <div className="mt-3" key={person.id.toString()}>
          <Row style={{fontSize: 'larger'}} noGutters>
            <Col>
             <span style={{marginRight: '0.5em'}}>Is {person.firstname + ' ' + person.lastname} pregnant?</span>
            </Col>
          </Row>
          <Row>
             <Col>
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    name={"person-pregnant-" + person.id.toString()}
                    id={"person-pregnant-no-" + person.id.toString()}
                    label="NO"
                    value="no"
                    onChange={handleChange(person.id)}
                    checked={personPregnancy(person.id) === false}
                  />
                  <Form.Check
                    custom
                    inline
                    type="radio"
                    name={"person-pregnant-" + person.id.toString()}
                    id={"person-pregnant-yes-" + person.id.toString()}
                    label="YES"
                    value="yes"
                    onChange={handleChange(person.id)}
                    checked={personPregnancy(person.id) === true}
                  />
            </Col>
          </Row>
        </div>
      ))}
    </>
  )
}

