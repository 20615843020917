// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect, useRef } from "react"

import axios from "axios"
import {Redirect, useHistory } from "react-router-dom"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'


import EnrolmentPlanOptionsCurrencies from './EnrolmentPlanOptionsCurrencies'
import EnrolmentPlanOptionsCore from './EnrolmentPlanOptionsCore'
import EnrolmentPlanOptionsComprehensive from './EnrolmentPlanOptionsComprehensive'
import EnrolmentPlanOptionsPremium from './EnrolmentPlanOptionsPremium'


export default function EnrolmentPlanOptions({
    primary,
    dependents,

    commonPlanOptions,
    setCommonPlanOptions,

    corePlanOptions,
    setCorePlanOptions,
    comprehensivePlanOptions,
    setComprehensivePlanOptions,
    premiumPlanOptions,
    setPremiumPlanOptions,

    planOptions,
    setPlanOptions,
    premiumChosen,
    setPremiumChosen,

    validated,
    setValidated}) {

  const mountedRef = useRef(true);
  let history = useHistory();
  const topLocation = useRef(null);

  if (! validated.primary || ! validated.main_questions) return <Redirect to="/ig/enrolment/primary" />

  const [error, setError] = React.useState('');
  const [exceptionMessage, setExceptionMessage] = React.useState('');

  const [allOptionsVisible, setAllOptionsVisible] = React.useState(planOptions.plan_group == 'no-plan-selected');
  const [currencies, setCurrencies] = React.useState(['USD', 'EUR', 'GBP']);
  // const defaultCommonPlanOptions = {
  //   deductible: 200,
  //   add_benefit: 100000,
  //   geographical_cover: 'worldwide_sans_us',
  //   payment_period: 'annual',
  //   currency: 'USD',
  //   maternity_cover: false,
  //   dental_option: false
  // };

  //const [commonPlanOptions, setCommonPlanOptions] = React.useState(defaultCommonPlanOptions);

  const debug = () => {
    console.log('commonPlanOptions');
    console.log(commonPlanOptions);
    // console.log('corePlanOptions');
    // console.log(corePlanOptions);
    // console.log('comprehensivePlanOptions');
    // console.log(comprehensivePlanOptions);
    // console.log('premiumPlanOptions');
    // console.log(premiumPlanOptions);
  }



  useEffect(() => {
    setValidated({...validated, plan_options: false});
    return () => {
      mountedRef.current = false
    }
  }, []);



  useEffect(() => {
    if (['yourincare_19_v', 'premierincare_19_v'].includes(planOptions.plan)) {
      setCurrencies(['USD', 'EUR']);
    } else {
      setCurrencies(['USD', 'EUR', 'GBP']);
    }
  }, [planOptions]);


  const handleCurrencyChange = (event) => {
    setCommonPlanOptions({
      ...commonPlanOptions,
      currency: event
    })
  };

  const handlePlanOptionsChosen = (planOptions, premium, planGroup) => {
    setValidated({...validated, plan_options: true});

    setCommonPlanOptions({
      ...commonPlanOptions,
      initial: false
    });

    setAllOptionsVisible(false);
    setPlanOptions({
      ...planOptions,
      plan_group: planGroup
    });
    setPremiumChosen(premium);
    savePlanOptions(planOptions);

  };


  const savePlanOptions = (planOptions) => {

    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + primary.id + "/plan_options",
      data: planOptions
    }).then(response => {
      if (! mountedRef.current) return null;

      if (response.data.success) {
        setError(null);
        setExceptionMessage(null);

        history.push("/ig/enrolment/passport");
      } else {
        setError(response.data.message);
        setExceptionMessage(response.data?.exception_message)
        if (topLocation.current) {
          topLocation.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }).catch(error => {
      if (error.response) {
        if (error.response.status == 401) {
          alert('permission denied');
        } else {
          setError(error.response.data?.message);
          setExceptionMessage(error.response.data?.exception_message)
        }
      } else {
        throw error;
      }

      if (topLocation.current) {
        topLocation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    });
  };


  return (
    <Container style={{maxWidth: 'unset', padding: 0}}>

      <h2  ref={topLocation} className="mt-4">Choose your plan and optional benefits</h2>

      {error &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>We are very sorry, something went wrong.</h3>
          <h1>{error}</h1>
          <p>{exceptionMessage}</p>
          <p className="text-left" style={{color: 'black'}}>
            Please contact us at:<br />
            International +44 333 405 3003<br />
            UK  0808 101 3483 (freephone)<br />
            US  +1 888 753 1377 (North America toll-free)<br />
            Germany +49 89 898 277 40<br />
            Email <a href="mailto:member-care@integraglobal.com">member-care@integraglobal.com</a>
          </p>
        </Jumbotron>
      }

      <Row>
        <Col>
          <div className="tabs" style={{background: 'rgb(245, 245, 245)'}}>
            <div className="currencies tabs__header tabs__header--mob-same js-tabs-header">
              <EnrolmentPlanOptionsCurrencies
                currency={commonPlanOptions.currency}
                setCurrency={handleCurrencyChange}
                currencies={currencies}
                format="symbol"
              />
              <Button variant="link" onClick={debug} style={{marginLeft: '1em'}}><i className="fa fa-bug"></i></Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="tab__inner">
            <div className="quote__tables team__form">
              <div
                className={
                  "quote__table js-quote-table" +
                  (
                    'core' == planOptions.plan_group ?
                    ' quote__table--big' :
                    (planOptions.plan_group == 'no-plan-selected' || allOptionsVisible == true ? '' : ' d-none d-md-block')
                  )
                }
              >
                <EnrolmentPlanOptionsCore
                  primary={primary}
                  dependents={dependents}
                  commonPlanOptions={commonPlanOptions}
                  setCommonPlanOptions={setCommonPlanOptions}
                  corePlanOptions={corePlanOptions}
                  setCorePlanOptions={setCorePlanOptions}
                  handlePlanOptionsChosen={handlePlanOptionsChosen}
                />
              </div>


              <div
                className={
                  "quote__table js-quote-table" +
                  (
                    'comprehensive' == planOptions.plan_group ?
                    ' quote__table--big' :
                    (planOptions.plan_group == 'no-plan-selected' || allOptionsVisible == true ? '' : ' d-none d-md-block')
                  )
                }
              >

                <EnrolmentPlanOptionsComprehensive
                  primary={primary}
                  dependents={dependents}
                  commonPlanOptions={commonPlanOptions}
                  setCommonPlanOptions={setCommonPlanOptions}
                  comprehensivePlanOptions={comprehensivePlanOptions}
                  setComprehensivePlanOptions={setComprehensivePlanOptions}
                  handlePlanOptionsChosen={handlePlanOptionsChosen}
                />
              </div>

              <div className={
                  "quote__table js-quote-table" +
                  (
                    'premium' == planOptions.plan_group ?
                    ' quote__table--big' :
                    (planOptions.plan_group == 'no-plan-selected' || allOptionsVisible == true ? '' : ' d-none d-md-block')
                  )
                }
              >
                <EnrolmentPlanOptionsPremium
                  primary={primary}
                  dependents={dependents}
                  commonPlanOptions={commonPlanOptions}
                  setCommonPlanOptions={setCommonPlanOptions}
                  premiumPlanOptions={premiumPlanOptions}
                  setPremiumPlanOptions={setPremiumPlanOptions}
                  handlePlanOptionsChosen={handlePlanOptionsChosen}
                />
              </div>

            </div>
          </div>
        </Col>
      </Row>
      {allOptionsVisible == false &&
      <Row className="d-md-none">
        <Col className="text-center">
          <Button type="button" onClick={() => setAllOptionsVisible(true)} style={{marginRight: '1em', fontSize: 'x-large'}}>SHOW ALL OPTIONS</Button>
        </Col>
      </Row>
      }

    </Container>
  )
}