import React, { useState, useEffect } from "react"

import axios from "axios"
import { Redirect, useHistory } from "react-router-dom"

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'

import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import CountrySelect            from '../country/CountrySelect'
import { COUNTRIES }            from '../country/countries'
import CountryCallingCodeSelect from '../country/CountryCallingCodeSelect'


export default function CustomerEdit({formAuthenticityToken, accessToken, customer, setCustomer, setEditing}) {

  const [error, setError] = React.useState('');

  const handleSubmit = (values, { setSubmitting }) => {
    axios
    .put( // we don't want the password be visible in the query string
      window.location.protocol + '//' + window.location.host + "/ws_customer/customer",
      values,
      {
        headers: {
          'X-CSRF-Token': formAuthenticityToken,
          'integraglobal-access-token': accessToken  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
        }
      }
    )
    .then(response => {
      if (response.data.success) {
        setEditing(false);
        setCustomer(response.data.customer);
      } else {
        setError(response.data.message);
        setSubmitting(false);
      }
    })
    .catch(error => {
      setError(error);
      setSubmitting(false);
    });

  }



  const validationSchema = Yup.object().shape({
    email_address: Yup.string().email('The email address you provided has an invalid format.').required('Please provide your email address.'),
    firstname: Yup.string().required('Please provide a firstname.'),
    lastname: Yup.string().required('Please provide a lastname.'),
    address_line_1: Yup.string().required('Please provide an address.'),
    postal_code: Yup.string().required('Please provide a postal code.'),
    city: Yup.string().required('Please provide a city.'),
    country_of_residence_outside_US: Yup.string().required('Please provide a country of residence.'),
  });



  return (
    <Container fluid className="customer-forms">
      <h1>PERSONAL DETAILS</h1>


        <Formik
          enableReinitialize={true}
          initialValues={{
            id:             customer?.id ?? 0,
            email_address:  customer?.email_address ?? '',
            password:       customer?.password ?? '',
            firstname:      customer?.firstname ?? '',
            lastname:       customer?.lastname ?? '',
            address_line_1: customer?.address_line_1 ?? '',
            address_line_2: customer?.address_line_2 ?? '',
            postal_code:    customer?.postal_code ?? '',
            city:           customer?.city ?? '',
            country_of_residence_outside_US: customer?.country_of_residence_outside_US ?? ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            submitCount,
            getFieldProps,
            validateField,
            setFieldError,
            setFieldTouched,

            handleChange,
            handleBlur
          }) => (

            <Form onSubmit={handleSubmit} noValidate >

              <Form.Control type="text" hidden {...getFieldProps('id')} />

              {error &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h3>We are sorry.</h3>
                  <h1>{error}</h1>
                </Jumbotron>
              }

              <p>You can update your personal details by editing the fields below.</p>

              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    type="text"
                    placeholder="Email*"
                    required
                    isInvalid={errors.email_address && touched.email_address}
                    {...getFieldProps('email_address')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email_address}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                  <Form.Group as={Col} md="6">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                      isInvalid={errors.password && touched.password}
                      {...getFieldProps('password')}
                    />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Form.Group>
              </Form.Row>


              <Form.Row>
                <Form.Group as={Col}  md="6">
                    <Form.Control
                      type="text"
                      placeholder="Firstname*"
                      required
                      isInvalid={errors.firstname && touched.firstname}
                      {...getFieldProps('firstname')}
                    />
                  <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    type="text"
                    placeholder="Lastname*"
                    required
                    isInvalid={errors.lastname && touched.lastname}
                    {...getFieldProps('lastname')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>


              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    type="text"
                    placeholder="Address Line 1*"
                    required
                    isInvalid={errors.address_line_1 && touched.address_line_1}
                    {...getFieldProps('address_line_1')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.address_line_1}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    type="text"
                    placeholder="Address Line 2"
                    {...getFieldProps('address_line_2')}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} className="" md="6">
                  <Form.Control
                    type="text"
                    placeholder="Postal Code*"
                    required
                    isInvalid={errors.postal_code && touched.postal_code}
                    {...getFieldProps('postal_code')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.postal_code}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    type="text"
                    placeholder="City*"
                    required
                    isInvalid={errors.city && touched.city}
                    {...getFieldProps('city')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" controlId="formGroupCountryOfResidenceOutsideUS">
                  <CountrySelect
                    fieldName="country_of_residence_outside_US"
                    placeholder="Country of Residence*"
                    value={values.country_of_residence_outside_US}
                    isInvalid={errors.country_of_residence_outside_US && touched.country_of_residence_outside_US}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    exclude={['HK']}
                  >
                    <Form.Control.Feedback type="invalid">Please provide your country of residence outside the US.</Form.Control.Feedback>
                  </CountrySelect>
                </Form.Group>
              </Form.Row>

              <Form.Row className="mb-3">
               <Col sm="12" md="3">
                  <div className="mb-3 mb-md-0 pr-md-5">
                    <Button className="w-100" onClick={() => setEditing(false)} variant="link" >Cancel</Button>
                  </div>
                </Col>
                <Col sm="12" md="3">
                  <div className="pl-md-5">
                    <Button className="w-100" type="submit" variant="primary" >SAVE</Button>
                  </div>
                </Col>
              </Form.Row>


              {Object.values(errors).length > 0 && submitCount > 0 &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h1>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h1>
                </Jumbotron>
              }
            </Form>
            )}
        </Formik>


    </Container>
  );
}