// Lists of Items:
// https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
//
import React from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
//import { EditTwoToneIcon } from '@material-ui/icons';

export default function IndividualPlanRow(props) {

  const onClick = event => {
    props.onEditClick(props.row)
  };

  return(
    <tr key={props.row.id}>
    {/*}
      <td><EditTwoToneIcon fontSize="small" onClick={onClick} /></td>
    */}
      <td><span style={{fontWeight: 400}}>{props.row.name}</span></td>
      <td>{props.row.il_next_individual_plan?.name}</td>
      <td>{props.row.il_underwriter?.name}</td>
      <td>{props.row.region}</td>
      <td className="date">{format(new Date(props.row.start_date), 'd. LLL yyyy')}</td>
      <td className="date">{format(new Date(props.row.end_date), 'd. LLL yyyy')}</td>
{/*      <td>{props.row.name}</td>*/}
{/*      <td>{props.row.next_plan_name}</td>
      <td>{props.row.plan_type}<br />{props.row.level}</td>
*/}
{/*      <td>{props.row.level}</td>*/}
{/*
      <td>{props.row.category}</td>
      <td>{props.row.adjustment_tier ? 'Yes' : 'No'}</td>
      <td>{props.row.gross_commission ? props.row.gross_commission + '%' : ''}</td>
      <td>
        <NumberFormat value={props.row.maximum_lifetime_plan_benefit_usd} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
        <NumberFormat value={props.row.maximum_lifetime_plan_benefit_eur} displayType={'text'} thousandSeparator={true} prefix={'€'} /><br />
        <NumberFormat value={props.row.maximum_lifetime_plan_benefit_gbp} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br />
        <NumberFormat value={props.row.maximum_lifetime_plan_benefit_aed} displayType={'text'} thousandSeparator={true} prefix={'د.إ'} />
      </td>
      <td>
        <NumberFormat value={props.row.annual_lifetime_plan_benefit_usd} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
        <NumberFormat value={props.row.annual_lifetime_plan_benefit_eur} displayType={'text'} thousandSeparator={true} prefix={'€'} /><br />
        <NumberFormat value={props.row.annual_lifetime_plan_benefit_gbp} displayType={'text'} thousandSeparator={true} prefix={'£'} /><br />
        <NumberFormat value={props.row.annual_lifetime_plan_benefit_aed} displayType={'text'} thousandSeparator={true} prefix={'د.إ'} />
      </td>
      <td>
        {props.row.out_of_pocket_limit_usd}<br />
        {props.row.out_of_pocket_limit_eur}<br />
        {props.row.out_of_pocket_limit_gbp}<br />
        {props.row.out_of_pocket_limit_aed}
      </td>
*/}
{/*      <td>{props.row.id}</td>*/}

    </tr>
  );
}
