// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect, useRef } from "react"
import {Redirect} from "react-router-dom"

// The useHistory hook gives you access to the history instance that you may use to navigate.
import { useHistory } from "react-router-dom"

import { MEDICAL_CONDITIONS, isCriticalBMI } from '../../exports'
import { saveEnrolmentInsured } from '../../insured'

import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { OverlayTrigger, Popover, Button, Switch } from 'react-bootstrap'
import Jumbotron from 'react-bootstrap/Jumbotron'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addDays } from '@progress/kendo-date-math'

import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'

import EnrolmentInsuredConditions from './EnrolmentInsuredConditions'
import EnrolmentInsuredConditionsText from './EnrolmentInsuredConditionsText'
import EnrolmentInsuredConditionsPregnancy from './EnrolmentInsuredConditionsPregnancy'

export default function EnrolmentMainQuestions({
  primary,
  setPrimary,
  dependents,
  setDependents,
  positiveAnswerToMedicalQuestions,
  setPositiveAnswerToMedicalQuestions,
  medicalConditions,
  setMedicalConditions,
  commonPlanOptions,
  setCommonPlanOptions,
  validated,
  setValidated
}) {
  const topLocation = useRef(null);
  let history = useHistory();

  const [errors, setErrors] = React.useState({});

  if (! validated.primary) return <Redirect to="/ig/enrolment/primary" />

  const numberOfPossiblePregnancies = () => {
    return dependents.reduce( (accumulator, dependent) => {
      return accumulator + (dependent.relationship == 'partner' && dependent.sex != 'male' ? 1 : 0);
    }, primary.sex != 'male' ? 1 : 0);
  };


  const handleChange = (event) => {
    // if (['medical_question_one', 'medical_question_two', 'medical_question_three', 'medical_question_four', 'medical_question_five',].includes(event.target.name)) {
    //   var value = event.target.checked;
    // } else {
    //   var value = event.target.value;
    // }
    const value = event.target.checked;
    const name = event.target.name;

    setPositiveAnswerToMedicalQuestions({
      ...positiveAnswerToMedicalQuestions,
      [name]: value
    });

    if (value == false) {
      if (name == 'answer_1') {
        primary.medical_conditions = []
        dependents.forEach(dep => dep.medical_conditions = [])
      } else {
        primary[name] = '';
        dependents.forEach(dep => dep[name] = '')
      }
    }
  };





  const handleContinue = (event) => {
    let newErrors = {};
    let invalid = false;

    // if (positiveAnswerToMedicalQuestions.answer_1 == true) {
    //   let empty = true;
    //   if (primary.medical_conditions && Object.keys(primary.medical_conditions).length > 0) {
    //     empty = false;
    //   }
    //   dependents.forEach(dependent => {
    //     if (dependent.medical_conditions && Object.keys(dependent.medical_conditions).length > 0) {
    //       empty = false;
    //     }
    //   })
    //   invalid = empty;
    // }

    //! (primary.medical_conditions && Object.keys(primary.medical_conditions).length > 0)

    if (positiveAnswerToMedicalQuestions.answer_1 == true) {
      newErrors.answer_1 = (! primary.medical_conditions || Object.keys(primary.medical_conditions).length < 1)  // no value for primary
                            &&
                            dependents.reduce(  // no value for all dependents
                              (accu, dependent) => {
                                return accu && (! dependent.medical_conditions || Object.keys(dependent.medical_conditions).length < 1)
                              },
                              true
                            );
      invalid = invalid || newErrors.answer_1;
    }

    invalid = [2, 3, 4, 5].map(i => 'answer_' + i).reduce(
      (accu, answer) => {
        newErrors[answer] = false;
        if (positiveAnswerToMedicalQuestions[answer] == true) {
          if (
               ! primary[answer]
               &&
               ! dependents.reduce(  // is there an answer text for at least dependent?
                 (accu, dependent) => {
                   return accu || (dependent[answer] && dependent[answer].trim() !== '')
                 },
                 false
               )
             ) {
            newErrors[answer] = true;
          }
        }
        return accu || newErrors[answer]
      },
      invalid
    );

    newErrors.answer_6 = false;
    if (
         (primary.pregnant === undefined && primary.sex != 'male') ||
         dependents.reduce(  // no value for all dependents
           (accu, dependent) => {
             return accu || (dependent.pregnant === undefined && dependent.relationship == 'partner' && dependent.sex != 'male')
           },
           false
         )
       ) {
      newErrors.answer_6 = true;
    }
    invalid = invalid || newErrors.answer_6;

    setErrors(newErrors);

    setValidated({...validated, main_questions: !invalid});

    if (! invalid) {
      setMedicalStatus();
      //save
      saveEnrolmentInsured(primary);
      dependents.forEach(dependent => {
        saveEnrolmentInsured(dependent);
      })
      history.push("/ig/enrolment/plan_options");
    } else {
      if (topLocation.current) {
        topLocation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  };


  const setMedicalStatus = () => {
    // cleanup, just to make sure and for inconsistent test data
    if (positiveAnswerToMedicalQuestions.answer_1) {
      if (primary.medical_conditions && primary.medical_conditions.length > 0) {
        primary.medical_status = 'medical_underwriting';
      } else {
        primary.medical_status ='medically_approved';
      }
      dependents.forEach(dep => {
        if (dep.medical_conditions && dep.medical_conditions.length > 0) {
          dep.medical_status = 'medical_underwriting';
        } else {
          dep.medical_status = 'medically_approved';
        }
      })
    } else {
      primary.medical_conditions = [];
      primary.medical_status ='medically_approved';
      dependents.forEach(dep => {
        dep.medical_conditions = [];
        dep.medical_status = 'medically_approved';
      })
    }
    ['answer_2', 'answer_3', 'answer_4','answer_5'].forEach((answer) => {
      if (positiveAnswerToMedicalQuestions[answer]) {
        if (primary[answer] && primary[answer].trim() !== '') {
          primary.medical_status = 'medical_underwriting';
        }
        dependents.forEach(dep => {
          if (dep[answer] && dep[answer].trim() !== '') {
            dep.medical_status = 'medical_underwriting';
          }
        })
      }
      // else {
      //   primary[answer] = '';
      //   primary.medical_status = 'medically_approved';
      //   dependents.forEach(dep => {
      //     dep[answer] = '';
      //     dep.medical_status = 'medically_approved';
      //   })
      // }
    })

    if (primary.pregnant || isCriticalBMI(primary.bmi)) {
      primary.medical_status = 'medical_underwriting';
    }
    dependents.forEach(dep => {
      if (dep.pregnant || isCriticalBMI(dep.bmi)) {
        dep.medical_status = 'medical_underwriting';
      }
    })
  }


  const popover = (
    <Popover>
      <Popover.Title as="h3">Routine Check-Up</Popover.Title>
      <Popover.Content>
        Routine check-up is defined as a
        physical exam and/or blood tests that are of a routine nature
        and not used for the purpose of treating a medical condition and
        nor which any medical diagnosis has been made.
      </Popover.Content>
    </Popover>
  );

  // Medical conditions treated or diagnosed
  // Planned medical events
  // Symptoms last 12 months
  // Medication
  // Pregnancy
  // Previous insurance issues

  return (
    <Form>
      {Object.values(errors).length > 0 &&
        <Jumbotron className="">
          <h3>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h3>
        </Jumbotron>
      }
      <h2 ref={topLocation} className="mt-4">And now {numberOfPossiblePregnancies() > 0 ? 6 : 5} easy questions about your health</h2>

      <Container style={{maxWidth: 'unset', padding: 0}}>


        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)'}}>
          <Row noGutters>
            <Col>
              <h3>1
                {errors.answer_1 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
                }
              </h3>
              <div>
              Have you or any of your dependants to be covered
              on this policy been treated or diagnosed for a medical condition
              in the last 15 years for anything other than colds, flu,
              or <OverlayTrigger overlay={popover}><span style={{textDecoration: 'underline'}}>routine check-up</span></OverlayTrigger>? </div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3">
            <Form.Group as={Col} className="">
              <Form.Check
                id="medical-question-one-switch"
                type='switch'
              >
                <FormCheck.Input
                  style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                  name="answer_1"
                  checked={positiveAnswerToMedicalQuestions.answer_1}
                  onChange={handleChange}
                />
                  <FormCheck.Label>
                    <span className="slider-button-label">
                      {positiveAnswerToMedicalQuestions.answer_1
                        ? 'YES'
                        : 'NO'
                      }
                    </span>
                  </FormCheck.Label>
              </Form.Check>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_1 == true &&
                <EnrolmentInsuredConditions
                  primary={primary}
                  setPrimary={setPrimary}
                  dependents={dependents}
                  setDependents={setDependents}
                />
              }
            </Col>
          </Row>
        </div>





        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
              <h3>2
                {errors.answer_2 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
                }
                </h3>
              <div>Are any inpatient or outpatient medical/surgical
              or dental procedures or oral surgery (including diagnostic
              testing) recommended/contemplated for you or your dependants
              to be covered on this policy?</div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3">
            <Form.Group as={Col}>
              <Form.Check
                id="medical-question-two-switch"
                type='switch'
              >
                <FormCheck.Input
                  style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                  name="answer_2"
                  checked={positiveAnswerToMedicalQuestions.answer_2}
                  onChange={handleChange}
                />
                  <FormCheck.Label>
                    <span className="slider-button-label">
                      {positiveAnswerToMedicalQuestions.answer_2
                        ? 'YES'
                        : 'NO'
                      }
                    </span>
                  </FormCheck.Label>
              </Form.Check>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_2 == true &&
                <EnrolmentInsuredConditionsText
                  answer_name='answer_2'
                  primary={primary}
                  setPrimary={setPrimary}
                  dependents={dependents}
                  setDependents={setDependents}
                />
              }
            </Col>
          </Row>

        </div>


        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
              <h3>3
                {errors.answer_3 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
                }
              </h3>
              <div>Have you or any of your dependants to be covered
              on this policy experienced any symptoms of any medical problem
              in the last 12 months, regardless of whether a healthcare
              professional has been consulted?</div>
            </Col>
          </Row>
          <Row noGutters className="text-center mt-3">
            <Form.Group as={Col}>
              <Form.Check
                id="medical-question-three-switch"
                type='switch'
              >
                <FormCheck.Input
                  style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                  name="answer_3"
                  checked={positiveAnswerToMedicalQuestions.answer_3}
                  onChange={handleChange}
                />
                  <FormCheck.Label>
                    <span className="slider-button-label">
                      {positiveAnswerToMedicalQuestions.answer_3
                        ? 'YES'
                        : 'NO'
                      }
                    </span>
                  </FormCheck.Label>
              </Form.Check>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_3 == true &&
                <EnrolmentInsuredConditionsText
                  answer_name='answer_3'
                  primary={primary}
                  setPrimary={setPrimary}
                  dependents={dependents}
                  setDependents={setDependents}
                />
              }
            </Col>
          </Row>


        </div>


        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
            <h3>4
              {errors.answer_4 &&
                <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
              }
            </h3>

            <div>Are you or any of your dependants to be covered
            on this policy currently taking any medication?</div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3">
              <Form.Group as={Col}>
                <Form.Check
                  id="medical-question-four-switch"
                  type='switch'
                >
                  <FormCheck.Input
                    style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                    name="answer_4"
                    checked={positiveAnswerToMedicalQuestions.answer_4}
                    onChange={handleChange}
                  />
                    <FormCheck.Label>
                      <span className="slider-button-label">
                        {positiveAnswerToMedicalQuestions.answer_4
                          ? 'YES'
                          : 'NO'
                        }
                      </span>
                    </FormCheck.Label>
                </Form.Check>
              </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_4 == true &&
                <EnrolmentInsuredConditionsText
                  answer_name='answer_4'
                  primary={primary}
                  setPrimary={setPrimary}
                  dependents={dependents}
                  setDependents={setDependents}
                />
              }
            </Col>
          </Row>

        </div>

        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
            <h3>5
              {errors.answer_5 &&
                <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
              }
            </h3>
            <div>Have you ever or any of your dependants to be
            covered on this policy had an application or an application for
            reinstatement of Life, Accident, or Health Insurance declined,
            postponed, rated or modified?</div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3">
              <Form.Group as={Col}>
                <Form.Check
                  id="medical-question-five-switch"
                  type='switch'
                >
                  <FormCheck.Input
                    style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                    name="answer_5"
                    checked={positiveAnswerToMedicalQuestions.answer_5}
                    onChange={handleChange}
                  />
                    <FormCheck.Label>
                      <span className="slider-button-label">
                        {positiveAnswerToMedicalQuestions.answer_5
                          ? 'YES'
                          : 'NO'
                        }
                      </span>
                    </FormCheck.Label>
                </Form.Check>
              </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_5 == true &&
                <EnrolmentInsuredConditionsText
                  answer_name='answer_5'
                  primary={primary}
                  setPrimary={setPrimary}
                  dependents={dependents}
                  setDependents={setDependents}
                />
              }
            </Col>
          </Row>

        </div>




        {numberOfPossiblePregnancies() > 0 &&
        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
              <h3>6
                {errors.answer_6 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please specify if pregnant.</span>
                }
              </h3>

              <EnrolmentInsuredConditionsPregnancy
                primary={primary}
                setPrimary={setPrimary}
                dependents={dependents}
                setDependents={setDependents}
              />
          </Col>
          </Row>
        </div>
        }
      </Container>

      <Container className="p-0 m-0 mt-5">
        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters className="align-items-center">
            <Col>
              <h3>When do you want to start your cover?</h3>
            </Col>
            <Col className="text-right">
              <DatePicker
                selected={commonPlanOptions.preferred_start_date}
                dateFormat="yyyy-MM-dd"
                minDate={new Date()}
                maxDate={addDays(new Date(), 30)}
                placeholderText="Preferred start date*"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date) => {
                  let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
                  setCommonPlanOptions({
                    ...commonPlanOptions,
                    'preferred_start_date': localDate
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>

      <Row className="text-center">
        <Col className="mt-4 mb-5" style={{}} >
          <Button variant="secondary" type="button" onClick={handleContinue}>CONTINUE</Button>
          {/*
          <Button type="button" onClick={() => history.push("/ig/enrolment/insured_conditions")} style={{marginRight: '1em'}}>YES</Button>
          <Button type="button" onClick={() => history.push("/ig/enrolment/plan_options")}>NO</Button>
          */}
        </Col>
      </Row>
    </Form>
  )
}