import axios from "axios";

export const saveEnrolmentInsured = (insured) => {
  //console.log(primary);
  //const method = primary.id ? 'put' : 'post';
  axios({
    method: 'put',
    url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + insured.id,
    data: insured
  }).then(response => {
    //console.log(response);
      // setDependents(dependents.map( dep => {
      //    if (dep.id != dependent.id) return dep;
      //    return {
      //      ...dependent,
      //      id: response.data.id
      //    };
      // }));
    })
    .catch(error => {
      if (error.response) {
        if (error.response.status == 401) {
          alert('permission denied');
        } else {

        }
      } else {
        throw error;
      }

    });
};

export const savePrimary = () => {
  //console.log(primary);
  //const method = primary.id ? 'put' : 'post';
  axios({
    method: 'put',
    url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + primary.id,
    data: primary
  }).then(response => {
      //console.log(response);
      // setPrimary({
      //   ...primary,
      //   id: response.data.id
      // });
    })
    .catch(error => {
      if (error.response) {
        if (error.response.status == 401) {
          alert('permission denied');
        } else {

        }
      } else {
        throw error;
      }

    });
};



export const saveDependent = (dependent) => {
    //console.log(primary);
    //const method = primary.id ? 'put' : 'post';
    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + dependent.id,
      data: dependent
    }).then(response => {
      //console.log(response);
        // setDependents(dependents.map( dep => {
        //    if (dep.id != dependent.id) return dep;
        //    return {
        //      ...dependent,
        //      id: response.data.id
        //    };
        // }));
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {

          }
        } else {
          throw error;
        }

      });
  };