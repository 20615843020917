// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { useEffect, useState, useRef } from 'react'
import axios from "axios"

import { useSelector, useDispatch } from 'react-redux'
import { removeIlRenewalTriggerDuration } from '../redux/ilRenewalTriggerDurationsSlice'

import * as yup from 'yup'
import { Formik } from 'formik'

import {Form, Row, Col, Button, InputGroup, FormControl, Modal} from 'react-bootstrap'
import Jumbotron from 'react-bootstrap/Jumbotron'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

export default function RenewalTriggerDurationDeleteModal({
  renewalTriggerDuration,
  show,
  setShow
}) {
  const dispatch = useDispatch();
  const ilUsers = useSelector(state => state.ilUsers);
  const [error, setError] = React.useState('');

  const handleDelete = () => {

    const deleteData = async () => {
      try {
        const response = await axios({
          method: 'delete',
          url: window.location.protocol + '//' + window.location.host + "/il_ws/il_renewal_trigger_durations/" + renewalTriggerDuration.id,
          data: {
            il_renewal_trigger_duration: renewalTriggerDuration,
            headers: {
              'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
            }
          }
        });

        if (response.data.success) {
          setError(null);
          dispatch(removeIlRenewalTriggerDuration({
            item: response.data.il_renewal_trigger_duration
          }));
          setShow(false);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        //console.log(error.config);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          // console.log(error.response);
          // console.log(error.toJSON());
          if (error.response.status == 401) {
            dispatch(logout());
          }
          setError(error.toJSON().message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          setError('The server is not responding. Please check your Internet connection.');
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(error.message);
        }
      }
    };
    deleteData();
  };


  return (
      <Modal show={show} onHide={() => setShow(false)}>
        {error &&
          <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)', backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
            <h3>Sorry! Something went wrong.</h3>
            <h1>{error}</h1>
          </Jumbotron>
        }
          <Modal.Header closeButton>
            <Modal.Title><FontAwesomeIcon icon={faTrashAlt} /> Renewal Trigger Duration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
                <Col sm={3}>Underwriter</Col>
                <Col sm={9}>{renewalTriggerDuration?.il_underwriter?.name}</Col>
            </Row>
            <Row>
                <Col sm={3}>Plan Region</Col>
                <Col sm={9}>{renewalTriggerDuration?.il_plan_region?.name}</Col>
            </Row>
            <Row>
                <Col sm={3}>Weeks</Col>
                <Col sm={9}>{renewalTriggerDuration?.weeks}</Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>Cancel</Button>
            <Button varoant="primary" type="submit" onClick={handleDelete}>Delete</Button>
          </Modal.Footer>
      </Modal>
  );
}