// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect} from "react"
import axios from "axios";
import { nanoid } from 'nanoid';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';


import CountrySelect            from '../country/CountrySelect';
import {COUNTRIES}              from '../country/countries';
import CountryCallingCodeSelect from '../country/CountryCallingCodeSelect';

import QuoteFormCountrySelect from './quote/QuoteFormCountrySelect';

export default function EmployersEnergyTeamQuote({
  authenticity_token,
  site,
  subsite
  }) {

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  const [showQuoteForm, setShowQuoteForm] = React.useState(false);

  //const [message, setMessage] = React.useState({fullname: '', from_address: '', message_text: ''});
  const [validated, setValidated] = React.useState(false);
  const [quoteRequested, setQuoteRequested] = React.useState(false);

  const initialQuote = {
    company_name: '',
    firstname: '',
    lastname: '',
    email_address: '',
    mobile_phone: '',
    country_calling_code: '',
    location_of_staff: '',
    number_of_employees: '',
    cover_for_dependents: false,
    special_request: '',
    newsletter_agreement: false,
    privacy_policy_agreement: false
  };

  const [quote, setQuote] = React.useState(initialQuote);

  //---------------------------------------------------------------------------
  const handleChange = (event) => {
    let { name, value, type } = event.currentTarget;

    if (['privacy_policy_agreement', 'cover_for_dependents', 'newsletter_agreement'].includes(name)) {
      value = event.target.checked;
    }
    setQuote({
      ...quote,
      [name]: value
    });

    // setMessage({
    //   ...message,
    //   [event.target.name]: event.target.value
    // });
  };


  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (typeof validation[name] !== 'function') return;
    const { [name]: removedError, ...rest } = errors;
    const error = validation[name](value);
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error })
    });
  };



  //---------------------------------------------------------------------------
  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();


    let numberOfErrors = validateForm();

    //if (Object.values(formValidation.errors).length > 0) {// errors object is not empty
    if (numberOfErrors > 0) {
      setValidated(false);
    } else {
      const form = event.currentTarget;
      axios({
        method: 'post',
        url: window.location.protocol + '//' + window.location.host + "/ws/employers/request-a-quote",
        data: quote
      }).then(response => {

        // handle success
        if (response.status != 200 || response.data.status != 'ok') {
          //setErrors(response.data.errors);
          setQuoteRequested(false);
        } else {
          //setErrors({});
          setQuoteRequested(true);
        }
      })
      .catch(function (response) {
        setQuoteRequested(false);
        // handle error
        // setPositiveServerResponse(false);
      });

      setValidated(true);
    }
  };







  const nameValidation = (fieldName, fieldValue) => {
    if (! fieldValue || fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    // if (/[^a-zA-Z -]/.test(fieldValue)) {
    //   return 'Invalid characters';
    // }
    // if (fieldValue.trim().length < 3) {
    //   return `${fieldName} needs to be at least three characters`;
    // }
    return null;
  };

  const numberValidation = (fieldName, fieldValue) => {
    if (typeof fieldValue == "string" && fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (! isNaN(fieldValue)) {
      return null;
    }
    return `${fieldName} needs to be at valid number`;
  };


  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };


  const privacyPolicyValidation = (value) => {
    if (value == false) {
      return 'Privacy policy must be accepted';
    }
    return null;
  };




  const validation = {
    company_name:                    name => nameValidation('Company name', name),
    firstname:                       name => nameValidation('Firstname', name),
    lastname:                        name => nameValidation('Lastname', name),
    //country_calling_code:            phone => nameValidation('Calling code', phone),
    //mobile_phone:                    name => nameValidation('Mobile phone', name),
    email_address:                   emailValidation,
    location_of_staff:               name => nameValidation('Location of staff', name),
    number_of_employees:             name => nameValidation('Number of employees', name),
    privacy_policy_agreement:        privacyPolicyValidation
  };






  const validateForm = () => {
    let numberOfErrors = 0;

    // Primary
    const formValidation = Object.keys(validation).reduce(
      (acc, key) => {
        const newError = validation[key](quote[key]);
        const newTouched = { [key]: true };

        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          }
        };

      },
      {
        //errors: { ...errors },
        errors: {},
        //touched: { ...touched }
        touched: {}
      }
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);
    numberOfErrors += Object.values(formValidation.errors).length;
    return numberOfErrors;
  }

  const handleGetAnotherQuote = () => {
    setQuoteRequested(false);
    setShowQuoteForm(true)
    setQuote(initialQuote);
  }

  // const Title = ({errors}) => {
  //   if (errors.length < 1) return null;
  //   // const listItems = errors.map((error) =>
  //   //   <li>{error}</li>
  //   // );

  //   return (
  //     <div>
  //     <h1>Thank You</h1>
  //     <p>We received your message successfully, but there might be something wrong.  Please check the values provided and submit your message again.</p>
  //     <ul className="errors">
  //     {errors.map((error) => <li>{error}</li>)}
  //     </ul>
  //     </div>
  //   )
  // };


  // const ErrorMessage = ({errors}) => {
  //   if (errors.length < 1) {
  //     return (
  //       <h4>Email us</h4>
  //     )
  //   }

  //   return (
  //     <div>
  //     <h1>Thank You</h1>
  //     <p>We received your message, but there might be something wrong.  Please check the values provided and submit your message again.</p>
  //     <ul className="errors">
  //     {errors.map((error, index) => <li key={nanoid()}>{error}</li>)}
  //     </ul>
  //     </div>
  //   )
  // };

  if (showQuoteForm == false) {
    return (
      <>
        <div className="white-space"></div>
        <div className="text-center"><Button className="btn btn-primary btn-lg" style={{color: '#f1f1f1'}} onClick={() => setShowQuoteForm(true)}>GET QUOTE</Button></div>
      </>
    )
  }

  if (quoteRequested == true) {
    return (
      <div className="mt-5 mb-5 text-center">
        <h1>Thank You</h1>
        <p>Your quote request has been successfully sent to Integra Global Ltd.</p>
        <Button className="btn btn-primary btn-lg" style={{color: '#f1f1f1'}} onClick={handleGetAnotherQuote}>GET ANOTHER QUOTE</Button>
      </div>
    )
  } else {
    return (
      <>

      <h2 className="mt-4 text-center">GET A TEAM QUOTE</h2>



      <Form
        action='/ws/employers/request-a-quote'
        className="energy-quote-form"
        noValidate

        onSubmit={handleSubmit}
        method="POST"
      >
        <Form.Text className="text-center">Fields marked with an <span>*</span> are required</Form.Text>
      	<div className="white-space"></div>


        <Form.Group as={Row} controlId="formGroupCompanyName">
          <Col sm="12">
            <Form.Control
              type="text"
              name="company_name"
              placeholder="Company Name*"
              value={quote.company_name}
              isInvalid={errors.company_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide the name of your company.</Form.Control.Feedback>
          </Col>
        </Form.Group>



        <Form.Group as={Row} controlId="formGroupFirstName">
          <Col sm="12">
            <Form.Control
              type="text"
              name="firstname"
              placeholder="Firstname*"
              value={quote.firstname}
              isInvalid={errors.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupLastName">
          <Col sm="12">
            <Form.Control
              type="text"
              name="lastname"
              placeholder="Lastname*"
              value={quote.lastname}
              isInvalid={errors.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGroupCountryCallingCode">
          <Col className="country-code-debug" md="12" lg="4">
            <CountryCallingCodeSelect
              className={errors.country_calling_code ? 'is-invalid' : ''}
              name="country_calling_code"
              validated={validated}
              placeholder="Country code"
              value={quote.country_calling_code}
              isInvalid={errors.country_calling_code}
              onChange={(option) => {
                setQuote({
                  ...quote,
                  country_calling_code: option.value
                })
                if (typeof validation['country_calling_code'] !== 'function') return;
                const { ['country_calling_code']: removedError, ...rest } = errors;
                const error = validation['country_calling_code'](option.value);
                setErrors({
                  ...rest,
                  ...(error && { ['country_calling_code']: touched['country_calling_code'] && error })
                });
              }}
              options={COUNTRIES.map((country) => {
                return {value: country.calling_code, label: country.name + ' (+' + country.calling_code + ')'};
              })}
            />

            {errors.country_calling_code &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>Please select your country calling code.</div>
            }

          </Col>
          <Col md="12" lg="8">
            <Form.Control
              type="text"
              name="mobile_phone"
              placeholder="Mobile phone"
              value={quote.mobile_phone}
              isInvalid={errors.mobile_phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your phone number.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupEmail">
          <Col sm="12">
            <Form.Control
              type="text"
              name="email_address"
              placeholder="Email*"
              value={quote.email_address}
              isInvalid={errors.email_address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your email address.</Form.Control.Feedback>
          </Col>
        </Form.Group>



          <Form.Group as={Row} controlId="formGroupStaff">
            <Col sm="6">
              <Form.Label srOnly>Location of Staff*</Form.Label>
              <QuoteFormCountrySelect
                className="custom-select location-of-staff"
                fieldName="location_of_staff"
                validated={validated}
                placeholder="Location of Staff*"
                value={quote.location_of_staff}
                isInvalid={errors.location_of_staff}
                onChange={handleChange}
                onBlur={handleBlur}
                exclude={['HK']}
              />
              <Form.Control.Feedback type="invalid" tooltip={false}>Please select a country.</Form.Control.Feedback>
            </Col>
            <Col sm="6">
              <Form.Control
                type="text"
                name="number_of_employees"
                placeholder="Number of Employees*"
                value={quote.number_of_employees}
                isInvalid={errors.number_of_employees}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid" tooltip={false}>Please provide the number of employees.</Form.Control.Feedback>
            </Col>

          </Form.Group>


          <Form.Group controlId="coverForDependents">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={quote.cover_for_dependents === true}
                value="true"
                onChange={handleChange}
                name="cover_for_dependents"
              />
              <Form.Check.Label>
                Cover for dependents
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>


          <Form.Group  as={Row} controlId="formGroupMessageText">
            <Col>
              <Form.Label srOnly>
                Special requests
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="special_request"
                placeholder="Special requests"
                onChange={handleChange}
                value={quote.special_request}
              />
            </Col>
          </Form.Group>



          <Form.Group controlId="newsletterAgreement">
            <Form.Check
              type="checkbox"
              name="newsletter_agreement"
              id="checkbox-keep-me-informed"
              label="Sign up to receive our latest news."
              onChange={handleChange}
              value="true"
              isInvalid={errors.newsletter_agreement}
              checked={quote.newsletter_agreement === true}
            />
            <Form.Text>Please tick if you would like us, and other members of the Integra Global and our appointed agents, to keep you updated about our products and services. Please note that you will be able to opt out of receiving these communications at any time.</Form.Text>
          </Form.Group>


          <Form.Group controlId="privacyPolicyAgreement">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={quote.privacy_policy_agreement === true}
                isInvalid={errors.privacy_policy_agreement}
                value="true"
                onChange={handleChange}
                name="privacy_policy_agreement"
              />
              <Form.Check.Label>
                I agree to the terms outlined in the privacy policy.
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid" tooltip={true} >
                Sorry. We are unable to give you an indicative price unless you agree to our
                <a href="/ig/privacy_policy">privacy policy</a>.
              </Form.Control.Feedback>
            </Form.Check>
            <Form.Text>By progressing to get an
                indicative price you agree that we or our appointed agents may contact you to
                discuss your quote and your needs, and to our using your personal information
                in accordance with our <a href="/ig/privacy_policy">Privacy Policy</a>.</Form.Text>
          </Form.Group>
	  <div className="white-space"></div>
         <div className="text-center"><Button type="submit">Send</Button></div>
      </Form>
      </>
    )
  }
}