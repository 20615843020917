import React, { forwardRef } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { normalize, schema } from 'normalizr';

import IndividualPlansTable from "./IndividualPlansTable"
import IndividualPlanForm from "./IndividualPlanForm";


export default function IndividualPlans() {
  let history = useHistory();

  // Declare a new state variable, which we'll call "rows"
  const [rows, setRows] = React.useState([]);

  const [wsError, setWsError] = React.useState(undefined);
  const [showIndividualPlanForm, setShowIndividualPlanForm] = React.useState(false);
  const [currentIndividualPlan, setCurrentIndividualPlan] = React.useState(0);

  // // Similar to componentDidMount and componentDidUpdate:
  // React.useEffect(() => {

  //   const host = window.location.protocol + '//' + window.location.host
  //   axios
  //     .get( // zwecks visibility of query string in URL
  //       host + "/ws/individual_plans.json",
  //       {
  //       },
  //       { withCredentials: true }
  //     )
  //     .then(response => {
  //       // let myData = { count: 2, users: [{ id: 1 }, { id: 2 }] };
  //       // const user = new schema.Entity('users');
  //       // let mySchema = { users: [user] };
  //       // const normalizedData = normalize(myData, mySchema);

  //       // myData = response.data;
  //       // const individual_plan = new schema.Entity('individual_plans');
  //       // mySchema = {individual_plans: new schema.Array(individual_plan)};
  //       // const normalizedIndividualPlans = normalize(myData, mySchema);


  //       //myData = response.data;
  //       //const individual_plan = new schema.Entity('individual_plans');
  //       //mySchema = { individual_plans: [individual_plan]};
  //       //const normalizedIndividualPlans = normalize(myData, mySchema);
  //       //setRows(normalizedIndividualPlans);

  //       setRows(response.data.individual_plans);
  //     })
  //     .catch(error => {

  //       if (error.response.status == 401) {
  //         history.push("/v7/mis");
  //       } else {

  //         setWsError(error.response.data.message);
  //       }
  //     });
  //   },
  //   [] // If you want to run an effect and clean it up only once (on mount and unmount), you can pass an empty array ([])
  //      // as a second argument. This tells React that your effect doesn’t depend on any values from props or state, so
  //      // it never needs to re-run.
  // );



  let onEditClick = (row) => {
    setCurrentIndividualPlan(row);
    setShowIndividualPlanForm(true);
  }
  let onSuccessfulSave = (value) => {

    setShowIndividualPlanForm(false);
  }

//    <div>
//      { /*showIndividualPlanForm ? <IndividualPlanForm onSuccessfulSave={onSuccessfulSave} /> : <IndividualPlansTable rows={rows} onEditClick={onEditClick} />*/
//      }
//    </div>

  if (showIndividualPlanForm) {
    //return (<IndividualPlanForm onSuccessfulSave={onSuccessfulSave} currentIndividualPlan={currentIndividualPlan} />);
    return (<IndividualPlanForm edit={true} onSuccessfulSave={onSuccessfulSave} currentIndividualPlan={currentIndividualPlan} />);
  } else {
    return (<IndividualPlansTable rows={rows} setRows={setRows} onEditClick={onEditClick} />);
  }
}
