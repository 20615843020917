// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useEffect, forwardRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import axios from "axios";

import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'

import Breadcrumb from 'react-bootstrap/Breadcrumb'

export default function Underwriters() {
  let history = useHistory();
  const ilUsers = useSelector(state => state.ilUsers)

  // Declare a new state variable, which we'll call "rows"
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(-1);

  useEffect(() => {
    doSearch();
  }, []);


  const doSearch = (values) => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_underwriters.json", {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setRows(response.data.il_underwriters);
        setTotalCount(response.data.total_count);
      })
      .catch(error => {
        history.push("/ig/admin");
      });
  }



  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/masterdata' }}>Admin</Breadcrumb.Item>
        <Breadcrumb.Item active>Underwriters</Breadcrumb.Item>
      </Breadcrumb>

      <Container fluid>
        <Table bordered hover id="il-underwriters-table" className="result-table">
          <thead className="">
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td><span style={{fontWeight: 400}}>{row.name}</span></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );

}
