// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React from "react"

import { BrowserRouter as Router} from "react-router-dom";
import Enrolment from './Enrolment';

// We need this container because useHistory() is only available within
// components nested inside a router.
export default function EnrolmentRouter({referrerCode, quote, planGroup}) {
  return (
    <Router>
      <Enrolment referrerCode={referrerCode} quote={quote} planGroup={planGroup} />
    </Router>
  )
}