import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container'

import { getBMI, formatHeight, formatWeight } from '../../exports';
import { COUNTRIES, countryName } from '../../country/countries';



export default function EnrolmentDependentShortView({
  dependent,
  dependents,
  setDependents
  }) {

  let history = useHistory();

  const handleChangeDependent = (dependent) => {
    history.push("/ig/enrolment/dependents/" + dependent.id);
  };

  const handleRemoveDependent = (dependent) => {
    let newDependents = dependents.filter(function(dep) {
      return dep.id != dependent.id
    })
    setDependents(newDependents);
  };

  return (
    <Container>
      <Row>
        <span style={{marginRight: '1ex'}}>{dependent.firstname} {dependent.lastname}</span>
        <span style={{marginRight: '1ex'}}>{dependent.sex == 'male' ? " ♂" : (dependent.sex == "female" ? " ♀" : (dependent.sex == "intersex" ? ' ⚥': ''))}</span>
        {dependent.date_of_birth &&
          <span style={{marginRight: '1ex'}}> {format(new Date(dependent.date_of_birth), 'dd MMM. yyyy')}</span>
        }
        <span style={{marginRight: '1ex', color: 'grey'}}>{dependent.relationship == 'partner' ? 'Partner' : 'Child'}</span>
        <ButtonGroup>
          <Button variant="link" style={{padding: 0, marginRight: '0.5em'}} onClick={() => handleChangeDependent(dependent)}>CHANGE</Button>
          <Button variant="secondary" style={{padding: 0, marginRight: '0.5em'}} onClick={() => handleRemoveDependent(dependent)}>REMOVE</Button>
        </ButtonGroup>
      </Row>
    </Container>
  )
}

