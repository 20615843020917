// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect} from "react"
import axios from "axios";
import { nanoid } from 'nanoid';

const { getDay, addDays, startOfHour } = require('date-fns')
const { zonedTimeToUtc, utcToZonedTime, format } = require('date-fns-tz')

import {Form, Row, Col, Button, InputGroup, FormControl, Modal} from 'react-bootstrap';

import CountrySelect from '../country/CountrySelect';
import {COUNTRIES}   from '../country/countries';
import CountryCallingCodeSelect from '../country/CountryCallingCodeSelect';

import QuoteFormCountrySelect from './quote/QuoteFormCountrySelect';

export default function CallMeForm({
  authenticity_token,
  site,
  subsite
  }) {

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [validated, setValidated] = React.useState(false);  // @TODO what's the difference to setSubmitted?

  let callingDays = (() => {
    let d = new Date;
    let days = []
    if (getDay(d) > 0 && getDay(d) < 6) {
      days.push({label: 'Today', value: format(d, 'yyyy-MM-dd')})
    }
    d = addDays(d, 1);
    for (let i = 0; i < 6; i += 1) {
      if (getDay(d) > 0 && getDay(d) < 6) {
        days.push({label: format(d, 'EEEE, yyyy-MM-dd'), value: format(d, 'yyyy-MM-dd')})
      }
      d = addDays(d, 1);
    }
    return days;
  })();


//  let callingDays = getCallingDays();

  const initialCallMe = {
    title: '',
    country_of_assignment: '',
    firstname: '',
    lastname: '',
    mobile_phone: '',
    country_calling_code: '',
    email_address: '',
    call_date: callingDays[0].value,
    call_time: '08:00-08:30',
    message: '',
    privacy_policy_agreement: false
  };
  const [callMe, setCallMe] = React.useState(initialCallMe);

  //---------------------------------------------------------------------------
  const handleChange = (event) => {
    let { name, value, type } = event.currentTarget;

    if (['privacy_policy_agreement'].includes(name)) {
      value = event.target.checked;
    }
    setCallMe({
      ...callMe,
      [name]: value
    });

    // was the field modified
    setTouched({
      ...touched,
      [name]: true
    });

    if (type == 'radio' || type == 'checkbox') {
      // Object destructuring with dynamic name (alias)
      // removedError = errors[name]
      // https://dmitripavlutin.com/javascript-object-destructuring/
      const { [name]: removedError, ...rest } = errors;
      setErrors({
        ...rest
      });
    }

  };


  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (typeof validation[name] !== 'function') return;
    const { [name]: removedError, ...rest } = errors;
    const error = validation[name](value);
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error })
    });
  };



  //---------------------------------------------------------------------------
  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();

    let numberOfErrors = validateForm();

    //if (Object.values(formValidation.errors).length > 0) {// errors object is not empty
    if (numberOfErrors > 0) {
      setValidated(false);
      setSubmitted(false);
    } else {
      const form = event.currentTarget;
      axios({
        method: 'post',
        url: window.location.protocol + '//' + window.location.host + "/ws/call_me",
        data: callMe
      }).then(response => {
        // handle success
        if (response.status != 200 || response.data.success != true) {
          //setErrors(response.data.errors);
          setSubmitted(false);
        } else {
          //setErrors({});
          setSubmitted(true);
        }
      })
      .catch(function (response) {
        setSubmitted(false);
        // handle error
        // setPositiveServerResponse(false);
      });
      setValidated(true);
    }
  };







  const nameValidation = (fieldName, fieldValue) => {
    if (! fieldValue || fieldValue.trim() === '' || fieldValue.trim() === 'select') {
      return `${fieldName} is required`;
    }
    // if (/[^a-zA-Z -]/.test(fieldValue)) {
    //   return 'Invalid characters';
    // }
    // if (fieldValue.trim().length < 3) {
    //   return `${fieldName} needs to be at least three characters`;
    // }
    return null;
  };

  const numberValidation = (fieldName, fieldValue) => {
    if (typeof fieldValue == "string" && fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (! isNaN(fieldValue)) {
      return null;
    }
    return `${fieldName} needs to be at valid number`;
  };


  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };


  const privacyPolicyValidation = (value) => {
    if (value == false) {
      return 'Privay policy must be accepted';
    }
    return null;
  };




  const validation = {
    country_of_assignment:           name => nameValidation('Country of assignment', name),
    firstname:                       name => nameValidation('Firstname', name),
    lastname:                        name => nameValidation('Lastname', name),
    country_calling_code:            phone => nameValidation('Calling code', phone),
    mobile_phone:                    name => nameValidation('Mobile phone', name),
    email_address:                   emailValidation,
    call_date:                       name => nameValidation('Day', name),
    call_time:                       name => nameValidation('Time', name),
    //message:                         name => nameValidation('Message', name),
    privacy_policy_agreement:        privacyPolicyValidation
  };






  const validateForm = () => {
    let numberOfErrors = 0;

    // Primary
    const formValidation = Object.keys(validation).reduce(
      (acc, key) => {
        const newError = validation[key](callMe[key]);
        const newTouched = { [key]: true };

        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          }
        };

      },
      {
        //errors: { ...errors },
        errors: {},
        //touched: { ...touched }
        touched: {}
      }
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);
    numberOfErrors += Object.values(formValidation.errors).length;
    return numberOfErrors;
  }


  const [submitted, setSubmitted] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    setSubmitted(false);
    setValidated(false);
  };



  let body;
  if (submitted) {
    body =
      <>
        <h3>Thank You!</h3>
        <p>Your request to call you has been successfully sent to Integra Global Ltd.</p>
      </>
  } else {
    body =
      <Form
        action='/ws/call_me'
        className="call-me-form"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        method="POST"
      >
        <Form.Text>Fields marked with an <span>*</span> are required</Form.Text>

        <Form.Group as={Row} controlId="formGroupTitle">
          <Col sm="12">
            <Form.Control
              as="select"
              custom
              name="title"
              value={callMe.title}
              isInvalid={errors.title}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="">Title</option>
              <option value="Dr.">Dr.</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Ms.">Ms.</option>
              <option value="Miss">Miss</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please provide the name of your company.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGroupCountryOfAssignment">
          <Col sm="12">
            <QuoteFormCountrySelect
              className="form-control"
              fieldName="country_of_assignment"
              validated={validated}
              placeholder="Country of Assignment*"
              value={callMe.country_of_assignment}
              isInvalid={errors.country_of_assignment}
              onChange={handleChange}
              onBlur={handleBlur}
              exclude={['HK']}
            />
            <Form.Control.Feedback type="invalid" tooltip={false}>Please select a country.</Form.Control.Feedback>
          </Col>
        </Form.Group>




        <Form.Group as={Row} controlId="formGroupFirstName">
          <Col sm="12">
            <Form.Control
              type="text"
              name="firstname"
              placeholder="Firstname*"
              value={callMe.firstname}
              isInvalid={errors.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupLastName">
          <Col sm="12">
            <Form.Control
              type="text"
              name="lastname"
              placeholder="Lastname*"
              value={callMe.lastname}
              isInvalid={errors.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGroupCountryCallingCode">
          <Col md="12" lg="5">
            <CountryCallingCodeSelect
              className={errors.country_calling_code ? 'is-invalid' : ''}
              name="country_calling_code"
              validated={validated}
              placeholder="Country Code*"
              value={callMe.country_calling_code}
              isInvalid={errors.country_calling_code}
              onChange={(option) => {
                setCallMe({
                  ...callMe,
                  country_calling_code: option.value
                })
                if (typeof validation['country_calling_code'] !== 'function') return;
                const { ['country_calling_code']: removedError, ...rest } = errors;
                const error = validation['country_calling_code'](option.value);
                setErrors({
                  ...rest,
                  ...(error && { ['country_calling_code']: touched['country_calling_code'] && error })
                });
              }}
              options={COUNTRIES.map((country) => {
                return {value: country.calling_code, label: country.name + ' (+' + country.calling_code + ')'};
              })}
              controlStyle={(styles, state) => ({
                  ...styles,
                  background: 'none',
                  border: '1px solid #ced4da',
                  borderRadius: '0.25rem',
                  //fontSize: '16px',
                  fontSize: '1rem',
                  //minHeight: '38px',
                  //boxShadow: 'none',
      //            padding: 0,
      //            margin: 0,
                  // '&:hover': {
                  //     border: '3px solid #c8d9f9'
                  // },
                  '&:focus': {
                      border: '3px solid #c8d9f9'
                  }
                })
              }
            />

            {errors.country_calling_code &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>Please select your country calling code.</div>
            }

          </Col>
          <Col className="mt-3 mt-lg-0" sm="12" lg="7">
            <Form.Control
              style={{minHeight: '38px'}}
              type="text"
              name="mobile_phone"
              placeholder="Mobile phone*"
              value={callMe.mobile_phone}
              isInvalid={errors.mobile_phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your phone number.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupEmail">
          <Col sm="12">
            <Form.Control
              type="text"
              name="email_address"
              placeholder="Email*"
              value={callMe.email_address}
              isInvalid={errors.email_address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your email address.</Form.Control.Feedback>
          </Col>
        </Form.Group>


          <Form.Group  as={Row} controlId="formGroupMessageText">
            <Col>

              <h3>When would you like to be called?</h3>
              Current local time in London:
              <span>{format(utcToZonedTime(new Date(), 'Europe/London'), 'HH:MM EEEE, d LLL yyyy')}</span>
            </Col>
          </Form.Group>

        <Form.Group as={Row} controlId="formGroupCallingDayAndTime">
          <Col sm="12" md="6">
            <Form.Control
              as="select"
              custom
              name="call_date"
              value={callMe.call_date}
              isInvalid={errors.call_date}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {callingDays.map( (day, index) =>
                <option key={index} value={day.value}>{day.label}</option>
              )}
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select the day we should call you.</Form.Control.Feedback>
          </Col>

          <Col className="mt-3 mt-md-0" sm="12" md="6">
            <Form.Control
              as="select"
              custom
              name="call_time"
              value={callMe.call_time}
              isInvalid={errors.call_time}
              onChange={handleChange}
              onBlur={handleBlur}
            >

            {/*  <option value="select">Please select</option>*/}
              <option value="08:00-08:30">08:00&mdash;08:30</option>
              <option value="08:30-09:00">08:30&mdash;09:00</option>
              <option value="09:00-09:30">09:00&mdash;09:30</option>
              <option value="09:30-10:00">09:30&mdash;10:00</option>
              <option value="10:00-10:30">10:00&mdash;10:30</option>
              <option value="10:30-11:00">10:30&mdash;11:00</option>
              <option value="11:00-11:30">11:00&mdash;11:30</option>
              <option value="11:30-12:00">11:30&mdash;12:00</option>
              <option value="12:00-12:30">12:00&mdash;12:30</option>
              <option value="12:30-13:00">12:30&mdash;13:00</option>
              <option value="13:00-13:30">13:00&mdash;13:30</option>
              <option value="13:30-14:00">13:30&mdash;14:00</option>
              <option value="14:00-14:30">14:00&mdash;14:30</option>
              <option value="14:30-15:00">14:30&mdash;15:00</option>
              <option value="15:00-15:30">15:00&mdash;15:30</option>
              <option value="15:30-16:00">15:30&mdash;16:00</option>
              <option value="16:00-16:30">16:00&mdash;16:30</option>
              <option value="16:30-17:00">16:30&mdash;17:00</option>
              <option value="17:00-17:30">17:00&mdash;17:30</option>
              <option value="17:30-18:00">17:30&mdash;18:00</option>
              <option value="18:00-18:30">18:00&mdash;18:30</option>
              <option value="18:30-19:00">18:30&mdash;19:00</option>
              <option value="19:00-19:30">19:00&mdash;19:30</option>
              <option value="19:30-20:00">19:30&mdash;20:00</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select the time we should call you.</Form.Control.Feedback>
          </Col>
        </Form.Group>



          <Form.Group  as={Row} controlId="formGroupMessageText">
            <Col>
              <Form.Label srOnly>
                Your Message
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="message"
                placeholder="Your message"
                value={callMe.message}
                isInvalid={errors.message}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="privacyPolicyAgreement">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={callMe.privacy_policy_agreement === true}
                isInvalid={errors.privacy_policy_agreement}
                value="true"
                onChange={handleChange}
                name="privacy_policy_agreement"
              />
              <Form.Check.Label>
                I agree to the terms outlined in the <a href="integraglobal/privacy_policy">privacy policy</a>.
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid" tooltip={true} >
                Sorry. We are unable to give you an indicative price unless you agree to our
                <a href="/ig/privacy_policy">privacy policy</a>.
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
      </Form>
  }




  return (
    <>
    <Button variant="primary" onClick={handleShow}>PLEASE CALL ME</Button>

    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title><h2>CALL ME</h2></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {body}
      </Modal.Body>

      <Modal.Footer>
        {submitted &&
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        }
        {submitted == false &&
          <Button variant="primary" onClick={handleSubmit}>CALL ME</Button>
        }
      </Modal.Footer>
    </Modal>
    </>
  );

}