// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React from "react"
import { Field, Formik, useFormik } from 'formik'
import { format, eachWeekOfInterval, getWeek, getYear, endOfWeek, getISOWeek, subWeeks, addWeeks } from 'date-fns';
import {Container, Form, Row, Col, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import TotalCount from "../util/TotalCount";


export default function SearchForm({doSearch, count, totalCount}) { // parameter destructuring

  const [value, setValue] = React.useState('');



  const weeks = eachWeekOfInterval(
    {
      // start: new Date(2021, 0, 1),  // monthIndex starts with 0
      // end: new Date(2021, 11, 31)},
      start: subWeeks(new Date(), 4),
      end:   addWeeks(new Date(), 54)
    },
    {
      weekStartsOn: 1
    }
  );
  const currentWeek = getISOWeek(new Date(), {weekStartsOn: 1, firstWeekContainsDate: 4});
  const [calendarWeek, setCalendarWeek] = React.useState(getISOWeek(new Date(), {weekStartsOn: 1, firstWeekContainsDate: 4}));

  const handleSubmit = (values, formik) => {
    console.log(values);
    doSearch(values);
  };

  return (
    <Container fluid className="">
      <h1>{calendarWeek}</h1>
      <Formik
       initialValues={{calendarWeek: calendarWeek}}
       onSubmit={handleSubmit}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        submitCount,
        getFieldProps,
        setFieldValue
        /* and other goodies */
      }) => (
        <Form inline noValidate onSubmit={handleSubmit}>

          <Form.Control
            as="select"
            name="calendarWeek"
            value={values.calendarWeek}
            onChange={(event) => {
//              debugger;
//              console.log(event.target.value)
              setFieldValue('calendarWeek', event.target.value);
              handleSubmit();
            }}
          >
            {weeks.map(week => (
            <option
              key={getYear(week).toString() + '_' + getWeek(week).toString()}
              value={getISOWeek(week, {weekStartsOn: 1, firstWeekContainsDate: 4})}
            >
              Week {getISOWeek(week, {weekStartsOn: 1, firstWeekContainsDate: 4})} ({format(week, 'd MMM. yyyy')}&mdash;{format(endOfWeek(week, {weekStartsOn: 1}), 'd MMM. yyyy')})
            </option>
            ))}
          </Form.Control>
          {/*
          <Form.Control
            className="mr-sm-2"
            size="sm"
            name="searchText"
            placeholder="Search Text"
            value={values.searchText || ''}
            onChange={handleChange}
            isInvalid={touched.searchText && errors.searchText}
          />

          <Button type="submit" size="sm" className="mr-1" name="search">Search</Button>
          */}

          <FontAwesomeIcon
            className="ml-3"
            icon={faSync}
            onClick={doSearch /*handleReload({showAlert: true})*/}
          />

          <TotalCount count={count} totalCount={totalCount} singularName="policy due for renewal" />
        </Form>
      )}
      </Formik>
    </Container>
  );

}