import React, { useState, useEffect } from "react"
import Select from 'react-select';

import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

export default function EnrolmentInsuredConditionsPregnancy({
  primary,
  setPrimary,
  dependents,
  setDependents
  }) {



  const handleChangePrimary = (event) => {
    setPrimary({
      ...primary,
      'pregnant': event.target.value == 'yes'
    });
  };

  // currying
  const handleChange = (dependent_id) => (event) => {
    const dependent = dependents.find(dep => dep.id == dependent_id);
    const modifiedDependent = {
      ...dependent,
      'pregnant': event.target.value == 'yes'
    };
    setDependents(dependents.map( dep => {
       if (dep.id != dependent.id) return dep;
       return modifiedDependent;
    }));
    //setDependent(modifiedDependent);
  };

  const dependentPregnancy = (dependent_id) => {
    const dependent = dependents.find(dep => dep.id == dependent_id);
    return dependent.pregnant;
  };


  return (
    <>
      {primary.sex != 'male' &&
      <div className="mt-3" key={primary.id.toString()}>
        <Row style={{fontSize: 'larger'}}>
          <Col>
            <span style={{marginRight: '0.5em'}}>Is {primary.firstname + ' ' + primary.lastname} pregnant?</span>
          </Col>
        </Row>
        <Row>
          <Col className="pregnancy-radio">
            <Form.Check
              className="radio-button-label"
              custom
              inline
              type="radio"
              name="primary-pregnant"
              id="primary-pregnant-no"
              label="NO"
              value="no"
              onChange={handleChangePrimary}
              checked={primary.pregnant === false}
            />
            <Form.Check
              className="radio-button-label"
              custom
              inline
              type="radio"
              name="primary-pregnant"
              id="primary-pregnant-yes"
              label="YES"
              value="yes"
              onChange={handleChangePrimary}
              checked={primary.pregnant === true}
            />

          </Col>
        </Row>
      </div>
      }

      {dependents
        .filter(dependent => dependent.relationship == 'partner' && dependent.sex != 'male')
        .map( (dependent, index) => (
        <div className="mt-3" key={dependent.id.toString()}>
          <Row style={{fontSize: 'larger'}} noGutters>
            <Col>
             <span style={{marginRight: '0.5em'}}>Is {dependent.firstname + ' ' + dependent.lastname} pregnant?</span>
            </Col>
          </Row>
          <Row>
             <Col className="pregnancy-radio">
                  <Form.Check
                    className="radio-button-label"
                    custom
                    inline
                    type="radio"
                    name={"dependent-pregnant-" + dependent.id.toString()}
                    id={"dependent-pregnant-no-" + dependent.id.toString()}
                    label="NO"
                    value="no"
                    onChange={handleChange(dependent.id)}
                    checked={dependentPregnancy(dependent.id) === false}
                  />
                  <Form.Check
                    className="radio-button-label"
                    custom
                    inline
                    type="radio"
                    name={"dependent-pregnant-" + dependent.id.toString()}
                    id={"dependent-pregnant-yes-" + dependent.id.toString()}
                    label="YES"
                    value="yes"
                    onChange={handleChange(dependent.id)}
                    checked={dependentPregnancy(dependent.id) === true}
                  />
            </Col>
          </Row>
        </div>
      ))}
    </>
  )
}

