// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { forwardRef, useState } from 'react';

import NumberFormat from 'react-number-format';

export default function IlFormatMoney({value, currency = 'USD', thousandSeparator = true, decimalScale = 0, showAed}) {

  if (currency == 'USD') {
    if (showAed) {
      value *= 3.675;
      currency = 'AED';
    }
  }

  let currencySymbol = '';
  if (currency == 'USD') {
    currencySymbol = '$'
  } else if (currency == 'EUR') {
    currencySymbol = '€'
  } else if (currency == 'GBP') {
    currencySymbol = '£'
  } else if (currency == 'AED') {
    currencySymbol = 'AED'
  }


  return (
    <NumberFormat value={value} displayType={'text'} thousandSeparator={thousandSeparator} decimalScale={decimalScale}  suffix={currencySymbol} />
  )
}
