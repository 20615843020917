// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import Button from 'react-bootstrap/Button';


export default function QuoteResultFormCurrencies({
  currency,
  setCurrency,
  setPreviousCurrency,
  currencies,
  format
  }) {


  const handleClick = (event) => {
    //console.log('currency changed');
    //if (setPreviousCurrency) {
    //  setPreviousCurrency(currency);
    //}
    setCurrency(event.currentTarget.name);
  };


  const CurrencyButton = ({currency, currencyName, format}) => {
    let className = '';

    if (format != 'text') {
      className = 'fa fa-' + currencyName.toLowerCase();
    }

    if (currency == currencyName) {
      className += ' active';
    }

    return (
      <Button
        name={currencyName}
        className="currency"
        variant="link"
        style={{fontSize: '30px', display: 'inline', minHeight: '3ex'}}
        onClick={handleClick}
      >
        {
          format == 'text' ?
          (<span className={className}>{currencyName}</span>)
          :
          (<i className={className} aria-hidden="true"></i>)
        }
      </Button>
    );
  }





  return (
    <>

      {currencies.map( (mapped_currency, index) => (
        <CurrencyButton key={mapped_currency} currency={currency} currencyName={mapped_currency} format={format} />
      ))}

{/*
      <CurrencyButton currency={props.currency} currencyName='USD' />
      <CurrencyButton currency={props.currency} currencyName='EUR' />
      <CurrencyButton currency={props.currency} currencyName='GBP' />
*/}
{/*
      <a className="active" href="#tab-section-1" style={{fontSize: '30px', display: 'inline'}}>
        <i className="fa fa-usd" aria-hidden="true"></i>
      </a>
      <a href="#tab-section-2" style={{fontSize: '30px', display: 'inline'}}>
        <i className="fa fa-eur" aria-hidden="true"></i>
      </a>
      <a href="#tab-section-2" style={{fontSize: '30px', display: 'inline'}}>
        <i className="fa fa-gbp" aria-hidden="true"></i>
      </a>
*/}
    </>
  )
}
