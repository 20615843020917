import { createSlice } from '@reduxjs/toolkit'

export const ilUsersSlice = createSlice({
  name: 'ilUsers',
  initialState: {
    currentUser: null
  },
  reducers: {
    login: (state, action) => {
      state.currentUser = action.payload
      localStorage.setItem('il_user', JSON.stringify(action.payload))
    },
    logout: state => {
      state.currentUser = null
      localStorage.removeItem('il_user')
    }
  }
})

// Action creators are generated for each case reducer function
export const { login, logout } = ilUsersSlice.actions

export default ilUsersSlice.reducer