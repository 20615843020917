// encoding: UTF-8
//
// (C) Copyright 2021-2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect, useRef } from "react"
import axios from "axios";
import { useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import { getAge } from '../../exports';

export default function EnrolmentPlanOptionsCore({
  primary,
  dependents,
  corePlanOptions,
  setCorePlanOptions,
  commonPlanOptions,
  setCommonPlanOptions,
  handlePlanOptionsChosen
}) {
  const mountedRef = useRef(true);
  let history = useHistory();

  const paymentPeriods = {
    annual:     'Annual',
    semiannual: 'Semi-annual',
    quarterly:  'Quarterly'
  };

  const addBenefits = {
    USD: {
      your:    [50000, 100000, 150000, 250000],
      premier: [100000, 150000, 250000]
    },
    EUR: {
      your:    [40000, 80000,  120000, 200000],
      premier: [80000, 120000, 200000]
    },
    GBP: {
      your:    [35000, 70000,  105000, 175000],
      premier: [70000, 105000, 175000]
    }
  };

  const deductibles = {
    USD: [0, 100, 200, 500, 1000, 5000],
    EUR: [0,  75, 150, 400,  750, 4000],
    GBP: [0,  65, 125, 300,  650, 3000]
  };

  // prevent invalid deductible to be set.  They might come from quotes for example.
  if (deductibles[corePlanOptions.currency].includes( corePlanOptions.deductible ) == false) {
    corePlanOptions.deductible = deductibles[corePlanOptions.currency][2];
  }

  // prevent invalid AD&D benefits being set.  They might come from quotes for example.
  if (addBenefits[corePlanOptions.currency][corePlanOptions.premier_incare ? 'premier' : 'your'].includes( corePlanOptions.add_benefit ) == false) {
    corePlanOptions.add_benefit = addBenefits[corePlanOptions.currency][corePlanOptions.premier_incare ? 'premier' : 'your'][1];
  }



  const findMatchingDeductible = (deductible, currency, previousCurrency) => {
    if (! previousCurrency) return deductible;
    const index = deductibles[previousCurrency].findIndex((deductible) => deductible == corePlanOptions.deductible)
    if (index > -1) {
      return deductibles[currency][index];
    }
    return deductibles[currency][2];
  };

  const findMatchingAddBenefit = (add_benefit, currency, previousCurrency) => {
    if (! previousCurrency) return add_benefit;
    const index = addBenefits[previousCurrency][corePlanOptions.premier_incare ? 'premier' : 'your'].findIndex((add_benefit) => add_benefit == corePlanOptions.add_benefit)
    if (index > -1) {
      return addBenefits[currency][corePlanOptions.premier_incare ? 'premier' : 'your'][index];
    }
    return addBenefits[currency][corePlanOptions.premier_incare ? 'premier' : 'your'][0];
  };

  const annualMaximumPlanBenefit = {
    USD: {
      your:    1000000,
      premier: 1500000
    },
    EUR: {
      your:    900000,
      premier: 1350000
    },
    GBP: {
      your:    0,
      premier: 0
    }
  };




  const lifeCover = {
    USD: {
      your:    5000,
      premier: 10000
    },
    EUR: {
      your:    5000,
      premier: 10000
    },
    GBP: {
      your:    5000,
      premier: 10000
    }
  }


  const currencyPrefix = {
    USD: '$',
    EUR: '€',
    GBP: '£'
  };

  const [premium, setPremium] = React.useState({annual: -1, semiannual: -1, quarterly: -1});

  const [optionsVisible, setOptionsVisible] = React.useState({
    deductible: false,
    add: false,
    geographical_cover: false,
    payment_period: false
  });


  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, []);


  // Plan options changed
  useEffect(() => {
    //console.log(corePlanOptions);
    getQuote();
  }, [corePlanOptions]);


  // common plan options changed
  useEffect(() => {
    let options = {
      //geographical_cover: commonPlanOptions['geographical_cover'],
      payment_period:       commonPlanOptions['payment_period'],
      //maternity_cover:    commonPlanOptions['maternity_cover'],
      //dental_option:        commonPlanOptions['dental_option'],
      currency:             commonPlanOptions.currency,
      preferred_start_date: commonPlanOptions.preferred_start_date
    };

    let mapped_deductible  = commonPlanOptions.deductible
    let mapped_add_benefit = commonPlanOptions.add_benefit

    if (commonPlanOptions.currency != corePlanOptions.currency) {
      mapped_deductible  = findMatchingDeductible(commonPlanOptions.deductible,  commonPlanOptions.currency, corePlanOptions.currency);
      mapped_add_benefit = findMatchingAddBenefit(commonPlanOptions.add_benefit, commonPlanOptions.currency, corePlanOptions.currency);
      options.currency = commonPlanOptions.currency;
    }

    // @TODO delete key from hash options instead?
    if (deductibles[commonPlanOptions.currency].includes( mapped_deductible )) {
      options.deductible = mapped_deductible;
    }
    if (addBenefits[commonPlanOptions.currency][corePlanOptions.premier_incare ? 'premier' : 'your'].includes( mapped_add_benefit )) {
      options.add_benefit = mapped_add_benefit;
    }

    setCorePlanOptions({
      ...corePlanOptions,
      ...options
    });
  }, [commonPlanOptions]);


  // //---------------------------------------------------------------------------
  // // @TODO do we need this?
  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {h//     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //   }
  //   // localStorage.setItem('quote', JSON.stringify(quote));
  // };




  //---------------------------------------------------------------------------
  const handleChange = (event) => {
    if (['deductible', 'add_benefit'].includes(event.target.name)) {
      var value = Number(event.target.value);
    } else {
      var value = event.target.value;
    }

    if (Object.keys(commonPlanOptions).includes(event.target.name)) {
      setCommonPlanOptions({
        ...commonPlanOptions,
        [event.target.name]: value,
        initial: false
      })
    } else {

      if (event.target.name == 'premier_incare') {
        if (event.target.checked) { // switch to premier plan
          if ( addBenefits[commonPlanOptions.currency]['premier'].includes( commonPlanOptions['add_benefit'] )) {
            var add_benefit = commonPlanOptions['add_benefit'];
          } else {
            var add_benefit = addBenefits[commonPlanOptions.currency]['premier'][0];
          }
          setCorePlanOptions({
            ...corePlanOptions,
            plan: 'premierincare_19_v',
            premier_incare: true,
            add_benefit: add_benefit
          });
        } else { // switch to life plan
          setCorePlanOptions({
            ...corePlanOptions,
            plan: 'yourincare_19_v',
            premier_incare: false
          });
        }
      } else {
        setCorePlanOptions({
          ...corePlanOptions,
          [event.target.name]: value,
          plan: plan
        });
      }
    }
  };

  // --------------------------------------------------------------------------
  const handleOptionsVisible = (event) => {
    const option = event.target.getAttribute('data-options-visible');
    setOptionsVisible({
      ...optionsVisible,
      [option]: ! optionsVisible[option]
    });
  };


  // --------------------------------------------------------------------------
  const getQuote = () => {

    if (commonPlanOptions.currency == 'GBP') return 0;
    if (['TH', 'HK', 'SG', 'AE'].includes(primary.country_of_residence_outside_US)) return 0;

    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/quotes/get_premium.json", {
          params: {
            plan:                 corePlanOptions.plan,
            country_of_residence: primary.country_of_residence_outside_US,
            geographical_cover:   'worldwide_sans_us',
            deductible:           corePlanOptions.deductible,
            age:                  getAge(primary.date_of_birth),
            dependents:           JSON.stringify(dependents),
            add_option:           true,
            add_benefit:          corePlanOptions.add_benefit,
            currency:             commonPlanOptions.currency
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        if (! mountedRef.current) return null;

        setPremium(response.data.rates.total_due);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {

          }
        } else {
          throw error;
        }

      });
  };


  // Components ---------------------------------------------------------------

  const Deductibles = () => {
    if (optionsVisible.deductible) {
      return (
        <div>
          {deductibles[commonPlanOptions.currency].map( (deductible, index) => (
            <Form.Check key={"deductible_" + deductible} inline type="radio">
              <Form.Check.Input
                name="deductible"
                value={deductible}
                type="radio"
                checked={corePlanOptions.deductible == deductible}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }


  const AccidentalDeathAndDisablement = () => {
    if (optionsVisible.add) {
      return (
        <div>
          {addBenefits[commonPlanOptions.currency][corePlanOptions.premier_incare ? 'premier' : 'your'].map( (add_benefit, index) => (
            <Form.Check key={"add_benefit_" + add_benefit} inline type="radio">
              <Form.Check.Input
                name="add_benefit"
                value={add_benefit}
                type="radio"
                checked={corePlanOptions.add_benefit == add_benefit}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }

  const PaymentPeriod = () => {
    if (optionsVisible.payment_period) {
      return (
        <div>
          {['annual', 'semiannual', 'quarterly'].map( (payment_period, index) => (
            <Form.Check key={"payment_period_" + payment_period} inline type="radio">
              <Form.Check.Input
                name="payment_period"
                value={payment_period}
                type="radio"
                checked={corePlanOptions.payment_period == payment_period}
                onChange={handleChange}
              />
              <Form.Check.Label>
                {paymentPeriods[payment_period]}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }

  const FormatPremium = (props) => {
    if (props.premium.annual == -1) {
      return (
        <span style={{fontSize: '40px'}}>Loading...</span>
      );
    } else {

      let paymentPeriod = '';
      switch (props.paymentPeriod) {
        case 'annual':
          paymentPeriod = '1 annual payment of';
          break;
        case 'semiannual':
          paymentPeriod = '2 semi-annual payments of';
          break;
        case 'quarterly':
          paymentPeriod = '4 quarterly payments of';
          break;
      }

      return (
        <>
          <div style={{fontSize: 'small', whiteSpace: 'nowrap'}}>{paymentPeriod}</div>
          <div className="m-0 p-0" style={{whiteSpace: 'nowrap', lineHeight: '90%'}}>
            <span className="quote__table__pricing__currency">{currencyPrefix[commonPlanOptions.currency]}</span>
            <NumberFormat value={props.premium[corePlanOptions.payment_period]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={2} />{props.children}
          </div>
        </>
      );
    }
  };


  if (commonPlanOptions.currency == 'GBP' || ['TH', 'HK', 'SG', 'AE'].includes(primary.country_of_residence_outside_US)) {
    return null;
  } else {
    return (
      <Form
        className="quote-form-core"
        noValidate
      >
        <div className="quote__table__row quote__table__heading">
          <span>CORE</span><br />
          <span className="plan-name">{corePlanOptions.plan == 'yourincare_19_v' ? 'InCare-Life' : 'InCare-Premier'} </span>
        </div>

        <div className="quote__table__row quote__table__pricing">
          <FormatPremium premium={premium}  paymentPeriod={corePlanOptions.payment_period} ><i style={{color: '#b8e673', verticalAlign: 'text-top', textShadow: '0 1px 1px rgba(0, 0, 0, 0.3)'}} className="fa fa-asterisk"></i></FormatPremium>
        </div>


        <div className="quote__table__row">
          Annual Maximum Plan Benefit
          <br />
          <strong>
          <NumberFormat
            value={annualMaximumPlanBenefit[commonPlanOptions.currency][corePlanOptions.premier_incare ? 'premier' : 'your']}
            displayType={'text'}
            thousandSeparator={true}
            prefix={currencyPrefix[commonPlanOptions.currency]} />
          </strong>
        </div>


        <div className="quote__table__row">
          Life Cover
          <br />
          <strong>
          <NumberFormat
            value={lifeCover[commonPlanOptions.currency][corePlanOptions.premier_incare ? 'premier' : 'your']}
            displayType={'text'}
            thousandSeparator={true}
            prefix={currencyPrefix[commonPlanOptions.currency]} />
          </strong>
        </div>


        <div className="quote__table__row">Comprehensive Inpatient Cover</div>

        <div className="quote__table__row">Rehab after Hospitalisation</div>

        <div className="quote__table__row">Medical Evacuation</div>

        <div className="quote__table__row">Life Aware Programme</div>

        <div className="quote__table__row">Digital Tools</div>

        <div className="quote__table__row">
          Deductible:&ensp;
          <strong><NumberFormat value={corePlanOptions.deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} /></strong>
          <Button className="change" variant="light" size="sm" data-options-visible="deductible" onClick={handleOptionsVisible}>CHANGE</Button>
          <Deductibles />
        </div>

        <div className="quote__table__row">
          Accidental death and disablement:&ensp;
          <strong><NumberFormat value={corePlanOptions.add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} /></strong>
          <Button className="change" variant="light" size="sm"  data-options-visible="add" onClick={handleOptionsVisible}>CHANGE</Button>
          <AccidentalDeathAndDisablement />
        </div>


        <div className="quote__table__row">
          Worldwide cover <strong>excluding US/Canada</strong>
        </div>

        <div className="quote__table__row">
          Payment method: <strong>{paymentPeriods[corePlanOptions.payment_period]}</strong>
          <Button className="change" variant="light" size="sm"  data-options-visible="payment_period" onClick={handleOptionsVisible}>CHANGE</Button>
          <PaymentPeriod />
        </div>

        <div className="quote__table__row">
          Enhanced cover<br />
          Upgrade to Premier to increase your annual maximum and
          accidental death and dismemberment.<br />

          <Form.Check
            name="premier_incare"
            type="checkbox"
            label="Upgrade"
            checked={corePlanOptions.premier_incare}
            onChange={handleChange}
          />

        </div>

        <div className="quote__table__row">
          <Button className="btn--big" style={{fontSize: 'x-large'}} onClick={() => handlePlanOptionsChosen(corePlanOptions, premium, 'core')}>CHOOSE</Button>
        </div>

        <div className="quote__table__row text-left mt-2" style={{fontSize: 'x-small', padding: '1em'}}>
          <p><i style={{color: 'rgb(150, 196, 81)', verticalAlign: 'top', fontSize: '.6rem', top: '.2rem'}} className="fa fa-asterisk"></i>
          These rates are indicative only and based on no medi&shy;cal conditions being declared.</p>
        </div>

      </Form>
    )
  }
}
