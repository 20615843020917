import React, { useState, useEffect } from "react"
import axios from "axios"
import { nanoid } from 'nanoid'

// The useHistory hook gives you access to the history instance that you may use to navigate.
import { useParams } from "react-router-dom"
import Jumbotron from 'react-bootstrap/Jumbotron'

export default function GroupInfo({groupId}) {

  const [group, setGroup] = React.useState(null);


  useEffect(() => {
    if (groupId) {
      getGroup(groupId);
    }
  }, []);


  const getGroup = (groupId) => {

    const sort = [{property: "name", direction: "ASC"}]
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/groups/" + groupId + ".json",
        { withCredentials: true }
      )
      .then(response => {
        setGroup(response.data.groups);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/v7/mis");
          } else {
            //setWsError(error.response.data.message);
          }
        } else {
          throw error;
        }

      });
  }

  if (! group) return null;

  return (
    <Jumbotron>
      <h3>Your Group</h3>
      <h2>{group.name}</h2>
      <p>
          {group.corr_street_1}<br />
          {group.corr_street_2 &&
            <>{group.corr_street_2}<br /></>
          }
          {(group.corr_zip_1 || group.corr_city_1) &&
            <>{group.corr_zip_1}&ensp;{group.corr_city_1}<br /></>
          }
          {[group.corr_state_1, group.corr_country_name].filter(Boolean).join(", ")}
      </p>
      {group.corr_contact_person_1 &&
      <>
          Contact: {group.corr_contact_person_1}<br />
      </>
      }
      {group.corr_email_1 &&
        <>
          Email: <a href={'mailto:' + group.corr_email_1}>{group.corr_email_1}</a><br />
        </>
      }
      {group.corr_phone_1 &&
        <>
          Telephone: {group.corr_phone_1}<br />
        </>
      }

    </Jumbotron>
  );
}