import React, { forwardRef, useState } from 'react';

export default function TotalCount(props) {
  if (props.totalCount < 0) {
    return <div className="m-2"></div>
  } else if (props.totalCount == 0) {
    return <div className="m-2">No {props.singularName} found</div>
  } else if (props.totalCount == 1) {
    return <div className="m-2">{props.totalCount} {props.singularName} found</div>
  }

  let pluralName = props.singularName;
  if (props.pluralName) {
    pluralName = props.pluralName;
  } else {
    pluralName += 's';
  }

  if (props.totalCount > 1) {
    return <div className="m-2">{props.totalCount} {pluralName} found</div>
  } else {
    return <div className="m-2">Number of {pluralName} found unknown</div>
  }
}

