import React, { useState, useEffect }  from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import axios from "axios"

import { Container } from 'react-bootstrap'

import { Provider } from "react-redux"
import store from "./redux/store"

import Home from "./Home"

export default function Admin ({formAuthenticityToken}) {

  // const checkLoginStatus = () => {
  //   var host = window.location.protocol + '//' + window.location.host
  //   axios
  //     .get(host + "/ws/user/login_status.json", { withCredentials: true })
  //     .then(response => {
  //       if (response.data.success /*&& loggedIn === false*/) {
  //         setLoggedIn(true);
  //         setUser(response.data.user);
  //       } else if (!response.data.success /*&& loggedIn == true */) {
  //         setLoggedIn(false);
  //         setUser({});
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }



  // useEffect(() => {
  //   console.log('loggedIn changed');
  //   console.log(loggedIn)
  // }, [loggedIn]);




  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>

          <Route
            path={['/ig/admin']}
          >
            <Home />
          </Route>

          <Route>
            <div className="container h-100">
              <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-12 col-md-8 col-lg-6">
                    <h1>Apparently some roads do not lead to Rome.</h1>
                  </div>
              </div>
            </div>
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
