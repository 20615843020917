import React, { useState, useEffect } from "react"

import { Redirect, useHistory } from "react-router-dom"

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Jumbotron from 'react-bootstrap/Jumbotron'

import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import axios from "axios";



export default function Login ({formAuthenticityToken, accessToken, handleSetAccessToken, emailAddressVerified, passwordChanged}) {

  let history = useHistory();

  const [displayEmailAddressVerified, setDisplayEmailAddressVerified] = React.useState(emailAddressVerified);
  const [displayPasswordChanged, setDisplayPasswordChanged] = React.useState(passwordChanged);

  //const [customer, setCustomer] = React.useState({email: '', password: ''});
  const [loginError, setLoginError] = React.useState('');
  const [resetLinkSent, setResetLinkSent] = React.useState(false);

  // const handleChange = event => {
  //   let { name, value, type } = event.currentTarget;
  //   setCustomer({
  //     ...customer,
  //     [name]: value
  //   });
  // }

  useEffect(() => {
    //setDisplayEmailAddressVerified(emailAddressVerified);
    if (emailAddressVerified || passwordChanged) {
      history.push('/ig/customer/login');
    }
  }, []);


  const handleSubmit = (values, { setSubmitting }) => {
    setDisplayEmailAddressVerified(false);
    setDisplayPasswordChanged(false);
    //event.preventDefault();
    axios
    .post( // we don't want the password be visible in the query string
      window.location.protocol + '//' + window.location.host + "/ws_customer/authenticate",
      values,
      {
        headers: {
          'X-CSRF-Token': formAuthenticityToken
        }
      }
    )
    .then(response => {
      if (response.data.success) {
        handleSetAccessToken(response.data.access_token);
        //history.push('/ig/customer');
      } else {
        handleSetAccessToken(null);
        if (response.data.code == 103) {
          setLoginError('Your email address has not been verified yet. Please click on the link we sent you per email.');
        } else if (response.data.code == 104) {
          setLoginError('Please check if the email address and password you entered are correct.');
        } else {
          setLoginError(response.data.message);
        }
        setSubmitting(false);
      }

    })
    .catch(error => {
      setLoginError(error.data.message);
      setSubmitting(false);
    });

  }

  const handleResetPassword = (values, errors, setFieldError, setFieldTouched, validateField, LoginSchema) => {
    // console.log('values');
    // console.log(values);
    // console.log('errors');
    // console.log(errors);

    setFieldTouched('email_address', true, false);
//    validateField('email_address');
    // if (!values.email_address) {
    //   setLoginError('Please provide your email address so that we can send you a reset link.');
    // }

    const schema = Yup.object().shape({
      email_address: Yup.string().email('The email address you provided has an invalid format.').required('Please provide your email address.'),
    });

    schema
    .validate(values)
    .then(values => {
      console.log('then');
      console.log(values);
      sendResetLink(values.email_address);
    })
    .catch(errors => {
      console.log('catch');
      console.log(errors);
      setLoginError('Please provide your email address so that we can send you a reset link.');
      setResetLinkSent(false);
    })

    //history.push("/ig/customer/reset_password")
  };


  const sendResetLink = (email_address) => {
    axios
    .post( // we don't want the password be visible in the query string
      window.location.protocol + '//' + window.location.host + "/ws_customer/reset_password",
      {
        email_address: email_address
      }
    )
    .then(response => {
      if (response.data.success) {
        setResetLinkSent(true);
      } else {
        setResetLinkSent(false);
        setLoginError(response.data.message);
      }
    })
    .catch(error => {
      setLoginError(error.data.message);
    });
  };



  const LoginSchema = Yup.object().shape({
    email_address: Yup.string().email('The email address you provided has an invalid format.').required('Please provide your email address.'),
    password: Yup.string().required('Please provide a password.')
  });


  if (resetLinkSent) {
    return (
      <Jumbotron className="" style={{color: '#505050', backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
        <h3>Success!</h3>
        <h1>An email with reset link has been sent to you.  Please click on the link in that email to proceed.</h1>
      </Jumbotron>
    )
  }


  if (accessToken) {
    return <Redirect to="/ig/customer/dashboard" />
  }


  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="customer-forms mt-5 mb-5 col-12 col-md-8 col-lg-6">

          {displayEmailAddressVerified &&
            <Jumbotron className="" style={{color: '#505050', backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
              <h3>Welcome!</h3>
              <h1>Your email address has been verified. You are now free to log into your account.</h1>
            </Jumbotron>
          }
          {displayPasswordChanged &&
            <Jumbotron className="" style={{color: '#505050', backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
              <h3>Success!</h3>
              <h1>Your password has been changed. You may now log into your account using your <strong>new</strong> password.</h1>
            </Jumbotron>
          }

          <Formik
           initialValues={{firstname: '', lastname: '', email_address: '', password: ''}}
           validationSchema={LoginSchema}
           onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              submitCount,
              getFieldProps,
              validateField,
              setFieldError,
              setFieldTouched
              /* and other goodies */
            }) => (

            <Form onSubmit={handleSubmit} noValidate >

            <h1 className="">LOGIN</h1>

            {loginError &&
              <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                <h3>We are sorry.</h3>
                <h1>{loginError}</h1>
              </Jumbotron>
            }


            <p>Input your email address and password to login, if you already have an account</p>



              <Form.Row>
                <Form.Group as={Col} className="col-12">
                  <Form.Control
                    type="text"
                    placeholder="Email*"
                    required
                    isInvalid={errors.email_address && touched.email_address}
                    {...getFieldProps('email_address')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email_address}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                  <Form.Group className="col-12">
                    <Form.Control
                      type="password"
                      placeholder="Password*"
                      required
                      isInvalid={errors.password && touched.password}
                      {...getFieldProps('password')}
                    />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </Form.Group>
              </Form.Row>

              <Form.Row className="mb-3">
                  <Col sm="12" md="4" className="mb-3">
                    <Button type="submit" variant="primary">Login</Button>
                  </Col>
                  <Col sm="12" md="4">
                    <Button variant="link" onClick={() => history.push("/ig/customer/create")} >Create Account</Button>
                  </Col>
                  {/*
                  <Col sm="12" md="4">
                    <Button variant="link" onClick={() => history.push("/ig/customer/send_reset_password_token")}>Forgot Password?</Button>
                  </Col>
                  */}
                  <Col sm="12" md="4">
                    <Button variant="link" onClick={() => history.push("/ig/customer/password_update")}>Forgot Password?</Button>
                  </Col>

              </Form.Row>

              {Object.values(errors).length > 0 && submitCount > 0 &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h1>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h1>
                </Jumbotron>
              }
            </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
