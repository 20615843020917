// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import TESTING from "../globals"

import React, { useEffect, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import axios from "axios";

import { setIlIndividualPolicyPeriods } from '../redux/ilIndividualPolicyPeriodsSlice'

import { format } from 'date-fns'

import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Jumbotron from 'react-bootstrap/Jumbotron'

import SearchForm from './SearchForm'


export default function IndividualPolicyPeriods() {
  const dispatch = useDispatch();
  const ilUsers = useSelector(state => state.ilUsers)
  const individualPolicyPeriods = useSelector(state => state.individualPolicyPeriods)

  let history = useHistory();


  // Declare a new state variable, which we'll call "rows"
  //const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(-1);
  const [error, setError] = React.useState('');

  // useEffect(() => {
  //   doSearch();
  // }, []);


  const doSearch = (values) => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_individual_policy_periods.json", {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          },
          params: {
          sort: 'policy_id:ASC,start_date:DESC',
            name: values.searchText
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        dispatch(setIlIndividualPolicyPeriods({
          rows: response.data.il_individual_policy_periods,
          totalCount: response.data.total_count
        }));
        //setRows(response.data.il_individual_policy_periods);
        //setTotalCount(response.data.total_count);
        setError(null);
      })
      .catch(error => {
        setError(error.response.statusText);
        //history.push("/ig/admin");
      });
  }



  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/individual_insured' }}>Individual Insured</Breadcrumb.Item>
        <Breadcrumb.Item active>Policies</Breadcrumb.Item>
      </Breadcrumb>

      <SearchForm doSearch={doSearch} totalCount={individualPolicyPeriods.totalCount} count={individualPolicyPeriods.rows.length} />

      {error &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>😳 This shouldn't have happened.</h3>
          <h1>{error}</h1>
        </Jumbotron>
      }


      <Container fluid>
        <Table striped bordered hover id="il-policy-periods-table" className="result-table mt-3">
          <thead className="">
            <tr>
              <th>Policy ID</th>
              <th>Certificate No.</th>
              <th>Primary Insured</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Plan</th>
              <th>Commencement Date</th>
              <th>Cancellation Date</th>
            </tr>
          </thead>
          <tbody>
          {individualPolicyPeriods.rows.map(policyPeriod => {
            return (
              <tr key={policyPeriod.id}  className="il-individual-policy-period">
                <td style={{fontWeight: 'bolder'}}>
                  <Link className="" to={'/ig/admin/individual_policy_periods/' + policyPeriod.id} >
                    {policyPeriod.il_individual_policy_id}<span style={{fontSize: 'smaller', color: 'grey'}}>_{policyPeriod.id}<span className='ig_ID'>_{policyPeriod._ig_policy_id}</span></span>
                  </Link>
                </td>
                <td style={{fontWeight: 'bolder'}}>{policyPeriod.certificate_number}</td>
                <td>
                  {policyPeriod.primary?.fullname}&emsp;{policyPeriod.primary?.il_person_core_id}<span className='period_ID'>_{policyPeriod.primary?.id}<span style={{fontSize: 'smaller', color: 'rgba(193, 66, 66, 0.6)'}}>_{policyPeriod.primary?._ig_individual_insured_id}</span></span>
                  {
                  TESTING &&
                  <div style={{color: 'rgba(193, 66, 66, 0.6)'}}>
                    {policyPeriod.il_persons?.reduce((count, person) => person.relationship != 'primary' ? 1 : 0, 0 /* initial value */) > 0 &&
                      <div>Dependents:</div>
                    }
                    {policyPeriod.il_persons?.map(person => {
                      if (person.relationship != 'primary') {
                        return (<div key={person.id}>{person.fullname}&emsp;{person.il_person_core_id}<span className='period_ID'>_{person.id}<span className='ig_ID'>_{person._ig_individual_insured_id}</span></span> ({person.relationship}) {format(new Date(person.validity_start_date), 'd. LLL yyyy')}-{format(new Date(person.validity_end_date), 'd. LLL yyyy')}</div>)
                      } else {
                        return null;
                      }
                    })}
                  </div>
                  }
                </td>
                <td>{format(new Date(policyPeriod.start_date), 'd. LLL yyyy')}</td>
                <td>{format(new Date(policyPeriod.end_date), 'd. LLL yyyy')}</td>
                <td>{policyPeriod?.il_individual_plan?.name}</td>
                <td>{policyPeriod.commencement_date && format(new Date(policyPeriod.commencement_date), 'd. LLL yyyy')}</td>
                <td>{policyPeriod.cancellation_date && format(new Date(policyPeriod.cancellation_date), 'd. LLL yyyy')}</td>
              </tr>
            );
          })}
          </tbody>
        </Table>
      </Container>
    </>
  );

}
