import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"

import {Container, Form, Row, Col, Card, Breadcrumb } from 'react-bootstrap'
import NavBar from "./NavBar"

export default function MasterData({user, loggedIn}) {

  let history = useHistory();

//   useEffect(() => {
//     console.log(loggedInStatus);
//     if (loggedInStatus == "NOT_LOGGED_IN") {
// //      history.push('/ig/mis');
//     }
//   }, []);


  // const handleLogoutClick = (event) => {
  // //let handleLogoutClick = (event) => {
  //   const host = window.location.protocol + '//' + window.location.host
  //   axios
  //     .get(host + "/il_ws/user/logout", { withCredentials: true })
  //     .then(response => {
  //       handleLogout();
  //       // history object is passed by the Router
  //       history.push("/ig/mis");
  //     })
  //     .catch(error => {

  //     });
  // };



  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Master Data</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="mt-5" >
        <Col>
          <Card className="mt-2" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Underwriters</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Someone has to pay.</Card.Subtitle>
              <Link className="stretched-link" to="/ig/admin/underwriters" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mt-2" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Plans</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Set up plans.</Card.Subtitle>
              <Link className="stretched-link" to="/ig/admin/plans" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mt-2" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Users</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Users and permissions.</Card.Subtitle>
              <Link className="stretched-link" to="/ig/admin/users" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mt-2" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>User Groups</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">User groups and permissions.</Card.Subtitle>
              <Link className="stretched-link" to="/ig/admin/usergroups" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mt-2" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Countries</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">List of countries.</Card.Subtitle>
              <Link className="stretched-link" to="/ig/admin/countries" />
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Container>
  );
}


