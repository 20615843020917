import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import { getAge, getBMI, formatHeight, formatWeight } from '../exports';
import { COUNTRIES, countryName } from '../country/countries';

import GroupEnrolmentMedicalQuestionsView from './GroupEnrolmentMedicalQuestionsView'
import FormatSex from './FormatSex'

export default function GroupEnrolmentPrimaryView({persons, groupPolicyId}) {

  let history = useHistory();

  const [person, setPerson] = React.useState(null);

  useEffect(() => {
    setPerson(persons.find((pers) => pers.relationship == 'primary'));
  }, []);

  if (!person) return null;

  return (
    <Container className="mt-5" style={{padding: 0, margin: 0}}>

      <Row className="enrolment-summary first-row">
        <Col xs="6" sm="4" lg="4">
          <h3>PRIMARY</h3>
        </Col>
        <Col>
          <Button variant="link" style={{padding: 0, marginRight: '0.5em'}} onClick={() => history.push("/ig/enrolment/group_policy/" + groupPolicyId)}>CHANGE</Button>
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Name
        </Col>
        <Col>
          <span style={{marginRight: '0.5em'}}>{person?.firstname} {person?.lastname}</span>
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Birthdate
        </Col>
        <Col>
        {person.date_of_birth &&
          <>
          <span style={{marginRight: '0.5em'}}>{format(new Date(person.date_of_birth), 'dd MMM. yyyy')}</span>
          <span style={{marginRight: '0.5em'}}>{getAge(person.date_of_birth)} years</span>
          </>
        }
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Sex
        </Col>
        <Col>
          <FormatSex person={person} />
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Height
        </Col>
        <Col>
          {formatHeight(person)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Weight
        </Col>
        <Col>
          {formatWeight(person)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Pregnant
        </Col>
        <Col>
          {person.pregnant ? 'YES' : 'NO'}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          BMI
        </Col>
        <Col>
          {getBMI(person)}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Email
        </Col>
        <Col>
          {person.email_address}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Phone
        </Col>
        <Col>
          {person.country_calling_code} {person?.mobile_phone}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Street
        </Col>
        <Col>
          {person.address_line_1}
          {person.address_line_2 &&
            <>
            <br />{person.address_line_2}
            </>
          }
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Postal Code
        </Col>
        <Col>
          {person.postal_code}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          City
        </Col>
        <Col>
          {person.city}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Country&nbsp;of&nbsp;Residence
        </Col>
        <Col>
          {countryName(person.country_of_residence_outside_US)}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Citizenship
        </Col>
        <Col>
          {countryName(person.citizenship)}
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <span style={{fontSize: 'large'}}>Your reponses to the medical question section for {person.firstname} {person.lastname}</span>
          <Button variant="link" style={{padding: 0}} onClick={() => history.push("/ig/enrolment/group_policy/" + groupPolicyId + "/main_questions")}>CHANGE</Button>
          <GroupEnrolmentMedicalQuestionsView person={person} />
        </Col>
      </Row>


    </Container>
  )
}

