// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import { getAge } from '../../exports'

import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import { nanoid } from 'nanoid'

// The useHistory hook gives you access to the history instance that you may use to navigate.
import {Redirect, useHistory, useParams } from "react-router-dom"

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'
import Jumbotron from 'react-bootstrap/Jumbotron'

import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import FormCheck from 'react-bootstrap/FormCheck'
import Feedback from 'react-bootstrap/Feedback'


import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addYears } from '@progress/kendo-date-math'

import CountrySelect from '../../country/CountrySelect'
import {CITIZENSHIPS} from '../../country/countries'

export default function EnrolmentDependent({
  primary,
  dependents,
  setDependents,
  validated}) {

  const topLocation = useRef(null);

  let history = useHistory();

  if (! validated.primary) return <Redirect to="/ig/enrolment/primary" />

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  // @TODO Do we need this?
  const initialDependent = {
    id: null,
    primary_id: null,
    relationship: '',
    firstname: '',
    lastname: '',
    email_address: '',
    sex: '',
    pregnant: false,
    date_of_birth: '',
    country_of_residence_outside_US: '',
    citizenship: '',
    weight: '',
    weight_measurement_unit : '',
    height: '',
    height_measurement_unit: '',
    medical_status: 'medically_approved',
    bmi: 0
  };

    // const [dependent, setDependent] = React.useState({
    //   ...initialDependent,
    //   id: nanoid()
    // });
  // }

  const [dependent, setDependent] = React.useState(initialDependent);

  let { dependent_id } = useParams();

  // version where an id is generated before navigating to ig/enrolment/dependents/
  useEffect(() => {
    let dependentToEdit = dependents.find(dep => dep.id == dependent_id);
    // if there is no dependent with the given ID create a new one
    if (! dependentToEdit) {
      dependentToEdit = {
        id: dependent_id,
        primary_id: primary.id,
        firstname: '',
        lastname: '',
        email_address: '',
        mobile_phone: '',
        sex: '',
        pregnant: false,
        date_of_birth: '',
        country_of_residence_outside_US: '',
        citizenship: '',
        weight: '',
        weight_measurement_unit : '',
        height: '',
        height_measurement_unit: '',
        medical_status: 'medically_approved',
        bmi: 0,
        relationship: '',
        errors: {
          sex: false,
          relationship: false
        }
      };
      // @TODO do not add unsaved dependents
      //setDependents([...dependents, dependentToEdit]);
    }
    setDependent(dependentToEdit);
  }, [dependent_id]);


  const nameValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    return null;
  };

  const countryValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '' || fieldValue.trim() === 'select_country') {
      return `${fieldName} is required`;
    }
    return null;
  };


  const numberValidation = (fieldName, fieldValue) => {
    if (typeof fieldValue == "string" && fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (! isNaN(fieldValue)) {
      return null;
    }
    return `${fieldName} needs to be at valid number`;
  };


  const emailValidation = email => {

    if (dependent.relationship == 'child') return null;

    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };



  const relationshipValidation = (relationship) => {
    if (relationship.trim() === '') {
      return 'Please choose the relationship to the primary.';
    }
    //return ageValidation(dateOfBirth, relationship);
    return null;
  };


  const ageValidation = (dateOfBirth, relationship = dependent.relationship) => {
    //const relationship = dependent.relationship;
    const primaryDateOfBirth = primary.date_of_birth;

    if (! dateOfBirth) {
      return 'Age is required';
    }
    if (! relationship) {
      return 'Unable calculate age without known relationship to primary insured';
    }

    const dep_age = getAge(dateOfBirth);

    if (dep_age > 26 && relationship == 'child') {
      return 'Children must be 26 years or younger';
    }
    const primaryAge = getAge(primaryDateOfBirth);
    if (primaryAge && dep_age >= primaryAge && relationship == 'child') {
      return 'Children must be younger than the primary insured';
    }
    if (dep_age < 17 && relationship == 'partner') {
      return 'Partners must be at least 17 years old';
    }
    if (dep_age > 69) {
      return 'Partners must be between 17 and 69 years old';
    }
    return null;
  };



  const validation = {
    relationship:                    relationship => relationshipValidation(relationship, dependent.date_of_birth),
    firstname:                       name => nameValidation('Firstname', name),
    lastname:                        name => nameValidation('Lastname', name),
    email_address:                   emailValidation,
    country_of_residence_outside_US: country => countryValidation('Country of residence', country),
    citizenship:                     country => countryValidation('Citizenship', country),
    date_of_birth:                   (dateOfBirth, relationship) => ageValidation(dateOfBirth, relationship),
    sex:                             name => nameValidation('Sex', name),
    weight:                          number => numberValidation('Weight', number),
    weight_measurement_unit:         name => nameValidation('Weight measurement unit', name),
    height:                          number => numberValidation('Height', number),
    height_measurement_unit:         name => nameValidation('Height measurement unit', name)
  };








  const handleChange = (event) => {

    let { name, value, type } = event.currentTarget;
    value = type === 'number' ? +value : value;
    //const value = event.target.type === 'number' ? +event.target.value : event.target.value;

    const modifiedDependent = {
      ...dependent,
      [name]: value
    };

    setDependent(modifiedDependent);

    setTouched({
      ...touched,
      [name]: true,
    });

    if (type == 'radio') {
      //const { [event.target.name]: removedError, ...rest } = errors;
      if (name == 'relationship') {
        //validateField(name, value);
        const { 'date_of_birth': removedError, ...rest } = errors;
        //validateField('date_of_birth', dependent.date_of_birth, );
        //const error = validation[name](value, moreArgs);
        const error = ageValidation(dependent.date_of_birth, value);
        setErrors({
          ...rest,
          ...(error && { ['date_of_birth']: touched['date_of_birth'] && error })
        });

      }
      // setErrors({
      //   ...rest
      // });
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    //const { [name]: removedError, ...rest } = errors;
    validateField(name, value);
    // if (typeof validation[name] === 'function') {
    //   const error = validation[name](value);
    //   setErrors({
    //     ...rest,
    //     ...(error && { [name]: touched[name] && error })
    //   });
    // }
  };

  const validateField = (name, value, ...moreArgs) => {
    const { [name]: removedError, ...rest } = errors;
    if (typeof validation[name] === 'function') {
      const error = validation[name](value, moreArgs);
      setErrors({
        ...rest,
        ...(error && { [name]: touched[name] && error })
      });
    }
  };

  // @TODO add validation!
  const handleAddDependent = () => {

    const newDependent = {
      id: nanoid(),
      primary_id: primary.id,
      firstname: '',
      lastname: '',
      email_address: '',
      mobile_phone: '',
      sex: '',
      date_of_birth: '',
      country_of_residence_outside_US: '',
      citizenship: '',
      weight: '',
      weight_measurement_unit : '',
      height: '',
      height_measurement_unit: '',
      relationship: '',
      medical_status: 'medically_approved',
      bmi: 0,
      errors: {
        sex: false,
        relationship: false
      }
    };

    const formValidation = validateForm();
    if (Object.values(formValidation.errors).length < 1) {// errors object is empty
      // @TODO only do this if validated!
      saveDependent(dependent, "/ig/enrolment/dependents/" + newDependent.id);

      // @TODO only do this if validated!
      setDependents([
        ...dependents,
        newDependent
      ]);
    }
  };


  const handleProceed = () => {

    const formValidation = validateForm();

    if (Object.values(formValidation.errors).length < 1) {// errors object is empty
      saveDependent(dependent, "/ig/enrolment/main_questions");
    }
  }


  const validateForm = () => {

    const formValidation = Object.keys(validation).reduce(
      (acc, key) => {
        const newError = validation[key](dependent[key]);
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          }
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      },
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (Object.values(formValidation.errors).length > 0) {
      if (topLocation.current) {
        topLocation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
    return formValidation;
  };

  const saveDependent = (dependent, route) => {
    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + dependent.id,
      data: dependent
    }).then(response => {

      let newDependents = dependents.filter(function(dep) {
        return dep.id != dependent.id
      })
      // newDependents.push({
      //   ...dependent,
      //   medical_status: response.data.medical_status,
      //   bmi: response.data.bmi
      // });
      // setDependents(newDependents);

      setDependents([
        ...newDependents, // @TODO why ...?
        {
          ...dependent,
          medical_status: response.data.medical_status,
          bmi: response.data.bmi
        }
      ]);

      history.push(route)

    }).catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {

          }
        } else {
          throw error;
        }

      });
  };



  return (
    <Form>
      {Object.values(errors).length > 0 &&
        <Jumbotron className="">
          <h3>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h3>
        </Jumbotron>
      }
      <h2 ref={topLocation} className="mt-4">Tell us who you want to include in your health plan</h2>

      <Form.Group as={Row}>
        <Col style={{paddingTop: '2px'}}>
          <Form.Label className="align-middle" style={{marginRight: '2rem', fontWeight: '500'}}>Relationship*</Form.Label>
          <FormCheck inline>
            <FormCheck.Input
              id="relationship_partner"
              name="relationship"
              type="radio"
              checked={dependent.relationship == 'partner'}
              value="partner"
              isInvalid={errors.relationship}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="relationship_partner" style={{marginRight: '2rem'}}>Partner</FormCheck.Label>
            <Form.Check.Input
              id="relationship_child"
              name="relationship"
              type="radio"
              label="Child"
              checked={dependent.relationship == 'child'}
              value="child"
              isInvalid={errors.relationship}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="relationship_child" style={{marginRight: '2rem'}}>Child</FormCheck.Label>
            <Form.Control.Feedback type="invalid">Please choose the relationship to the primary insured.</Form.Control.Feedback>
          </FormCheck>
        </Col>
      </Form.Group>

      <Form.Text className="">Fields marked with an <span>*</span> are required</Form.Text>
      <Form.Group as={Row} controlId="formGroupFirstName">
        <Col sm="12">
          <Form.Control
            type="text"
            name="firstname"
            placeholder="Firstname*"
            required
            value={dependent.firstname}
            isInvalid={errors.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupLastName">
        <Col sm="12">
          <Form.Control
            type="text"
            name="lastname"
            placeholder="Lastname*"
            required
            value={dependent.lastname}
            isInvalid={errors.lastname}
            onChange={handleChange}
            onBlur={handleBlur}

          />
          <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupEmail">
        <Col sm="12">
          <Form.Control
            type="text"
            name="email_address"
            placeholder={dependent.relationship == 'child' ? 'Email' : 'Email*'}
            value={dependent.email_address}
            isInvalid={errors.email_address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide an email address.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupCountryOfResidenceOutsideUS">
        <Col sm="12">
          <CountrySelect
            fieldName="country_of_residence_outside_US"
            handleChange={handleChange}
            validated={validated}
            placeholder="Country of Residence*"
            value={dependent.country_of_residence_outside_US}
            isInvalid={errors.country_of_residence_outside_US}
            onChange={handleChange}
            onBlur={handleBlur}
            exclude={['HK']}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupCitizenship">
        <Col sm="12">
          <CountrySelect
            countries={CITIZENSHIPS}
            fieldName="citizenship"
            handleChange={handleChange}
            validated={validated}
            placeholder="Citizenship*"
            value={dependent.citizenship}
            isInvalid={errors.citizenship}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Form.Group>

      <div className="white-space"></div>
      <h2 className="mt-4">And now we need to get a little personal</h2>

      <Row className="mt-4">
        <Form.Group as={Col} controlId="formGroupAge">
          <DatePicker
            className={'dependent-insured-birthdate form-control ' + (errors.date_of_birth ? 'is-invalid' : '') }
            selected={dependent.date_of_birth}
            dateFormat="yyyy-MM-dd"
            minDate={addYears(new Date(), -70)}
            maxDate={new Date()}
            placeholderText="Date of birth*"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onChange={(date) => {
              let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);

              setDependent({
                ...dependent,
                'date_of_birth': localDate
              });
              setTouched({
                ...touched,
                'date_of_birth': true,
              });

              const { 'date_of_birth': removedError, ...rest } = errors;
              //const error = validation['date_of_birth'](localDate, dependent.relationship);
              const error = ageValidation(localDate, dependent.relationship);

              setErrors({
                ...rest,
                ...(error && { 'date_of_birth': error })
              });
            }}
          />
          {/* @TODO workaround because I can't make Control.Feedback work with radio groups: */}
          {errors.date_of_birth &&
            <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>{errors.date_of_birth}.</div>
          }
          <Form.Text className="">For example 1969-12-31</Form.Text>
          <Form.Control.Feedback type="invalid">Please provide your date of birth.</Form.Control.Feedback>
        </Form.Group>
      </Row>


      <Row className="mt-4">
        <Form.Group as={Col}>
          <Form.Label className="align-middle" style={{marginRight: '2rem', fontWeight: '500'}}>Sex*</Form.Label>
          <FormCheck inline>
            <FormCheck.Input
              id="sex_male"
              name="sex"
              type="radio"
              checked={dependent.sex == 'male'}
              value="male"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="sex_male" style={{marginRight: '2rem'}}>male</FormCheck.Label>
            <FormCheck.Input
              id="sex_female"
              name="sex"
              type="radio"
              checked={dependent.sex == 'female'}
              value="female"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="sex_female" style={{marginRight: '2rem'}}>female</FormCheck.Label>
            <FormCheck.Input
              id="sex_intersex"
              name="sex"
              type="radio"
              checked={dependent.sex == 'intersex'}
              value="intersex"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="sex_intersex" style={{marginRight: '2rem'}}>inter</FormCheck.Label>
            <Form.Control.Feedback type="invalid">Please state your sex.</Form.Control.Feedback>
          </FormCheck>
        </Form.Group>
      </Row>



      {/* WEIGHT */}
      <Row className="mt-4 mb-0">
        <Col md={3} className="mr-1">
          <Form.Group className="mb-1" controlId="formGroupWeight">
            <Form.Control
              type="number"
              name="weight"
              placeholder="Weight*"
              required
              value={dependent.weight}
              isInvalid={errors.weight}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your weight.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Check inline>
              <FormCheck.Input
                id="weight_measurement_unit_kg"
                name="weight_measurement_unit"
                type="radio"
                checked={dependent.weight_measurement_unit == 'kg'}
                value="kg"
                isInvalid={errors.weight_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label htmlFor="weight_measurement_unit_kg" style={{marginRight: '1em'}}>Kilograms</FormCheck.Label>
              <FormCheck.Input
                id="weight_measurement_unit_lbs"
                name="weight_measurement_unit"
                type="radio"
                checked={dependent.weight_measurement_unit == 'lbs'}
                value="lbs"
                isInvalid={errors.weight_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label htmlFor="weight_measurement_unit_lbs">Pounds</FormCheck.Label>
            </Form.Check>
            {errors.weight_measurement_unit &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', width: '100%', marginTop: 0}}>Please choose a weight measurement unit.</div>
            }
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group className="mb-1"  controlId="formGroupHeight">
            <Form.Control
              type="number"
              name="height"
              placeholder="Height*"
              required
              value={dependent.height}
              isInvalid={errors.height}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your height.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Check inline>
              <FormCheck.Input
                id="height_measurement_unit_cm"
                name="height_measurement_unit"
                type="radio"
                checked={dependent.height_measurement_unit == 'cm'}
                value="cm"
                isInvalid={errors.height_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label htmlFor="height_measurement_unit_cm" style={{marginRight: '1em'}}>Centimeters</FormCheck.Label>
              <FormCheck.Input
                id="height_measurement_unit_in"
                name="height_measurement_unit"
                type="radio"
                checked={dependent.height_measurement_unit == 'in'}
                value="in"
                isInvalid={errors.height_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label htmlFor="height_measurement_unit_in" style={{marginRight: '0.5em'}}>Inches</FormCheck.Label>
            </Form.Check>
            {errors.height_measurement_unit &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', width: '100%', marginTop: 0}}>Please choose a height measurement unit.</div>
            }
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="mt-4 mb-5" lg="8">
          <h2>Do you have any additional family members to add to the policy?</h2>
          <div className="text-center">
            <Button variant="secondary" type="button" onClick={handleAddDependent} style={{marginRight: '1em'}}>YES</Button>
            <Button variant="secondary" type="button" onClick={handleProceed}>NO</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

