import React from "react"
import axios from "axios"
import { Redirect, useHistory } from "react-router-dom"

import CustomerAccount from './CustomerAccount'
import Container from 'react-bootstrap/Container'


export default function Dashboard ({formAuthenticityToken, accessToken, setAccessToken}) {
  return (
    <Container fluid className="w-100">
      <CustomerAccount formAuthenticityToken={formAuthenticityToken} accessToken={accessToken} setAccessToken={setAccessToken} />
    </Container>
  );
}