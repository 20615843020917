// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import { TESTING } from '../../exports'
import React, { useState, useEffect } from "react"
import {Redirect, useHistory } from "react-router-dom"
import { format } from 'date-fns'
import { nanoid } from 'nanoid'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Table from 'react-bootstrap/Table'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import EnrolmentPrimaryView from './EnrolmentPrimaryView'
import EnrolmentDependentView from './EnrolmentDependentView'
import EnrolmentPlanOptionsView from './EnrolmentPlanOptionsView'


import { getAge, getBMI, formatHeight, formatWeight } from '../../exports'
import { COUNTRIES, countryName } from '../../country/countries'

export default function EnrolmentOverview({
  primary,
  dependents,
  setDependents,
  setDependentToEdit,
  planOptions,
  premium,
  validated
  }) {

  let history = useHistory();
  if (! TESTING) {
    if (! validated.primary || ! validated.main_questions || ! validated.passport_image || ! validated.plan_options) return <Redirect to="/ig/enrolment/primary" />
  }

  return (
    <Container className="overview-table" style={{padding: 0, margin: 0}}>
      <h2 className="mt-4">Summary</h2>

      <Row className="mt-5 text-center" >
        <Col>
          <Button variant="secondary" type="button" onClick={() => history.push("/ig/enrolment/dependents/" + nanoid())}>ADD DEPENDENT</Button>
          <Button variant="secondary" type="button" onClick={() => history.push("/ig/enrolment/declaration")}>CONTINUE</Button>
        </Col>
      </Row>


      <Row noGutters className="align-items-start">
        <Col lg={12} >
          <EnrolmentPrimaryView
            primary={primary}
          />
        </Col>

      {dependents.map( (dependent, index) => (
        <Col lg={12} key={dependent.id.toString()}>
            <EnrolmentDependentView
              dependent={dependent}
              dependents={dependents}
              setDependents={setDependents}
              setDependentToEdit={setDependentToEdit}
            />
        </Col>
      ))}
      </Row>

      <Row className="align-items-start">
        <Col>
          <EnrolmentPlanOptionsView
            primary={primary}
            dependents={dependents}
            planOptions={planOptions}
            premium={premium}
          />
        </Col>
      </Row>
      <Row className="mt-5 text-center" >
        <Col>
          <Button variant="secondary" type="button" onClick={() => history.push("/ig/enrolment/dependents/" + nanoid())}>ADD DEPENDENT</Button>
          <Button variant="secondary" type="button" onClick={() => history.push("/ig/enrolment/declaration")}>CONTINUE</Button>
        </Col>
      </Row>
    </Container>
  )
}

