// encoding: UTF-8
//
// (C) Copyright 2021-2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"

import {Form, Col, Button, InputGroup, FormControl, Modal} from 'react-bootstrap';

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addYears, addMonths } from '@progress/kendo-date-math'
import { format, formatISO } from 'date-fns'

import QuoteFormCountrySelect from './QuoteFormCountrySelect';
import QuoteFormDependents    from './QuoteFormDependents';



// https://robinpokorny.com/blog/index-as-a-key-is-an-anti-pattern/
// => 2. Using indexes in key Prop https://codeburst.io/how-to-not-react-common-anti-patterns-and-gotchas-in-react-40141fe0dcd#76bd


export default function QuoteFormIntegraglobal({
  authenticity_token,
  site,
  subsite,
  referrerCode
  }) {


  const initialValues = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    age: '',
    sex: 'male',
    country: 'select_country',
    dependents: [],
    site: site,
    subsite: subsite,
    newsletter_agreement: false,
    privacy_policy_agreement: false,
    //preferred_start_date: '',
    preferred_start_date: new Date(new Date().setHours(24, 0, 0, 0)),  // important for DatePicker and Axios libary. MantisBT #4289'',
    errors: {
      country: true,
      privacyPolicyAgreement: false
    }
    //dependents: [{id: 1, lastname: 'eins', age: 21, relationship: 'partner'}, {id: 2, lastname: 'zwei', age: 22, relationship: 'child'}]
  };

  const [quote, setQuote] = React.useState(initialValues);
  const [validated, setValidated] = React.useState(false);
  const [submitPath, setSubmitPath] = React.useState('');
  const [accesshealth, setAccesshealth] = React.useState(false);

  const [showUnitedArabEmirates, setShowUnitedArabEmirates] = React.useState(false);

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  //---------------------------------------------------------------------------
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    let country ='select_country';
    if (site == 'ah') {
      setSubmitPath('/ah/quote_results');
      setAccesshealth(true);
      country = 'AE';
    } else {
      setSubmitPath('/ig/quote_results');
    }

    // Update the document title using the browser API
    if (localStorage.getItem('quote')) {
      const storedQuote = JSON.parse(localStorage.getItem('quote'));

      // The string created with JSON.stringify(quote) does not contain Date objects.  We have to restore the Date  objects
      let preferred_start_date = new Date(storedQuote.preferred_start_date);
      // if the parsed date is not a date initialise it
      if (! (preferred_start_date instanceof Date && !isNaN(preferred_start_date))) {
         preferred_start_date = new Date(new Date().setHours(24, 0, 0, 0));
      }

      setQuote({
        ...storedQuote,
        id: storedQuote.id ? storedQuote.id : 0,
        preferred_start_date: preferred_start_date//new Date(storedQuote.preferred_start_date) // restore Date object from string.
      });
    } else {
      setQuote({
        ...initialValues,
        country: country
      });
    }
  }, []); // empty array => run hook only once




  /*
  const handleRemovePreferredStartDate = (event) => {
    const {preferred_start_date, ...newQuote} = quote;
    setQuote({
      ...newQuote
    });
    localStorage.setItem('quote', JSON.stringify(quote));
  };
  <Button onClick={handleRemovePreferredStartDate}>Remove Preferred Start Date</Button>
  */

  //---------------------------------------------------------------------------
  const handleSubmit = (event) => {
    let numberOfErrors = validateForm();

    if (numberOfErrors > 0) {
      setValidated(false);
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    localStorage.setItem('quote', JSON.stringify(quote));
  };


  const validateForm = () => {
    let numberOfErrors = 0;

    // Primary
    const formValidation = Object.keys(validation).reduce(
      (acc, key) => {
        const newError = validation[key](quote[key]);
        const newTouched = { [key]: true };

        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          }
        };

      },
      {
        errors: {},
        touched: {}
      }
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);
    numberOfErrors += Object.values(formValidation.errors).length;

    // Dependent
    numberOfErrors += validateDependents();
    return numberOfErrors;
  }

  const validateDependents = () => {
    let numberOfErrors = 0;

    let newDependents = [];
    quote.dependents.forEach( (dep, index) => {
      const dependentFormValidation = Object.keys(dependentValidation).reduce(
        (acc, key) => {
          const newError = dependentValidation[key](dep[key], dep.relationship);
          const newTouched = { [key]: true };
          return {
            errors: {
              ...acc.errors,
              ...(newError && { [key]: newError }),
            },
            touched: {
              ...acc.touched,
              ...newTouched,
            }
          };

        },
        {
          errors: {},
          touched: {}
        }
      );
      dep.errors = dependentFormValidation.errors;
      dep.touched = dependentFormValidation.touched;
      newDependents.push({
        ...dep,
        errors: dependentFormValidation.errors,
        touched: dependentFormValidation.touched
      })
      setQuote({
        ...quote,
        dependents: newDependents
      });
      numberOfErrors += Object.values(dependentFormValidation.errors).length;
    });
    return numberOfErrors;
  };












  const nameValidation = (fieldName, fieldValue) => {
    if (! fieldValue || fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    return null;
  };

  const countryValidation = (fieldName, fieldValue, emptyValue) => {
    if (! fieldValue || fieldValue.trim() === emptyValue) {
      return 'Please select your ' + fieldName;
    }
    return null;
  };

  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };


  const ageValidation = (field_name, age) => {
    if (typeof age == "string" && age.trim() === '') {
      return 'Age is required';
    }
    if (! isNaN(age)) {
      const primary_age = parseInt(age);
      if (primary_age < 18) {
        return 'Age must be at least 18';
      }
      if (primary_age > 69) {
        return 'Age must be under 70';
      }
      let error = quote.dependents.reduce(
        (acc, dep) => {
          if (primary_age < parseInt(dep.age) && dep.relationship == 'child') {
            return 'Primary insured must be older than every child';
          }
          return acc;
        },
        null
      );
      return error;
    }
    return 'Age needs to be at valid number';
  };


  const privacyPolicyValidation = (value) => {
    if (value == false) {
      return 'Privay policy must be accepted';
    }
    return null;
  };


  const validation = {
    firstname:                       name => nameValidation('Firstname', name),
    lastname:                        name => nameValidation('Lastname', name),
    email:                           emailValidation,
    country:                         name => countryValidation('Country', name, 'select_country'),
    age:                             age  => ageValidation('Age', age),
    preferred_start_date:            date => (null),
    //newsletter_agreement:
    privacy_policy_agreement:        privacyPolicyValidation
  };





  const dependentAgeValidation = (field_name, age, relationship) => {
    if (typeof age == "string" && age.trim() === '') {
      return 'Please enter the dependent\'s age';
    }
    if (! isNaN(age)) {
      const dep_age = parseInt(age);
      if (dep_age > 26 && relationship == 'child') {
        return 'Children must be 26 years or younger';
      }
      if (quote.age && dep_age >= parseInt(quote.age) && relationship == 'child') {
        return 'Children must be younger than the primary insured';
      }
      if (dep_age < 17 && relationship == 'partner') {
        return 'Partners must be at least 17 years old';
      }
      if (dep_age > 69) {
        return 'Partners must be between 17 and 69 years old';
      }
      return null;
    }
    return 'Age needs to be at valid number';
  };

  const dependentValidation = {
    age:          (age, relationship)          => dependentAgeValidation('Age', age, relationship)
//    relationship: (relationship, dep) => dependentAgeValidation('Age', relationship, dep)
  };




  //---------------------------------------------------------------------------
  const handleChange = (event) => {
    let { name, value, type } = event.currentTarget;

    if (name == 'country') {
      setShowUnitedArabEmirates(value == 'AE');
    }

    if (['privacy_policy_agreement', 'newsletter_agreement'].includes(name)) {
      value = event.target.checked;
    }

    setQuote({
      ...quote,
      [name]: value
    });

    // was the field modified
    setTouched({
      ...touched,
      [name]: true,
    });

    if (type == 'checkbox') {
      const { [name]: removedError, ...rest } = errors;
      setErrors({
        ...rest
      });
    }

  };


  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (typeof validation[name] !== 'function') return;
    const { [name]: removedError, ...rest } = errors;
    const error = validation[name](value);
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error })
    });
    if (name == 'age') {
      validateDependents();
    }
  };





  const UaeRedirectDialog = (props) => {
    const handleClose = (answer) => {
      if (answer == 'yes') {
        window.location = window.location.protocol + "//accesshealth.ae" + (window.location.port ? ':' + window.location.port: '');
      } else {
        setQuote({
          ...quote,
          'country': 'select_country'
        });
      }
      setShowUnitedArabEmirates(false);
    };
    //const handleShow = () => setShowUnitedArabEmirates(true);

    return (
      <>
        <Modal show={showUnitedArabEmirates} onHide={() => handleClose('no')}>
          <Modal.Header closeButton>
            <Modal.Title>United Arab Emirates</Modal.Title>
          </Modal.Header>
          <Modal.Body>UAE is available on our site <strong>accesshealth.ae</strong>. Do you want to go there?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose('no')}>
              No
            </Button>
            <Button variant="primary" onClick={() => handleClose('yes')}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }




//      validated={validated && numberOfErrors() < 1}

  return (
    <Form
      action={submitPath}
      className="quote-form"
      noValidate
      onSubmit={handleSubmit}
      method="POST"
    >
      <Form.Control type="text" hidden name="id" value={quote.id} onChange={handleChange} />
      <Form.Control type="text" hidden name="referrer_code" value={referrerCode} onChange={handleChange} />
      <Form.Control type="text" hidden name="site" value={site} onChange={handleChange} />
      <Form.Control type="text" hidden name="subsite" value={subsite} onChange={handleChange} />


      <Form.Text className="">
        Fields marked with an <span>*</span> are required
      </Form.Text>

      <Form.Row>
        <Form.Group controlId="formGroupFirstName">
          <Form.Label srOnly>
            First name
          </Form.Label>
          <Form.Control
            type="text"
            name="firstname"
            placeholder="First name*"
            value={quote.firstname}
            isInvalid={errors.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid" tooltip={true}>Please provide a first name.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group controlId="formGroupLastName">
            <Form.Label srOnly>
            Last name
          </Form.Label>
          <Form.Control
            type="text"
            name="lastname"
            placeholder="Last name*"
            value={quote.lastname}
            isInvalid={errors.lastname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid" tooltip={true}>Please provide a last name.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>


      <Form.Row>
        <Form.Group controlId="formGroupEmail">
          <Form.Label srOnly>
            Email
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Your email*"
            value={quote.email}
            isInvalid={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid" tooltip={true}>Please provide an email address.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Col className="lg-3 sm-12">
          <Form.Group controlId="formGroupAge">
            <Form.Label srOnly>
              Your age
            </Form.Label>
            <Form.Control
              type="number"
              name="age"
              placeholder="Age*"
              value={quote.age}
              isInvalid={errors.age}
              onChange={handleChange}
              onBlur={handleBlur}
              min="18"
              max="69"
            />
            <Form.Control.Feedback type="invalid" tooltip={true}>
              {errors.age}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formGroupCountry">
            <Form.Label srOnly>Country</Form.Label>
            <QuoteFormCountrySelect
              className="custom-select"
              fieldName="country"
              validated={validated}
              placeholder="Country of Residence*"
              value={quote.country}
              isInvalid={errors.country}
              onChange={handleChange}
              onBlur={handleBlur}
              exclude={['HK']}
            />
            <Form.Control.Feedback type="invalid" tooltip={true}>Please select a country.</Form.Control.Feedback>
          </Form.Group>

        </Col>
      </Form.Row>





     <Form.Row className="">
        <Form.Group controlId="formGroupPreferredStart">
          <Form.Label srOnly>
            Preferred Start Date
          </Form.Label>

          <DatePicker
            name="preferred_start_date"
            className={'quote-preferred-start-date form-control ' + (errors.preferred_start_date ? 'is-invalid' : '') }
            selected={quote.preferred_start_date}
            dateFormat="dd-LL-yyyy"
            minDate={addYears(new Date(), -2) /* minDate={new Date(new Date().setHours(24, 0, 0, 0))} */ }
            maxDate={addYears(new Date(), +1)}
            placeholderText="Preferred start date"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onChange={(date) => {
              let localDate = initialValues.preferred_start_date;
              if (date !== null) {
                localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
              }
              setQuote({
                ...quote,
                'preferred_start_date': localDate
              });
              setTouched({
                ...touched,
                'preferred_start_date': true,
              });

{/*              const { 'preferred_start_date': removedError, ...rest } = errors;
              const error = validation['preferred_start_date'](localDate); //(new Date(format(date, 'yyyy-MM-dd')));  <= also works
              setErrors({
                ...rest,
                ...(error && { 'preferred_start_date': error })
              });
*/}
            }}
          />
          {/* @TODO workaround because I can't make Control.Feedback work with radio groups: */}
{/*          {errors.preferred_start_date &&
            <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>{errors.preferred_start_date}</div>
          }
*/}
          <Form.Text muted className="">Preferred start date DD-MM-YYYY. For example {format(addMonths(new Date(), +1), 'dd-LL-yyyy')}.</Form.Text>
          <Form.Control.Feedback type="invalid">Please provide a preferred start date.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>


      <QuoteFormDependents
        quote={quote}
        setQuote={setQuote}
        validated={validated}
        setValidated={setValidated}
        validation={dependentValidation}
        validateForm={validateForm}
      />


      <UaeRedirectDialog />


      <Form.Row className="mt-3 mb-3" style={{fontSize: 'smaller'}}>
        <Col>
          <Form.Group controlId="newsletterAgreement">
            <Form.Check
              type="checkbox"
              name="newsletter_agreement"
              id="checkbox-keep-me-informed"
              label="Yes, keep me informed of new product information from Integra Global"
              onChange={handleChange}
              value="true"
              isInvalid={errors.newsletter_agreement}
              checked={quote.newsletter_agreement === true}
            />
          </Form.Group>


          <Form.Group controlId="privacyPolicyAgreement">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={quote.privacy_policy_agreement === true}
                isInvalid={errors.privacy_policy_agreement}
                value="true"
                onChange={handleChange}
                name="privacy_policy_agreement"
              />
              <Form.Check.Label>
                I agree to the terms outlined in the privacy policy. By progressing to get an
                indicative price you agree that we or our appointed agents may contact you to
                discuss your quote and your needs, and to our using your personal information
                in accordance with ourr <a href="/ig/privacy_policy">Privacy Policy</a>.
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid" tooltip={true} >
                Sorry. We are unable to give you an indicative price unless you agree to our
                <a href="/ig/privacy_policy">privacy policy</a>.
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
        </Col>
      </Form.Row>




     <Form.Row className="mt-3">
       <Button type="submit">Get quote</Button>
     </Form.Row>


    </Form>
  )
}