// encoding: UTF-8
//
// (C) Copyright 2021-2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import axios from "axios";

import NumberFormat from 'react-number-format';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import QuoteResultFormSendEmail from '../../quote/QuoteResultFormSendEmail';


export default function QuoteResultFormPremium({
  quoteId,
  commonQuoteOptions,
  setCommonQuoteOptions
}) {
  const coverRegions = {
    worldwide: 'including US/Canada',
    worldwide_sans_us: 'excluding US/Canada'
  };

  const paymentPeriods = {
    annual: 'Annual',
    semiannual: 'Semi-annual',
    quarterly: 'Quarterly'
  };

  const networks = {
    silver:   'Silver',
    gold:     'Gold',
    platinum: 'Platinum'
  };

  const addBenefits = {
    USD: {
      your:    [25000, 50000, 100000, 150000, 250000],
      premier: [100000, 150000, 250000]
    },
    EUR: {
      your:    [20000, 40000,  80000, 120000, 200000],
      premier: [80000, 120000, 200000]
    },
    GBP: {
      your:    [17500, 35000,  70000, 105000, 175000],
      premier: [70000, 105000, 175000]
    }
  };

  const deductibles = {
    USD: [0, 100, 200, 500, 1000, 5000],
    EUR: [0,  75, 150, 400,  750, 4000],
    GBP: [0,  65, 125, 300,  650, 3000]
  };




  const findMatchingDeductible = (deductible, currency, previousCurrency) => {
    const index = deductibles[previousCurrency].findIndex((deductible) => deductible == quote.deductible)
    if (index > -1) {
      return deductibles[currency][index];
    }
    return deductibles[currency][2];
  };


  const findMatchingAddBenefit = (add_benefit, currency, previousCurrency) => {
    const index = addBenefits[previousCurrency]['premier'].findIndex((add_benefit) => add_benefit == quote.add_benefit)
    if (index > -1) {
      return addBenefits[currency]['premier'][index];
    }
    return addBenefits[currency]['premier'][0];
  };



  const annualMaximumPlanBenefit = {
    USD: {
      life:   2000000,
      family: 2000000
    },
    EUR: {
      life:   1500000,
      family: 1500000
    },
    GBP: {
      life:   1250000,
      family: 1250000
    }
  };


  const lifeCover = {
    USD: {
      your:    5000,
      premier: 10000
    },
    EUR: {
      your:    5000,
      premier: 10000
    },
    GBP: {
      your:    5000,
      premier: 10000
    }
  }

  const currencyPrefix = {
    USD: '$',
    EUR: '€',
    GBP: '£'
  };


  const initialValues = {
    id: quoteId,
    firstname: '',
    lastname: '',
    email: '',
    age: '',
    country: 'select_country',
    dependents: [],
    premium_plan: 'premierlife_row_17_v',
    deductible: 200,
    add_benefit: 100000,
    geographical_cover: 'worldwide_sans_us',
    payment_period: 'quarterly',
    // maternity_cover: false,
    // dental_option: false,
    currency: 'USD',
    network: 'silver',        // CHKS_18_NETWORKS: [['Platinum', 'platinum'], ['Gold', 'gold'], ['Silver', 'silver']],
    preferred_start_date: new Date(new Date().setHours(24, 0, 0, 0))
  };
  const [quote, setQuote] = React.useState(initialValues);
  const [premium, setPremium] = React.useState(-1);
  const [optionsVisible, setOptionsVisible] = React.useState({
    deductible: false,
    add: false,
    geographical_cover: false,
    payment_period: false,
    network: false
  });
  const [showSendEmail, setShowSendEmail] = React.useState(false);
  const [showingInitialValues, setShowingInitialValues] = React.useState(true);
  const [csRegion, setCsRegion] = React.useState(false);

  // --------------------------------------------------------------------------
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    if (localStorage.getItem('quote')) {
      const storedQuote = JSON.parse(localStorage.getItem('quote'));
      storedQuote.preferred_start_date = new Date(storedQuote.preferred_start_date);

      let maternity_cover = false
      const number_of_children = storedQuote.dependents.reduce((total, dep) => {
          return dep.relationship == 'child' ? total + 1 : total;
      }, 0)

      let premium_plan = '';
      if (['CN', 'MO', 'SG'].includes(storedQuote.country)) {
        setCsRegion(true);
        premium_plan = 'premierlife_cs_18_v';
        if (number_of_children > 0) {
          premium_plan = 'premierfamily_cs_18_v';
          maternity_cover = true;
        }
      } else {
        premium_plan = 'premierlife_row_17_v';
        if (number_of_children > 0) {
          premium_plan = 'premierfamily_row_17_v';
          maternity_cover = true;
        }
      }

      // @TODO why is this not working?
      setQuote({
        ...initialValues,
        ...storedQuote,
        maternity_cover: maternity_cover,
        premium_plan: premium_plan,
        id: quoteId
      });


      // setCommonQuoteOptions({
      //   ...commonQuoteOptions,
      //   ...storedQuote,
      //   maternity_cover: maternity_cover
      // });

      setShowingInitialValues(false);
    } else {
      setQuote(initialValues);
    }
  }, []); // empty array => run hook only once

  // --------------------------------------------------------------------------
  useEffect(() => {
    if (showingInitialValues == false) {
      localStorage.setItem('quote', JSON.stringify(quote));
    }
    getQuote();
  }, [quote]); // empty array => run hook only once


  // --------------------------------------------------------------------------
  useEffect(() => {
    if (showingInitialValues) {
      return;
    }
    let options = {
      geographical_cover: commonQuoteOptions.geographical_cover,
      payment_period:     commonQuoteOptions.payment_period,
      maternity_cover:    commonQuoteOptions.maternity_cover,
      dental_option:      commonQuoteOptions.dental_option,
      currency:           commonQuoteOptions.currency,
      network:            commonQuoteOptions.network
    };

    let mapped_deductible  = commonQuoteOptions.deductible
    let mapped_add_benefit = commonQuoteOptions.add_benefit

    if (commonQuoteOptions.currency != quote.currency) {
      mapped_deductible  = findMatchingDeductible(commonQuoteOptions.deductible,  commonQuoteOptions.currency, quote.currency);
      mapped_add_benefit = findMatchingAddBenefit(commonQuoteOptions.add_benefit, commonQuoteOptions.currency, quote.currency);
      options.currency = commonQuoteOptions.currency;
    }

    // @TODO delete key from hash options instead?
    if (deductibles[commonQuoteOptions.currency].includes( mapped_deductible )) {
      options.deductible = mapped_deductible;
    }
    if (addBenefits[commonQuoteOptions.currency]['premier'].includes( mapped_add_benefit )) {
      options.add_benefit = mapped_add_benefit;
    }




    // options['premium_plan'] = 'premierlife_row_17_v';
    // if (commonQuoteOptions['maternity_cover']) {
    //   options['premium_plan'] = 'premierfamily_row_17_v';
    // }
    if (['CN', 'MO', 'SG'].includes(quote.country)) {
      options['premium_plan'] = 'premierlife_cs_18_v';
      if (commonQuoteOptions['maternity_cover']) {
        options['premium_plan'] = 'premierfamily_cs_18_v';
      }
    } else {
      options['premium_plan'] = 'premierlife_row_17_v';
      if (commonQuoteOptions['maternity_cover']) {
        options['premium_plan'] = 'premierfamily_row_17_v';
      }
    }

    setQuote({
      ...quote,
      ...options
    });

    localStorage.setItem('common_quote_options', JSON.stringify(commonQuoteOptions));
  }, [commonQuoteOptions]);



  // // --------------------------------------------------------------------------
  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {

  //     // var bodyFormData = new FormData();
  //     // axios({
  //     //   method: 'get',
  //     //   url: '/accesshealth/quote_results',
  //     //   data: bodyFormData,
  //     //   headers: {'Content-Type': 'multipart/form-data' }
  //     // })
  //     // .then(function (response) {
  //     //     //handle success
  //     // })
  //     // .catch(function (response) {
  //     //     //handle error
  //     // });
  //   }

  //   localStorage.setItem('quote', JSON.stringify(quote));
  // };


  // --------------------------------------------------------------------------
  const handleChange = (event) => {
    //const value = ['maternity_cover', 'dental_option'].includes(event.target.name) ? event.target.checked : event.target.value

    if (['maternity_cover', 'dental_option'].includes(event.target.name)) {
      var value = event.target.checked;
    } else if (['deductible', 'add_benefit'].includes(event.target.name)) {
      var value = Number(event.target.value);
    } else {
      var value = event.target.value;
    }



    let premium_plan = '';
    if (['CN', 'MO', 'SG'].includes(quote.country)) {
      premium_plan = 'premierlife_cs_18_v';
      if (event.target.name == 'maternity_cover') {
        if (value) {
          premium_plan = 'premierfamily_cs_18_v';
        }
      }
    } else {
      premium_plan = 'premierlife_row_17_v';
      if (event.target.name == 'maternity_cover') {
        if (value) {
          premium_plan = 'premierfamily_row_17_v';
        }
      }
    }

    if (Object.keys(commonQuoteOptions).includes(event.target.name)) {
      setCommonQuoteOptions({
        ...commonQuoteOptions,
        [event.target.name]: value,
        premium_plan: premium_plan
      })
    } else {

      // let premium_plan = 'premierlife_row_17_v';
      // if (event.target.name == 'maternity_cover') {
      //   if (value) {
      //     premium_plan = 'premierfamily_row_17_v';
      //   }
      // }
      setQuote({
        ...quote,
        [event.target.name]: value,
        premium_plan: premium_plan
      });
    }
  };

  // --------------------------------------------------------------------------
  const handleOptionsVisible = (event) => {
    const option = event.target.getAttribute('data-options-visible');
    setOptionsVisible({
      ...optionsVisible,
      [option]: ! optionsVisible[option]
    });
  };


  // --------------------------------------------------------------------------
  const getQuote = () => {

    if (showingInitialValues) {
      return;
    }

    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/quotes/get_premium.json", {
          params: {
            plan:                 quote.premium_plan,
            country_of_residence: quote.country,
            geographical_cover:   commonQuoteOptions['geographical_cover'],
            deductible:           quote.deductible,
            age:                  quote.age,
            dependents:           JSON.stringify(quote.dependents),
            add_option:           true,
            add_benefit:          quote.add_benefit,
            currency:             commonQuoteOptions.currency,
            dental_option:        quote.dental_option,
            network:              quote.network,
            preferred_start_date: quote.preferred_start_date
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setPremium(response.data.rates.total_due[commonQuoteOptions['payment_period']]);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {
          }
        } else {
          throw error;
        }

      });
  };


  // Components ---------------------------------------------------------------

  const Deductibles = () => {
    if (optionsVisible.deductible) {
      return (
        <div>
          {deductibles[commonQuoteOptions.currency].map( (deductible, index) => (
            <Form.Check key={"deductible_" + deductible} inline type="radio">
              <Form.Check.Input
                name="deductible"
                value={deductible}
                type="radio"
                checked={quote.deductible == deductible}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }


  const AccidentalDeathAndDisablement = () => {
    if (optionsVisible.add) {
      return (
        <div>
          {addBenefits[commonQuoteOptions.currency]['premier'].map( (add_benefit, index) => (
            <Form.Check key={"add_benefit_" + add_benefit} inline type="radio">
              <Form.Check.Input
                name="add_benefit"
                value={add_benefit}
                type="radio"
                checked={quote.add_benefit == add_benefit}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }

  const CoverRegion = () => {
    if (optionsVisible.geographical_cover) {
      return (
        <div>
          <Form.Check key="geographical_cover_worldwide_sans_us" inline type="radio">
            <Form.Check.Input
              name="geographical_cover"
              value="worldwide_sans_us"
              type="radio"
              checked={quote.geographical_cover == 'worldwide_sans_us'}
              onChange={handleChange}
            />
            <Form.Check.Label>Worldwide excluding US/Canada</Form.Check.Label>
          </Form.Check>

          <Form.Check key="geographical_cover_worldwide" inline type="radio">
            <Form.Check.Input
              name="geographical_cover"
              value="worldwide"
              type="radio"
              checked={quote.geographical_cover == 'worldwide'}
              onChange={handleChange}
            />
            <Form.Check.Label>Worldwide including US/Canada</Form.Check.Label>
          </Form.Check>
        </div>
      );
    } else return null;
  };


  const PaymentPeriod = () => {
    if (optionsVisible.payment_period) {
      return (
        <div>
          {['annual', 'semiannual', 'quarterly'].map( (payment_period, index) => (
            <Form.Check key={"payment_period_" + payment_period} inline type="radio">
              <Form.Check.Input
                name="payment_period"
                value={payment_period}
                type="radio"
                checked={quote.payment_period == payment_period}
                onChange={handleChange}
              />
              <Form.Check.Label>
                {paymentPeriods[payment_period]}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  };


  const Network = () => {
    if (optionsVisible.network) {
      return (
        <div>
          {['silver', 'gold', 'platinum'].map( (network, index) => (
            <Form.Check key={"network_" + network} inline type="radio">
              <Form.Check.Input
                name="network"
                value={network}
                type="radio"
                checked={quote.network == network}
                onChange={handleChange}
              />
              <Form.Check.Label>
                {networks[network]}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  };


  const AddOns = () => {
    return (
      <div className="checkboxes">
        <div className="checkbox">
          <Form.Check
            id="premium_maternity_cover"
            name="maternity_cover"
            type="checkbox"
            label="Maternity cover"
            checked={quote.maternity_cover}
            onChange={handleChange}
          />
        </div>
        <div className="checkbox">
          <Form.Check
            id="premium_dental_option"
            name="dental_option"
            type="checkbox"
            label="Dental plan"
            checked={quote.dental_option}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };



  const FormatPremium = (props) => {
    if (props.premium == -1) {
      return (
        <span style={{fontSize: '40px'}}>Loading...</span>
      );
    } else {
      let paymentPeriod = '';
      switch (props.paymentPeriod) {
        case 'annual':
          paymentPeriod = '1 annual payment of';
          break;
        case 'semiannual':
          paymentPeriod = '2 semi-annual payments of';
          break;
        case 'quarterly':
          paymentPeriod = '4 quarterly payments of';
          break;
      }

      return (
        <>
          <div style={{fontSize: 'small', whiteSpace: 'nowrap'}}>{paymentPeriod}</div>
          <div className="m-0 p-0" style={{whiteSpace: 'nowrap', lineHeight: '90%'}}>
            <span className="quote__table__pricing__currency">{currencyPrefix[commonQuoteOptions.currency]}</span>
            <NumberFormat value={props.premium } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={2} />{props.children}
          </div>
        </>
      );
    }
  };

  const handleEnrolmentClick = (event) => {
    window.location = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '') +
                      '/ig/enrolment' +
                      '?quote_id=' + quote.id +
                      '&plan_group=premium' +
                      //'&co_pay=' + quote.co_pay +
                      '&geographical_cover=' + quote.geographical_cover +
                      '&currency=' + quote.currency +
                      '&add_benefit=' + quote.add_benefit +
                      '&plan=' + quote.premium_plan +
                      '&deductible=' + quote.deductible +
                      '&payment_period=' + quote.payment_period +
                      '&network=' + quote.network +
                      '&dental_option=' + quote.dental_option +
                      '&maternity_cover=' + quote.maternity_cover;
  };

  const handleLearnMore = () => {
    if (quote.maternity_cover) {
      window.location.href = "/ig/personal_plans_premium_family";
    } else {
      window.location.href = "/ig/personal_plans_premium_individual";
    }
  };


  return (
    <>
    <Form className="quote-result-form quote-form-premium" noValidate>
      <div className="quote__table__row quote__table__heading">
        <div className="plan-type">PREMIUM</div>
        <div className="plan-name">{['premierlife_row_17_v', 'premierlife_cs_18_v'].includes(quote.premium_plan) ? 'premierLife' : 'premierFamily'} </div>
        <div className="plan-description">
          If you want the best health cover but don't want to pay an arm and a leg for it,
          then our Premier plans are made to order for you. Simple to understand yet
          extensive protection, convenience and service.
        </div>
        <Button variant="secondary" className="plan-learn-more" onClick={handleLearnMore}>LEARN MORE</Button>
      </div>

      <div className="quote__table__row quote__table__pricing">
        <FormatPremium premium={premium}  paymentPeriod={quote.payment_period}><i style={{color: '#b8e673', verticalAlign: 'text-top', textShadow: '0 1px 1px rgba(0, 0, 0, 0.3)'}} className="fa fa-asterisk"></i></FormatPremium>
      </div>



      <div className="quote__table__row">
        Annual Maximum Plan Benefit
        <br />
        <strong>
        <NumberFormat
          value={annualMaximumPlanBenefit[commonQuoteOptions.currency][quote.maternity_cover ? 'family' : 'life']}
          displayType={'text'}
          thousandSeparator={true}
          prefix={currencyPrefix[commonQuoteOptions.currency]} />
        </strong>
      </div>


      <div className="quote__table__row">
        Deductible:&ensp;
        <strong><NumberFormat value={quote.deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} /></strong>
        <Button className="change" variant="light" size="sm" data-options-visible="deductible" onClick={handleOptionsVisible}>CHANGE</Button>
        <Deductibles />
      </div>

      <div className="quote__table__row">
        Accidental death and disablement:&ensp;
        <strong><NumberFormat value={quote.add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} /></strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="add" onClick={handleOptionsVisible}>CHANGE</Button>
        <AccidentalDeathAndDisablement />
      </div>


      <div className="quote__table__row">
        Worldwide cover <strong>{coverRegions[quote.geographical_cover]}</strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="geographical_cover" onClick={handleOptionsVisible}>CHANGE</Button>
        <CoverRegion />
      </div>

      <div className="quote__table__row">
        Payment method: <strong>{paymentPeriods[quote.payment_period]}</strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="payment_period" onClick={handleOptionsVisible}>CHANGE</Button>
        <PaymentPeriod />
      </div>

      {csRegion &&
      <div className="quote__table__row">
        Network: <strong>{networks[quote.network]}</strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="network" onClick={handleOptionsVisible}>CHANGE</Button>
        <Network />
      </div>
      }

      <div className="quote__table__row">
        Add-ons<br />
        <AddOns />
      </div>

      <div className="quote__table__row">
        <Button className="btn--big" onClick={handleEnrolmentClick}>GET COVER</Button>
      </div>

      <div className="quote__table__row text-left p-3" style={{fontSize: 'x-small'}}>
        <p><i style={{color: 'rgb(150, 196, 81)', verticalAlign: 'top', fontSize: '.6rem', top: '.2rem'}} className="fa fa-asterisk"></i>
        These rates are indicative only and based on no medical conditions being declared.</p>
      </div>

    </Form>

    <QuoteResultFormSendEmail
      quote={quote}
      handleChange={handleChange}
      show={showSendEmail}
      onHide={() => setShowSendEmail(false)}
    />
    </>
  )
}
