// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { useEffect, forwardRef } from 'react'
import { Link, useHistory } from "react-router-dom"
import axios from "axios";

import { useSelector, useDispatch } from 'react-redux'
import { setIlRenewalTriggerDurations } from '../redux/ilRenewalTriggerDurationsSlice'
import { logout } from '../redux/ilUsersSlice'

import { format } from 'date-fns'

import Container from 'react-bootstrap/Container'
import { Table, Row, Col, Button, Modal, Alert } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Jumbotron from 'react-bootstrap/Jumbotron'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faSync, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
//import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import RenewalTriggerDurationForm from "./RenewalTriggerDurationForm"
import RenewalTriggerDurationDeleteModal from "./RenewalTriggerDurationDeleteModal"

export default function RenewalTriggerDurations() {
  let history = useHistory();

  const dispatch = useDispatch();
  const ilUsers = useSelector(state => state.ilUsers)
  const ilRenewalTriggerDurations = useSelector(state => state.ilRenewalTriggerDurations)


  // Declare a new state variable, which we'll call "rows"
//  const [rows, setRows] = React.useState([]);
//  const [totalCount, setTotalCount] = React.useState(-1);
  const [error, setError] = React.useState('');
  const [exceptionMessage, setExceptionMessage] = React.useState('');
  // const [alert, setAlert] = React.useState('');  // Success alert
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [showEditRenewalTriggerDuration, setShowEditRenewalTriggerDuration] = React.useState(false);
  const [renewalTriggerDuration, setRenewalTriggerDuration] = React.useState(null);

  useEffect(() => {
    doSearch();
  }, []);

  // useEffect(() => {

  // }, [rows]);

  const addRenewalTriggerDuration = (values) => {
    setRenewalTriggerDuration({
      il_underwriter_id: 0,
      il_plan_region_id: 0,
      weeks: 3
    })
    setShowEditRenewalTriggerDuration(true);
  };

  // const handleReload = options => () => {
  //   doSearch(options);
  // };


  // const doSearch = ({values, showAlert} = {showAlert: false}) => {
  const doSearch = () => {

    (async () => {

      try {
        const response = await axios.get( // zwecks visibility of query string in URL
          window.location.protocol + '//' + window.location.host + "/il_ws/il_individual_policies/renewal_trigger_durations.json",
          {
            //timeout: 5000,
            headers: {
              'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
            }
            // ,
            // params: {
            //   sort: 'name'
            // }
          },
          { withCredentials: true }
        );
        setError(null);
        // if (showAlert) {  // Success alert
        //   setAlert('Records loaded successfully.');
        // }
        dispatch(setIlRenewalTriggerDurations({
          rows: response.data.il_renewal_trigger_durations,
          totalCount: response.data.total_count
        }));
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status == 401) {
            dispatch(logout());
          }
          setError(error.toJSON().message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          setError('The server is not responding. Please check your Internet connection.');
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(error.message);
        }
      }
    })();
  }



  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Renewal Trigger Durations</Breadcrumb.Item>
      </Breadcrumb>

      <RenewalTriggerDurationForm
        handleClose={() => setShowEditRenewalTriggerDuration(false)}
        show={showEditRenewalTriggerDuration}
        renewalTriggerDuration={renewalTriggerDuration}
      />

      <RenewalTriggerDurationDeleteModal
        renewalTriggerDuration={renewalTriggerDuration}
        show={showDeleteConfirmation}
        setShow={setShowDeleteConfirmation}
      />



      {error &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>😳 This shouldn't have happened.</h3>
          <h1>{error}</h1>
        </Jumbotron>
      }

      {/*alert &&
        <Alert variant='success' dismissible onClose={() => setAlert(null)}>
          <h3>😀 {alert}</h3>
        </Alert>
      */}



      <Container className="float-left">
        <Row className="align-items-center">
        <Col>
        <Button variant="primary" onClick={addRenewalTriggerDuration}>Add</Button>
        </Col>
        <Col className="text-right" style={{verticalAlign: 'middle'}}>
          <FontAwesomeIcon
            icon={faSync}
            onClick={doSearch /*handleReload({showAlert: true})*/}
          />
        </Col>
        </Row>


        <Table responsive striped bordered hover id="il-users-table" className="result-table mt-3">
          <thead className="">
            <tr>
              <th style={{whiteSpace: 'nowarp', textAlign: 'center'}}>Edit</th>
              <th>Underwriter</th>
              <th>Region</th>
              <th>Weeks</th>
              <th style={{whiteSpace: 'nowarp', textAlign: 'center'}}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {ilRenewalTriggerDurations.rows.map(row => (
              <tr key={row.id}>
                <td style={{textAlign: 'center'}}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => {
                      setRenewalTriggerDuration(ilRenewalTriggerDurations.rows.find((rtd) => row.id == rtd.id));
                      // setAlert(''); // Success alert
                      setShowEditRenewalTriggerDuration(true);
                    }}
                  />
                </td>
                <td>
                  {row.il_underwriter?.name}
                </td>
                <td>
                  {row.il_plan_region?.name}
                </td>
                <td>
                  {row.weeks}
                </td>
                <td style={{textAlign: 'center'}}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    onClick={() => {
                      setRenewalTriggerDuration(ilRenewalTriggerDurations.rows.find((rtd) => row.id == rtd.id));
                      //setAlert(''); // Success alert
                      setShowDeleteConfirmation(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>


    </>
  );

}
