// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { forwardRef, useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import * as yup from 'yup'

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import {Form, Col, Button, InputGroup, FormControl, Modal} from 'react-bootstrap'

import SearchForm from "./SearchForm"
import CountryForm from "./CountryForm"
import { format, parseISO} from "date-fns"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'



export default function Countries(props) {

  const [totalCount, setTotalCount] = React.useState(-1);
  const [countries, setCountries] = React.useState([]);
  const [showEditCountry, setShowEditCountry] = React.useState(false);
  const [country, setCountry] = React.useState({
    iso: '',
    name: '',
    calling_code: '',
    plan_region:  ''
  });

  let history = useHistory();

  // useEffect(() => {
  //   doSearch({searchText: ''});
  // }, []); // empty array => run hook only once

  const formatDate = date_string => (
    date_string ? format(parseISO(date_string), "dd MMM Y") : ''
  );


  const doReset = () => {
    setTotalCount(-1);
  }

  const doSearch = (values) => {
    //"sort"=>"[{\"property\":\"created_at\",\"direction\":\"DESC\"}]"
    const sort = [{property: "name", direction: "ASC"}]
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/il_ws/il_countries.json", {
          params: {
            name: values.searchText,
            sort: JSON.stringify(sort)
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setCountries(response.data.il_countries);
        setTotalCount(response.data.total_count);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/ig/admin");
          } else {
            //setWsError(error.response.data.message);
          }
        } else {
          throw error;
        }

      });
  }


  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/masterdata' }}>Admin</Breadcrumb.Item>
        <Breadcrumb.Item active>Countries</Breadcrumb.Item>
      </Breadcrumb>


      <CountryForm
        handleClose={() => setShowEditCountry(false)}
        show={showEditCountry}
        country={country}
        setCountry={setCountry}
        countries={countries}
        setCountries={setCountries}
      />

      <SearchForm {...props} doSearch={doSearch} totalCount={totalCount} doReset={doReset} />

      <Container fluid>
        <Table striped bordered hover id="il-countries-table" className="result-table mt-3">
          <thead className="">
            <tr>
              <th></th>
              <th>ISO 2</th>
              <th>Name</th>
              <th>ISO 3</th>
              <th>Number</th>
              <th>Calling Code</th>
            </tr>
          </thead>
          <tbody>
            {countries.map(country => (
              <tr key={country.id}>
                <td>
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => {
                      setCountry(countries.find((c) => country.id == c.id));
                      setShowEditCountry(true);
                    }}
                  />
                </td>
                <td>{country.iso}</td>
                <td>{country.name}</td>
                <td>{country.iso3}</td>
                <td>{country.numcode}</td>
                <td>{country.calling_code}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
