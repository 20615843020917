// encoding: UTF-8
//
// (C) Copyright 2021-2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import axios from "axios";

import NumberFormat from 'react-number-format';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import QuoteResultFormSendEmail from '../../quote/QuoteResultFormSendEmail';

export default function QuoteResultFormCore({
  quoteId,
  commonQuoteOptions,
  setCommonQuoteOptions
}) {
  const paymentPeriods = {
    annual:     'Annual',
    semiannual: 'Semi-annual',
    quarterly:  'Quarterly'
  };

  const addBenefits = {
    USD: {
      your:    [50000, 100000, 150000, 250000],
      premier: [100000, 150000, 250000]
    },
    EUR: {
      your:    [40000, 80000,  120000, 200000],
      premier: [80000, 120000, 200000]
    },
    GBP: {
      your:    [35000, 70000,  105000, 175000],
      premier: [70000, 105000, 175000]
    }
  };

  const deductibles = {
    USD: [0, 100, 200, 500, 1000, 5000],
    EUR: [0,  75, 150, 400,  750, 4000],
    GBP: [0,  65, 125, 300,  650, 3000]
  };


  const findMatchingDeductible = (deductible, currency, previousCurrency) => {
    const index = deductibles[previousCurrency].findIndex((deductible) => deductible == quote.deductible)
    if (index > -1) {
      return deductibles[currency][index];
    }
    return deductibles[currency][2];
  };

  const findMatchingAddBenefit = (add_benefit, currency, previousCurrency) => {
    const index = addBenefits[previousCurrency][quote.premier_incare ? 'premier' : 'your'].findIndex((add_benefit) => add_benefit == quote.add_benefit)
    if (index > -1) {
      return addBenefits[currency][quote.premier_incare ? 'premier' : 'your'][index];
    }
    return addBenefits[currency][quote.premier_incare ? 'premier' : 'your'][0];
  };


  const annualMaximumPlanBenefit = {
    USD: {
      your:    1000000,
      premier: 1500000
    },
    EUR: {
      your:    900000,
      premier: 1350000
    },
    GBP: {
      your:    0,
      premier: 0
    }
  };



  const lifeCover = {
    USD: {
      your:    5000,
      premier: 10000
    },
    EUR: {
      your:    5000,
      premier: 10000
    },
    GBP: {
      your:    5000,
      premier: 10000
    }
  }


  const currencyPrefix = {
    USD: '$',
    EUR: '€',
    GBP: '£'
  };

  const initialValues = {
    id:             quoteId,
    firstname:      '',
    lastname:       '',
    email:          '',
    age:            '',
    country:        'select_country',
    dependents:     [],
    incare_plan:    'yourincare_19_v',
    premier_incare: false,
    deductible: 200,
    add_benefit: 50000,
    geographical_cover: 'worldwide_sans_us',
    payment_period: 'quarterly',
    currency: 'USD',
    preferred_start_date: new Date(new Date().setHours(24, 0, 0, 0))
  };
  const [quote, setQuote] = React.useState(initialValues);

  const [premium, setPremium] = React.useState(-1);

  const [optionsVisible, setOptionsVisible] = React.useState({
    deductible: false,
    add: false,
    geographical_cover: false,
    payment_period: false
  });
  const [showSendEmail, setShowSendEmail] = React.useState(false);
  const [showingInitialValues, setShowingInitialValues] = React.useState(true);


  //---------------------------------------------------------------------------
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    if (localStorage.getItem('quote')) {
      const storedQuote = JSON.parse(localStorage.getItem('quote'));
      storedQuote.preferred_start_date = new Date(storedQuote.preferred_start_date);

      let incare_plan = 'yourincare_19_v';
      if (storedQuote.premier_incare) {
        incare_plan = 'premierincare_19_v';
      }

      // @TODO why is this not working?
      setQuote({
        ...initialValues,
        ...storedQuote,
        incare_plan: incare_plan,
        id: quoteId,
        geographical_cover: 'worldwide_sans_us'  // no worldwide coverage for InCare!
      });

      setShowingInitialValues(false);
    } else {
      setQuote(initialValues);
    }
  }, []); // empty array => run hook only once

  //---------------------------------------------------------------------------
  useEffect(() => {
    if (showingInitialValues == false) {
      localStorage.setItem('quote', JSON.stringify(quote));
    }
    getQuote();
  }, [quote]); // run hook when quote changes

  //---------------------------------------------------------------------------
  // useEffect(() => {
  //   const deductible = findMatchingDeductible(quote.deductible, currency, previousCurrency)
  //   const add_benefit = findMatchingAddBenefit(quote.add_benefit, currency, previousCurrency)
  //   setQuote({
  //     ...quote,
  //     deductible: deductible,
  //     add_benefit: add_benefit
  //   });
  // }, [currency]);


  //---------------------------------------------------------------------------
  useEffect(() => {
    if (showingInitialValues) {
      return;
    }

    let options = {
      //geographical_cover: commonQuoteOptions['geographical_cover'],
      payment_period:     commonQuoteOptions['payment_period'],
      //maternity_cover:    commonQuoteOptions['maternity_cover'],
      //dental_option:        commonQuoteOptions['dental_option'],
      currency:           commonQuoteOptions.currency
    };

    // if (deductibles[commonQuoteOptions.currency].includes( commonQuoteOptions['deductible'] )) {
    //   options['deductible'] = commonQuoteOptions['deductible'];
    // }
    // if (addBenefits[commonQuoteOptions.currency][quote.premier_incare ? 'premier' : 'your'].includes( commonQuoteOptions['add_benefit'] )) {
    //   options['add_benefit'] = commonQuoteOptions['add_benefit'];
    // }

    // if (commonQuoteOptions.currency != commonQuoteOptions.previous_currency) {
    //   options.deductible  = findMatchingDeductible(options.deductible, commonQuoteOptions.currency, commonQuoteOptions.previous_currency);
    //   options.add_benefit = findMatchingAddBenefit(options.add_benefit, commonQuoteOptions.currency, commonQuoteOptions.previous_currency);
    // }

    let mapped_deductible  = commonQuoteOptions.deductible
    let mapped_add_benefit = commonQuoteOptions.add_benefit

    if (commonQuoteOptions.currency != quote.currency) {
      mapped_deductible  = findMatchingDeductible(options.deductible,  commonQuoteOptions.currency, quote.currency);
      mapped_add_benefit = findMatchingAddBenefit(options.add_benefit, commonQuoteOptions.currency, quote.currency);
      options.currency = commonQuoteOptions.currency;
    }

    // @TODO delete key from hash options instead?
    if (deductibles[commonQuoteOptions.currency].includes( mapped_deductible )) {
      options.deductible = mapped_deductible;
    }
    if (addBenefits[commonQuoteOptions.currency][quote.premier_incare ? 'premier' : 'your'].includes( mapped_add_benefit )) {
      options.add_benefit = mapped_add_benefit;
    }
    setQuote({
      ...quote,
      ...options
    });
    localStorage.setItem('common_quote_options', JSON.stringify(commonQuoteOptions));
  }, [commonQuoteOptions]);


  // //---------------------------------------------------------------------------
  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     // var bodyFormData = new FormData();
  //     // axios({
  //     //   method: 'get',
  //     //   url: '/accesshealth/quote_results',
  //     //   data: bodyFormData,
  //     //   headers: {'Content-Type': 'multipart/form-data' }
  //     // })
  //     // .then(function (response) {
  //     //     //handle success
  //     // })
  //     // .catch(function (response) {
  //     //     //handle error
  //     // });
  //   }
  //   localStorage.setItem('quote', JSON.stringify(quote));
  // };




  //---------------------------------------------------------------------------
  const handleChange = (event) => {
    //const value = event.target.name == 'premier_incare' ? event.target.checked : event.target.value

    // if (['maternity_cover', 'dental_option'].includes(event.target.name)) {
    //   const value - event.target.checked;
    // else

    if (['deductible', 'add_benefit'].includes(event.target.name)) {
      var value = Number(event.target.value);
    } else {
      var value = event.target.value;
    }

    if (Object.keys(commonQuoteOptions).includes(event.target.name)) {
      setCommonQuoteOptions({
        ...commonQuoteOptions,
        [event.target.name]: value
      })
    } else {
      if (event.target.name == 'premier_incare') {
        // if (event.target.checked) { // switch to premier plan
        //   if ( addBenefits[commonQuoteOptions.currency]['premier'].includes( commonQuoteOptions['add_benefit'] )) {
        //     var add_benefit = commonQuoteOptions['add_benefit'];
        //   } else {
        //     var add_benefit = addBenefits[commonQuoteOptions.currency]['premier'][0];
        //   }
        //   setQuote({
        //     ...quote,
        //     incare_plan: 'premierincare_19_v',
        //     premier_incare: true,
        //     add_benefit: add_benefit
        //   });
        // } else { // switch to life plan
        //   if ( addBenefits[commonQuoteOptions.currency]['your'].includes( commonQuoteOptions['add_benefit'] )) {
        //     var add_benefit = commonQuoteOptions['add_benefit'];
        //   } else {
        //     var add_benefit = addBenefits[commonQuoteOptions.currency]['your'][0];
        //   }
        //   setQuote({
        //     ...quote,
        //     incare_plan: 'yourincare_19_v',
        //     premier_incare: false
        //   });
        // }

        if ( addBenefits[commonQuoteOptions.currency][event.target.checked ? 'premier' : 'your'].includes( commonQuoteOptions['add_benefit'] )) {
          var add_benefit = commonQuoteOptions['add_benefit'];
        } else {
          var add_benefit = addBenefits[commonQuoteOptions.currency][event.target.checked ? 'premier' : 'your'][0];
        }
        setQuote({
          ...quote,
          incare_plan: event.target.checked ? 'premierincare_19_v' : 'yourincare_19_v',
          premier_incare: event.target.checked,
          add_benefit: add_benefit
        });


      } else {
        setQuote({
          ...quote,
          [event.target.name]: value
        });
      }
    }
  };

  // --------------------------------------------------------------------------
  const handleOptionsVisible = (event) => {
    const option = event.target.getAttribute('data-options-visible');
    setOptionsVisible({
      ...optionsVisible,
      [option]: ! optionsVisible[option]
    });
  };


  // --------------------------------------------------------------------------
  const getQuote = () => {
    if (showingInitialValues) {
      return;
    }


    if (commonQuoteOptions.currency == 'GBP') return 0;
    if (['TH', 'HK', 'SG', 'AE'].includes(quote.country)) return 0;

    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/quotes/get_premium.json", {
          params: {
            plan:                 quote.incare_plan,
            country_of_residence: quote.country,
            geographical_cover:   'worldwide_sans_us',
            deductible:           quote.deductible,
            age:                  quote.age,
            dependents:           JSON.stringify(quote.dependents),
            add_option:           true,
            add_benefit:          quote.add_benefit,
            currency:             commonQuoteOptions.currency,
            preferred_start_date: quote.preferred_start_date
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setPremium(response.data.rates.total_due[commonQuoteOptions['payment_period']]);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {

          }
        } else {
          throw error;
        }

      });
  };


  // Components ---------------------------------------------------------------

  const Deductibles = () => {
    if (optionsVisible.deductible) {
      return (
        <div>
          {deductibles[commonQuoteOptions.currency].map( (deductible, index) => (
            <Form.Check key={"deductible_" + deductible} inline type="radio">
              <Form.Check.Input
                name="deductible"
                value={deductible}
                type="radio"
                checked={quote.deductible == deductible}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }


  const AccidentalDeathAndDisablement = () => {
    if (optionsVisible.add) {
      return (
        <div>
          {addBenefits[commonQuoteOptions.currency][quote.premier_incare ? 'premier' : 'your'].map( (add_benefit, index) => (
            <Form.Check key={"add_benefit_" + add_benefit} inline type="radio">
              <Form.Check.Input
                name="add_benefit"
                value={add_benefit}
                type="radio"
                checked={quote.add_benefit == add_benefit}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }

  const PaymentPeriod = () => {
    if (optionsVisible.payment_period) {
      return (
        <div>
          {['annual', 'semiannual', 'quarterly'].map( (payment_period, index) => (
            <Form.Check key={"payment_period_" + payment_period} inline type="radio">
              <Form.Check.Input
                name="payment_period"
                value={payment_period}
                type="radio"
                checked={quote.payment_period == payment_period}
                onChange={handleChange}
              />
              <Form.Check.Label>
                {paymentPeriods[payment_period]}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }

  const FormatPremium = (props) => {
    if (props.premium == -1) {
      return (
        <span style={{fontSize: '40px'}}>Loading...</span>
      );
    } else {

      let paymentPeriod = '';
      switch (props.paymentPeriod) {
        case 'annual':
          paymentPeriod = '1 annual payment of';
          break;
        case 'semiannual':
          paymentPeriod = '2 semi-annual payments of';
          break;
        case 'quarterly':
          paymentPeriod = '4 quarterly payments of';
          break;
      }

      return (
        <>
          <div style={{fontSize: 'small', whiteSpace: 'nowrap'}}>{paymentPeriod}</div>
          <div className="m-0 p-0" style={{whiteSpace: 'nowrap', lineHeight: '90%'}}>
            <span className="quote__table__pricing__currency">{currencyPrefix[commonQuoteOptions.currency]}</span>
            <NumberFormat value={props.premium } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={2} />{props.children}
          </div>
        </>
      );
    }
  };

  const handleEnrolmentClick = (event) => {
    window.location = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '') +
                      '/ig/enrolment' +
                      '?quote_id=' + quote.id +
                      '&plan_group=core' +
                      // '&co_pay=' + quote.co_pay +
                      '&geographical_cover=' + quote.geographical_cover +
                      '&currency=' + quote.currency +
                      '&add_benefit=' + quote.add_benefit +
                      '&plan=' + quote.incare_plan +
                      '&deductible=' + quote.deductible +
                      '&premier_incare=' + quote.premier_incare +
                      '&payment_period=' + quote.payment_period +
                      '&network=' + quote.network +
                      '&dental_option=' + quote.dental_option +
                      '&maternity_cover=' + quote.maternity_cover;
;
  };


  if (commonQuoteOptions.currency == 'GBP' || ['TH', 'HK', 'SG', 'AE'].includes(quote.country)) {
    return null;
  } else {
    return (
      <>
      <Form className="quote-result-form quote-form-core" noValidate>
        <div className="quote__table__row quote__table__heading">
          <div className="plan-type">CORE</div>
          <div className="plan-name">{quote.incare_plan == 'yourincare_19_v' ? 'InCare-Life' : 'InCare-Premier'} </div>
          <div className="plan-description">
            Our InCare plans give you all-encompassing inpatient health cover.
            Ensuring you that you have great protection with extensive benefits not found in similar offerings.
          </div>
          <Button variant="secondary" className="plan-learn-more" href="/ig/personal_plans_core_individual">LEARN MORE</Button>
        </div>

        <div className="quote__table__row quote__table__pricing">
          <FormatPremium premium={premium} paymentPeriod={quote.payment_period}><i style={{color: '#b8e673', verticalAlign: 'text-top', textShadow: '0 1px 1px rgba(0, 0, 0, 0.3)'}} className="fa fa-asterisk"></i></FormatPremium>
        </div>

        <div className="quote__table__row">
          Annual Maximum Plan Benefit
          <br />
          <strong>
          <NumberFormat
            value={annualMaximumPlanBenefit[commonQuoteOptions.currency][quote.premier_incare ? 'premier' : 'your']}
            displayType={'text'}
            thousandSeparator={true}
            prefix={currencyPrefix[commonQuoteOptions.currency]} />
          </strong>
        </div>


        <div className="quote__table__row">
          Deductible:&ensp;
          <strong><NumberFormat value={quote.deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} /></strong>
          <Button className="change" variant="light" size="sm" data-options-visible="deductible" onClick={handleOptionsVisible}>CHANGE</Button>
          <Deductibles />
        </div>

        <div className="quote__table__row">
          Accidental death and disablement:&ensp;
          <strong><NumberFormat value={quote.add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonQuoteOptions.currency]} /></strong>
          <Button className="change" variant="light" size="sm"  data-options-visible="add" onClick={handleOptionsVisible}>CHANGE</Button>
          <AccidentalDeathAndDisablement />
        </div>


        <div className="quote__table__row">
          Worldwide cover <strong>excluding US/Canada</strong>
        </div>

        <div className="quote__table__row">
          Payment method: <strong>{paymentPeriods[quote.payment_period]}</strong>
          <Button className="change" variant="light" size="sm"  data-options-visible="payment_period" onClick={handleOptionsVisible}>CHANGE</Button>
          <PaymentPeriod />
        </div>

        <div className="quote__table__row">
          Enhanced cover<br />
          Upgrade to Premier to increase your annual maximum and
          accidental death and dismemberment.<br />

          <Form.Check
            id="premier_incare_upgrade"
            name="premier_incare"
            type="checkbox"
            label="Upgrade"
            checked={quote.premier_incare}
            onChange={handleChange}
          />

        </div>

        <div className="quote__table__row">
          <Button className="btn--big" onClick={handleEnrolmentClick}>GET COVER</Button>
        </div>

        <div className="quote__table__row text-left p-3" style={{fontSize: 'x-small'}}>
          <p><i style={{color: 'rgb(150, 196, 81)', verticalAlign: 'top', fontSize: '.6rem', top: '.2rem'}} className="fa fa-asterisk"></i>
          These rates are indicative only and based on no medical conditions being declared.</p>
        </div>

      </Form>

      <QuoteResultFormSendEmail
        quote={quote}
        handleChange={handleChange}
        show={showSendEmail}
        onHide={() => setShowSendEmail(false)}
      />
      </>
    )
  }
}
