// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import TESTING from "../globals"

import React, { useEffect, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import axios from "axios";

import { format } from 'date-fns'

import Container from 'react-bootstrap/Container'
import { Table, Row, Col } from 'react-bootstrap'

import IlFormatDate from '../util/IlFormatDate'
import IlFormatCurrency from '../util/IlFormatCurrency'


export default function IndividualPolicyPeriodUnderwritingTerms({person, setError}) {

  const ilUsers = useSelector(state => state.ilUsers)

  const [underwritingTerms, setUnderwritingTerms] = React.useState([]);



  useEffect(() => {
    doRetrieve(person.id);
  }, []);


  const doRetrieve = (id) => {
    console.log(id);
    console.log('not retrieving')
    if (! id) return;
    console.log('retrieving')
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
//                                            /underwriting_notes.json?_dc=1623868138338&uw_notable_id=122&uw_notable_type=individual-insured
        host + "/il_ws/il_person/il_underwriting_terms?uw_termable_id=" + id, {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setError(null);
        console.log(response);
        setUnderwritingTerms(response.data.il_underwriting_terms);
      })
      .catch(error => {
        setError(error.response.statusText);
        setPolicyPeriod(null);
      });
  }

  const FormatLoadingType = ({type}) => {
    if (type == 'percentage') {
      return '% Percentage'
    } else if (type == 'fixed') {
      return 'Fixed amount'
    } else {
      return 'unknown'
    }
  }


  if (! person) return null;

  return (
    <>
      <Row className="mt-2 mt-sm-0">
        <Col xs="">
          Customer
        </Col>

        <Col xs="">
          Loading Name
        </Col>
        <Col xs="">
          Type
        </Col>
        <Col xs="">
          Amount
        </Col>
        <Col xs="">
          Show Loading Name on Certificate of Insurance
        </Col>
        <Col xs="">
          Show Underwriting Notes on Certificate of Insurance
        </Col>
      </Row>
      {underwritingTerms.map(row => (
        <Container key={row.id} className="double-row" style={{padding: 0}} >
          <Row className="mt-2 mt-sm-0" style={row.notes ? {borderBottom: 'none'} : {}}>
            <Col xs="">
              {person.fullname}
            </Col>
            <Col xs="">
              {row.loading_name}
            </Col>
            <Col xs="">
              <FormatLoadingType type={row.loading_type} />
            </Col>
            <Col xs="">
              {row.loading_amount}
            </Col>
            <Col xs="">
              {row.show_loading_name_on_certificate_of_insurance ? 'YES' : 'NO'}
            </Col>
            <Col xs="">
              {row.show_loading_notes_on_certificate_of_insurance ? 'YES' : 'NO'}
            </Col>
          </Row>
          {row.notes &&
          <Row className="mt-2 mt-sm-0" style={{borderTop: 'none'}}>
            <Col xs="2">
              Notes:
            </Col>
            <Col xs="10">
              {row.notes}
            </Col>
          </Row>
          }
        </Container>
      ))}
    </>
  );

}
