import React, { useState, useEffect } from "react"
import { format } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container'

import { getAge, getBMI, formatHeight, formatWeight } from '../exports';
import { COUNTRIES, countryName } from '../country/countries';



export default function GroupEnrolmentMedicalQuestionsView({person}) {

  if (!person) return null;

  if ((! person.medical_conditions || (person.medical_conditions && person.medical_conditions.length < 1)) &&
      ! person.answer_2 &&
      ! person.answer_3 &&
      ! person.answer_4 &&
      ! person.answer_5 &&
      ! person.pregnant) {
    return (
      <Row className="">
        <Col>
          You responded in the negative to all questions asked.
        </Col>
      </Row>
    );
  }

  return (
    <>
    {person.medical_conditions && person.medical_conditions.length > 0 &&
    <Row className="enrolment-summary">
      <Col>
        <h3 style={{display: 'inline', marginRight: '0.5em'}}>1</h3> Medical conditions treated or diagnosed:
        <ul style={{margin: 0}}>
        {
          person.medical_conditions && person.medical_conditions.map((condition, index) => (
            <li key={index.toString()}>
            {condition.label}
            </li>
          ))
        }
        </ul>
      </Col>
    </Row>
    }
    {(! person.medical_conditions || person.medical_conditions.length < 1) &&
    <Row className="enrolment-summary">
      <Col>
        <h3 style={{display: 'inline', marginRight: '0.5em'}}>1</h3> Medical conditions treated or diagnosed: NONE
      </Col>
    </Row>
    }
    <Row className="enrolment-summary">
      <Col>
        <h3 style={{display: 'inline', marginRight: '0.5em'}}>2</h3> Planned medical events: {person.answer_2 ? person.answer_2 : 'NONE'}
      </Col>
    </Row>
    <Row className="enrolment-summary">
      <Col>
        <h3 style={{display: 'inline', marginRight: '0.5em'}}>3</h3> Symptoms last 12 months: {person.answer_3 ? person.answer_3 : 'NONE'}
      </Col>
    </Row>
    <Row className="enrolment-summary">
      <Col>
        <h3 style={{display: 'inline', marginRight: '0.5em'}}>4</h3> Medication: {person.answer_4 ? person.answer_4 : 'NONE'}
      </Col>
    </Row>
    <Row className="enrolment-summary">
      <Col>
        <h3 style={{display: 'inline', marginRight: '0.5em'}}>5</h3> Previous insurance issues: {person.answer_5 ? person.answer_5 : 'NONE'}
      </Col>
    </Row>
    </>
  );
}

