import { configureStore } from '@reduxjs/toolkit'

import ilUsersReducer from './ilUsersSlice'
import ilIndividualPolicyPeriodsReducer from './ilIndividualPolicyPeriodsSlice'
import ilRenewalTriggerDurationsReducer from './ilRenewalTriggerDurationsSlice'

export default configureStore({
  reducer: {
    ilUsers: ilUsersReducer,
    ilIndividualPolicyPeriods: ilIndividualPolicyPeriodsReducer,
    ilRenewalTriggerDurations: ilRenewalTriggerDurationsReducer
  }
})