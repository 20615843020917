import React, { useState, useEffect } from "react"

import { format } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import { getBMI, formatHeight, formatWeight } from '../../exports';
import { COUNTRIES, countryName } from '../../country/countries';

export default function EnrolmentPrimaryShortView({
  primary}) {


  const handleChangePrimary = (event) => {
    history.push("/ig/enrolment/primary");
  };


  return (
    <Container>
      <Row className="align-baseline">
        <span style={{marginRight: '1ex'}}>{primary.firstname} {primary.lastname}</span>
        <span style={{marginRight: '1ex'}}>{primary.sex == 'male' ? " ♂" : (primary.sex == "female" ? " ♀" : (primary.sex == "intersex" ? ' ⚥': ''))}</span>
        {primary.date_of_birth &&
          <span style={{marginRight: '1ex'}}> {format(new Date(primary.date_of_birth), 'dd MMM. yyyy')}</span>
        }
        <span style={{marginRight: '1ex', color: 'grey'}}>Primary</span>
        <Button variant="link" style={{padding: 0}} onClick={handleChangePrimary}>CHANGE</Button>
      </Row>
    </Container>
  )
}

