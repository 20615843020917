import React, { useState, useEffect } from "react"
import Select from 'react-select';

import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import { MEDICAL_CONDITIONS } from '../../exports';

export default function EnrolmentInsuredConditions({
  primary,
  setPrimary,
  dependents,
  setDependents
  }) {

  const handlePrimaryChange = (options) => {
    setPrimary({
      ...primary,
      medical_conditions: options
    })
  };

  const medicalConditionsPrimary = () => {
    return primary.medical_conditions;
  };




  // currying
  const handleChange = (dependent_id) => (options) => {
    const dependent = dependents.find(dep => dep.id == dependent_id);
    const modifiedDependent = {
      ...dependent,
      medical_conditions: options
    };
    setDependents(dependents.map( dep => {
       if (dep.id != dependent.id) return dep;
       return modifiedDependent;
    }));
  };

  const medicalConditionsDependent = (dependent_id) => {
    const dependent = dependents.find(dep => dep.id == dependent_id);
    return dependent.medical_conditions;
  };


  return (
    <Container>
      <Row style={{fontSize: 'larger'}}>
        <Col>
        <span style={{marginRight: '1ex'}}>{primary.firstname} {primary.lastname}</span>
        <span style={{marginRight: '1ex'}}>{primary.sex == 'male' ? " ♂" : (primary.sex == "female" ? " ♀" : (primary.sex == "intersex" ? ' ⚥': ''))}</span>
        {primary.date_of_birth &&
          <span style={{marginRight: '1ex'}}> {format(new Date(primary.date_of_birth), 'dd MMM. yyyy')}</span>
        }
        </Col>
      </Row>
      <Row style={{fontSize: 'normal'}}>
        <Col>
          <Select
            onChange={handlePrimaryChange}
            value={medicalConditionsPrimary()}
            className="conditions-select"
            options={MEDICAL_CONDITIONS}
            isMulti
            styles={{
              multiValueLabel: base => ({
                ...base,
                backgroundColor: '#96c451',
                color: 'white',
                whiteSpace: 'wrap'
              }),
            }}
          />
        </Col>
      </Row>
      {dependents.map( (dependent, index) => (
        <div className="mt-3" key={dependent.id.toString()}>
          <Row style={{fontSize: 'larger'}}>
            <Col>
            <span style={{marginRight: '1ex', color: 'grey'}}>{dependent.relationship == 'partner' ? 'Partner' : 'Child'}</span>
            <span style={{marginRight: '1ex'}}>{dependent.firstname} {dependent.lastname}</span>
            <span style={{marginRight: '1ex'}}>{dependent.sex == 'male' ? " ♂" : (dependent.sex == "female" ? " ♀" : (dependent.sex == "intersex" ? ' ⚥': ''))}</span>
            {dependent.date_of_birth &&
              <span>{format(new Date(dependent.date_of_birth), 'dd MMM. yyyy')}</span>
            }
            </Col>
          </Row>
          <Row style={{fontSize: 'normal'}}>
            <Col>
              <Select
                onChange={handleChange(dependent.id)}
                value={medicalConditionsDependent(dependent.id)}
                className="conditions-select"
                options={MEDICAL_CONDITIONS}
                isMulti
                styles={{
                  multiValueLabel: base => ({
                    ...base,
                    backgroundColor: '#96c451',
                    color: 'white',
                    whiteSpace: 'wrap'
                  }),
                }}
              />
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  )
}

