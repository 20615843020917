import React, { useState, useEffect } from "react"

import axios from "axios"
import { Redirect, useHistory } from "react-router-dom"

import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import { COUNTRIES, countryName } from '../country/countries'

export default function CustomerView({accessToken, setAccessToken, customer, setEditing}) {

  let history = useHistory();
  {/*
  if (! accessToken) {
    return <Redirect to="/ig/customer/login" />
  }


  const [customer, setCustomer] = React.useState({});

  useEffect(() => {
    getCustomer(accessToken);
  }, []);



  const getCustomer = (accessToken) => {
    axios
    .get(
      window.location.protocol + '//' + window.location.host + "/ws_customer/customer.json",
      {
        headers: {
          'integraglobal-access-token': accessToken  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
        }
      }
    )
    .then(response => {
      if (response.data.success) {
        setCustomer(response.data.customer);
      } else if (! response.data.success) {
        setCustomer(null);
      }
    })
    .catch(error => {
      setCustomer(null);
    });
  };
  */}

  const handleLogout = () => {
    setAccessToken(null);
    history.push("/ig/customer");
  };

  const handleEdit = () => {
    setEditing(true);
  };


  return (
    <Container fluid className="">
      <h1>{customer?.fullname}</h1>
      <h3>PERSONAL DETAILS</h3>

      <Row className="mt-5">
        <Col sd="12" md="3" style={{color: 'gray'}}>Firstname:</Col>
        <Col sd="12" md="3">{customer?.firstname}</Col>
      </Row>
      <Row>
        <Col sd="12" md="3" style={{color: 'gray'}}>Lastname:</Col>
        <Col sd="12" md="3">{customer?.lastname}</Col>
      </Row>
      <Row>
        <Col sd="12" md="3" style={{color: 'gray'}}>Address:</Col>
        <Col sd="12" md="3">{customer?.address_line_1} {customer?.address_line_2}</Col>
      </Row>
     <Row>
        <Col sd="12" md="3" style={{color: 'gray'}}>Postal Code:</Col>
        <Col sd="12" md="3">{customer?.postal_code}</Col>
      </Row>
       <Row>
        <Col sd="12" md="3" style={{color: 'gray'}}>City:</Col>
        <Col sd="12" md="3">{customer?.city}</Col>
      </Row>
      <Row>
        <Col sd="12" md="3" style={{color: 'gray'}}>Country:</Col>
        <Col sd="12" md="3">{countryName(customer?.country_of_residence_outside_US)}</Col>
      </Row>
      <Row>
        <Col sd="12" md="3" style={{color: 'gray'}}>Email:</Col>
        <Col sd="12" md="3">{customer?.email_address}</Col>
      </Row>

      <Row className="mt-5">
        <Col sm="12" md="4">
          <div className="mb-3 mb-md-0 pr-md-5">
            <Button className="" variant="primary" onClick={handleEdit}>EDIT PERSONAL DETAILS</Button>
          </div>
        </Col>
        <Col sm="12" md="4">
          <div className="pl-md-5">
            <Button className="" variant="link" onClick={handleLogout}>LOGOUT</Button>
          </div>
        </Col>
      </Row>

    </Container>
  );
}