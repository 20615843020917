import React, { useState, useEffect } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import axios from "axios";

import { getBMI, isCriticalBMI } from '../exports';

export default function GroupEnrolmentConfirmation({
  persons,
  validated
  }) {

  if (! validated.main_questions) return <Redirect to="/ig/enrolment/group/primary" />

  useEffect(() => {
    getConfirmation();
  }, []);


  const getConfirmation = () => {
    const primary = persons.find(person => person.relationship == 'primary')
    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + primary.id + "/confirmation",
      data: {
        log: "CONFIRMATION"
      }
    }).then(response => {
      //console.log(response);
    }).catch(error => {
      if (error.response) {
        if (error.response.status == 401) {
          alert('permission denied');
        } else {

        }
      } else {
        throw error;
      }

    });
  };


  const medicalStatusForPolicy = () => {
    return persons.reduce(
      (accumulator, person) => {
        return person.medical_status == 'medical_underwriting' ? 'medical_underwriting' : accumulator
      },
      'medical_underwriting'
    );
  };




  const medicalApprovalRequired = () => {
    //const bmi = getBMI(primary);
    //let numberOfBMIsWhichAreTooHigh = isCriticalBMI(bmi) ? 1 : 0;
    let numberOfBMIsWhichAreTooHigh = persons.reduce((total, person) => {
      return isCriticalBMI(getBMI(person)) ? total + 1 : total;
    }, 0)

    return numberOfBMIsWhichAreTooHigh;
  };

  const numberOfBMIsWhichAreTooHigh = medicalApprovalRequired();


  // B. Referred to Medical Underwriting. The application has either had a BMI value for one of the insured applicants
  //     outside the acceptable range or has a positive response to one of our medical questions. For a Referred to
  //     Medical Underwriting we use the following text:
  //if (numberOfBMIsWhichAreTooHigh > 0) {
  if (medicalStatusForPolicy() == 'medical_underwriting') {
    return (
      <Container>
        <h2>Almost there!</h2>
        <p>Based upon one or more of your responses in the enrolment form, your application has been referred to our
        medical underwriting team. One of our team will contact you no later than 1 working day to help with
        obtaining the information necessary to fully evaluate your application and get you covered.
        A confirmation email of your application has been sent to you for your records. We will be back in touch shortly.</p>
      </Container>
    )
  }


  // A. Medically Approved. The application has been medically approved for
  // all insured. This happens if BMI is within acceptable range for all
  // insured and the answers to the questions are negative for all insured.
  // For a medically approved application we use the following text:
  return (
    <Container>
      <h2>Congratulations!</h2>
      <p>Your application has been medically approved.</p>
      <p>Here are the next steps:</p>
      <ol>
        <li>We have sent you an email confirmation of your application and a copy for your records. Please notify us if anything is amiss.</li>
        <li>In accordance with E.U. regulations we need to make a final review of your application. This process should only take a few hours but no later than 1 working day.</li>
        <li>We send you an electronic invoice to your email.</li>
        <li>Upon receipt of your payment, we send you confirmation of your cover and instructions on how to access your policy documents via our yourHealth App.</li>
      </ol>
    </Container>
  )
}

