import React from "react";

export default class NoMatchingRoute extends React.Component {
  render() {
    return (
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6">
              <h1>Apparently some roads do not lead to Rome.</h1>
            </div>
        </div>
      </div>
    );
  }
}

