// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { useEffect, useState, useRef } from 'react'
import axios from "axios"

import { useSelector, useDispatch } from 'react-redux'
import { updateIlRenewalTriggerDuration } from '../redux/ilRenewalTriggerDurationsSlice'
import { addIlRenewalTriggerDuration } from '../redux/ilRenewalTriggerDurationsSlice'


import * as yup from 'yup'
import { Formik } from 'formik'

import {Form, Row, Col, Button, InputGroup, FormControl, Modal} from 'react-bootstrap'
import Jumbotron from 'react-bootstrap/Jumbotron'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'

export default function RenewalTriggerDurationForm(props) {
  if (! props.show) return null;


//  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const ilUsers = useSelector(state => state.ilUsers)
  const ilRenewalTriggerDurations = useSelector(state => state.ilRenewalTriggerDurations)

  const [underwriters, setUnderwriters] = React.useState([])
  const [planRegions, setPlanRegions] = React.useState([])
  const [error, setError] = React.useState('');
  const [exceptionMessage, setExceptionMessage] = React.useState('');





  useEffect(() => {
    getUnderwriters();
    getPlanRegions();

    // return () => {
    //   mountedRef.current = false
    // }

  }, []);


  const getUnderwriters = () => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_underwriters", {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setError(null);
        setUnderwriters(response.data.il_underwriters);
      })
      .catch(error => {
        setError(error.response.statusText);
      });
  }


  const getPlanRegions = () => {

    axios
      .get( // zwecks visibility of query string in URL
        window.location.protocol + '//' + window.location.host + "/il_ws/il_plan_regions", {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setError(null);
        setPlanRegions(response.data.il_plan_regions);
      })
      .catch(error => {
        setError(error.response.statusText);
      });
  }



  const handleSubmit = (values, { setErrors, setSubmitting }) => {

    const method = values.id ? 'put' : 'post';

    if (method == 'post') { // new entry
      if (ilRenewalTriggerDurations.rows.find((rtd) => (
        values.il_underwriter_id == rtd.il_underwriter_id && values.il_plan_region_id == rtd.il_plan_region_id
      ))) {
        setErrors({
          il_underwriter_id: 'Duration for this combination of underwriter and plan region already exists.',
          il_plan_region_id: 'Duration for this combination of underwriter and plan region already exists.'
        });
        return;
      }
    }

    const putData = async () => {
      try {
        const response = await axios({
          method: method,
          url: window.location.protocol + '//' + window.location.host + "/il_ws/il_renewal_trigger_durations/" + (method == 'put' ? values.id : ''),
          data: {
            il_renewal_trigger_duration: values,
            headers: {
              'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
            }
          }
        });

        if (response.data.success) {
          setError(null);
          if (method == 'put') {
            dispatch(updateIlRenewalTriggerDuration({
              item: response.data.il_renewal_trigger_duration
            }));
          } else {
            dispatch(addIlRenewalTriggerDuration({
              item: response.data.il_renewal_trigger_duration
            }));
          }
          props.handleClose();
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        //console.log(error.config);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          // console.log(error.response);
          // console.log(error.toJSON());
          if (error.response.status == 401) {
            dispatch(logout());
          }
          setError(error.toJSON().message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          setError('The server is not responding. Please check your Internet connection.');
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(error.message);
        }
      }
    };
    putData();

  };



  const schema = yup.object().shape({
    il_underwriter_id: yup.number().positive('Please select an underwriter').required('Required'),
    il_plan_region_id: yup.number().positive('Please select a plan region').required('Required'),
    weeks: yup.number().integer().min(1).max(12).required('Required')
  });


  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>

        {error &&
          <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)', backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
            <h3>Sorry! Something went wrong.</h3>
            <h1>{error}</h1>
          </Jumbotron>
        }
        <Formik
          initialValues={
            /*{
            il_underwriter_id: -1, il_plan_region_id: -1, weeks: 1
            }*/
            props.renewalTriggerDuration
          }
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {formik => (

            <Form
              noValidate
              onSubmit={formik.handleSubmit}
            >

              <Modal.Header closeButton>
                <Modal.Title><FontAwesomeIcon icon={faEdit} /> Renewal Trigger Duration</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Form.Group as={Row} controlId="formUnderwriter">
                  <Form.Label column sm={3}>Underwriter</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as="select"
                      name="il_underwriter_id"
                      onChange={formik.handleChange}
                      value={formik.values.il_underwriter_id}
                      isInvalid={formik.touched.il_underwriter_id && formik.errors.il_underwriter_id}
                    >
                      <option key="-1" value="-1">— Select Underwriter —</option>
                      {underwriters.map( (underwriter, index) =>
                        <option key={underwriter.id} value={underwriter.id}>{underwriter.name}</option>
                      )
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{formik.errors.il_underwriter_id}</Form.Control.Feedback>
                  </Col>
                </Form.Group>


                <Form.Group as={Row} controlId="formPlanRegion">
                  <Form.Label column sm={3}>Plan Region</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as="select"
                      name="il_plan_region_id"
                      onChange={formik.handleChange}
                      value={formik.values.il_plan_region_id}
                      isInvalid={formik.touched.il_plan_region_id && formik.errors.il_plan_region_id}
                    >
                      <option key="-1" value="-1">— Select Plan Region —</option>
                      {planRegions.map( (planRegion, index) =>
                        <option key={planRegion.id} value={planRegion.id} >{planRegion.name}</option>
                      )
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{formik.errors.il_plan_region_id}</Form.Control.Feedback>
                  </Col>
                </Form.Group>





                <Form.Group as={Row} controlId="formGroupWeeks">
                  <Form.Label column sm={3}>Weeks</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder=""
                      required
                      isInvalid={formik.errors.weeks && formik.touched.weeks}
                      {...formik.getFieldProps('weeks')}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.weeks}</Form.Control.Feedback>
                  </Col>

                </Form.Group>





              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>Close</Button>
                <Button varoant="primary" type="submit">Save</Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>

      </Modal>
    </>
  );
}