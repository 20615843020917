import React, { Component } from "react";
import axios from "axios";

import Registration from "./Registration"
import Login from "./Login"

export default class Home extends Component {

  handleSuccessfulAuth = data => {
    this.props.handleLogin(data);
    this.props.history.push("/v7/mis/dashboard");
  }

  render() {
    return (
        <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="customer-forms mt-5 mb-5 col-12 col-md-8 col-lg-6">
                  <Login {...this.props} handleSuccessfulAuth={this.handleSuccessfulAuth} />
                </div>
            </div>
        </div>
    );
  }



}

