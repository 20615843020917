import React, { Component } from "react"

import { Redirect, useHistory, useParams } from "react-router-dom"

import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import Jumbotron from 'react-bootstrap/Jumbotron'

import { Formik } from 'formik'
import * as Yup from 'yup'

import axios from "axios"

export default function PasswordReset({formAuthenticityToken}) {

  let { reset_password_token } = useParams();
  let history = useHistory();

  const [resetLinkSent, setResetLinkSent] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleSubmit = (values, { setSubmitting }) => {
    axios
      .post( // we don't want the password be visible in the query string
        window.location.protocol + '//' + window.location.host + "/ws_customer/reset_password",
        {
          customer: values,
          reset_password_token: reset_password_token
        },
        {
          headers: {
            'X-CSRF-Token': formAuthenticityToken
          }
        }
      )
      .then(response => {
        if (response.data.success) {
          setError(null);
          setResetLinkSent(true);
        } else {
          setResetLinkSent(false);
          if (response.data.code == 101) {
            setError('We could not find your email address.');
          } else {
            setError(response.data.message);
          }
        }
      })
      .catch(error => {
        setError(response.data.message);
        setResetLinkSent(false);
        setSubmitting(false);
      });
  };


  const SignupSchema = Yup.object().shape({
    password: Yup.string().required('Please provide a password.')
  });


  if (resetLinkSent) {
    return (
      <>
        <Jumbotron className="" style={{color: '#505050', backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>Success!</h3>
          <h1>Your new password has been saved. You can now <a href="/ig/customer/login">log into your account</a></h1>
        </Jumbotron>
        <Button variant="link" onClick={() => history.push("/ig/customer/login")} style={{textTransform: 'uppercase'}}>Login</Button>
      </>
    )
  }

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="customer-forms mt-5 mb-5 col-12 col-md-8 col-lg-6">

          <Formik
           initialValues={{password: ''}}
           validationSchema={SignupSchema}
           onSubmit={handleSubmit}
          >
           {({
             values,
             errors,
             touched,
             handleChange,
             handleBlur,
             handleSubmit,
             isSubmitting,
             submitCount,
             getFieldProps,
             /* and other goodies */
           }) => (

              <Form
                noValidate
                onSubmit={handleSubmit}
              >
              <h1 className="">RESET PASSWORD</h1>


              {error &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h3>We are sorry.</h3>
                  <h1>{error}</h1>
                </Jumbotron>
              }

              <p>Please enter your new password.</p>

              <Form.Group as={Row} controlId="formGroupPassword">
                <Col sm="10" md="12">
                  <Form.Control
                    type="password"
                    placeholder="Password*"
                    required
                    isInvalid={errors.password && touched.password}
                    {...getFieldProps('password')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Col>
              </Form.Group>


              <Form.Row className="mb-3">
                  <Col sm="12" md="4" className="mb-3">
                    <Button type="submit" variant="primary">Save</Button>
                  </Col>
                  {/*
                  <Col sm="12" md="4">
                    <Button variant="link" onClick={() => history.push("/ig/customer/login")} >Login</Button>
                  </Col>
                  */}
              </Form.Row>


              {Object.values(errors).length > 0 && submitCount > 0 &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h1>Oops! You've missed something. Please fill in the field highlighted in red.</h1>
                </Jumbotron>
              }


              </Form>
              )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
