import React from "react"
import { Field, Formik, useFormik } from 'formik'


import {Container, Form, Row, Col, Button } from 'react-bootstrap'

import TotalCount from "../util/TotalCount";


export default function SearchForm({doSearch, count, totalCount}) { // parameter destructuring

  const [value, setValue] = React.useState('');


  const handleSubmit = (values, formik) => {
    doSearch(values);
  };

  return (
    <Container fluid className="">
      <Formik
       initialValues={{searchText: ''}}
       onSubmit={handleSubmit}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        submitCount,
        getFieldProps,
        /* and other goodies */
      }) => (
        <Form inline noValidate onSubmit={handleSubmit}>
          <Form.Control
            className="mr-sm-2"
            size="sm"
            name="searchText"
            placeholder="Search Text"
            value={values.searchText || ''}
            onChange={handleChange}
            isInvalid={touched.searchText && errors.searchText}
          />
          <Button type="submit" size="sm" className="mr-1" name="search">Search</Button>
          <TotalCount count={count} totalCount={totalCount} singularName="Policy Period" />
        </Form>
      )}
      </Formik>
    </Container>
  );

}