import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container'

import { getAge, getBMI, formatHeight, formatWeight } from '../exports';
import { COUNTRIES, countryName } from '../country/countries';

import GroupEnrolmentMedicalQuestionsView from './GroupEnrolmentMedicalQuestionsView'
import FormatSex from './FormatSex'

export default function GroupEnrolmentDependentView({
  person,
  persons,
  setPersons,
  groupPolicyId
  }) {

  let history = useHistory();

  const handleChangePerson = (event) => {
    history.push("/ig/enrolment/group_policy/" + groupPolicyId + "/dependents/" + person.id);
  };

  const handleRemovePerson = (person) => {
    let newPersons = persons.filter(function(pers) {
      return pers.id != person.id
    })
    setPersons(newPersons);
  };

  if (!person) return null;

  return (
    <Container className="mt-5" style={{padding: 0, margin: 0}}>

      <Row className="enrolment-summary first-row">
        <Col xs="6" sm="4" lg="4">
          <h3>DEPENDENT {person.relationship == 'partner' ? 'PARTNER' : 'CHILD'}</h3>
        </Col>
        <Col>
         <ButtonGroup>
           <Button variant="link" style={{padding: 0, marginRight: '1.5em'}} onClick={() => handleChangePerson(person)}>CHANGE</Button>
           <Button variant="link" style={{padding: 0, marginRight: '0em'}} onClick={() => handleRemovePerson(person)}>REMOVE</Button>
         </ButtonGroup>
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Name
        </Col>
        <Col>
          <span style={{marginRight: '0.5em'}}>{person.firstname} {person.lastname}</span>
         </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Birthdate
        </Col>
        <Col>
        {person.date_of_birth &&
          <>
          <span style={{marginRight: '0.5em'}}>{format(new Date(person.date_of_birth), 'dd MMM. yyyy')}</span>
          <span style={{marginRight: '0.5em'}}>{getAge(person.date_of_birth)} years</span>
          </>
        }
        </Col>
      </Row>



      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Sex
        </Col>
        <Col>
          <FormatSex person={person} />
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Height
        </Col>
        <Col>
          {formatHeight(person)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Weight
        </Col>
        <Col>
          {formatWeight(person)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Pregnant
        </Col>
        <Col>
          {person.pregnant ? 'YES' : 'NO'}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          BMI
        </Col>
        <Col>
          {getBMI(person)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Email
        </Col>
        <Col>
          {person.email_address}
        </Col>
      </Row>



      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Country&nbsp;of&nbsp;Residence
        </Col>
        <Col>
          {countryName(person.country_of_residence_outside_US)}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="4">
          Citizenship
        </Col>
        <Col>
          {countryName(person.citizenship)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col>
          <span style={{fontSize: 'large'}}>Your reponses to the medical question section for {person.firstname} {person.lastname}</span>
           <Button variant="link" style={{padding: 0, marginLeft: '1.5em'}} onClick={() => history.push("/ig/enrolment/group_policy/" + groupPolicyId + "/main_questions")}>CHANGE</Button>
           <GroupEnrolmentMedicalQuestionsView person={person} />
        </Col>
      </Row>

    </Container>
  )
}

