// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import { createSlice } from '@reduxjs/toolkit'

export const ilPlanRegionSlice = createSlice({
  name: 'ilPlanRegions',
  initialState: {
    rows: [],
    totalCount: 0
  },
  reducers: {
    setIlPlanRegions: (state, action) => {
      state.rows = action.payload.rows
      state.totalCount = action.payload.totalCount
    },
    removeIlPlanRegions: state => {
      state.rows = null
      state.totalCount = 0
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIlPlanRegions, removeIlPlanRegions } = ilPlanRegionsSlice.actions

export default ilPlanRegionsSlice.reducer