// encoding: UTF-8
//
// (C) Copyright 2020-2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import QuoteFormAccesshealthDependents from './QuoteFormAccesshealthDependents';


// https://robinpokorny.com/blog/index-as-a-key-is-an-anti-pattern/
// => 2. Using indexes in key Prop https://codeburst.io/how-to-not-react-common-anti-patterns-and-gotchas-in-react-40141fe0dcd#76bd


export default function QuoteFormAccesshealth({
  site,
  subsite,
  referrerCode
  }) {

  const initialValues = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    age: '',
    sex: 'male',
    uae_sex: 'male',
    uae_actively_at_work: true,
    country: 'select_country',
    dependents: [],
    site: site,
    subsite: subsite,
    errors: {
      country: true
    }
    //dependents: [{id: 1, lastname: 'eins', age: 21, relationship: 'partner'}, {id: 2, lastname: 'zwei', age: 22, relationship: 'child'}]
  };
  const [quote, setQuote] = React.useState(initialValues);
  const [validated, setValidated] = React.useState(false);
  const [submitPath, setSubmitPath] = React.useState('');
  const [accesshealth, setAccesshealth] = React.useState(false);

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});


  //---------------------------------------------------------------------------
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    let country ='select_country';
    if (site == 'ah') {
      setSubmitPath('/ah/quote_results');
      setAccesshealth(true);
      country = 'AE';
    } else {
      setSubmitPath('/ig/quote_results');
    }

    // Update the document title using the browser API
    if (localStorage.getItem('quote')) {
      const storedQuote = JSON.parse(localStorage.getItem('quote'));
      setQuote({
        ...storedQuote,
        id: storedQuote.id ? storedQuote.id : 0
      });
    } else {
      setQuote({
        ...initialValues,
        country: country
      });
    }
  }, []); // empty array => run hook only once





  //---------------------------------------------------------------------------
  const handleSubmit = (event) => {
    // const form = event.currentTarget;

    // if (form.checkValidity() === false || numberOfErrors() > 0) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // } else {
    // }
    let numberOfErrors = validateForm();
    if (numberOfErrors > 0) {
      setValidated(false);
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    localStorage.setItem('quote', JSON.stringify(quote));
  };




  const validateForm = () => {
    let numberOfErrors = 0;

    // Primary
    const formValidation = Object.keys(validation).reduce(
      (acc, key) => {
        const newError = validation[key](quote[key]);
        const newTouched = { [key]: true };

        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          }
        };

      },
      {
        errors: {},
        touched: {}
      }
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);
    numberOfErrors += Object.values(formValidation.errors).length;

    // Dependent
    numberOfErrors += validateDependents();
    return numberOfErrors;
  }

  const validateDependents = () => {
    let numberOfErrors = 0;

    let newDependents = [];
    quote.dependents.forEach( (dep, index) => {
      const dependentFormValidation = Object.keys(dependentValidation).reduce(
        (acc, key) => {
          const newError = dependentValidation[key](dep[key], dep.relationship);
          const newTouched = { [key]: true };
          return {
            errors: {
              ...acc.errors,
              ...(newError && { [key]: newError }),
            },
            touched: {
              ...acc.touched,
              ...newTouched,
            }
          };

        },
        {
          errors: {},
          touched: {}
        }
      );
      dep.errors = dependentFormValidation.errors;
      dep.touched = dependentFormValidation.touched;
      newDependents.push({
        ...dep,
        errors: dependentFormValidation.errors,
        touched: dependentFormValidation.touched
      })
      setQuote({
        ...quote,
        dependents: newDependents
      });
      numberOfErrors += Object.values(dependentFormValidation.errors).length;
    });
    return numberOfErrors;
  };




  const nameValidation = (fieldName, fieldValue) => {
    if (! fieldValue || fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    return null;
  };


  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };


  const ageValidation = (field_name, age) => {
    if (typeof age == "string" && age.trim() === '') {
      return 'Age is required';
    }
    if (! isNaN(age)) {
      const primary_age = parseInt(age);
      if (primary_age < 18) {
        return 'Age must be at least 18';
      }
      if (primary_age > 69) {
        return 'Age must be under 70';
      }
      let error = quote.dependents.reduce(
        (acc, dep) => {
          if (primary_age < parseInt(dep.age) && dep.relationship == 'child') {
            return 'Primary insured must be older than every child';
          }
          return acc;
        },
        null
      );
      return error;
    }
    return 'Age needs to be at valid number';
  };


  const validation = {
    firstname:                       name => nameValidation('Firstname', name),
    lastname:                        name => nameValidation('Lastname', name),
    email:                           emailValidation,
    age:                             age  => ageValidation('Age', age)
  };





  const dependentAgeValidation = (field_name, age, relationship) => {
    if (typeof age == "string" && age.trim() === '') {
      return 'Please enter the dependent\'s age';
    }
    if (! isNaN(age)) {
      const dep_age = parseInt(age);
      if (dep_age > 26 && relationship == 'child') {
        return 'Children must be 26 years or younger';
      }
      if (quote.age && dep_age >= parseInt(quote.age) && relationship == 'child') {
        return 'Children must be younger than the primary insured';
      }
      if (dep_age < 17 && relationship == 'partner') {
        return 'Partners must be at least 17 years old';
      }
      if (dep_age > 69) {
        return 'Partners must be between 17 and 69 years old';
      }
      return null;
    }
    return 'Age needs to be at valid number';
  };

  const dependentValidation = {
    age:          (age, relationship)          => dependentAgeValidation('Age', age, relationship)
  };








  // //---------------------------------------------------------------------------
  // const numberOfErrors = () => {
  //   let number = quote.dependents.reduce((total, dep) => {
  //     // https://stackoverflow.com/a/42317235/6838484
  //     // Boolean is a function that returns true when true.
  //     return total + [dep.errors.age, dep.errors.sex, dep.errors.relationship].filter(Boolean).length
  //   }, 0)

  //   if (site == 'ah') {
  //     return number;
  //   }
  //   return number + [quote.errors.country].filter(Boolean).length;
  // };


  //---------------------------------------------------------------------------
  const handleChange = (event) => {
    let { name, value, type } = event.currentTarget;
    let sex = quote.sex;
    let uae_actively_at_work = quote.uae_actively_at_work;

    if (name == 'uae_sex') {
      switch (value) {
        case 'female':
          sex = 'female';
          uae_actively_at_work = false;
          break;
        case 'female_actively_at_work':
          sex = 'female';
          uae_actively_at_work = true;
          break;
        default:
          sex = 'male';
      }
    }

    setQuote({
      ...quote,
      sex: sex,
      uae_actively_at_work: uae_actively_at_work,
      [name]: value
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (type == 'checkbox') {
      const { [name]: removedError, ...rest } = errors;
      setErrors({
        ...rest
      });
    }

  };


  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (typeof validation[name] !== 'function') return;
    const { [name]: removedError, ...rest } = errors;
    const error = validation[name](value);
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error })
    });
    if (name == 'age') {
      validateDependents();
    }
  };

  return (
    <Form
      action={submitPath}
      className="quote-form"
      noValidate
      onSubmit={handleSubmit}
      method="POST">

      <Form.Control type="text" hidden name="id" value={quote.id} onChange={handleChange} />
      <Form.Control type="text" hidden name="referrer_code" value={referrerCode} onChange={handleChange} />
      <Form.Control type="text" hidden name="site" value={site} onChange={handleChange} />
      <Form.Control type="text" hidden name="subsite" value={subsite} onChange={handleChange} />


      <Form.Text className="">
        Fields marked with an <span>*</span> are required
      </Form.Text>

      <Form.Row>
        <Form.Group controlId="formGroupFirstName">
          <Form.Label srOnly>
            First name
          </Form.Label>
          <Form.Control
            type="text"
            name="firstname"
            placeholder="First name*"
            value={quote.firstname}
            isInvalid={errors.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid" tooltip={true}>Please provide a first name.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group controlId="formGroupLastName">
            <Form.Label srOnly>
            Last name
          </Form.Label>
          <Form.Control
            type="text"
            name="lastname"
            placeholder="Last name*"
            value={quote.lastname}
            isInvalid={errors.lastname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid" tooltip={true}>Please provide a last name.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>


      <Form.Row>
        <Form.Group controlId="formGroupEmail">
          <Form.Label srOnly>
            Email
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Your email*"
            value={quote.email}
            isInvalid={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid" tooltip={true}>Please provide an email address.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Col>
          <Form.Group controlId="formGroupAge">
            <Form.Label srOnly>
              Your age
            </Form.Label>
            <Form.Control
              type="number"
              name="age"
              placeholder="Age*"
              value={quote.age}
              isInvalid={errors.age}
              onChange={handleChange}
              onBlur={handleBlur}
              min="18"
              max="69"
            />
            <Form.Control.Feedback type="invalid" tooltip={true}>Must be from 18 to 69.</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="uae_sex" className="sex">
            <Form.Label srOnly>
              Sex
            </Form.Label>
            <Form.Control
              as="select"
              name="uae_sex"
              value={quote.uae_sex}
              isInvalid={errors.uae_sex}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option key="male"                    value="male"                    disabled={false}>Male</option>
              <option key="female_actively_at_work" value="female_actively_at_work" disabled={false}>Female actively at work</option>
              <option key="female"                  value="female"                  disabled={false}>Female not actively at work</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid" tooltip={true}>Please select your sex.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>

      <QuoteFormAccesshealthDependents
        quote={quote}
        setQuote={setQuote}
        validated={validated}
        setValidated={setValidated}
        validation={dependentValidation}
        validateForm={validateForm}
      />


      <Button type="submit">Get quote</Button>

    </Form>
  )
}