// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import { TESTING } from '../../exports';
import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
//import { nanoid } from 'nanoid/non-secure'
//import { nanoid } from 'nanoid/nanoid';

//import { nanoid } from 'nanoid/async'


import { nanoid } from 'nanoid'
import { format, parseISO, subYears } from "date-fns"
//import { nanoid } from 'nanoid/nanoid';
//import nanoid from 'nanoid';

import { useHistory, useLocation, Redirect } from "react-router-dom";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';


import EnrolmentInsuredShortView  from './EnrolmentInsuredShortView';
import EnrolmentPrimary           from './EnrolmentPrimary';
import EnrolmentPrimaryView       from './EnrolmentPrimaryView';
import EnrolmentDependent         from './EnrolmentDependent';
import EnrolmentMainQuestions     from './EnrolmentMainQuestions';
import EnrolmentPlanOptions       from './EnrolmentPlanOptions';
import EnrolmentPrimaryPassport   from './EnrolmentPrimaryPassport';
import EnrolmentOverview          from './EnrolmentOverview';
import EnrolmentDeclaration       from './EnrolmentDeclaration';
import EnrolmentConfirmation      from './EnrolmentConfirmation';




export default function Enrolment({referrerCode, quote, planGroup}) {

  // const row_to_cs_mapping = {
  //   'premierlife_row_17_v':   'premierlife_cs_18_v',
  //   'premierfamily_row_17_v': 'premierfamily_cs_18_v',
  //   'yourlife_row_17_v':      'yourlife_cs_18_v',
  //   'yourfamily_row_17_v':    'yourfamily_cs_18_v'
  // };

  // const cs_to_row_mapping = {
  //   'premierlife_cs_18_v':   'premierlife_row_17_v',
  //   'premierfamily_cs_18_v': 'premierfamily_row_17_v',
  //   'yourlife_cs_18_v':      'yourlife_row_17_v',
  //   'yourfamily_cs_18_v':    'yourfamily_row_17_v'
  // }

  const primary_id = nanoid();
  let initialPrimary = {
    id: primary_id,
    primary_id: primary_id,
    occupation: 'select_occupation',
    firstname: '',
    lastname: '',
    email_address: '',
    mobile_phone: '',
    country_calling_code: '',
    sex: '',
    pregnant: false,
    date_of_birth: '',
    address_line_1: '',
    address_line_2: '',
    postal_code: '',
    city: '',
    country_of_residence_outside_US: '',
    citizenship: '',
    weight: '',
    weight_measurement_unit : '',
    height: '',
    height_measurement_unit: '',
    medical_status: 'medically_approved',
    bmi: 0,
    passport_image_path: null,
    passport_image_file_name: null,
    privacy_policy_agreement: false,
    referrer_code: referrerCode
  };

  if (TESTING) {
    const faker = require('faker');
    // const primary_id = nanoid();
    initialPrimary = {
      id:             primary_id,
      primary_id:     primary_id,
      firstname:      faker.name.firstName(), //"Eagan",
      lastname:       "@TEST@ " + faker.name.lastName(),//"@TEST@ Clay",
      occupation:     'Chief Executive',
      citizenship:    faker.address.countryCode(), //"KH",
      city:           faker.address.city(), //"Schärding",
      address_line_1: faker.address.streetAddress(), //"Schneebauernhöhe 4",
      address_line_2: faker.address.secondaryAddress(), //"Beim Campingplatz",
      postal_code:    faker.address.zipCode(),//"11201",
      country_of_residence_outside_US: faker.address.countryCode(), //"OM",
      date_of_birth:  subYears( new Date, faker.datatype.number({min: 20, max: 70})),//new Date ('1990-01-01'),
      mobile_phone:   faker.phone.phoneNumber(), //'664 5027659',
      country_calling_code: "+43",
      email_address: "horst@tellioglu.at",
      height: "180",
      height_measurement_unit: "cm",
      mobile_phone: "+1 (695) 517-1012",
      sex: "female",
      pregnant: false,
      weight: "60",
      weight_measurement_unit: 'kg',
      medical_status: 'medical_underwriting',
      bmi: 0,
      passport_image_path: '',
      privacy_policy_agreement: true,
      referrer_code: referrerCode
      //answer_2: 'I am short sighted in both eyes and require the use of corrective lenses and / or glasses in order to see clearly. In September 2011 I had reconstructive surgery on my left shoulder to repair damage to ligaments and humeral head caused by repeated and habitual dislocations. Since the surgery I have continued to lead an active life with no problems at all. In September 2013 I had surgery to repair a small tear in the meniscus of my left knee. I have made a full recovery.',
      // medical_conditions: [
      //   {value: 'mh_treated_for_heart',                        label: 'Heart / Blood vessels'},
      //   {value: 'mh_treated_for_mental_disorder',              label: 'Mental / Nervous Disorder'},
      //   {value: 'mh_treated_for_high_blood_pressure',          label: 'Stroke / Blood Pressure / Hypertension'},
      //   {value: 'mh_treated_for_hiv',                          label: 'HIV/AIDS or Related Diseases'},
      //   {value: 'mh_treated_for_ortho_problems',               label: 'Ortho Problems & Arthritis (Back, Joints, etc.)'}
      // ]
    }
  }
  const [primary, setPrimary] = React.useState(initialPrimary);

  let initialDependents = [];
  if (TESTING) {
    const testDependent1 = {
      id: nanoid(),
      primary_id: initialPrimary.id,
      citizenship: "",
      country_of_residence_outside_US: "",
      date_of_birth: new Date ('2014-12-11'),
      email_address: "gimimy@mailinator.com",
      errors: {sex: false, relationship: false},
      firstname: "Asher",
      height: "167",
      height_measurement_unit: "cm",
      lastname: "@TEST@ Combs",
      mobile_phone: "",
      relationship: "child",
      sex: "male",
      pregnant: undefined,
      weight: "74",
      weight_measurement_unit: "kg",
      medical_status: 'medical_underwriting',
      bmi: 0
      //answer_2: "I am short sighted in both eyes and require the use of corrective lenses and / or glasses in order to see clearly. In September 2011 I had reconstructive surgery on my left shoulder to repair damage to ligaments and humeral head caused by repeated and habitual dislocations. Since the surgery I have continued to lead an active life with no problems at all. In September 2013 I had surgery to repair a small tear in the meniscus of my left knee. I have made a full recovery.",
      // medical_conditions: [
      //   {value: 'mh_treated_for_high_blood_pressure',          label: 'Stroke / Blood Pressure / Hypertension'},
      //   {value: 'mh_treated_for_cancer',                       label: 'Cancer'},
      //   {value: 'mh_treated_for_respiratory_problems',         label: 'Respiratory Problems (Asthma)'},
      //   {value: 'mh_treated_for_diabetes',                     label: 'Diabetes (Related Problems)'},
      //   {value: 'mh_treated_for_ortho_problems',               label: 'Ortho Problems & Arthritis (Back, Joints, etc.)'}
      // ]
    };
    const testDependent2 = {
      id: nanoid(),
      primary_id: initialPrimary.id,
      citizenship: "DZ",
      country_of_residence_outside_US: "MY",
      date_of_birth: new Date ('2002-12-07'),
      email_address: "jicagytaja@mailinator.com",
      errors: {sex: false, relationship: false},
      firstname: "Thaddeus",
      height: "62",
      height_measurement_unit: "in",
      lastname: "@TEST@ Burns",
      mobile_phone: "",
      relationship: "partner",
      sex: "female",
      pregnant: false,
      weight: "110",
      weight_measurement_unit: "lbs",
      medical_status: 'medical_underwriting',
      bmi: 0
    };
    initialDependents = [testDependent1, testDependent2];
  }
  const [dependents, setDependents] = React.useState(initialDependents)
  // TEST

  // Anwer 1: Medical conditions treated or diagnosed
  // Anwer 2: Planned medical events
  // Anwer 3: Symptoms last 12 months
  // Anwer 4: Medication
  // Anwer 5: Pregnancy
  // Anwer 6: Previous insurance issues

  const [positiveAnswerToMedicalQuestions, setPositiveAnswerToMedicalQuestions] = React.useState({
    answer_1: false,
    answer_2: false,
    answer_3: false,
    answer_4: false,
    answer_5: false
  });

  // const [positiveAnswerToMedicalQuestions, setPositiveAnswerToMedicalQuestions] = React.useState({
  //   answer_1: false,
  //   answer_2: false,
  //   answer_3: false,
  //   answer_4: false,
  //   answer_5: false
  // });

  // @TODO combine, change data structure, integrate into dependents
  // @TODO combine primary and dependents

  // https://stackoverflow.com/a/33800398
  // Since you can't keep the state in the component itself when it unmounts, you have to decide where else it should be saved.

  const initialCommonPlanOptions = {
    initial:              true,
    premium:              0,
    add_benefit:          100000,
    currency:             'USD',
    deductible:           200,
    geographical_cover:   'worldwide_sans_us',
    payment_period:       'annual',
    maternity_cover:      false,
    dental_option:        false,
    network:              'silver',
    preferred_start_date: new Date(new Date().setHours(24, 0, 0, 0))  // important for DatePicker and Axios libary. MantisBT #4289
  };

  const [commonPlanOptions, setCommonPlanOptions] = React.useState(initialCommonPlanOptions);

  const [ReferrerCode, setReferrerCode] = React.useState('');

  // the plan options chosen
  const [planOptions, setPlanOptions] = React.useState({
    plan_group: planGroup || 'no-plan-selected' // the plan group selected out of the 3 displayed (Core, Comprehensive, Premium)
  });
  const [premium, setPremium] = React.useState({annual: -1, semiannual: -1, quarterly: -1});

  // https://stackoverflow.com/a/33800398
  // Since you can't keep the state in the component itself when it unmounts, you have to decide where else it should be saved.


  // @TODO replace with context
  const [corePlanOptions, setCorePlanOptions] = React.useState({
      //id:                 undefined,
      plan_group:         'core',
      plan:               'yourincare_19_v',
      premier_incare:     false,
      add_benefit:        50000,
      currency:           'USD',
      deductible:         200,
      geographical_cover: 'worldwide_sans_us',
      payment_period:     'annual',
      maternity_cover:    false,
      dental_option:      false,
      network:            ''
  });
  const [comprehensivePlanOptions, setComprehensivePlanOptions] = React.useState({
      //id:                 undefined,
      plan_group:         'comprehensive',
      plan:               'yourlife_row_17_v',
      add_benefit:        50000,
      currency:           'USD',
      deductible:         200,
      geographical_cover: 'worldwide_sans_us',
      payment_period:     'annual',
      maternity_cover:    false,
      dental_option:      false,
      network:            'silver'
  });
  const [premiumPlanOptions, setPremiumPlanOptions] = React.useState({
      //id:                 undefined,
      plan_group:         'premium',
      plan:               'premierlife_row_17_v',
      add_benefit:        100000,
      currency:           'USD',
      deductible:         200,
      geographical_cover: 'worldwide_sans_us',
      payment_period:     'annual',
      maternity_cover:    false,
      dental_option:      false,
      network:            'silver'
  });


  // const initialDependent = {
  //   id: '',
  //   relationship: '',
  //   firstname: '',
  //   lastname: '',
  //   email_address: '',
  //   sex: '',
  //   date_of_birth: '',
  //   country_of_residence_outside_US: '',
  //   citizenship: '',
  //   weight: '',
  //   weight_measurement_unit : '',
  //   height: '',
  //   height_measurement_unit: ''
  // };
  //const [dependentToEdit, setDependentToEdit] = React.useState(initialDependent);

  const [validated, setValidated] = React.useState({
    primary: false,
    dependent: false,
    main_questions: false,
    plan_options: false,
    passport_image: false
  });




  var history = useHistory();

  //const [errors, setErrors] = React.useState({dependents: []});


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    if (quote) {
      setDependents([]);
      setPrimary({
        ...primary,
        //id:             nanoid(),
        firstname:                       quote.firstname ?? '',
        lastname:                        quote.lastname ?? '',
        email_address:                   quote.email_address ?? '',
        mobile_phone:                    quote.mobile_phone ?? '',
        sex:                             quote.sex ?? '',
        date_of_birth:                   quote.date_of_birth ?? '',
        address_line_1:                  '',
        address_line_2:                  '',
        postal_code:                     '',
        city:                            '',
        country_of_residence_outside_US: quote.country_of_residence_code ?? '',
        citizenship:                     quote.citizenship_code ?? '',
        weight:                          '',
        weight_measurement_unit :        '',
        height:                          '',
        height_measurement_unit:         ''
      });

      const newCommonPlanOptions = {
        ...commonPlanOptions,
        initial:              false,
        add_benefit:          quote.add_benefit,
        currency:             quote.currency,
        deductible:           quote.deductible,
        geographical_cover:   quote.geographical_cover,
        payment_period:       quote.payment_period,
        maternity_cover:      quote.maternity_cover,
        dental_option:        quote.dental_option,
        network:              quote.network,
        preferred_start_date: new Date(quote.preferred_start_date)
      }
      setCommonPlanOptions(newCommonPlanOptions);

      const newCorePlanOptions = {
        ...newCommonPlanOptions,
        premier_incare:     quote.premier_incare,
        plan:               quote.premier_incare ? 'premierincare_19_v' : 'yourincare_19_v',
        geographical_cover: 'worldwide_sans_us'
      }
      setCorePlanOptions(newCorePlanOptions);

      let comprehensive_plan = '';
      let premium_plan = '';
      if (['CN', 'MO', 'SG'].includes(quote.country_of_residence_code)) {
        comprehensive_plan = 'yourlife_cs_18_v';
        premium_plan = 'premierlife_cs_18_v';
        if (quote.maternity_cover) {
          comprehensive_plan = 'yourfamily_cs_18_v';
          premium_plan = 'premierfamily_cs_18_v';
        }
      } else {
        comprehensive_plan = 'yourlife_row_17_v';
        premium_plan = 'premierlife_row_17_v';
        if (quote.maternity_cover) {
          comprehensive_plan = 'yourfamily_row_17_v';
          premium_plan = 'premierfamily_row_17_v';
        }
      }

      const newComprehensivePlanOptions = {
        comprehensivePlanOptions,
        ...newCommonPlanOptions,
        plan: comprehensive_plan
      }
      setComprehensivePlanOptions(newComprehensivePlanOptions);

      const newPremiumPlanOptions = {
        premiumPlanOptions,
        ...newCommonPlanOptions,
        plan: premium_plan
      }
      setPremiumPlanOptions(newPremiumPlanOptions);

      if (planGroup == 'core') {
        setPlanOptions({
          ...newCorePlanOptions,
          plan_group: planGroup
        });
      } else if (planGroup == 'comprehensive') {
        setPlanOptions({
          ...newComprehensivePlanOptions,
          plan_group: planGroup
        });
      } else if (planGroup == 'premium') {
        setPlanOptions({
          ...newPremiumPlanOptions,
          plan_group: planGroup
        });
      }


    }
  }, []);



  const initComprehensiveAndPremiumPlans = (country_of_residence_outside_US) => {

    // Change the plan if the country of residence changes
    let comprehensive_plan = '';
    let premium_plan = '';
    if (['CN', 'MO', 'SG'].includes(country_of_residence_outside_US)) {
      //setCsRegion(true);
      comprehensive_plan = 'yourlife_cs_18_v';
      premium_plan = 'premierlife_cs_18_v';
      if (commonPlanOptions.maternity_cover) {
        comprehensive_plan = 'yourfamily_cs_18_v';
        premium_plan = 'premierfamily_cs_18_v';
      }
    } else {
      comprehensive_plan = 'yourlife_row_17_v';
      premium_plan = 'premierlife_row_17_v';
      if (commonPlanOptions.maternity_cover) {
        comprehensive_plan = 'yourfamily_row_17_v';
        premium_plan = 'premierfamily_row_17_v';
      }
    }
    setComprehensivePlanOptions({
      ...comprehensivePlanOptions,
      plan: comprehensive_plan
    });
    setPremiumPlanOptions({
      ...premiumPlanOptions,
      plan: premium_plan
    });

  };





  // -- scroll to top --
  //location.href = "#top";
  const topLocation = useRef(null);
  const { pathname } = useLocation();
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (topLocation.current) {
      topLocation.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [pathname]); // empty array => run hook only once


  const numberOfErrors = () => {
    return enrolment.dependents.reduce((total, dep) => {
      return dep.errors.age ? total + 1 : total;
    }, 0)
  };


  useEffect(() => {
    if (commonPlanOptions.initial === true && ! quote) {
      const maternity_cover = dependents.reduce(
        (accumulator, dependent) => {
          return accumulator || dependent.relationship == 'child'
        },
        false
      );
      // Our rule should Life plans if no children.
      setCommonPlanOptions({
        ...commonPlanOptions,
        maternity_cover: maternity_cover
      })
      // setPlanOptions({
      //   ...planOptions,
      //   maternity_cover: maternity_cover
      // })
    }
  }, [dependents]); // empty array => run hook only once


  // useEffect(() => {
  //   console.log('commonPlanOptions changed');
  //   console.log(commonPlanOptions);
  //   setComprehensivePlanOptions({
  //     ...planOptions,
  //     maternity_cover: commonPlanOptions.maternity_cover
  //   });
  //   setPremiumPlanOptions({
  //     ...planOptions,
  //     maternity_cover: commonPlanOptions.maternity_cover
  //   });
  // }, [commonPlanOptions]); // empty array => run hook only once


  const debug = () => {
    console.log('primary');
    console.log(primary);
    console.log('dependents');
    console.log(dependents);
    console.log('planOptions');
    console.log(planOptions);
    console.log('positiveAnswerToMedicalQuestions');
    console.log(positiveAnswerToMedicalQuestions);
    console.log('validated');
    console.log(validated);
    console.log('commonPlanOptions');
    console.log(commonPlanOptions);

  }



  return (
      <Container className="enrolment-forms">
        <Row>
          <Col>
          <h1 ref={topLocation} >Enrolment
          {TESTING &&
            <>
              <Button variant="link" onClick={debug} style={{marginLeft: '1em'}}><i className="fa fa-bug"></i></Button>
              <Button variant="link" onClick={() => history.push("/ig/enrolment/passport")} style={{marginLeft: '1em'}}><i className="fa fa-bug"></i> Passport</Button>
            </>
          }
          </h1>
          </Col>
        </Row>

        <Switch>

          <Route
            exact
            path={[
              "/integraglobal/enrolment",
              "/integraglobal/enrolment/intermediary/:referrer_code",
              "/integraglobal/enrolment/primary",
              "/integraglobal/enrolment/primary/intermediary/:referrer_code",
              "/ig/enrolment",
              "/ig/enrolment/intermediary/:referrer_code",
              "/ig/enrolment/primary",
              "/ig/enrolment/primary/intermediary/:referrer_code"
            ]}
          >
            <EnrolmentPrimary
              primary={primary}
              setPrimary={setPrimary}
              validated={validated}
              setValidated={setValidated}
              initComprehensiveAndPremiumPlans={initComprehensiveAndPremiumPlans}
            />
          </Route>

          <Route path="/ig/enrolment/dependents/:dependent_id?">
            <EnrolmentDependent
              primary={primary}
              dependents={dependents}
              setDependents={setDependents}
              validated={validated}
              setValidated={setValidated}
            />
          </Route>
          <Route path="/ig/enrolment/main_questions">
            <EnrolmentMainQuestions
              primary={primary}
              setPrimary={setPrimary}
              dependents={dependents}
              setDependents={setDependents}
              positiveAnswerToMedicalQuestions={positiveAnswerToMedicalQuestions}
              setPositiveAnswerToMedicalQuestions={setPositiveAnswerToMedicalQuestions}

              commonPlanOptions={commonPlanOptions}
              setCommonPlanOptions={setCommonPlanOptions}

              validated={validated}
              setValidated={setValidated}
            />
          </Route>
          <Route path="/ig/enrolment/plan_options">
            <EnrolmentPlanOptions
              primary={primary}
              dependents={dependents}

              commonPlanOptions={commonPlanOptions}
              setCommonPlanOptions={setCommonPlanOptions}

              corePlanOptions={corePlanOptions}
              setCorePlanOptions={setCorePlanOptions}
              comprehensivePlanOptions={comprehensivePlanOptions}
              setComprehensivePlanOptions={setComprehensivePlanOptions}
              premiumPlanOptions={premiumPlanOptions}
              setPremiumPlanOptions={setPremiumPlanOptions}

              planOptions={planOptions}
              setPlanOptions={setPlanOptions}

              premiumChosen={premium}
              setPremiumChosen={setPremium}

              validated={validated}
              setValidated={setValidated}
            />
          </Route>

          <Route path="/ig/enrolment/passport">
            <EnrolmentPrimaryPassport
              primary={primary}
              setPrimary={setPrimary}
              validated={validated}
              setValidated={setValidated}
            />
          </Route>

          <Route path="/ig/enrolment/overview">
            <EnrolmentOverview
              primary={primary}
              dependents={dependents}
              setDependents={setDependents}
              planOptions={planOptions}
              premium={premium}
              validated={validated}
            />
          </Route>
          <Route path="/ig/enrolment/declaration">
            <EnrolmentDeclaration
              validated={validated}
              planOptions={planOptions}
              primary={primary}
            />
          </Route>
          <Route path="/ig/enrolment/confirmation">
            <EnrolmentConfirmation
              primary={primary}
              dependents={dependents}
              validated={validated}
              planOptions={planOptions}
              premium={premium}
            />
          </Route>
        </Switch>
      </Container>
  )
}