import React, { useState, useEffect } from "react"
import Select from 'react-select';

import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import { MEDICAL_CONDITIONS, PERSONAL_RELATIONSHIPS } from '../exports';

export default function GroupEnrolmentInsuredConditions({
  persons,
  setPersons
  }) {

  // currying
  const handleChange = (personId) => (options) => {
    const person = persons.find(person => person.id == personId);
    const modifiedPerson = {
      ...person,
      medical_conditions: options
    };
    setPersons(persons.map( pers => {
       if (pers.id != person.id) return pers;
       return modifiedPerson;
    }));
  };

  const medicalConditionsPerson = (personId) => {
    const person = persons.find(pers => pers.id == personId);
    return person.medical_conditions;
  };


  return (
    <Container>
      {persons.map( (person, index) => (
        <div className="mt-3" key={person.id.toString()}>
          <Row style={{fontSize: 'larger'}}>
            <Col>
            <span style={{marginRight: '1ex', color: 'silver'}}>{PERSONAL_RELATIONSHIPS[person.relationship].name}</span>
            <span style={{marginRight: '1ex'}}>{person.firstname} {person.lastname}</span>
            <span style={{marginRight: '1ex'}}>{person.sex == 'male' ? " ♂" : (person.sex == "female" ? " ♀" : (person.sex == "intersex" ? ' ⚥': ''))}</span>
            {person.date_of_birth &&
              <span>{format(new Date(person.date_of_birth), 'dd MMM. yyyy')}</span>
            }
            </Col>
          </Row>
          <Row style={{fontSize: 'normal'}}>
            <Col>
              <Select
                onChange={handleChange(person.id)}
                value={medicalConditionsPerson(person.id)}
                className="conditions-select"
                options={MEDICAL_CONDITIONS}
                isMulti
                styles={{
                  multiValueLabel: base => ({
                    ...base,
                    backgroundColor: '#96c451',
                    color: 'white',
                    whiteSpace: 'wrap'
                  }),
                }}
              />
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  )
}

