import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { COUNTRIES_OF_RESIDENCE } from './countries';

export default function CountrySelect(props) {
  let countries = props.countries ? props.countries : COUNTRIES_OF_RESIDENCE;
  if (props.exclude) {
    countries = countries.reduce((accu, country) => {
        if (! props.exclude.includes(country.iso)) accu.push(country);
        return accu;
      },
      []
    );
  }

  const handleChange = (event) => {
    if (event.target.value == 'separator') {
      event.target.value = 'select_country'
    }
    props.onChange(event);
  };

  return (
    <>
      <Form.Control
        custom={true}
        as="select"
        className={props.className}
        name={props.fieldName ?? 'country'}
        required
        value={props.value}
        isInvalid={props.isInvalid}
        onChange={handleChange}
        onBlur={props.onBlur}
      >
        <option key="-1" value="select_country">{props.placeholder ? props.placeholder : '— Select Country —'}</option>
        {
          // https://reactjs.org/docs/lists-and-keys.html#keys
          // using the indexs is ok if
          // 1. the list and items are static–they are not computed and do not change;
          // 2. the items in the list have no ids;
          // 3. the list is never reordered or filtered.
          countries.map( (country, index) =>
            <option key={index} value={country.iso}>{country.name}</option>
          )
        }
      </Form.Control>
      {props.children}
    </>
  );
}