import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Select from 'react-select'


export default function IndividualPlanReactSelect(props) {

  const [plans, setPlans] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await axios.get(window.location.protocol + '//' + window.location.host + "/ws/individual_plans.json");
      let all_plans = response.data.individual_plans.map((plan) => (
          {value: plan.name, label: plan.name_display}
      ));
      setPlans(all_plans);

      //const currentRow = all_plans.find(row => row.value == props.value);
      //setValue(currentRow);

      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
//      value={plans ? plans.find(row => row.value == props.value) : ''}
      /*onChange={props.onChange}*/

//      onChange={(option) => props.formik.setFieldValue(props.name, option.value)}

    <Select
      name={props.name}
      value={plans ? plans.find(row => row.value == props.value) : ''}
      options={plans}
      isClearable={true}
      isLoading={isLoading}
      onChange={props.onChange}
      isInvalid={props.isInvalid}

    />
  );
}