import { createSlice } from '@reduxjs/toolkit'

export const ilIndividualPolicyPeriodsSlice = createSlice({
  name: 'individualPolicyPeriods',
  initialState: {
    rows: [],
    totalCount: 0
  },
  reducers: {
    setIlIndividualPolicyPeriods: (state, action) => {
      state.rows = action.payload.rows
      state.totalCount = action.payload.totalCount
    },
    removeIlIndividualPolicyPeriods: state => {
      state.rows = null
      state.totalCount = 0
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIlIndividualPolicyPeriods, removeIlIndividualPolicyPeriods } = ilIndividualPolicyPeriodsSlice.actions

export default ilIndividualPolicyPeriodsSlice.reducer