import React from "react"
import { Formik, useFormik } from 'formik';
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Button from 'react-bootstrap/Button';
import * as yup from 'yup';

const schema = yup.object({
  firstName:  yup.string().required('First name is required'),
  lastName:   yup.string().required('Last name is required'),
  address:    yup.string().required('Address is required'),
  city:       yup.string().required('City is required'),
  region:     yup.string().required('Region is required'),
  country:    yup.string().required('Country is required').default('Afghanistan'),
  postalCode: yup
    .string()
    .when('country', {
      is: 'United States',
      then: yup.string().matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid postal code'),
    })
    .when('country', {
      is: 'Canada',
      then: yup.string().matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Invalid postal code'),
    })
    .required(),

  phone: yup
    .string()
    .when('country', {
      is: country => ["United States", "Canada"].includes(country),
      then: yup.string().matches(/^[2-9]\d{2}[2-9]\d{2}\d{4}$/, 'Invalid phone nunber')
    })
    .required(),

  email: yup.string().email('Invalid email').required('Email is required'),

  age: yup
    .number()
    .required('Age is required')
    .min(0, 'Minimum age is 0')
    .max(200, 'Maximum age is 200'),
});





export default function IndividualPlanForm({ // parameter destructuring
  edit,
  onSave,
  setContacts,
  contact,
  onCancelAdd,
  onCancelEdit,
  currentIndividualPlan
}) {

  const [value, setValue] = React.useState('');

  let handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {

    //alert('A name was submitted: ' + this.state.value);

    event.preventDefault();
    props.onSuccessfulSave(value);
  };

  // <Formik children={props => <ContactForm {...props} />} />
  // // or...
  // <Formik>
  //   {({ handleSubmit, handleChange, handleBlur, values, errors }) => (

  return (
    <div className="form">
    <Formik
      initialValues={{ name: 'jared' }}
      onSubmit={(values) => {

        onSave();
      }}
    >
      {props => (
        <form noValidate onSubmit={props.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-12" id="firstName">
              <label>First name</label>
              <input
                className="form-control"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={props.values.firstName || ''}
                onChange={handleChange}
              />
              <input
                className="form-control"
                type="text"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.name}
                name="name"
              />
              {props.errors.name && <div id="feedback">{props.errors.name}</div>}
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      )}
    </Formik>


    </div>
  );

}