import React from "react"

import { BrowserRouter as Router} from "react-router-dom";
import GroupEnrolment from './GroupEnrolment';

// We need this container because useHistory() is only available within
// components nested inside a router.
export default function GroupEnrolmentRouter({groupPolicyId}) {
  return (
    <Router>
      <GroupEnrolment groupPolicyId={groupPolicyId} />
    </Router>
  )
}