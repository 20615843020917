// Lists of Items:
// https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
//
import React from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';


export default function IndividualInsuredRow(props) {

  const onClick = event => {
    props.onEditClick(props.row)
  };

  return(
    <tr key={props.row.id}>
      <td><EditTwoToneIcon fontSize="small" onClick={onClick} /></td>
      <td>{props.row.firstname}</td>
      <td>{props.row.lastname}</td>
    </tr>
  );
}
