// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useEffect, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { format } from 'date-fns'

import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Jumbotron from 'react-bootstrap/Jumbotron'

import SearchForm from './SearchForm'

export default function Persons({accessToken}) {
  const ilUsers = useSelector(state => state.ilUsers)
  let history = useHistory();

  // Declare a new state variable, which we'll call "rows"
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(-1);
  const [error, setError] = React.useState('');

  // useEffect(() => {
  //   doSearch();
  // }, []);


  const doSearch = (values) => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_persons.json", {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          },
          params: {
            sort: 'person_id',//'lastname',
            name: values.searchText
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setRows(response.data.il_persons);
        setTotalCount(response.data.total_count);
        setError('');
      })
      .catch(error => {
        setError(error.response.statusText);
        //history.push("/ig/admin");
      });
  }



  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/individual_insured' }}>Individual Insured</Breadcrumb.Item>
        <Breadcrumb.Item active>Customers</Breadcrumb.Item>
      </Breadcrumb>

      <SearchForm doSearch={doSearch} totalCount={totalCount} count={rows.length} />

      {error &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>😳 This shouldn't have happened.</h3>
          <h1>{error}</h1>
        </Jumbotron>
      }


      <Container fluid>
        <Table striped bordered hover id="il-users-table" className="result-table mt-3">
          <thead className="">
            <tr>
              <th>ID</th>
              {/* <th>ID in Period</th> */}
              <th>Name</th>
              <th>Newest Period</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Correspondence Address</th>
              <th>Residential Address</th>
              <th>Billing Address</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.id}>
                <td>
                  <Link className="" to={'/ig/admin/persons/' + row.id} >
                    {row.il_person_core_id}<span style={{fontSize: 'smaller', color: 'grey'}}>_{row.id}<span className='ig_ID'>_{row._ig_individual_insured_id}</span></span>
                  </Link>
                </td>
                {/* <td>{row.id}</td> */}
                <td><span style={{fontWeight: 400}}>{row.fullname}</span> <span style={{fontSize: 'smaller', color: 'grey'}}>{row.relationship}</span></td>
                <td style={new Date(row.validity_start_date) > new Date(row.validity_end_date) ? {color: 'red'} : {}}>{format(new Date(row.validity_start_date), 'd. LLL yyyy')}&ensp;&mdash;&ensp;{format(new Date(row.validity_end_date), 'd. LLL yyyy')}</td>
                <td><span style={{fontWeight: 400}}>{row.email_address}</span></td>
                <td>
                  {row.mobile_phone &&
                    <div>Mobile: {row.mobile_phone}</div>
                  }
                  {row.phone_1 &&
                    <div>Phone: {row.phone_1}</div>
                  }
                  {row.phone_2 &&
                    <div>Phone: {row.phone_2}</div>
                  }
                  {row.fax &&
                    <div>Fax: {row.fax}</div>
                  }
                </td>

                <td>
                {row.correspondence_address_line_1 &&
                  <div>{row.correspondence_address_line_1}</div>
                }
                {row.correspondence_address_line_2 &&
                  <div>{row.correspondence_address_line_2}</div>
                }
                {(row.correspondence_address_postal_code || row.correspondence_address_city) &&
                  <div style={{whiteSpace: 'nowrap'}}>{row.correspondence_address_postal_code}&nbsp;{row.correspondence_address_city}</div>
                }
                {row.correspondence_address_country &&
                  <div>{row.correspondence_address_country.name}</div>
                }
                </td>

                <td>
                {row.residential_address_line_1 &&
                  <div>{row.residential_address_line_1}</div>
                }
                {row.residential_address_line_2 &&
                  <div>{row.residential_address_line_2}</div>
                }
                {(row.residential_address_postal_code || row.residential_address_city) &&
                  <div style={{whiteSpace: 'nowrap'}}>{row.residential_address_postal_code}&nbsp;{row.residential_address_city}</div>
                }
                {row.residential_address_country &&
                  <div>{row.residential_address_country.name}</div>
                }
                </td>

                <td>
                {row.billing_address_line_1 &&
                  <div>{row.billing_address_line_1}</div>
                }
                {row.billing_address_line_2 &&
                  <div>{row.billing_address_line_2}</div>
                }
                {(row.billing_address_postal_code || row.billing_address_city) &&
                  <div style={{whiteSpace: 'nowrap'}}>{row.billing_address_postal_code}&nbsp;{row.billing_address_city}</div>
                }
                {row.billing_address_country &&
                  <div>{row.billing_address_country.name}</div>
                }
                </td>

              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );

}
