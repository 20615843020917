import React from "react"
import { Formik, useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import * as yup from 'yup';


import IndividualPlanSelect from './IndividualPlanSelect';
import IndividualPlanReactSelect from './IndividualPlanReactSelect';

// const schema = yup.object({
//   internalName: yup.string().required('Internal name is required'),
//   displayName:  yup.string().required('Display name is required'),
//   address:      yup.string().required('Address is required'),
//   city:         yup.string().required('City is required'),
//   region:       yup.string().required('Region is required'),
//   country:      yup.string().required('Country is required').default('Afghanistan'),
//   postalCode:   yup
//     .string()
//     .when('country', {
//       is: 'United States',
//       then: yup.string().matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid postal code'),
//     })
//     .when('country', {
//       is: 'Canada',
//       then: yup.string().matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Invalid postal code'),
//     })
//     .required(),

//   phone: yup
//     .string()
//     .when('country', {
//       is: country => ["United States", "Canada"].includes(country),
//       then: yup.string().matches(/^[2-9]\d{2}[2-9]\d{2}\d{4}$/, 'Invalid phone nunber')
//     })
//     .required(),

//   email: yup.string().email('Invalid email').required('Email is required'),

//   age: yup
//     .number()
//     .required('Age is required')
//     .min(0, 'Minimum age is 0')
//     .max(200, 'Maximum age is 200'),
// });





export default function IndividualPlanForm({ // parameter destructuring
  edit,
  onSave,
  onCancelAdd,
  onCancelEdit,
  currentIndividualPlan
}) {

  //const [value, setValue] = React.useState('');



  // const handleSubmit = (event) => {
  //   //alert('A name was submitted: ' + this.state.value);
  //   event.preventDefault();
  //   props.onSuccessfulSave(value);
  // };


  const formik = useFormik({
    initialValues: currentIndividualPlan || {},
    onSubmit: values => {
      onSave(values);
    }
  });

  let handleChange = (event) => {
    //setValue(event.target.value);

    //formik.handleChange(event.value);
    //formik.handleChange(event);
    //onChange={(option: Option) => form.setFieldValue(field.name, option.value)}

  };

  const myHandleChange = (component, action) => {
  };

  return (
    <div className="form">
      {/*
      // Parenthesize the body of a function to return an object literal expression:
      params => ({foo: bar})
      */}
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="name_display">
                  {/*<Form.Label>Display Name</Form.Label>*/}
                  <Form.Control
                    size="sm"
                    type="text"
                    name="name_display"
                    placeholder="Display Name"
                    value={formik.values.name_display || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.internalName && formik.errors.name_display}
                  />
                  <Form.Text className="text-muted">
                    Name of plan as shown to humans
                  </Form.Text>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name_display}
                  </Form.Control.Feedback>
                </Form.Group>


                <Form.Group as={Col} md="12" controlId="name">
                  {/*<Form.Label>Internal Name</Form.Label>*/}
                  <Form.Control
                    size="sm"
                    type="text"
                    name="name"
                    placeholder="Internal Name"
                    value={formik.values.name || ''}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                  <Form.Text className="text-muted">
                  Must be a valid <a href="https://ruby-doc.org/docs/ruby-doc-bundle/Manual/man-1.4/syntax.html#ident" target="_blank">identifier in Ruby</a>
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

{/*
                <Form.Group as={Col} md="12" controlId="next_plan_name">
                  <Form.Label>Next Plan</Form.Label>
                  <IndividualPlanSelect />
                </Form.Group>


                <Form.Group as={Col} md="12" controlId="next_plan_name">
                  <Form.Label>Next Plan</Form.Label>
                  <IndividualPlanReactSelect
                    value={formik.values.next_plan_name}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
*/}


                <Form.Group as={Col} md="12" controlId="next_plan_name">
                  <Form.Label>Next Plan</Form.Label>
                  <IndividualPlanReactSelect
                    name='next_plan_name'
                    value={formik.values.next_plan_name}
                    onChange={(option) => formik.setFieldValue('next_plan_name', option.value)}
                    isInvalid={formik.touched.next_plan_name && formik.errors.next_plan_name}
                  />
                </Form.Group>


              </Form.Row>
              <Button type="submit" style={{ 'marginRight': '10px' }}>Save</Button>
              <Button type="button" onClick={edit ? onCancelEdit : onCancelAdd}>Cancel</Button>
            </Form>

    </div>
  );

}