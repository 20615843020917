import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import FormControl from 'react-bootstrap/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export default function EnrolmentAccesshealthPersonalDetails({
  enrolment,
  setEnrolment,
  handleChange,
  validated,
  step,
  setStep}) {



  if (step != 4) return null;

  return (
    <>
      <div>Step 4 of 5</div>

      <h2>Personal details</h2>

      <Form.Group as={Row} controlId="formGroupDateOfBirth">
        <Form.Label column sm="2">Date of birth*</Form.Label>
        <Col sm="10">
          <DatePicker
            onChange={(date) => {
              let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
              setEnrolment({
                ...enrolment,
                'date_of_birth': localDate
              });
            }}
            selected={enrolment.date_of_birth}
            dateFormat="yyyy-MM-dd"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupSex">
        <Form.Label column sm="2">Sex*</Form.Label>
        <Col sm="10">
          <Form.Check
            inline
            name="sex"
            type="radio"
            label="male"
            checked={enrolment.sex == 'male'}
            onChange={handleChange}
            value="male"
          />
          <Form.Check
            inline
            name="sex"
            type="radio"
            label="female"
            checked={enrolment.sex == 'female'}
            onChange={handleChange}
            value="female"
          />
          <Form.Check
            inline
            name="sex"
            type="radio"
            label="intersex"
            checked={enrolment.sex == 'intersex'}
            onChange={handleChange}
            value="intersex"
          />

        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupWeight">
        <Form.Label column sm="2">Weight*</Form.Label>
        <Col sm="10">
          <Row>
            <Col>
              <Form.Control
                type="text"
                name="weight"
                required
                onChange={handleChange}
                value={enrolment.weight}
              />
            </Col>
            <Col>
              <Form.Control
                as="select"
                name="weight_measurement_unit"
                required
                onChange={handleChange}
                value={enrolment.weight_measurement_unit}
                isInvalid={validated == true && enrolment.weight_measurement_unit == 'select_weight_measurement_unit'}
              >
                <option key="-1" value="select_weight_measurement_unit">— Please select measurement —</option>
                <option>Kilograms</option>
                <option>Pounds</option>
              </Form.Control>
            </Col>
          </Row>
        </Col>
      </Form.Group>




      <Form.Group as={Row} controlId="formGroupHeight">
        <Form.Label column sm="2">Height*</Form.Label>
        <Col sm="10">
          <Row>
            <Col>
              <Form.Control
                type="text"
                name="height"
                required
                onChange={handleChange}
                value={enrolment.height}
              />
            </Col>
            <Col>
              <Form.Control
                as="select"
                name="height_measurement_unit"
                required
                onChange={handleChange}
                value={enrolment.height_measurement_unit}
                isInvalid={validated == true && enrolment.height_measurement_unit == 'select_height_measurement_unit'}
              >
                <option key="-1" value="select_height_measurement_unit">— Please select measurement —</option>
                <option>Centimeters</option>
                <option>Inches</option>
              </Form.Control>
            </Col>
          </Row>
        </Col>
      </Form.Group>


      <Button type="button" onClick={() => setStep(step - 1)}>PREVIOUS</Button>
      <Button type="button" onClick={() => setStep(step + 1)}>NEXT</Button>

    </>
  )
}
