import React from "react"
import {useFormik } from 'formik'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import TotalCount from "../util/TotalCount";

export default function SearchForm(props) { // parameter destructuring

  const [value, setValue] = React.useState('');

  // We pass our form's initialValues and a submission function (onSubmit) to the useFormik() hook. The hook then
  // returns to us a goodie bag of form state and helpers in a variable we are calling formik.
  const formik = useFormik({
    initialValues: {
      searchText: ''
    },
    onReset: (values, formik) => {
      props.doReset();
    },
    onSubmit: (values, formik) => {
      props.doSearch(values);
    }
  });

  return (
    <div className="form ml-4 mb-2">
      <Form className="form-inline" noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Form.Row>
            <Form.Group md="12" controlId="searchText">
              {/*<Form.Label>Internal Name</Form.Label>*/}
              <Form.Control
                className="mr-sm-2"
                size="sm"
                type="text"
                name="searchText"
                placeholder="Search Text"
                value={formik.values.searchText || ''}
                onChange={formik.handleChange}
                isInvalid={formik.touched.searchText && formik.errors.searchText}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.searchText}
              </Form.Control.Feedback>
            </Form.Group>

            <Button type="submit" size="sm" className="mr-1" name="search">Search</Button>
            <Button type="reset" variant="outline-secondary" size="sm" className="" name="clear">Clear</Button>

            <TotalCount totalCount={props.totalCount} singularName="Country" pluralName="Countries" />
        </Form.Row>
      </Form>
    </div>

  );

}