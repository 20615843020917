import React, { useState, useEffect } from "react"
import Select from 'react-select';

import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import { PERSONAL_RELATIONSHIPS } from '../exports';

export default function GroupEnrolmentInsuredConditionsText({
  answer_name,
  persons,
  setPersons,
  medicalConditionsPersons,
  setMedicalConditionsPersons
  }) {

  const handleChange = (personId) => (event) => {
    const person = persons.find(pers => pers.id == personId);
    const modifiedPerson = {
      ...person,
      [answer_name]: event.target.value
    };
    setPersons(persons.map( pers => {
       if (pers.id != person.id) return pers;
       return modifiedPerson;
    }));
    //setPerson(modifiedPerson);
  };

  const medicalConditionsTextPerson = (personId) => {
    const person = persons.find(pers => pers.id == personId);
    return person[answer_name];
  };

  return (
    <Container>
      {persons.map( (person, index) => (
        <div className="mt-3" key={person.id.toString()}>
          <Row style={{fontSize: 'larger'}}>
            <Col>
            <span style={{marginRight: '1ex', color: 'silver'}}>{PERSONAL_RELATIONSHIPS[person.relationship].name}</span>
            <span style={{marginRight: '1ex'}}>{person.firstname} {person.lastname}</span>
            <span style={{marginRight: '1ex'}}>{person.sex == 'male' ? " ♂" : (person.sex == "female" ? " ♀" : (person.sex == "intersex" ? ' ⚥': ''))}</span>
            {person.date_of_birth &&
              <span>{format(new Date(person.date_of_birth), 'dd MMM. yyyy')}</span>
            }
            </Col>
          </Row>
          <Row style={{fontSize: 'normal'}}>
            <Col>
              <Form.Control
                className="conditions-text"
                as="textarea"
                rows={5}
                name="message_text"
                placeholder="Please provide details if applicable"
                required
                onChange={handleChange(person.id)}
                value={medicalConditionsTextPerson(person.id)}
              />
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  )
}