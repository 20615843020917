// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { forwardRef, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import axios from "axios";

import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Breadcrumb from 'react-bootstrap/Breadcrumb'



import IndividualPlanRow from './IndividualPlanRow';
import IndividualPlanForm from "./IndividualPlanForm";
import SearchPlanForm from "./SearchPlanForm";




export default function IndividualPlansTable(props) {

  let history = useHistory();
  const ilUsers = useSelector(state => state.ilUsers)

  // // Declare a new state variable, which we'll call "count"
  // const [rows, setRows] = React.useState([]);
  const [wsError, setWsError] = React.useState(undefined);
  // const [showIndividualPlanForm, setShowIndividualPlanForm] = React.useState(false);

  // // Similar to componentDidMount and componentDidUpdate:
  // React.useEffect(() => {
  //   const host = window.location.protocol + '//' + window.location.host
  //   axios
  //     .get( // zwecks visibility of query string in URL
  //       host + "/ws/individual_plans.json",
  //       {
  //       },
  //       { withCredentials: true }
  //     )
  //     .then(response => {

  //       setRows(response.data.individual_plans);
  //     })
  //     .catch(error => {

  //       if (error.response.status == 401) {
  //         history.push("/v7/mis");
  //       } else {

  //         setWsError(error.response.data.message);
  //       }
  //     });
  //   },
  //   [] // If you want to run an effect and clean it up only once (on mount and unmount), you can pass an empty array ([])
  //      // as a second argument. This tells React that your effect doesn’t depend on any values from props or state, so
  //      // it never needs to re-run.
  // );



  // let onEditClick = () => {

  //   setShowIndividualPlanForm(true);
  // }
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [totalCount, setTotalCount] = React.useState(-1);
  const [currentIndividualPlan, setCurrentIndividualPlan] = useState({});


  const openModal = (row) => {
    setCurrentIndividualPlan(row);

    setOpenAddModal(true);
    setOpenEditModal(true);
  }
  const closeModal = (values) => {
    setOpenAddModal(false);
    setOpenEditModal(false);
    //getData();
  }

  const onSave = values => {
    closeModal();
  }


  const doReset = () => {
    setTotalCount(-1);
  }

  const doSearch = (values) => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_individual_plans.json", {
          params: {
            name: values.searchText,
            underwriter: values.underwriter,
            region: values.region
          },
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        props.setRows(response.data.il_individual_plans);
        setTotalCount(response.data.total_count);
        //totalCount = response.data.total_count;
      })
      .catch(error => {
        if (error.response.status == 401) {
          history.push("/ig/admin");
        } else {
          setWsError(error.response.data.message);
        }
      });
  }

  return (
    <>
      <Modal show={openEditModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Individual Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IndividualPlanForm edit={true} onSave={onSave} currentIndividualPlan={currentIndividualPlan}  />
        </Modal.Body>
      </Modal>



      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/masterdata' }}>Admin</Breadcrumb.Item>
        <Breadcrumb.Item active>Plans</Breadcrumb.Item>
      </Breadcrumb>

      <SearchPlanForm {...props} doSearch={doSearch} totalCount={totalCount} doReset={doReset} />

    <Container fluid>
      <Table id="il-individual-plan-table" striped bordered hover className="result-table mt-3">
        <thead className="">
          <tr>
{/*              <th></th>*/}
            <th>Name</th>
            <th>Next Plan</th>
            <th>Underwriter</th>
            <th>Region</th>
            <th>Start Date</th>
            <th>End Date</th>
{/*              <th>Internal Name</th>*/}
{/*              <th>Next Plan</th>
            <th>Type /<br />Level</th>
            <th>Category</th>
            <th>Adjustment Tier</th>
            <th>Gross Commission</th>
            <th>Max Lifetime Benefit<br />USD EUR GBP AED</th>
            <th>Annual Lifetime Benefit<br />USD EUR GBP AED</th>
            <th>Out of Pocket Limit<br />USD EUR GBP AED</th>
*/}
          </tr>
        </thead>
        <tbody>
          {props.rows.map(row => (
            <IndividualPlanRow key={row.id} row={row} onEditClick={openModal} />
            /*
            <tr key={row.id}>
              <td><EditTwoToneIcon onClick={props.onEditClick} id={row.id}/></td>
              <td>{row.id}</td>
              <td>{row.underwriter}</td>
              <td>{row.region}</td>
              <td>{row.name}</td>
              <td>{row.name_display}</td>
              <td>{row.next_plan_name}</td>
              <td>{row.category}</td>
            </tr>
            */
          ))}
        </tbody>
      </Table>
      </Container>
    </>
  );
}
