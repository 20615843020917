// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

export default function EnrolmentAccesshealthContact({enrolment, handleChange, validated, step, setStep}) {

  // const handleSubmit = (event) => {

  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false || numberOfErrors() > 0) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {

  //     // var bodyFormData = new FormData();
  //     // axios({
  //     //   method: 'get',
  //     //   url: '/accesshealth/quote_results',
  //     //   data: bodyFormData,
  //     //   headers: {'Content-Type': 'multipart/form-data' }
  //     // })
  //     // .then(function (response) {
  //     //     //handle success
  //     // })
  //     // .catch(function (response) {
  //     //     //handle error
  //     // });
  //   }

  //   setValidated(true);
  //   localStorage.setItem('quote', JSON.stringify(quote));
  // };

  // const handleChange = (event) => {
  //   setQuote({
  //     ...quote,
  //     [event.target.name]: event.target.value
  //   });
  // };




  if (step != 1) return null;

  return (
    <>
      <div>Step 1 of 5</div>
      <h2>Contact details</h2>

      <Form.Text className="">Fields marked with an <span>*</span> are required</Form.Text>

      <Form.Group as={Row} controlId="formGroupFirstName">
         <Form.Label column sm="2">Title</Form.Label>
         <Col sm="10">
           <Form.Control
             as="select"
             name="title"
             required
             onChange={handleChange}
             value={enrolment.country}
             isInvalid={validated == true && enrolment.title == 'select_title'}
           >
             <option key="-1" value="select_title">— Please select your title —</option>
             <option>Mr.</option>
             <option>Mrs.</option>
             <option>Ms.</option>
             <option>Miss</option>
             <option>Dr.</option>
           </Form.Control>
         </Col>
      </Form.Group>


        <Form.Group as={Row} controlId="formGroupFirstName">
          <Form.Label column sm="2">First name*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="firstname"
              required
              onChange={handleChange}
              value={enrolment.firstname}
            />
            <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupMiddleName">
          <Form.Label column sm="2">Middle name</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="middlename"
              onChange={handleChange}
              value={enrolment.middlename}
            />
            <Form.Control.Feedback type="invalid">Please provide your middle name.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupLastName">
          <Form.Label column sm="2">Last name*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="lastname"
              required
              onChange={handleChange}
              value={enrolment.lastname}
            />
            <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupJobTitle">
          <Form.Label column sm="2">Job Title/Position*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="job_title"
              required
              onChange={handleChange}
              value={enrolment.job_title}
            />
            <Form.Control.Feedback type="invalid">Please provide your job title or position.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupEmail">
          <Form.Label column sm="2">Email*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="email_1"
              required
              onChange={handleChange}
              value={enrolment.email_1}
            />
            <Form.Control.Feedback type="invalid">Please provide your email address.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGroupAlternativeEmail">
          <Form.Label column sm="2">Alternative email</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="email_2"
              required
              onChange={handleChange}
              value={enrolment.email_2}
            />
            <Form.Control.Feedback type="invalid">Please provide your email address.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupPhone">
          <Form.Label column sm="2">Phone*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="phone_1"
              required
              onChange={handleChange}
              value={enrolment.phone_1}
            />
            <Form.Control.Feedback type="invalid">Please provide your phone number.</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGroupMobilePhone">
          <Form.Label column sm="2">Mobile phone</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="mobile_phone_1"
              required
              onChange={handleChange}
              value={enrolment.mobile_phone_1}
            />
            <Form.Control.Feedback type="invalid">Please provide your phone number.</Form.Control.Feedback>
            <Form.Text className="">Please enter at least one phone number.</Form.Text>
          </Col>
        </Form.Group>


      <Button type="button" onClick={() => setStep(step + 1)}>NEXT</Button>

    </>
  )
}

