// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import QuoteResultFormCurrencies    from '../../quote/QuoteResultFormCurrencies';

import QuoteResultFormCore          from './QuoteResultFormCore';
import QuoteResultFormComprehensive from './QuoteResultFormComprehensive';
import QuoteResultFormPremium       from './QuoteResultFormPremium';

export default function QuoteResultForm({
    authenticity_token,
    quoteId
  }) {

  const initialCommonQuoteOptions = {
    deductible: 200,
    add_benefit: 100000,
    geographical_cover: 'worldwide_sans_us',
    payment_period: 'quarterly',
    currency: 'USD',
    maternity_cover: false,
    dental_option: false,
    network: 'silver',        // CHKS_18_NETWORKS: [['Platinum', 'platinum'], ['Gold', 'gold'], ['Silver', 'silver']],
    preferred_start_date: new Date(new Date().setHours(24, 0, 0, 0)),  // important for DatePicker and Axios libary. MantisBT #4289'',
  };

  const [commonQuoteOptions, setCommonQuoteOptions] = React.useState(initialCommonQuoteOptions);

  const handleCurrencyChange = (event) => {
    setCommonQuoteOptions({
      ...commonQuoteOptions,
      currency: event
    })
  };


  useEffect(() => {

    if (localStorage.getItem('common_quote_options')) {
      const storedCommonQuoteOptions = JSON.parse(localStorage.getItem('common_quote_options'));
      setCommonQuoteOptions({
        ...storedCommonQuoteOptions,
        preferred_start_date: new Date(storedCommonQuoteOptions.preferred_start_date)
      });
      // @TODO Maternity Cover?
    }
    // else {
    //   setCommonQuoteOptions(initialCommonQuoteOptions);
    // }

  }, []); // empty array => run hook only once


  return (
    <>
      <div className="tabs" style={{background: 'rgb(245, 245, 245)'}}>
        <div className="section__inner">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="tabs__header tabs__header--mob-same js-tabs-header">
                  <QuoteResultFormCurrencies currency={commonQuoteOptions.currency} setCurrency={handleCurrencyChange} currencies={['USD', 'EUR', 'GBP']} format="symbol" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section__inner">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="tab__inner">
                <div className="quote__tables team__form">

                  <div className="quote__table js-quote-table">
                    <QuoteResultFormCore          quoteId={quoteId} commonQuoteOptions={commonQuoteOptions} setCommonQuoteOptions={setCommonQuoteOptions} />
                  </div>


                  <div className="quote__table quote__table--big js-quote-table">
                    <QuoteResultFormComprehensive quoteId={quoteId} commonQuoteOptions={commonQuoteOptions} setCommonQuoteOptions={setCommonQuoteOptions} />
                  </div>


                  <div className="quote__table js-quote-table">
                    <QuoteResultFormPremium       quoteId={quoteId} commonQuoteOptions={commonQuoteOptions} setCommonQuoteOptions={setCommonQuoteOptions} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
