import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import FormControl from 'react-bootstrap/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export default function EnrolmentCoverageDetails({
  enrolment,
  setEnrolment,
  handleChange,
  validated}) {



  if (step != 5) return null;

  return (
    <>
      <h2>Coverage details</h2>


      <Form.Group as={Row} controlId="formGroupCoPay">
        <Form.Label column sm="2">Co-pay option</Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            name="co_pay_option"
            required
            onChange={handleChange}
            value={enrolment.co_pay_option}
          >
            <option value="op80">OP80</option>
            <option value="op90">OP90</option>
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupNetwork">
        <Form.Label column sm="2">Network</Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            name="network"
            required
            onChange={handleChange}
            value={enrolment.network}
          >
            <option value="gold">Gold</option>
            <option value="silver_premium">Silver Premium</option>
            <option value="silver_classic">Silver Classic</option>
          </Form.Control>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupGeographicalCover">
        <Form.Label column sm="2">Coverage requested</Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            name="geographical_cover"
            required
            onChange={handleChange}
            value={enrolment.geographical_cover}
          >
            <option value="uae_worldwide">Worldwide coverage including US</option>
            <option value="uae_worldwide_sans_us">Worldwide coverage NOT including US</option>
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupPreferredStartDate">
        <Form.Label column sm="2">Your preferred start date*</Form.Label>
        <Col sm="10">
          <DatePicker
            onChange={(date) => {
              let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
              setEnrolment({
                ...enrolment,
                'preferred_start_date': localDate
              });
            }}
            selected={enrolment.preferred_start_date}
            dateFormat="yyyy-MM-dd"
          />
        </Col>
      </Form.Group>

      <Button type="button" onClick="">PREVIOUS</Button>
      <Button type="button" onClick="">NEXT</Button>

    </>
  )
}
