import React, { useState, useEffect, useRef } from "react"
import { BrowserRouter, Switch, Route, Redirect, useHistory } from "react-router-dom"

import Jumbotron from 'react-bootstrap/Jumbotron'

import axios from "axios"


import Login from "./Login"
import CreateAccount from "./CreateAccount"
import PasswordSendResetLink from "./PasswordSendResetLink"
import PasswordReset from "./PasswordReset"
import PasswordUpdate from "./PasswordUpdate"
import Dashboard from "./Dashboard"
import NoMatchingRoute from "./NoMatchingRoute"

export default function Router({emailAddressVerified, passwordChanged, formAuthenticityToken}) {

  const [validatingAccessToken, setValidatingAccessToken] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState('');


  const validateAccessToken = (accessToken) => {
    if (!accessToken) return;

    setValidatingAccessToken(true);
    var host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws_customer/validate_access_token.json",
        {
          headers: {
            "integraglobal-access-token": accessToken,
            'X-CSRF-Token': formAuthenticityToken
          }  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
        }
      )
      .then(response => {
        if (response.data.success) {
          handleSetAccessToken(accessToken);
          //setAccessToken(accessToken);
          //localStorage.setItem('access_token', accessToken);
        } else if (! response.data.success) {
          handleSetAccessToken(null);
          //setAccessToken(null);
          //console.log('removing accessToken 41')
          //localStorage.removeItem('access_token');
        }
        setValidatingAccessToken(false);
      })
      .catch(error => {
        handleSetAccessToken(null);
        //setAccessToken(null);
        //console.log('removing accessToken 48')
        //localStorage.removeItem('access_token');
        setValidatingAccessToken(false);
      });
  }

  const handleSetAccessToken = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('access_token', accessToken);
    } else {
      localStorage.removeItem('access_token');
    }
    setAccessToken(accessToken);
  };


  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      validateAccessToken(accessToken);
    }
  }, []);

  // useEffect(() => {
  //   console.log('accessToken changed');
  //   console.log(accessToken);
  // }, [accessToken]);

  // useEffect(() => {
  //   console.log('customer changed');
  //   console.log(customer);
  // }, [customer]);

  if (validatingAccessToken) {
    return (
      <Jumbotron className="" style={{color: '#505050'/*'rgba(162, 36, 47, 1.0)'*/, backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
        <h1>One moment please!</h1>
        <h3>We are looking up your account...</h3>
      </Jumbotron>
    )
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={[
            '/ig/customer',
            "/ig/customer/login",
            "/ig/customer/verify_email_address/:verify_email_address_token",
            "/ig/customer/confirm_password_change/:confirm_password_change_token"
          ]}
        >
          <Login
            className="container h-100"
            accessToken={accessToken}
            handleSetAccessToken={handleSetAccessToken}
            emailAddressVerified={emailAddressVerified}
            passwordChanged={passwordChanged}
          />
        </Route>

        <Route
          exact
          path={['/ig/customer/dashboard']}
        >
          <Dashboard
            formAuthenticityToken={formAuthenticityToken}
            accessToken={accessToken}
            setAccessToken={handleSetAccessToken}
          />
        </Route>

        <Route path="/ig/customer/create">
          <CreateAccount formAuthenticityToken={formAuthenticityToken} />
        </Route>
        <Route path="/ig/customer/send_reset_password_token">
          <PasswordSendResetLink formAuthenticityToken={formAuthenticityToken} />
        </Route>
        <Route path="/ig/customer/password_update">
          <PasswordUpdate formAuthenticityToken={formAuthenticityToken} />
        </Route>

        <Route exact path={["/ig/customer/reset_password/:reset_password_token", "/ig/customer/reset_password"]}>
          <PasswordReset formAuthenticityToken={formAuthenticityToken} />
        </Route>

        <Route component={NoMatchingRoute} />
      </Switch>
    </BrowserRouter>
  )
}

