import React, { useState, useEffect } from "react"
import Select from 'react-select';

import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'


export default function EnrolmentInsuredConditionsText({
  answer_name,
  primary,
  setPrimary,
  dependents,
  setDependents,


  medicalConditionsPrimary,
  setMedicalConditionsPrimary,
  medicalConditionsDependents,
  setMedicalConditionsDependents
  }) {


  const handleChangePrimary = (event) => {
    setPrimary({
      ...primary,
      [answer_name]: event.target.value
    });
  };

  const medicalConditionsTextPrimary = () => {
    return primary[answer_name];
  };



  // currying
  const handleChange = (dependent_id) => (event) => {
    const dependent = dependents.find(dep => dep.id == dependent_id);
    const modifiedDependent = {
      ...dependent,
      [answer_name]: event.target.value
    };
    setDependents(dependents.map( dep => {
       if (dep.id != dependent.id) return dep;
       return modifiedDependent;
    }));
    //setDependent(modifiedDependent);
  };

  const medicalConditionsTextDependent = (dependent_id) => {
    const dependent = dependents.find(dep => dep.id == dependent_id);
    return dependent[answer_name];
  };

  return (
    <Container>
      <Row style={{fontSize: 'larger'}}>
        <Col>
        <span style={{marginRight: '1ex'}}>{primary.firstname} {primary.lastname}</span>
        <span style={{marginRight: '1ex'}}>{primary.sex == 'male' ? " ♂" : (primary.sex == "female" ? " ♀" : (primary.sex == "intersex" ? ' ⚥': ''))}</span>
        {primary.date_of_birth &&
          <span style={{marginRight: '1ex'}}> {format(new Date(primary.date_of_birth), 'dd MMM. yyyy')}</span>
        }
        </Col>
      </Row>
      <Row style={{fontSize: 'normal'}}>
        <Col>
          <Form.Control
            className="conditions-text"
            as="textarea"
            rows={5}
            name="message_text"
            placeholder="Please provide details if applicable"
            required
            onChange={handleChangePrimary}
            value={medicalConditionsTextPrimary()}
          />
        </Col>
      </Row>
      {dependents.map( (dependent, index) => (
        <div className="mt-3" key={dependent.id.toString()}>
          <Row style={{fontSize: 'larger'}}>
            <Col>
            <span style={{marginRight: '1ex', color: 'grey'}}>{dependent.relationship == 'partner' ? 'Partner' : 'Child'}</span>
            <span style={{marginRight: '1ex'}}>{dependent.firstname} {dependent.lastname}</span>
            <span style={{marginRight: '1ex'}}>{dependent.sex == 'male' ? " ♂" : (dependent.sex == "female" ? " ♀" : (dependent.sex == "intersex" ? ' ⚥': ''))}</span>
            {dependent.date_of_birth &&
              <span>{format(new Date(dependent.date_of_birth), 'dd MMM. yyyy')}</span>
            }
            </Col>
          </Row>
          <Row style={{fontSize: 'normal'}}>
            <Col>
              <Form.Control
                className="conditions-text"
                as="textarea"
                rows={5}
                name="message_text"
                placeholder="Please provide details if applicable"
                required
                onChange={handleChange(dependent.id)}
                value={medicalConditionsTextDependent(dependent.id)}
              />
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  )
}