// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React from "react"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { nanoid } from 'nanoid';

export default function QuoteFormDependents(props) {
  const maxDependents = 6;

  const [partnerAdded, setPartnerAdded] = React.useState(false);
  const [relationshipOptions, setRelationshipOptions] = React.useState([]);
  // const [validated, setValidated] = React.useState(false);

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});


  // const handleSubmit = (event) => {

  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   setValidated(true);
  // };
  const allOptions = [
    {key: "partner", value: "partner", label: "Partner", disabled: false},
    {key: "child",   value: "child",   label: "Child",   disabled: false}
  ];

  const childOption = [
    {key: "partner", value: "partner", label: "Partner", disabled: true},
    {key: "child",   value: "child",   label: "Child",   disabled: false}
  ];


  const OptionsList = ({options}) => {
    return (
      options.map( (option, index) =>
        <option key={option.key} value={option.value} disabled={option.disabled} >{option.label}</option>
      )
    )
  };


  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    // Update the document title using the browser API
    setRelationshipOptions(allOptions)
  }, []); // empty array => run hook only once

  const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };









  const handleChange = (event) => {
    const targetName = event.target.name.match(/(\[\]\[)(.*)(\])/)[2]

    const dep = props.quote.dependents[event.target.getAttribute('data-dependent-no') - 1]
    dep[targetName] = event.target.value;

    // const invalid_age = (
    //   ! dep.age ||
    //   dep.age > 26 && dep.relationship == 'child' ||
    //   dep.age >= props.quote.age && dep.relationship == 'child' ||
    //   dep.age < 17 && dep.relationship == 'partner' ||
    //   dep.age > 69
    // )
    // dep.errors.age = invalid_age;

    // was the field modified
    dep.touched = {
      ...dep.touched,
      [targetName]: true
    };

    if (targetName == 'relationship') {
      //props.validateForm();
      dep.errors = validateDependentAge('age', dep.age, dep.relationship);
    }

    let dependents = [...props.quote.dependents];
    dependents[event.target.getAttribute('data-dependent-no') - 1] = dep

    props.setQuote({
      ...props.quote,
      dependents: dependents
    });

    updateRelationshipOptions();
  };

  const validateDependentAge = (name, age, relationship) => {
    const { [name]: removedError, ...rest } = errors;
    const error = props.validation[name](age, relationship);
    return {
      ...rest,
      ...(error && { [name]: error })
    };
  };


  const handleBlur = (event) => {

    const { name, value } = event.target;
    const targetName = name.match(/(\[\]\[)(.*)(\])/)[2]

    //validate(event, targetName, value);
    if (typeof props.validation[targetName] !== 'function') return;

    const dep = props.quote.dependents[event.target.getAttribute('data-dependent-no') - 1]
    const { [targetName]: removedError, ...rest } = dep.errors;
    const error = props.validation[targetName](value, dep.relationship);

    dep.errors = {
      ...rest,
      ...(error && { [targetName]: dep.touched[targetName] && error })
    };

    let dependents = [...props.quote.dependents];
    dependents[event.target.getAttribute('data-dependent-no') - 1] = dep
    props.setQuote({
      ...props.quote,
      dependents: dependents
    });
  };

  // const validate = (event, targetName, value) => {
  //   if (typeof props.validation[targetName] !== 'function') return;

  //   const dep = props.quote.dependents[event.target.getAttribute('data-dependent-no') - 1]
  //   const { [targetName]: removedError, ...rest } = dep.errors;
  //   const error = props.validation[targetName](value, dep.relationship);

  //   dep.errors = {
  //     ...rest,
  //     ...(error && { [targetName]: dep.touched[targetName] && error })
  //   };

  //   let dependents = [...props.quote.dependents];
  //   dependents[event.target.getAttribute('data-dependent-no') - 1] = dep
  //   props.setQuote({
  //     ...props.quote,
  //     dependents: dependents
  //   });

  // }


  const handleAddDependent = (event) => {
    if (props.quote.dependents.length >= maxDependents) {
      return;
    }
    props.setValidated(false);
    const deps = Array.from(props.quote.dependents);
    deps.push({
      id: nanoid(),
      age: '',
      sex: 'male',
      relationship: 'child',
      errors: {},
      // errors: {
      //   age: true,
      //   sex: false,
      //   relationship: false
      // },
      touched: {}
    });
    //setDependents(deps);
    props.setQuote({
      ...props.quote,
      dependents: deps
    });

    triggerRepaintForParallax();
  };








  const handleRemoveDependent = (event) => {
    props.quote.dependents.splice(event.target.getAttribute('data-dependent-no') - 1, 1);
    props.setQuote({
      ...props.quote,
      dependents: Array.from(props.quote.dependents)
    });

    updateRelationshipOptions();

    triggerRepaintForParallax();
  };



  // See if there is already one dependent with relationship partner.
  // Only one partner allowed.
  const updateRelationshipOptions = () => {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
    // (param1, param2, …, paramN) => expression
    // equivalent to: => { return expression; }

    const index = props.quote.dependents.findIndex(dep => dep.relationship == 'partner');
    if (index > -1) {
      setPartnerAdded(true);
      setRelationshipOptions(childOption)
    } else {
      setPartnerAdded(false);
      setRelationshipOptions(allOptions)
    }
  };



  const QuoteFormButtonAddDependents = (props) => {
    if (props.dependents.length < props.maxDependents) {
      return (
        <Button variant="secondary" type="button" onClick={props.handleAddDependent} >Add Dependent</Button>
      );
    }
    return <></>;
  };


  const triggerRepaintForParallax = () => {
    //jQuery(window).trigger('resize.px.parallax');
    //jQuery(window).trigger('resize').trigger('resize');
    //window.dispatchEvent(new Event('resize'));
  }


  // const AgeFeedback = ({age, relationship}) => {
  //   let feedback = "Please enter the dependent's age."

  //   if (relationship == 'child') {
  //     if (! age) {
  //       feedback = "Please enter the dependent's age.";
  //     } else {
  //       if (age >= props.quote.age) {
  //         feedback = "Children must be younger than the primary insured.";
  //       } else if (age > 26) {
  //         feedback = "Children must be 26 years or younger.";
  //       }
  //     }
  //   } else if (relationship == 'partner') {
  //     if (age > 69) {
  //       feedback = "Partners must be between 17 and 69 years old.";
  //     } else if (age < 17) {
  //       feedback = "Partners must be at least 17 years old."
  //     }
  //   }
  //   return (<>{feedback}</>)
  // };


  // const validateRelationship = (index) => {
  //   return (props.validated == true && props.quote.dependents[index].relationship == 'select_relationship');
  // };

  return (
    <>
      {props.quote.dependents.map( (dependent, index) => (

        <div key={dependent.id.toString()}>

          <Form.Row className="dependent-row">
            <Form.Text className="form-note">
              {index + 1}{nth(index + 1)} Dependent
            </Form.Text>
          </Form.Row>
          <Form.Row>
              <Form.Group controlId={"dependentAge" + (index + 1)} className="age">
                <Form.Label srOnly>
                  Your age
                </Form.Label>
                <Form.Control
                  type="number"
                  name="dependents[][age]"
                  data-dependent-no={index + 1}
                  placeholder="Age*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={props.quote.dependents[index].age}
                  min="0"
                  max="69"
                  isInvalid={props.quote.dependents[index].errors.age}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  tooltip={true}
                >
                  {props.quote.dependents[index].errors.age}
                  {/*
                  <AgeFeedback age={props.quote.dependents[index].age} relationship={props.quote.dependents[index].relationship} />
                   */}
                </Form.Control.Feedback>
              </Form.Group>

             <Form.Group controlId={"dependentSex" + (index + 1)} className="sex">
                <Form.Label srOnly>
                  Dependent sex
                </Form.Label>
                <Form.Control
                  as="select"
                  name="dependents[][sex]"
                  data-dependent-no={index + 1}
                  onChange={handleChange}
                  value={props.quote.dependents[index].sex}
                >
                  <option key="male"   value="male"   disabled={false} >Male</option>
                  <option key="female" value="female" disabled={false} >Female</option>
                </Form.Control>
                <Form.Control.Feedback
                  type="invalid"
                  tooltip={true}
                >
                  Please select the dependent's sex.
                </Form.Control.Feedback>
              </Form.Group>


              <Form.Group controlId={"dependentRelationship" + (index + 1)} className="relationship">
                <Form.Label srOnly>
                  Dependent relationship
                </Form.Label>
                <Form.Control
                  as="select"
                  name="dependents[][relationship]"
                  data-dependent-no={index + 1}
                  onChange={handleChange}
                  value={props.quote.dependents[index].relationship}
                >
                  <OptionsList options={relationshipOptions} />
                </Form.Control>
                <Form.Control.Feedback
                  type="invalid"
                  tooltip={true}>
                  Please select a relationship.
                </Form.Control.Feedback>
              </Form.Group>


              <Button variant="secondary" name={"remove_dependent_" + (index + 1)} data-dependent-no={index + 1} type="button" onClick={handleRemoveDependent} className="remove-bin">Remove</Button>
          </Form.Row>
        </div>
      ))}
      <QuoteFormButtonAddDependents dependents={props.quote.dependents} maxDependents={maxDependents} handleAddDependent={handleAddDependent} />
    </>
  );
}
