// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import TESTING from "../globals"

import React, { useEffect, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import { format } from 'date-fns'
import NumberFormat from 'react-number-format';

import Container from 'react-bootstrap/Container'
import { Table, Row, Col } from 'react-bootstrap'

import IlFormatDate from '../util/IlFormatDate'
import IlFormatMoney from '../util/IlFormatMoney'


export default function IndividualPolicyPeriodRates({quote, currency, paymentPeriod}) {


  const Relationship = ({relationship}) => {
    if (relationship == 'spouse') {
      relationship = 'Partner';
    }
    return relationship = relationship.charAt(0).toUpperCase() + relationship.slice(1);
  };

  if (! quote) return null;

  return (
    <>
      {/*
        var plan = Ext.getCmp('client-policy-unisex-plan').getValue();
        var format = ig.mis.clients.formatMoney;
        var currency = Ext.getCmp('client-policy-currency-usd').getGroupValue();
        var paymentPeriod = Ext.getCmp('client-policy-payment-option').getValue();
        if (paymentPeriod == false || paymentPeriod === 'unknown') {
            return '';
        }
        var plan = Ext.getCmp('client-policy-unisex-plan').getValue();

        var rates = '
      */}

      <Container className="premium-json mt-3">
        <h2>Rates</h2>
        <Row>
          <Col xs="2">Coverage for:</Col>
          <Col>{quote.coverage}</Col>
        </Row>
        <Row>
          <Col xs="2">Geo Rate:</Col>
          <Col>{quote.rates?.primary_rates.database_record.geo_rate}</Col>
        </Row>
        <Row>
         <Col xs="2">Discount:</Col>
         <Col>
           <NumberFormat value={(1 - quote.rates?.discount_factor) * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix="%" />&nbsp;
            (Family        <NumberFormat value={(1 - quote.rates?.family_discount_factor) * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix="%" />,
             UAE Network:  <NumberFormat value={(1 - quote.rates?.uae_network_discount_factor) * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix="%" />,
             CHKS Network: <NumberFormat value={(1 - quote.rates?.chks_15_network_discount_factor) * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix="%" />)
         </Col>
       </Row>

       {quote.rates?.adjustment_tier.name &&
       <Row className="coverage-for">
         <Col xs="2">UAE&nbsp;Adjustment&nbsp;Tier:</Col>
         <Col>{quote.rates?.adjustment_tier.factor} ("{quote.rates?.adjustment_tier.name}", <IlFormatDate date={quote.rates?.adjustment_tier.start_date} />&mdash;<IlFormatDate date={quote.rates?.adjustment_tier.end_date} />)</Col>
       </Row>
       }
       </Container>



       <Table className="premium-json mt-3" style={{fontSize: 'smaller', marginBottom: 0}} >
        <thead>
        <tr>
          <td className="text-left">
            <span className="vertical-header"><strong>Name</strong></span>
          </td>
          <td className="text-left"><span className="vertical-header"><strong>Relationship</strong></span></td>
          <td><span className="vertical-header"><strong>Age</strong></span></td>
          <td>{/* Tiered Rate */}
            <span className="vertical-header"><strong>Final Rates</strong></span>
          </td>
          <td>
            <span className="vertical-header"><strong>PSP</strong></span>
          </td>
          <td>
            <span className="vertical-header">
              <strong>Dental</strong><br />
              <span style={{fontSize: 'smaller'}}>Tier {((quote.rates.adjustment_tier.factor) * 100) + "%, x-Days"}</span>
            </span>
          </td>
          <td><span className="vertical-header"><strong>AD&amp;D</strong></span></td>
          <td><span className="vertical-header"><strong>Embedded AD&amp;D</strong></span></td>
          <td>
            <span className="vertical-header">
              <strong>Prorated</strong>{/* Prorated Rate */}
              <br />
              <span style={{fontSize: 'smaller'}} title="No tier adjustment for AD&D">
                Tier {((quote.rates.adjustment_tier.factor) * 100) + "%, x-Days"}<br />
                minus ficitious AD&amp;D
              </span>
            </span>
          </td>
          <td><span className="vertical-header"><strong>Days</strong></span></td>
          <td>
            <span className="vertical-header">
            Including<br />
            Loadings
            </span>
          </td>
          <td>
            <span className="vertical-header">
            <strong>Loading</strong> {/* based on base rate and family discount */}
            </span>
          </td>
          <td>
            <span className="vertical-header">
            Loading<br />
            Components
            </span>
          </td>
          <td>
            <span className="vertical-header">
              incl. >3 children<br />
              discount{/* >3 Children */}
            </span>
          </td>
          <td className="">
            <span className="vertical-header">
              <strong>Family Discount</strong><br />

              <span style={{fontSize: 'smaller'}}>
                <NumberFormat value={(1 - quote.rates.family_discount_factor) * 100} displayType={'text'} thousandSeparator={true} decimalScale={0} suffix="%" />
              </span>
            </span>
          </td>
          <td>
            <span className="vertical-header">
              <strong>SAND</strong><br />
              <span style={{fontSize: 'smaller'}}>
                included in base rate
              </span>
            </span>
          </td>
          <td>
            <span className="vertical-header">
              <strong>Base Rate</strong><br />
              <span style={{fontSize: 'smaller'}}>
                incl. network discounts
              </span>
            </span>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className="text-left">{quote.rates.primary_rates.primary.fullname}</td>
          <td className="text-left">
            Primary   {/* Relationship */}
          </td>
          <td>{quote.rates.primary_rates.primary.age}</td>
          {/* <td>// Totals below</td> */}
          <td>{/* Final Rates */}
            <IlFormatMoney value={quote.rates.primary_rates.including_loadings_and_adjustment_tier_and_dental_rates_and_add_rates[paymentPeriod]} currency={currency} />
          </td>
          <td>
            <IlFormatMoney value={quote.rates.primary_rates.psp.amount} currency={currency} />
          </td>
          <td>
            <IlFormatMoney value={quote.rates.primary_rates.dental_rates_aliquot_including_adjustment_tier[paymentPeriod]} currency={currency} />
          </td>
          <td>
            <span title="AD&amp;D rate">
              <IlFormatMoney value={quote.rates.primary_rates.add_rates[paymentPeriod]} currency={currency} />
            </span>
          </td>
          <td>
            <span title="Embedded AD&amp;D rate">
              <IlFormatMoney value={quote.rates.primary_rates.fictitious_add_rates[paymentPeriod]} currency={currency} />
            </span>
          </td>
          <td>
            <IlFormatMoney value={quote.rates.primary_rates.including_loadings_and_adjustment_tier[paymentPeriod]} currency={currency} />
          </td>
          <td>{/* days insured */}
            {quote.rates.primary_rates.days_insured}
          </td>
          <td>
            <IlFormatMoney value={quote.rates.primary_rates.including_loadings[paymentPeriod]} currency={currency} />
          </td>
          <td>{/* Loading */}
            <IlFormatMoney value={quote.rates.primary_rates.loadings[paymentPeriod]} currency={currency} />
          </td>
          <td>{/* Loading Components */}
            <IlFormatMoney value={quote.rates.primary_rates.loadings.amount[paymentPeriod]} currency={currency} />&nbsp;+&nbsp;
            {quote.rates.primary_rates.loadings.percentage}%
          </td>
          <td>{/* >3 Children */}
            <IlFormatMoney value={quote.rates.primary_rates.including_discounts[paymentPeriod]} currency={currency} />
          </td>
          <td>
            <IlFormatMoney value={quote.rates.primary_rates.family_discount[paymentPeriod]} currency={currency} />
          </td>
          <td>
            <IlFormatMoney value={quote.rates.primary_rates.pocund[paymentPeriod]} currency={currency} />
          </td>
          <td>
            <IlFormatMoney value={quote.rates.primary_rates.including_network_discount_and_pocund[paymentPeriod]} currency={currency} />
          </td>
        </tr>

        {quote.rates.dependent_rates.map(dependent_rate => (
        <tr key={dependent_rate.dependent.id}>
          <td className="text-left">{dependent_rate.dependent.fullname}</td>
          <td className="text-left"><Relationship relationship={dependent_rate.relationship} /></td>
          <td>{dependent_rate.dependent.age}</td>
          <td>{/* Rate adjusted to tier */}<IlFormatMoney value={dependent_rate.including_loadings_and_adjustment_tier_and_dental_rates_and_add_rates[paymentPeriod]} currency={currency} /></td>
          <td>{/* PSP                   */}<IlFormatMoney value={dependent_rate.psp.amount} currency={currency} /></td>
          <td>{/* Dental                */}<IlFormatMoney value={dependent_rate.dental_rates_aliquot_including_adjustment_tier[paymentPeriod]} currency={currency} /></td>
          <td>{/* AD&D                  */}n/a</td>
          <td>{/* AD&D                  */}n/a</td>
          <td>{/* Aliquot               */}<IlFormatMoney value={dependent_rate.including_loadings_and_adjustment_tier[paymentPeriod]} currency={currency} /></td>
          <td>{/* Days insured          */}{dependent_rate.days_insured}</td>
          <td>{/* Including Loadings    */}<IlFormatMoney value={dependent_rate.including_loadings[paymentPeriod]} currency={currency} /></td>
          <td>{/* Loading               */}<IlFormatMoney value={dependent_rate.loadings[paymentPeriod]} currency={currency} /></td>
          <td>{/* Loading Components    */}<IlFormatMoney value={dependent_rate.loadings.amount[paymentPeriod]} currency={currency} /> + {dependent_rate.loadings.percentage ? dependent_rate.loadings.percentage + '%' : '0%'}</td>
          <td>{/* >3 Children           */}<IlFormatMoney value={dependent_rate.including_discounts[paymentPeriod]} currency={currency} /></td>
          <td>{/* Family Discount       */}<IlFormatMoney value={dependent_rate.family_discount[paymentPeriod]} currency={currency} /></td>
          <td>{/* Included SAND         */}<IlFormatMoney value={dependent_rate.pocund[paymentPeriod]} currency={currency} /></td>
          <td>{/* Base Rate             */}<IlFormatMoney value={dependent_rate.including_network_discount_and_pocund[paymentPeriod]} currency={currency} /></td>
        </tr>
        ))}

        {/* SUBTOTAL */}
        <tr>
          <td>{/* Name */}</td>
          <td className="totals" colSpan="2">Subtotal{/* Age */}</td>
          <td className="">
            <span style={{borderTop: '1px solid grey'}}>
              <IlFormatMoney value={quote.rates.including_loadings_and_adjustment_tier_and_dental_rates_and_add_rates[paymentPeriod]} currency={currency} />
            </span>
          </td>
          <td className="">
            <span style={{borderTop: '1px solid grey'}}>
              <IlFormatMoney value={quote.rates.psp.amount} currency={currency} />
            </span>
          </td>
          <td className="">
            <span title="Sum of split dental premiums" style={{borderTop: '1px solid grey'}}>
              <IlFormatMoney value={quote.rates.dental_rates_aliquot_including_adjustment_tier[paymentPeriod]} currency={currency} />
            </span>{/* Dental rates */}
          </td>
          <td className="">
            <span style={{borderTop: '1px solid grey'}}>
              <span title="Sum ofAD&amp;D premiums.">
                <IlFormatMoney value={quote.rates.add_rates[paymentPeriod]} currency={currency} />
              </span>
            </span>
          </td>
          <td className="">
            <span title="Sum of embedded AD&amp;D premiums" style={{borderTop: '1px solid grey'}}>
              <IlFormatMoney value={quote.rates.fictitious_add_rates[paymentPeriod]} currency={currency} />
            </span>{/* AD&D rates */}
          </td>
          <td className="">
            <span style={{borderTop: '1px solid grey'}}>
              <IlFormatMoney value={quote.rates.including_loadings_and_adjustment_tier[paymentPeriod]} currency={currency} />
            </span>
          </td>
          <td className="">{/* days insured */}</td>
          <td className="">{/* Including Loadings */}</td>
          <td className="">{/* loadings*/}</td>
          <td className="">{/* Loading Components*/}</td>
          <td className="">{/* >3 Children */}</td>
          <td className="">{/* family discount */}</td>
          <td className="">{/* pocund */}</td>
          <td className="">{/* base rate*/}</td>
        </tr>

        {/* TAX */}
        <tr>
          <td>{/* Name */}</td>
          {quote.rates.tax.amount > 0 &&
              <td className="tax" colSpan="2">{/*<!-- Relationship -->*/}{quote.rates.tax.amount}% {quote.rates.tax.kind}</td>
          }
          {quote.rates.tax.amount <= 0 &&
              <td className="tax" colSpan="2">{/*<!-- Relationship -->*/}TAX</td>
          }
          {/*<!-- <td> Age </td>-->*/}
          <td>{/* Tax */}<IlFormatMoney value={quote.rates.tax[paymentPeriod]} currency={currency} /></td>
          <td>{/* PSP */}<IlFormatMoney value={quote.rates.psp.tax_amount} currency={currency} /></td>
          <td>{/* Dental rate */}</td>
          <td>{/* AD&D rate */}</td>
          <td>{/* Embedded AD&D rate */}</td>
          <td>{/* days insured */}</td>
          <td>{/* Including Loadings */}</td>
          <td>{/* Loadings */}</td>
          <td>{/* Loading Components */}</td>
          <td>{/* >3 Children */}</td>
          <td>{/* Family Discount */}</td>
          <td>{/* SAND */}</td>
          <td></td>
        </tr>
        {/*


        {/* Total Due */}
        <tr>
        <td className="totals-due" style={{textAlign: 'right'}} colSpan="3">
          Total <span style={{textTransform: 'capitalize'}}>{paymentPeriod}</span> Due{/*<!-- Relationship -->*/}
        </td>
          {/*<!-- <td> Age </td>-->*/}
        <td style={{fontWeight: 'bolder'}}>{/*<!-- Total Due -->*/}
          <span style={{borderTop: '1px solid black'}}><IlFormatMoney value={quote.rates.total_due[paymentPeriod]} currency={currency} /></span>
        </td>
        <td >
          {/*<!-- PSP -->*/}
          <span style={{borderTop: '1px solid black'}}><IlFormatMoney value={quote.rates.psp.total_due} currency={currency} /></span>
        </td>
        <td>{/* Dental rate  */}</td>
        <td>{/* AD&D rate */}</td>
        <td>{/* Embedded AD&D rate */}</td>
        <td>{/* Prorated Rate */}</td>
        <td>{/* days insured */}</td>
        <td>{/* Including Loadings */}</td>
        <td>{/* Loadings */}</td>
        <td>{/* Loading Components */}</td>
        <td>{/* >3 Children */}</td>
        <td>{/* Family Discount */}</td>
        <td>{/* SAND */}</td>
        <td></td>
        </tr>
        {/*
        if (calculationURL) {
            <tr><td colspan="17" style="padding-top: 3em;"><a href="' + calculationURL + '" target="_blank">Show gory calculation details...</td></tr>';
        }
        */}
        </tbody>
      </Table>

    </>
  );

}
