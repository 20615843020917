// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import { TESTING, getAge } from '../../exports';
import React, { useState, useRef } from "react"
import axios from "axios"
import { nanoid } from 'nanoid'
import { format } from 'date-fns';

// The useHistory hook gives you access to the history instance that you may use to navigate.
import {Redirect, useHistory } from "react-router-dom"

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'

import FormCheck from 'react-bootstrap/FormCheck'
import Feedback from 'react-bootstrap/Feedback'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addYears } from '@progress/kendo-date-math'

import EnrolmentPrimaryView from './EnrolmentPrimaryView'

import CountrySelect    from '../../country/CountrySelect'
import OccupationSelect from '../../OccupationSelect'

import {isNumeric} from '../../exports'
import {CITIZENSHIPS} from '../../country/countries'

// @TODO remove

import Select from 'react-select'
import {COUNTRIES} from '../../country/countries'
import CountryCallingCodeSelect from '../../country/CountryCallingCodeSelect'

export default function EnrolmentPrimary({
  primary,
  setPrimary,
  validated,
  setValidated,
  initComprehensiveAndPremiumPlans}) {

  const debug = () => {
    console.log('errors');
    console.log(errors);
    console.log('primary');
    console.log(primary);
  }

  const topLocation = useRef(null);

  let history = useHistory();


  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});



  const nameValidation = (fieldName, fieldValue) => {
    if (! fieldValue || fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    // if (/[^a-zA-Z -]/.test(fieldValue)) {
    //   return 'Invalid characters';
    // }
    // if (fieldValue.trim().length < 3) {
    //   return `${fieldName} needs to be at least three characters`;
    // }
    return null;
  };

  const countryValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '' || fieldValue.trim() === 'select_country') {
      return `${fieldName} is required`;
    }
    return null;
  };


  const occupationValidation = (fieldName, fieldValue, emptyValue) => {
    if (! fieldValue || fieldValue.trim() === emptyValue) {
      return 'Please select your occuption';
    }
    return null;
  };


  const numberValidation = (fieldName, fieldValue) => {
    if (typeof fieldValue == "string" && fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (! isNaN(fieldValue)) {
      return null;
    }
    return `${fieldName} needs to be at valid number`;
  };


  const emailValidation = email => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };

  const ageValidation = dateOfBirth => {

    if (typeof dateOfBirth == "string" && dateOfBirth.trim() === '') {
      return 'Please provide your date of birth.';
    }

    const age = getAge(dateOfBirth);

    if (! isNaN(age)) {
      const primary_age = parseInt(age);
      if (primary_age < 18) {
        return 'Primary insured must be at least 18 years old.';
      }
      if (primary_age > 69) {
        return 'Primary insurde must be under 70 years old.';
      }
    } else {
      return 'Age needs to be at valid number.';
    }
    return null;
  };


  const privacyPolicyValidation = (value) => {
    if (! value) {
      return 'Privay policy must be accepted';
    }
    return null;
  };







  const validation = {
    occupation:                      name => occupationValidation('Occupation', name, 'select_occupation'),
    firstname:                       name => nameValidation('Firstname', name),
    lastname:                        name => nameValidation('Lastname', name),
    email_address:                   emailValidation,
    mobile_phone:                    name => nameValidation('Mobile phone', name),
    country_calling_code:            phone => nameValidation('Calling code', phone),
    postal_code:                     name => nameValidation('Zip/postcode', name),
    city:                            name => nameValidation('City', name),
    country_of_residence_outside_US: country => countryValidation('Country of residence', country),
    citizenship:                     country => countryValidation('Citizenship', country),
    date_of_birth:                   ageValidation,
    sex:                             name => nameValidation('Sex', name),
    weight:                          number => numberValidation('Weight', number),
    weight_measurement_unit:         name => nameValidation('Weight measurement unit', name),
    height:                          number => numberValidation('Height', number),
    height_measurement_unit:         name => nameValidation('Height measurement unit', name),
    privacy_policy_agreement:        privacyPolicyValidation
  };

  const handleChange = (event) => {
    //const { name, value: newValue, type } = event.target;
    let { name, value, type } = event.currentTarget;

    value = type === 'number' ? +value : value;

    if (['privacy_policy_agreement'].includes(name)) {
      value = event.target.checked;
    }


    setPrimary({
      ...primary,
      [name]: value
    });

    // was the field modified
    setTouched({
      ...touched,
      [name]: true
    });

    if (type == 'radio' || type == 'checkbox') {
      // Object destructuring with dynamic name (alias)
      // removedError = errors[name]
      // https://dmitripavlutin.com/javascript-object-destructuring/
      const { [name]: removedError, ...rest } = errors;
      setErrors({
        ...rest
      });
    }
    if (name == 'country_of_residence_outside_US') {
      initComprehensiveAndPremiumPlans(value);
    }

  };




  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (typeof validation[name] !== 'function') return;
    const { [name]: removedError, ...rest } = errors;
    const error = validation[name](value);
    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error })
    });
  };


  const handleProceed = target => () => {

    const formValidation = Object.keys(validation).reduce(
      (acc, key) => {
        const newError = validation[key](primary[key]);
        const newTouched = { [key]: true };

        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          }
        };

      },
      {
        errors: { ...errors },
        touched: { ...touched },
      },
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (Object.values(formValidation.errors).length < 1) {// errors object is empty
      setValidated({...validated, primary: true});
      savePrimary(target);
      return
    }

    if (topLocation.current) {
      topLocation.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
    setValidated({...validated, primary: false});
  }



  const savePrimary = (target) => {
    //console.log(primary);
    //const method = primary.id ? 'put' : 'post';

    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + primary.id,
      data: primary
    }).then(response => {

      setPrimary({
        ...primary,
        medical_status: response.data.medical_status,
        bmi: response.data.bmi
      });

      if (target == "add_dependent") {
        history.push("/ig/enrolment/dependents/" + nanoid());
      } else if (target == "main_questions") {
        history.push("/ig/enrolment/main_questions");
      } else {
        history.push("/ig/enrolment");
      }


      // setPrimary({
      //   ...primary,
      //   id: response.data.id
      // });
    }).catch(error => {
      if (error.response) {
        if (error.response.status == 401) {
          alert('permission denied');
        } else {

        }
      } else {
        throw error;
      }

    });
  };


  return (
    <Form>
      {Object.values(errors).length > 0 &&
        <Jumbotron className="">
          <h3>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h3>
        </Jumbotron>
      }

      <h2 ref={topLocation} className="mt-4">Tell us about yourself</h2>

      <Form.Text className="enrolment-required-fields">Fields marked with an <span>*</span> are required</Form.Text>

      <Form.Group as={Row} controlId="formGroupFirstName">
        <Col sm="12">
          <Form.Control
            type="text"
            name="firstname"
            placeholder="Firstname*"
            required
            value={primary.firstname}
            isInvalid={errors.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupLastName">
        <Col sm="12">
          <Form.Control
            type="text"
            name="lastname"
            placeholder="Lastname*"
            required
            value={primary.lastname}
            isInvalid={errors.lastname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupEmail">
        <Col sm="12">
          <Form.Control
            type="text"
            name="email_address"
            placeholder="Email*"
            required
            value={primary.email_address}
            isInvalid={errors.email_address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide your email address.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupCountryCallingCode">
        <Col sm="12" lg="6">
          <CountryCallingCodeSelect
            className={errors.country_calling_code ? 'is-invalid' : ''}
            name="country_calling_code"
            validated={validated}
            placeholder="Country Code*"
            value={primary.country_calling_code}
            isInvalid={errors.country_calling_code}
            onChange={(option) => {
              setPrimary({
                ...primary,
                country_calling_code: option.value
              })
              if (typeof validation['country_calling_code'] !== 'function') return;
              const { ['country_calling_code']: removedError, ...rest } = errors;
              const error = validation['country_calling_code'](option.value);
              setErrors({
                ...rest,
                ...(error && { ['country_calling_code']: touched['country_calling_code'] && error })
              });
            }}
            options={COUNTRIES.map((country) => {
              return {value: country.calling_code, label: country.name + ' (+' + country.calling_code + ')'};
            })}
          />

          {errors.country_calling_code &&
            <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>Please select your country calling code.</div>
          }

        </Col>
        <Col className="mt-3 mt-lg-0" sm="12" lg="6">
          <Form.Control
            type="text"
            name="mobile_phone"
            placeholder="Mobile phone*"
            required
            value={primary.mobile_phone}
            isInvalid={errors.mobile_phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide your phone number.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      {/*
      <Form.Group as={Row} controlId="formGroupMobilePhone">
        <Col sm="12">
          <PhoneInput
            name="mobile_phone"
            placeholder="Mobile phone number"
            value={primary.mobile_phone}
            onChange={(mobile_phone) => {
              //console.log(mobile_phone)
              // console.log('isValidPhoneNumber(mobile_phone):' + isValidPhoneNumber(mobile_phone));
              //if (mobile_phone) {
              //  console.log('parsePhoneNumber(mobile_phone):')
              //  console.log(parsePhoneNumber(mobile_phone));
              //  console.log('country:')
              //  console.log(parsePhoneNumber(mobile_phone) && parsePhoneNumber(mobile_phone).country);
              //}
              setPrimary({
                ...primary,
                'mobile_phone': mobile_phone
              });
              setTouched({
                ...touched,
                'mobile_phone': true,
              });
            }}
            onBlur={handleBlur}
            error={primary.mobile_phone ? (isValidPhoneNumber(primary.mobile_phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
          />
          {errors.mobile_phone &&
            <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>Please provide your phone number.</div>
          }
        </Col>
      </Form.Group>
      */}

      <Form.Group as={Row} controlId="formGroupOccupation">
        <Col sm="12">
          <OccupationSelect
            placeholder='Occupation'
            name="occupation"
            value={primary.occupation}
            isInvalid={errors.occupation}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please select an occupation.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <div className="white-space"></div>
      <h2 className="mt-4">Tell us about where you live and where you are from</h2>


      <Form.Group as={Row} controlId="formGroupAddressLine1">
        <Col sm="12">
          <Form.Control
            type="text"
            name="address_line_1"
            placeholder="Address Line 1"
            required
            onChange={handleChange}
            value={primary.address_line_1}
          />
          <Form.Control.Feedback type="invalid">Please provide a street name.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupAddressLine2">
        <Col sm="12">
          <Form.Control
            type="text"
            name="address_line_2"
            placeholder="Address Line 2"
            onChange={handleChange}
            value={primary.address_line_2}
          />
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupPostalCode">
        <Col sm="12">
          <Form.Control
            type="text"
            name="postal_code"
            placeholder="Zip/postcode*"
            required
            value={primary.postal_code}
            isInvalid={errors.postal_code}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide a zip/postcode.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupCity">
        <Col sm="12">
          <Form.Control
            type="text"
            name="city"
            placeholder="City* and State (if applicable)"
            required
            value={primary.city}
            isInvalid={errors.city}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide the name of the city you live in.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupCountryOfResidenceOutsideUS">
        <Col sm="12">
          <CountrySelect
            className={'form-control ' + (errors.country_of_residence_outside_US ? 'is-invalid' : '') }
            fieldName="country_of_residence_outside_US"
            validated={validated}
            placeholder="Country of Residence*"
            value={primary.country_of_residence_outside_US}
            isInvalid={errors.country_of_residence_outside_US}
            onChange={handleChange}
            onBlur={handleBlur}
            exclude={['HK', 'US']}
          >
            <Form.Control.Feedback type="invalid">Please provide your country of residence outside the US.</Form.Control.Feedback>
          </CountrySelect>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupCitizenship">
        <Col sm="12">
          <CountrySelect
            countries={CITIZENSHIPS}
            className={'form-control ' + (errors.citizenship ? 'is-invalid' : '') }
            fieldName="citizenship"
            validated={validated}
            placeholder="Citizenship*"
            value={primary.citizenship}
            isInvalid={errors.citizenship}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <Form.Control.Feedback type="invalid">Please provide your citizenship.</Form.Control.Feedback>
          </CountrySelect>

        </Col>
      </Form.Group>

      <div className="white-space"></div>
      <h2 className="mt-4">And now we need to get a little personal</h2>

      <Row className="mt-4">
        <Form.Group as={Col} controlId="formGroupAge">
          <DatePicker
            className={'primary-insured-birthdate form-control ' + (errors.date_of_birth ? 'is-invalid' : '') }
            selected={primary.date_of_birth}
            dateFormat="yyyy-MM-dd"
            minDate={addYears(new Date(), -70)}
            maxDate={new Date()}
            placeholderText="Date of birth*"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onChange={(date) => {
              //let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
              //let localDate = new Date(date - tzoffset)
              let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
              setPrimary({
                ...primary,
                'date_of_birth': localDate //new Date(format(date, 'yyyy-MM-dd')) <= also works
              });
              setTouched({
                ...touched,
                'date_of_birth': true,
              });

              const { 'date_of_birth': removedError, ...rest } = errors;
              const error = validation['date_of_birth'](localDate); //(new Date(format(date, 'yyyy-MM-dd')));  <= also works
              setErrors({
                ...rest,
                ...(error && { 'date_of_birth': error })
              });
            }}
          />
          {/* @TODO workaround because I can't make Control.Feedback work with radio groups: */}
          {errors.date_of_birth &&
            <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>{errors.date_of_birth}</div>
          }

          <Form.Text muted className="">For example 1969-12-31</Form.Text>
          <Form.Control.Feedback type="invalid">Please provide your date of birth.</Form.Control.Feedback>
        </Form.Group>
      </Row>



      <Row className="mt-4">
        <Form.Group as={Col}>
          <Form.Label className="align-middle" style={{marginRight: '2rem', fontWeight: '500'}}>Sex*</Form.Label>
          <Form.Check inline>
            <FormCheck.Input
              id="sex-male"
              name="sex"
              type="radio"
              checked={primary.sex == 'male'}
              value="male"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="sex-male" style={{marginRight: '2rem'}}>male</FormCheck.Label>
            <FormCheck.Input
              id="sex-female"
              name="sex"
              type="radio"
              checked={primary.sex == 'female'}
              value="female"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="sex-female" style={{marginRight: '2rem'}}>female</FormCheck.Label>
            <FormCheck.Input
              id="sex-intersex"
              name="sex"
              type="radio"
              checked={primary.sex == 'intersex'}
              value="intersex"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label htmlFor="sex-intersex" style={{marginRight: '2rem'}}>inter</FormCheck.Label>
            <Form.Control.Feedback type="invalid">Please state your sex.</Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
      </Row>


      {/* WEIGHT */}
      <Row className="mt-4 mb-0">
        <Col md={3} className="mr-1">
          <Form.Group className="mb-1" controlId="formGroupWeight">
            <Form.Control
              type="number"
              name="weight"
              placeholder="Weight*"
              required
              value={primary.weight}
              isInvalid={errors.weight}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your weight.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formGroupWeightMeasurementUnit">
            <FormCheck inline>
              <FormCheck.Input
                id="weight_measurement_unit_kg"
                name="weight_measurement_unit"
                type="radio"
                checked={primary.weight_measurement_unit == 'kg'}
                value="kg"
                isInvalid={errors.weight_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label htmlFor="weight_measurement_unit_kg" style={{marginRight: '1em'}}>Kilograms</FormCheck.Label>
              <FormCheck.Input
                id="weight_measurement_unit_lbs"
                name="weight_measurement_unit"
                type="radio"
                checked={primary.weight_measurement_unit == 'lbs'}
                value="lbs"
                isInvalid={errors.weight_measurement_unit}
                onChange={handleChange}
                label="Pounds"
              />
              <FormCheck.Label htmlFor="weight_measurement_unit_lbs">Pounds</FormCheck.Label>
              {/*
              <Feedback type="invalid">Please choose a weight measurement unit.</Feedback>
               @TODO workaround because I can't make Control.Feedback work with radio groups:
              {errors.height_measurement_unit &&
                <div style={{color: '#A2242F', fontSize: '80%', display: 'block', width: '100%', marginTop: '.25rem'}}>Please choose a height measurement unit.</div>
              }
              */}
            </FormCheck>
            {errors.weight_measurement_unit &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', width: '100%', marginTop: 0}}>Please choose a weight measurement unit.</div>
            }
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group className="mb-1"  controlId="formGroupHeight">
            <Form.Control
              type="number"
              name="height"
              placeholder="Height*"
              required
              value={primary.height}
              isInvalid={errors.height}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your height.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="" controlId="formGroupHeightMeasurementUnit">
            <FormCheck inline>
              <FormCheck.Input
                id="height_measurement_unit_cm"
                name="height_measurement_unit"
                type="radio"
                checked={primary.height_measurement_unit == 'cm'}
                value="cm"
                isInvalid={errors.height_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label htmlFor="height_measurement_unit_cm" style={{marginRight: '1em'}}>Centimeters</FormCheck.Label>
              <FormCheck.Input
                id="height_measurement_unit_in"
                name="height_measurement_unit"
                type="radio"
                checked={primary.height_measurement_unit == 'in'}
                value="in"
                isInvalid={errors.height_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label htmlFor="height_measurement_unit_in" style={{}}>Inches</FormCheck.Label><br />
              {/*
              <Feedback type="invalid">Please choose a height measurement unit.</Feedback>
              {/* @TODO workaround because I can't make Control.Feedback work with radio groups:*/}
            </FormCheck>
            {errors.height_measurement_unit &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', width: '100%', marginTop: 0}}>Please choose a height measurement unit.</div>
            }
          </Form.Group>
        </Col>
      </Row>

      <Form.Row className="mt-3 mb-3">
        <Col>
          <Form.Group controlId="privacyPolicyAgreement" className={(errors.privacy_policy_agreement ? 'is-invalid' : '') }>
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={primary.privacy_policy_agreement === true}
                isInvalid={errors.privacy_policy_agreement}
                value="true"
                onChange={handleChange}
                name="privacy_policy_agreement"
              />
              <Form.Check.Label>
                I agree to the terms outlined in the privacy policy. By progressing with the
                enrolment you agree that we or our appointed agents may contact you to
                discuss your quote and your needs, and to our using your personal information
                in accordance with our <a href="/ig/privacy_policy">Privacy Policy</a>.
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid">
                Sorry. We are unable to give you an indicative price unless you agree to our <a href="/ig/privacy_policy">privacy policy</a>.
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
        </Col>
      </Form.Row>


      <Row>
        <Col className="mt-4 mb-5" lg="8">
          <h2>Do you have any family members to add to the policy?
          {TESTING &&
            <Button variant="link" onClick={debug} style={{marginLeft: '1em'}}><i className="fa fa-bug"></i></Button>
          }
          </h2>
          <div className="text-center">
            <Button variant="secondary" onClick={handleProceed("add_dependent")}>YES</Button>
            <Button variant="secondary" onClick={handleProceed("main_questions")}>NO</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}