// encoding: UTF-8
//
// (C) Copyright 2021-2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect, useRef } from "react"
import axios from "axios";
//import { clone, cloneDeep } from "lodash";
import { useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import { getAge } from '../../exports';

export default function EnrolmentPlanOptionsComprehensive({
  primary,
  dependents,
  comprehensivePlanOptions,
  setComprehensivePlanOptions,
  commonPlanOptions,
  setCommonPlanOptions,
  handlePlanOptionsChosen
}) {
  const mountedRef = useRef(true);
  let history = useHistory();

  const coverRegions = {
    worldwide:         'including US/Canada',
    worldwide_sans_us: 'excluding US/Canada'
  };

  const paymentPeriods = {
    annual:     'Annual',
    semiannual: 'Semi-annual',
    quarterly:  'Quarterly'
  };

  const networks = {
    silver:   'Silver',
    gold:     'Gold',
    platinum: 'Platinum'
  };

  const addBenefits = {
    USD: {
      your:    [0, 25000, 50000, 100000, 150000, 250000],
      premier: [100000, 150000, 250000]
    },
    EUR: {
      your:    [0, 20000, 40000,  80000, 120000, 200000],
      premier: [80000, 120000, 200000]
    },
    GBP: {
      your:    [0, 17500, 35000,  70000, 105000, 175000],
      premier: [70000, 105000, 175000]
    }
  };

  const deductibles = {
    USD: [200, 500, 1000, 5000],
    EUR: [150, 400,  750, 4000],
    GBP: [125, 300,  650, 3000]
  };


  if (deductibles[comprehensivePlanOptions.currency].includes( comprehensivePlanOptions.deductible ) == false) {
    comprehensivePlanOptions.deductible = deductibles[comprehensivePlanOptions.currency][2];
  }


  const findMatchingDeductible = (deductible, currency, previousCurrency) => {
    if (!previousCurrency) return deductible;
    const index = deductibles[previousCurrency].findIndex((deductible) => deductible == comprehensivePlanOptions.deductible)
    if (index > -1) {
      return deductibles[currency][index];
    }
    return deductibles[currency][2];
  };


  const findMatchingAddBenefit = (add_benefit, currency, previousCurrency) => {
    if (!previousCurrency) return add_benefit;
    const index = addBenefits[previousCurrency]['your'].findIndex((add_benefit) => add_benefit == comprehensivePlanOptions.add_benefit)
    if (index > -1) {
      return addBenefits[currency]['your'][index];
    }
    return addBenefits[currency]['your'][0];
  };


  const annualMaximumPlanBenefit = {
    USD: {
      life:   1500000,
      family: 1500000
    },
    EUR: {
      life:   1250000,
      family: 1250000
    },
    GBP: {
      life:   1000000,
      family: 1000000
    }
  };


  const lifeCover = {
    USD: {
      your:    5000,
      premier: 10000
    },
    EUR: {
      your:    5000,
      premier: 10000
    },
    GBP: {
      your:    5000,
      premier: 10000
    }
  }

  const currencyPrefix = {
    USD: '$',
    EUR: '€',
    GBP: '£'
  };


  const [premium, setPremium] = React.useState({annual: -1, semiannual: -1, quarterly: -1});
  const [optionsVisible, setOptionsVisible] = React.useState({
    deductible: false,
    add: false,
    geographical_cover: false,
    payment_period: false,
    network: false
  });
  const [csRegion, setCsRegion] = React.useState(false);



  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, []);


  useEffect(() => {
    getQuote();
  }, [comprehensivePlanOptions]);


  useEffect(() => {

    let options = {
      geographical_cover:   commonPlanOptions.geographical_cover,
      payment_period:       commonPlanOptions.payment_period,
      maternity_cover:      commonPlanOptions.maternity_cover,
      dental_option:        commonPlanOptions.dental_option,
      currency:             commonPlanOptions.currency,
      network:              commonPlanOptions.network,
      preferred_start_date: commonPlanOptions.preferred_start_date
    };

    let mapped_deductible  = commonPlanOptions.deductible;
    let mapped_add_benefit = commonPlanOptions.add_benefit;

    // @TODO could this be replaced with comprehensivePlanOptions.currency != commonPlanOptions.currency and would it be clearer?
    //if (commonPlanOptions.currency != commonPlanOptions.previous_currency) {

    if (commonPlanOptions.currency != comprehensivePlanOptions.currency) {
      mapped_deductible  = findMatchingDeductible(commonPlanOptions.deductible,  commonPlanOptions.currency, comprehensivePlanOptions.currency);
      mapped_add_benefit = findMatchingAddBenefit(commonPlanOptions.add_benefit, commonPlanOptions.currency, comprehensivePlanOptions.currency);
      options.currency = commonPlanOptions.currency;
    }

    // @TODO delete key from hash options instead?
    if (deductibles[commonPlanOptions.currency].includes( mapped_deductible )) {
      options.deductible = mapped_deductible;
    } else { // use default deductible if invalid deductible is not mappable
      options.deductible = deductibles[commonPlanOptions.currency][0];
    }
    if (addBenefits[commonPlanOptions.currency]['your'].includes( mapped_add_benefit )) {
      options.add_benefit = mapped_add_benefit;
    }

    if (['CN', 'MO', 'SG'].includes(primary.country_of_residence_outside_US)) {
      setCsRegion(true);
      options['plan'] = 'yourlife_cs_18_v';
      if (commonPlanOptions['maternity_cover']) {
        options['plan'] = 'yourfamily_cs_18_v';
      }
    } else {
      options['plan'] = 'yourlife_row_17_v';
      if (commonPlanOptions['maternity_cover']) {
        options['plan'] = 'yourfamily_row_17_v';
      }
    }

    // options['plan'] = 'yourlife_row_17_v';
    // if (commonPlanOptions.maternity_cover) {
    //   options['plan'] = 'yourfamily_row_17_v';
    // }

    setComprehensivePlanOptions({
      ...comprehensivePlanOptions,
      ...options
    });


  }, [commonPlanOptions]);



  const handleChange = (event) => {
    // const value = ['maternity_cover', 'dental_option'].includes(event.target.name) ? event.target.checked : event.target.value
    // const value = ['deductible', 'add_benefit'].includes(event.target.name) ? Number(event.target.value) : event.target.value


    if (['maternity_cover', 'dental_option'].includes(event.target.name)) {
      var value = event.target.checked;
    } else if (['deductible', 'add_benefit'].includes(event.target.name)) {
      var value = Number(event.target.value);
    } else {
      var value = event.target.value;
    }

    if (Object.keys(commonPlanOptions).includes(event.target.name)) {
      setCommonPlanOptions({
        ...commonPlanOptions,
        [event.target.name]: value,
        initial: false
      })
    } else {

      // Change the plan if the maternity cover changes
      let plan = '';
      if (['CN', 'MO', 'SG'].includes(storedQuote.country)) {
        plan = 'yourlife_cs_18_v';
        if (event.target.name == 'maternity_cover') {
          if (value) {
            plan = 'yourfamily_cs_18_v';
          }
        }
      } else {
        plan = 'yourlife_row_17_v';
        if (event.target.name == 'maternity_cover') {
          if (value) {
            plan = 'yourfamily_row_17_v';
          }
        }
      }
      setComprehensivePlanOptions({
        ...comprehensivePlanOptions,
        [event.target.name]: value,
        plan: plan
      });
    }

  };


  const handleOptionsVisible = (event) => {
    const option = event.target.getAttribute('data-options-visible');
    setOptionsVisible({
      ...optionsVisible,
      [option]: ! optionsVisible[option]
    });
  };


  const getQuote = () => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/quotes/get_premium.json", {
          params: {
            plan:                 comprehensivePlanOptions.plan,
            country_of_residence: primary.country_of_residence_outside_US,
            geographical_cover:   commonPlanOptions['geographical_cover'],
            deductible:           comprehensivePlanOptions.deductible,
            age:                  getAge(primary.date_of_birth),
            dependents:           JSON.stringify(dependents),
            add_option:           true,
            add_benefit:          comprehensivePlanOptions.add_benefit,
            currency:             commonPlanOptions.currency,
            dental_option:        comprehensivePlanOptions.dental_option,
            network:              comprehensivePlanOptions.network
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        if (! mountedRef.current) return null;

        //setPremium(response.data.rates.total_due[commonPlanOptions['payment_period']]);
        setPremium(response.data.rates.total_due);
      })
      .catch(error => { // @TODO error handling
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {

          }
        } else {
          throw error;
        }

      });
  };


  // Components ---------------------------------------------------------------

  // @TODO rename
  const Deductibles = () => {

    if (optionsVisible.deductible) {
      return (
        <div>
          {deductibles[commonPlanOptions.currency].map( (deductible, index) => (
            <Form.Check key={"deductible_" + deductible} inline type="radio">
              <Form.Check.Input
                name="deductible"
                value={deductible}
                type="radio"
                checked={comprehensivePlanOptions.deductible == deductible}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }


  const AccidentalDeathAndDisablement = () => {
    if (optionsVisible.add) {
      return (
        <div>
          {addBenefits[commonPlanOptions.currency]['your'].map( (add_benefit, index) => (
            <Form.Check key={"add_benefit_" + add_benefit} inline type="radio">
              <Form.Check.Input
                name="add_benefit"
                value={add_benefit}
                type="radio"
                checked={comprehensivePlanOptions.add_benefit == add_benefit}
                onChange={handleChange}
              />
              <Form.Check.Label>
                <NumberFormat value={add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  }

  const CoverRegion = () => {
    if (optionsVisible.geographical_cover) {
      return (
        <div>
          <Form.Check key="geographical_cover_worldwide_sans_us" inline type="radio">
            <Form.Check.Input
              name="geographical_cover"
              value="worldwide_sans_us"
              type="radio"
              checked={comprehensivePlanOptions.geographical_cover == 'worldwide_sans_us'}
              onChange={handleChange}
            />
            <Form.Check.Label>Worldwide excluding US/Canada</Form.Check.Label>
          </Form.Check>

          <Form.Check key="geographical_cover_worldwide" inline type="radio">
            <Form.Check.Input
              name="geographical_cover"
              value="worldwide"
              type="radio"
              checked={comprehensivePlanOptions.geographical_cover == 'worldwide'}
              onChange={handleChange}
            />
            <Form.Check.Label>Worldwide including US/Canada</Form.Check.Label>
          </Form.Check>
        </div>
      );
    } else return null;
  };


  const PaymentPeriod = () => {
    if (optionsVisible.payment_period) {
      return (
        <div>
          {['annual', 'semiannual', 'quarterly'].map( (payment_period, index) => (
            <Form.Check key={"payment_period_" + payment_period} inline type="radio">
              <Form.Check.Input
                name="payment_period"
                value={payment_period}
                type="radio"
                checked={comprehensivePlanOptions.payment_period == payment_period}
                onChange={handleChange}
              />
              <Form.Check.Label>
                {paymentPeriods[payment_period]}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  };

  const Network = () => {
    if (optionsVisible.network) {
      return (
        <div>
          {['silver', 'gold', 'platinum'].map( (network, index) => (
            <Form.Check key={"network_" + network} inline type="radio">
              <Form.Check.Input
                name="network"
                value={network}
                type="radio"
                checked={comprehensivePlanOptions.network == network}
                onChange={handleChange}
              />
              <Form.Check.Label>
                {networks[network]}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  };

  const AddOns = () => {
    return (
      <div className="checkboxes">
        <div className="checkbox">
          <Form.Check
            name="maternity_cover"
            type="checkbox"
            label="Maternity cover"
            checked={comprehensivePlanOptions.maternity_cover}
            onChange={handleChange}
          />
        </div>
        <div className="checkbox">
          <Form.Check
            name="dental_option"
            type="checkbox"
            label="Dental plan"
            checked={comprehensivePlanOptions.dental_option}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };





  const FormatPremium = (props) => {
    //if (props.premium == -1) {
    // empty object?
    //if (props.premium && Object.keys(props.premium).length === 0 && props.premium.constructor === Object) {
    if (props.premium.annual == -1) {
      return (
        <span style={{fontSize: '40px'}}>Loading...</span>
      );
    } else {

      let paymentPeriod = '';
      switch (props.paymentPeriod) {
        case 'annual':
          paymentPeriod = '1 annual payment of';
          break;
        case 'semiannual':
          paymentPeriod = '2 semi-annual payments of';
          break;
        case 'quarterly':
          paymentPeriod = '4 quarterly payments of';
          break;
      }

      return (
        <>
          <div style={{fontSize: 'small', whiteSpace: 'nowrap'}}>{paymentPeriod}</div>
          <div className="m-0 p-0" style={{whiteSpace: 'nowrap', lineHeight: '90%'}}>
            <span className="quote__table__pricing__currency">{currencyPrefix[commonPlanOptions.currency]}</span>
            <NumberFormat value={props.premium[comprehensivePlanOptions.payment_period]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={2} />{props.children}
          </div>
        </>
      );
    }
  };




  return (
    <Form
      className="plan-options quote-form-core"
      noValidate
    >
      <div className="quote__table__row quote__table__heading">
        <span>COMPREHENSIVE</span><br />
        <span className="plan-name">{comprehensivePlanOptions.plan == 'yourlife_row_17_v' ? 'yourLife' : 'yourFamily'} </span>
      </div>
      <div className="quote__table__row quote__table__pricing">
        <FormatPremium premium={premium} paymentPeriod={comprehensivePlanOptions.payment_period} ><i style={{color: '#b8e673', verticalAlign: 'text-top', textShadow: '0 1px 1px rgba(0, 0, 0, 0.3)'}} className="fa fa-asterisk"></i></FormatPremium>
      </div>


      <div className="quote__table__row">
        Annual Maximum Plan Benefit
        <br />
        <strong>
        <NumberFormat
          value={annualMaximumPlanBenefit[commonPlanOptions.currency][comprehensivePlanOptions.maternity_cover ? 'family' : 'life']}
          displayType={'text'}
          thousandSeparator={true}
          prefix={currencyPrefix[commonPlanOptions.currency]} />
        </strong>
      </div>


      <div className="quote__table__row">
        Life Cover
        <br />
        <strong>
        <NumberFormat
          value={lifeCover[commonPlanOptions.currency]['your']}
          displayType={'text'}
          thousandSeparator={true}
          prefix={currencyPrefix[commonPlanOptions.currency]} />
        </strong>
      </div>






      <div className="quote__table__row">Comprehensive Inpatient Cover</div>

      <div className="quote__table__row">Full Outpatient and Prescription Benefits</div>

      <div className="quote__table__row">Generous Wellness Benefits</div>

      <div className="quote__table__row">Medical Evacuation</div>

      <div className="quote__table__row">Life Aware Programme</div>

      <div className="quote__table__row">Digital Tools</div>

      <div className="quote__table__row">
        Deductible:&ensp;
        <strong><NumberFormat value={comprehensivePlanOptions.deductible} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} /></strong>
        <Button className="change" variant="light" size="sm" data-options-visible="deductible" onClick={handleOptionsVisible}>CHANGE</Button>
        <Deductibles />
      </div>

      <div className="quote__table__row">
        Accidental death and disablement:&ensp;
        <strong><NumberFormat value={comprehensivePlanOptions.add_benefit} displayType={'text'} thousandSeparator={true} prefix={currencyPrefix[commonPlanOptions.currency]} /></strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="add" onClick={handleOptionsVisible}>CHANGE</Button>
        <AccidentalDeathAndDisablement />
      </div>


      <div className="quote__table__row">
        Worldwide cover <strong>{coverRegions[comprehensivePlanOptions.geographical_cover]}</strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="geographical_cover" onClick={handleOptionsVisible}>CHANGE</Button>
        <CoverRegion />
      </div>

      <div className="quote__table__row">
        Payment method: <strong>{paymentPeriods[comprehensivePlanOptions.payment_period]}</strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="payment_period" onClick={handleOptionsVisible}>CHANGE</Button>
        <PaymentPeriod />
      </div>


      {csRegion &&
      <div className="quote__table__row">
        Network: <strong>{networks[comprehensivePlanOptions.network]}</strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="network" onClick={handleOptionsVisible}>CHANGE</Button>
        <Network />
      </div>
      }

      <div className="quote__table__row">
        Add-ons<br />
        <AddOns />
      </div>

      <div className="quote__table__row">
        <Button className="btn--big" style={{fontSize: 'x-large'}} onClick={() => handlePlanOptionsChosen(comprehensivePlanOptions, premium, 'comprehensive')}>CHOOSE</Button>
      </div>

      <div className="quote__table__row text-left mt-2" style={{fontSize: 'x-small', padding: '1em'}}>
        <p><i style={{color: 'rgb(150, 196, 81)', verticalAlign: 'top', fontSize: '.6rem', top: '.2rem'}} className="fa fa-asterisk"></i>
        These rates are indicative only and based on no medical conditions being declared.</p>
      </div>

    </Form>
  )
}
