// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import { getAge, getBMI, formatHeight, formatWeight } from '../../exports';
import { COUNTRIES, countryName } from '../../country/countries';

export default function EnrolmentPrimaryView({primary}) {

  let history = useHistory();

  const FormatSex = ({insured}) => {
    if (insured.sex == 'male') {
      return (
        <>♂&nbsp;male</>
      )
    } else if (insured.sex == 'female') {
      return (
        <>♀&nbsp;female</>
      )
    }
    return (
      <>⚥&nbsp;inter</>
    )
  };



  const MedicalQuestions = ({insured}) => {

    if ((! insured.medical_conditions || (insured.medical_conditions && insured.medical_conditions.length < 1)) &&
        ! insured.answer_2 &&
        ! insured.answer_3 &&
        ! insured.answer_4 &&
        ! insured.answer_5 &&
        ! insured.pregnant) {
      return (
        <Row className="">
          <Col>
            You responded in the negative to all questions asked.
          </Col>
        </Row>
      );
    }

    return (
      <>
      {insured.medical_conditions && insured.medical_conditions.length > 0 &&
      <Row className="">
        <Col>
          <h3 style={{display: 'inline', marginRight: '0.5em'}}>1</h3> Medical conditions treated or diagnosed:
          <ul style={{margin: 0}}>
          {
            insured.medical_conditions && insured.medical_conditions.map((condition, index) => (
              <li key={index.toString()}>
              {condition.label}
              </li>
            ))
          }
          </ul>
        </Col>
      </Row>
      }
      {(! insured.medical_conditions || insured.medical_conditions.length < 1) &&
      <Row className="">
        <Col>
          <h3 style={{display: 'inline', marginRight: '0.5em'}}>1</h3> Medical conditions treated or diagnosed: NONE
        </Col>
      </Row>
      }
      <Row className="">
        <Col>
          <h3 style={{display: 'inline', marginRight: '0.5em'}}>2</h3> Planned medical events: {insured.answer_2 ? insured.answer_2 : 'NONE'}
        </Col>
      </Row>
      <Row className="">
        <Col>
          <h3 style={{display: 'inline', marginRight: '0.5em'}}>3</h3> Symptoms last 12 months: {insured.answer_3 ? insured.answer_3 : 'NONE'}
        </Col>
      </Row>
      <Row className="">
        <Col>
          <h3 style={{display: 'inline', marginRight: '0.5em'}}>4</h3> Medication: {insured.answer_4 ? insured.answer_4 : 'NONE'}
        </Col>
      </Row>
      <Row className="">
        <Col>
          <h3 style={{display: 'inline', marginRight: '0.5em'}}>5</h3> Previous insurance issues: {insured.answer_5 ? insured.answer_5 : 'NONE'}
        </Col>
      </Row>
      </>
    );
  };


  return (
    <Container className="mt-5" style={{padding: 0, margin: 0}}>

      <Row className="enrolment-summary first-row">
        <Col xs="6" sm="4" lg="3">
          <h3 className="m-0 p-0">PRIMARY <Button variant="link" className="change btn  view btn-light btn-sm" style={{display: 'inline-block'}} onClick={() => history.push("/ig/enrolment/primary")}>CHANGE</Button></h3>
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Name
        </Col>
        <Col>
          <span style={{marginRight: '0.5em'}}>{primary.firstname} {primary.lastname}</span>
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Birthdate
        </Col>
        <Col>
        {primary.date_of_birth &&
          <>
          <span style={{marginRight: '0.5em'}}>{format(new Date(primary.date_of_birth), 'dd MMM. yyyy')}</span>
          <span style={{marginRight: '0.5em'}}>{getAge(primary.date_of_birth)} years</span>
          </>
        }
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Sex
        </Col>
        <Col>
          <FormatSex insured={primary} />
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Height
        </Col>
        <Col>
          {formatHeight(primary)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Weight
        </Col>
        <Col>
          {formatWeight(primary)}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Pregnant
        </Col>
        <Col>
          {primary.pregnant ? 'YES' : 'NO'}
        </Col>
      </Row>

      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          BMI
        </Col>
        <Col>
          {getBMI(primary)}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Email
        </Col>
        <Col>
          {primary.email_address}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Phone
        </Col>
        <Col>
          {primary.country_calling_code} {primary.mobile_phone}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Street
        </Col>
        <Col>
          {primary.address_line_1}
          {primary.address_line_2 &&
            <>
            <br />{primary.address_line_2}
            </>
          }
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Postal Code
        </Col>
        <Col>
          {primary.postal_code}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          City
        </Col>
        <Col>
          {primary.city}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Country&nbsp;of&nbsp;Residence
        </Col>
        <Col>
          {countryName(primary.country_of_residence_outside_US)}
        </Col>
      </Row>


      <Row className="enrolment-summary">
        <Col xs="6" sm="4" lg="3">
          Citizenship
        </Col>
        <Col>
          {countryName(primary.citizenship)}
        </Col>
      </Row>

      <Row className="enrolment-summary last-row">
        <Col xs="6" sm="4" lg="3">
          Passport image
        </Col>
        <Col>
          <img className="w-25 rounded" style={{width: '200px'}} src={primary.passport_image_path} alt={primary.passport_image_file_name} />
        </Col>
      </Row>

      <Row className="enrolment-summary first-row mt-3">
        <Col>
          <h3>Your responses to the medical question section <Button variant="link" className="change btn view btn-light btn-sm" style={{display: 'inline-block'}} onClick={() => history.push("/ig/enrolment/main_questions")}>CHANGE</Button></h3>
          <MedicalQuestions insured={primary} />
        </Col>
      </Row>


    </Container>
  )
}

