import React, { useState, useEffect } from "react"
import axios from "axios"

// The useHistory hook gives you access to the history instance that you may use to navigate.
import { useParams } from "react-router-dom"
import Jumbotron from 'react-bootstrap/Jumbotron'

export default function IntermediaryView({referrerCode}) {
  const [intermediary, setIntermediary] = React.useState(null);
  const [representative, setRepresentative] = React.useState(null);


  useEffect(() => {
    if (referrerCode) {
      getIntermediary(referrerCode || referrer_code);
    }
  }, []);

  const getIntermediary = (referrerCode) => {

    const sort = [{property: "name", direction: "ASC"}]
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/intermediaries/" + referrerCode,
        { withCredentials: true }
      )
      .then(response => {
        setIntermediary(response.data.intermediary);
        setRepresentative(response.data.representative);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/v7/mis");
          } else {
            //setWsError(error.response.data.message);
          }
        } else {
          throw error;
        }

      });
  }

  if (! intermediary && ! representative) return null;

  return (
    <Jumbotron className="text-left intermediary-view" style={{backgroundColor: 'transparent'}}>
      <h3>Your Insurance Consultant</h3>
      <h2>{intermediary.name}</h2>
      <p>
          {intermediary.corr_1_street_1}<br />
          {intermediary.corr_1_street_2 &&
            <>{intermediary.corr_1_street_2}<br /></>
          }
          {intermediary.corr_1_zip} {intermediary.corr_1_city}<br />
          {intermediary.corr_1_state &&
            <>{intermediary.corr_1_state}, </>
          }
          {intermediary.corr_1_country}
      </p>
      <p>
              Email: <a href="mailto:<{intermediary.admin_contact_email}">{intermediary.admin_contact_email}</a><br />
              Telephone: {intermediary.admin_contact_phone_1}
      </p>
      {representative &&
          <p>
              {representative.fullname &&
                <>{representative.fullname} <br /></>
              }
              Email: <a href="mailto:<{representative.email}">{representative.email}</a><br />
              {representative.phone &&
                <span>Telephone: {representative.phone}</span>
              }
          </p>
      }
    </Jumbotron>
  );
}