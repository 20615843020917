// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CountrySelect from '../../country/CountrySelect';

export default function EnrolmentNationalityAndResidence({
  enrolment,
  setEnrolment,
  handleChange,
  validated,
  step,
  setStep}) {



  if (step != 3) return null;

  return (
    <>
      <div>Step 3 of 5</div>
      <h2>Nationality and Residence</h2>
      <p>Effective immediately no insurance documents will be issued unless all DHA required member information, including the "Emirates ID number",
                     is provided. We can only accept your "Emirates ID Application Number” if you have newly arrived in Dubai.</p>

      <Form.Group as={Row} controlId="formGroupUaeIdNumber">
        <Form.Label column sm="2">Emirates ID number*</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            name="uae_id_number"
            required
            onChange={handleChange}
            value={enrolment.uae_id_number}
            placeholder="123-1234-1234567-1 (15 digits, 3 dashes)"
          />
          {/*
          <p>Example: 123-1234-1234567-1 (<span style={{fontWeight: 'bolder'}}>15 digits, 3 dashes</span>)</p>
          */}
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupUaeEidApplicationNumber">
        <Form.Label column sm="2">Emirates ID Application Number*</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            name="uae_eid_application_number"
            required
            onChange={handleChange}
            value={enrolment.uae_eid_application_number}
            placeholder="800220150506105023709 (21 digits)"
          />
          <Form.Control.Feedback type="invalid">Please provide a emirates ID application number.</Form.Control.Feedback>
          <div>Please enter if the Emirates ID Number has not been issued yet.</div>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupCurrentlyInsured">
        <Form.Label column sm="2"> </Form.Label>
        <Col sm="10">
          <Form.Check
             name="currently_insured"
             type="checkbox"
             label="Currently insured"
             checked={enrolment.uae_3rd_party_insurance_at_enrolment}
             onChange={handleChange}
           />
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupUaeUnifiedNumber">
        <Form.Label column sm="2">Unified Number (UID No.)*</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            name="uae_unified_number"
            required
            onChange={handleChange}
            value={enrolment.uae_unified_number}
          />
          <Form.Control.Feedback type="invalid">Unified Number (UID No.).</Form.Control.Feedback>
          <div>UID No. is from the Residence Visa. Please enter up to 15 digits or characters depending on your citizenship.</div>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupResidencyFileNumber">
        <Form.Label column sm="2">Residency File/Visa Number</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            name="uae_residency_file_number"
            required
            onChange={handleChange}
            value={enrolment.uae_residency_file_number}
          />
          <Form.Control.Feedback type="invalid">Please provide your residency file/visa number.</Form.Control.Feedback>
        </Col>
      </Form.Group>




      <Form.Group as={Row} controlId="formGroupMaritalStatus">
        <Form.Label column sm="2">Marital Status*</Form.Label>
        <Col sm="10">
          <Form.Check
            inline
            name="marital_status"
            type="radio"
            label="single"
            checked={enrolment.marital_status == 'single'}
            onChange={handleChange}
            value="single"
          />
          <Form.Check
            inline
            name="marital_status"
            type="radio"
            label="married"
            checked={enrolment.marital_status == 'married'}
            onChange={handleChange}
            value="married"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupEmirateOfResidency">
        <Form.Label column sm="2">Emirate of Residency*</Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            name="uae_emirate_of_residency"
            required
            onChange={handleChange}
            value={enrolment.uae_emirate_of_residency}
            isInvalid={validated == true && enrolment.uae_emirate_of_residency == 'select_uae_emirate_of_residency'}
          >
            <option key="-1" value="select_uae_emirate_of_residency">— Please select emirate —</option>
            <option>Abu Dhabi</option>
            <option>Ajman</option>
            <option>Dubai</option>
            <option>Fujairah</option>
            <option>Ras al-Khaimah</option>
            <option>Sharjah</option>
            <option>Umm al-Quwain</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Please select emirate.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupEmirateOfWork">
        <Form.Label column sm="2">Emirate of Work*</Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            name="uae_emirate_of_work"
            required
            onChange={handleChange}
            value={enrolment.uae_emirate_of_work}
            isInvalid={validated == true && enrolment.uae_emirate_of_work == 'select_uae_emirate_of_work'}
          >
            <option key="-1" value="select_uae_emirate_of_work">— Please select emirate —</option>
            <option>Abu Dhabi</option>
            <option>Ajman</option>
            <option>Dubai</option>
            <option>Fujairah</option>
            <option>Ras al-Khaimah</option>
            <option>Sharjah</option>
            <option>Umm al-Quwain</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Please select emirate.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupEmirateOfVisaIssuance">
        <Form.Label column sm="2">Emirate of Visa Issuance*</Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            name="uae_emirate_of_visa_issuance"
            required
            onChange={handleChange}
            value={enrolment.uae_emirate_of_visa_issuance}
            isInvalid={validated == true && enrolment.uae_emirate_of_work == 'select_uae_emirate_of_visa_issuance'}
          >
            <option key="-1" value="select_uae_emirate_of_visa_issuance">— Please select emirate —</option>
            <option>Outside UAE</option>
            <option>Diplomat</option>
            <option>Abu Dhabi</option>
            <option>Ajman</option>
            <option>Dubai</option>
            <option>Fujairah</option>
            <option>Ras al-Khaimah</option>
            <option>Sharjah</option>
            <option>Umm al-Quwain</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Please select emirate.</Form.Control.Feedback>
        </Col>
      </Form.Group>



      <Form.Group as={Row} controlId="formGroupVisaIssuanceDate">
        <Form.Label column sm="2">Visa Issuance Date</Form.Label>
        <Col sm="10">
          <DatePicker
            onChange={(date) => {
              let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
              setEnrolment({
                ...enrolment,
                'uae_visa_start_date': localDate
              });
            }}
            selected={enrolment.uae_visa_start_date}
            dateFormat="yyyy-MM-dd"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupVisaExpiryDate">
        <Form.Label column sm="2">Visa Expiry Date</Form.Label>
        <Col sm="10">
          <DatePicker
            onChange={(date) => {
              let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
              setEnrolment({
                ...enrolment,
                'uae_visa_end_date': localDate
              });
            }}
            selected={enrolment.uae_visa_end_date}
            dateFormat="yyyy-MM-dd"
          />
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupSalaryBand">
        <Form.Label column sm="2">Salary band*</Form.Label>
        <Col sm="10">
          <Form.Control
            as="select"
            name="uae_salary_band"
            required
            onChange={handleChange}
            value={enrolment.uae_salary_band}
            isInvalid={validated == true && enrolment.uae_salary_band == 'select_uae_salary'}
          >
            <option key="-1" value="select_uae_salary">— Please select your salary —</option>
            <option value="4001-12000">Between 4,001 and 12,000 AED per month</option>
            <option value=">12000">Greater than 12,000 AED per month</option>
            <option value="0">No salary</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Please select your salary.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupCommission">
        <Form.Label column sm="2">Commission</Form.Label>
        <Col sm="10">
          <Form.Check
             name="uae_commission"
             type="checkbox"
             label="Select if there is a salary and some element is commission based"
             checked={enrolment.uae_commission}
             onChange={handleChange}
           />
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupPassportNumber">
        <Form.Label column sm="2">Passport number</Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            name="passport_number"
            required
            onChange={handleChange}
            value={enrolment.passport_number}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupPassportCountryOfIssuance">
        <Form.Label column sm="2">Passport country of issuance</Form.Label>
        <Col sm="10">
          <CountrySelect country={enrolment.passport_country_of_issuance_code} handleChange={handleChange} validated={validated} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupCitizenship">
        <Form.Label column sm="2">Citizenship</Form.Label>
        <Col sm="10">
          <CountrySelect country={enrolment.citizenship_code} handleChange={handleChange} validated={validated} />
        </Col>
      </Form.Group>

      <Button type="button" onClick={() => setStep(step - 1)}>PREVIOUS</Button>
      <Button type="button" onClick={() => setStep(step + 1)}>NEXT</Button>

    </>
  )
}
