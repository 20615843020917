import React, { useState, useEffect } from "react"
import axios from "axios";

import { Redirect, useHistory } from "react-router-dom";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { countryPlanRegion } from '../country/countries'

export default function GroupEnrolmentDeclaration({persons, groupPolicyId}) {

  let history = useHistory();
  const [declarationItems, setDeclarationItems] = useState([]);

  const getDocumentPath = (plan, iso, type) => {

    let region = countryPlanRegion(iso);

    let regionPath = {
      row: 'World-',
      cs: 'CS-',
      gcc: 'GCC-'
    }[region];

    let planPath = {
      yourincare_19_v:        'InCareLife',
      premierincare_19_v:     'InCarePremier',
      yourlife_row_17_v:      'yourLife',
      yourfamily_row_17_v:    'yourFamily',
      premierlife_row_17_v:   'PremierLife',
      premierfamily_row_17_v: 'PremierFamily'
    }[plan];

    let fileName = {
      tob:            '-TOB-2022.pdf',
      polcy_wording:  '-Individual-Policy-Wording-2022.pdf',
      policy_summary: '-PolicySummary-2022.pdf',
      idd:            '-IDD-2022.pdf'
    }[type];

    return regionPath + planPath + '/' + regionPath + planPath + fileName;
  };


  useEffect(() => {
    getDeclaration();
  }, []);

  const getDeclaration = () => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(host + "/ws/enrolment_declaration.json")
      .then(response => {
        setDeclarationItems(response.data)
      })
  };


  return (
    <Container>
      <h2 className="mt-4">Declaration</h2>

      <ListGroup>
        {declarationItems.map((item, index) =>
          <ListGroup.Item
            key={index.toString()}
            dangerouslySetInnerHTML={{__html: item}}
          />
        )}
      </ListGroup>

{/*      <ListGroup className="mt-3" variant="info" horizontal="lg" style={{fontSize: 'large'}}>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'tob')}>Table of Benefits <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'polcy_wording')}>Policy Wording <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'policy_summary')}>Policy Summary <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
        <ListGroup.Item className="" style={{whiteSpace: 'nowrap'}}><a target="_blank" href={"/download/4IGweb7/" + getDocumentPath(planOptions.plan, primary.country_of_residence_outside_US, 'idd')}>IDD <i className="fa fa-file-pdf-o" aria-hidden="true"></i></a></ListGroup.Item>
      </ListGroup>
*/}
      <Row className="mt-4 mb-5 text-center">
        <Col>
          <Button
            type="button"
            onClick={() => history.push("/ig/enrolment/group_policy/" + groupPolicyId + "/confirmation")}
            style={{marginRight: '1em', fontSize: 'x-large'}}>
            ACCEPT
          </Button>
        </Col>
      </Row>

    </Container>
  )
}

