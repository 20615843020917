import { TESTING } from '../exports';
import React, { useState, useEffect } from "react"
import {Redirect, useHistory } from "react-router-dom"
import { format } from 'date-fns'
import { nanoid } from 'nanoid'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Table from 'react-bootstrap/Table'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import GroupEnrolmentPrimaryView from './GroupEnrolmentPrimaryView'
import GroupEnrolmentDependentView from './GroupEnrolmentDependentView'



import { getAge, getBMI, formatHeight, formatWeight } from '../exports'
import { COUNTRIES, countryName } from '../country/countries'

export default function GroupEnrolmentOverview({
  persons,
  setPersons,
  groupPolicyId,
  validated
  }) {

  let history = useHistory();
  if (! TESTING) {
    if (! validated.primary || ! validated.main_questions ) return <Redirect to={"/ig/enrolment/group_policy/" + groupPolicyId} />
  }

  return (
    <Container style={{padding: 0, margin: 0}}>
      <h2 className="mt-4">Summary</h2>

      <Row className="align-items-start">
        <Col lg={6} >
          <GroupEnrolmentPrimaryView
            persons={persons}
            groupPolicyId={groupPolicyId}
          />
        </Col>

      {persons.filter((person) => (person.relationship != 'primary')).map( (person, index) => (
        <Col lg={6} key={person.id.toString()}>
            <GroupEnrolmentDependentView
              person={person}
              persons={persons}
              setPersons={setPersons}
              groupPolicyId={groupPolicyId}
            />
        </Col>
      ))}
      </Row>

      <Row className="mt-5 mb-5 text-center" >
        <Col>
          <Button type="button" variant="secondary" onClick={() => history.push("/ig/enrolment/group_policy/" + groupPolicyId + "/dependents/" + nanoid())} style={{marginRight: '1em', fontSize: 'x-large'}}>ADD DEPENDENT</Button>
          <Button type="button" onClick={() => history.push("/ig/enrolment/group_policy/" + groupPolicyId + "/declaration")} style={{marginRight: '1em', fontSize: 'x-large'}}>CONTINUE</Button>
        </Col>
      </Row>
    </Container>
  )
}

