import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from "axios";


export default function IndividualPlanSelect() {
  const [plans, setPlans] = React.useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const host = window.location.protocol + '//' + window.location.host
      const response = await axios.get(
                               host + "/ws/individual_plans.json",
                               { withCredentials: true }
                             );
      response.data.individual_plans.unshift({id: 0, name: "", name_display: "Please select..."});
      response.data.total_count = parseInt(response.data.total_count) + 1;
      setPlans(response.data.individual_plans);
    };
    fetchData();
  }, []);

  return (
    <Form.Control
      as="select"
    >
      {plans.map((plan) => (
          <option key={plan.id} value={plan.name}>{plan.name_display}</option>
      ))}
    </Form.Control>
  );
}