import React, { useState, useEffect } from "react"
import axios from "axios";

import NumberFormat from 'react-number-format';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import QuoteResultFormSendEmail from '../quote/QuoteResultFormSendEmail';


export default function QuoteResultFormAccesshealthPlan({
  network,
  currency,
  //previousCurrency,
  quote,
  setQuote
  }) {

  const networkOptions = {
    gold:           'Gold',
    silver_classic: 'Silver Classic',
    silver_premium: 'Silver Premium'
  };

  const coverRegions = {
    uae_worldwide: 'including US',
    uae_worldwide_sans_us: 'excluding US'
  };

  const paymentPeriods = {
    annual: 'Annual',
    semiannual: 'Semi-annual',
    quarterly: 'Quarterly'
  };

  const [premium, setPremium] = React.useState(-1);

  const [optionsVisible, setOptionsVisible] = React.useState({
    add: false,
    geographical_cover: false,
    payment_period: false
  });
  const [showSendEmail, setShowSendEmail] = React.useState(false);


  // --------------------------------------------------------------------------
  // @TODO is this ever called?  There is no submit button
  const handleSubmit = (event) => {

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
    }

    localStorage.setItem('quote', JSON.stringify(quote));
  };


  // --------------------------------------------------------------------------
  const handleChange = (event) => {
    const value = ['maternity_cover'].includes(event.target.name) ? event.target.checked : event.target.value
    setQuote({
      ...quote,
      [event.target.name]: value,
      plan: 'accesshealth_uae_20_dic_r'
    });
  };

  // --------------------------------------------------------------------------
  const handleOptionsVisible = (event) => {
    const option = event.target.getAttribute('data-options-visible');
    setOptionsVisible({
      ...optionsVisible,
      [option]: ! optionsVisible[option]
    });
  };

  // --------------------------------------------------------------------------
  useEffect(() => {
    fetchPremium();
  }, [quote]); // empty array => run hook only once




  // --------------------------------------------------------------------------
  const fetchPremium = () => {
    if (quote.default_values) {
      return;
    }

    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/quotes/get_premium.json", {
          params: {
            plan: quote.plan,
            country_of_residence: quote.country,
            geographical_cover: quote.geographical_cover,
            co_pay: quote.co_pay,
            network: network,
            age: quote.age,
            sex: quote.sex,
            uae_actively_at_work: quote.uae_actively_at_work,
            dependents: JSON.stringify(quote.dependents),
            currency: quote.currency,
            dental_option: false
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setPremium(response.data.rates.including_loadings_and_adjustment_tier_and_dental_rates_and_add_rates[quote.payment_period]);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {
            setPremium(-1);
          }
        } else {
          throw error;
        }

      });
  };


  // Components ---------------------------------------------------------------
  const CoPay = () => {
    return (
      <div className="radiobuttons">
          <Form.Check
            inline
            name="co_pay"
            type="radio"
            label="Co-Pay 80"
            checked={quote.co_pay == 'op80'}
            onChange={handleChange}
            value="op80"
          />
          <Form.Check
            inline
            name="co_pay"
            type="radio"
            label="Co-Pay 90"
            checked={quote.co_pay == 'op90'}
            onChange={handleChange}
            value="op90"
          />
      </div>
    );
  };


  const CoverRegion = () => {
    if (optionsVisible.geographical_cover) {
      return (
        <div>
          <Form.Check key="geographical_cover_worldwide_sans_us" inline type="radio">
            <Form.Check.Input
              name="geographical_cover"
              value="uae_worldwide_sans_us"
              type="radio"
              checked={quote.geographical_cover == 'uae_worldwide_sans_us'}
              onChange={handleChange}
            />
            <Form.Check.Label>Worldwide excluding US</Form.Check.Label>
          </Form.Check>

          <Form.Check key="geographical_cover_worldwide" inline type="radio">
            <Form.Check.Input
              name="geographical_cover"
              value="uae_worldwide"
              type="radio"
              checked={quote.geographical_cover == 'uae_worldwide'}
              onChange={handleChange}
            />
            <Form.Check.Label>Worldwide including US</Form.Check.Label>
          </Form.Check>
        </div>
      );
    } else return null;
  };


  const PaymentPeriod = () => {
    if (optionsVisible.payment_period) {
      return (
        <div>
          {['annual', 'semiannual', 'quarterly'].map( (payment_period, index) => (
            <Form.Check key={"payment_period_" + payment_period} inline type="radio">
              <Form.Check.Input
                name="payment_period"
                value={payment_period}
                type="radio"
                checked={quote.payment_period == payment_period}
                onChange={handleChange}
              />
              <Form.Check.Label>
                {paymentPeriods[payment_period]}
              </Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      );
    } else return null;  // To render nothing return null
  };

  /*
  const AddOns = () => {
    return (
      <div className="checkboxes">
        <div className="checkbox">
          <Form.Check
            name="maternity_cover"
            type="checkbox"
            label="Maternity cover"
            checked={quote.maternity_cover}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };
  */
  const handleEnrolmentClick = (event) => {
    window.location = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '') +
                      '/ah/enrolment' +
                      '?quote_id=' + quote.id +
                      '&network=' + network +
                      '&co_pay=' + quote.co_pay +
                      '&geographical_cover=' + quote.geographical_cover;

  };


  const FormatPremium = (props) => {
    if (props.premium == -1) {
      return (
        <span style={{fontSize: 'small'}}>Loading</span>
      );
    } else {
      return (
        <>
        <NumberFormat value={props.premium * (currency == 'AED' ? 3.675 : 1)} displayType={'text'} thousandSeparator={true} decimalScale={0} />{props.children}
        </>
      );
    }
  };

  return (
    <>
    <Form className="quote-form-core" noValidate onSubmit={handleSubmit}>

      <div className="quote__table__row quote__table__heading">
        <span className="plan-name" style={{fontSize: 'x-large'}}>Access Health</span><br />
        <span>{networkOptions[network]}</span>
      </div>

      <div className="quote__table__row quote__table__pricing" style={{whiteSpace: 'nowrap'}}>
        <span className="quote__table__pricing__currency">{currency}&nbsp;</span>
        <FormatPremium premium={premium} ><i style={{color: '#b8e673', verticalAlign: 'text-top', textShadow: '0 1px 1px rgba(0, 0, 0, 0.3)'}} className="fa fa-asterisk"></i></FormatPremium>
      </div>

      <div className="quote__table__row">
        Annual maximum plan benefit
        <br />
        <strong>
        <NumberFormat
          value={(currency == 'AED' ? 5500000 : 1500000)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={currency + ' '}
          />
        </strong>
      </div>


      <div className="quote__table__row"> Comprehensive Inpatient Cover</div>

      <div className="quote__table__row">Full Outpatient and Prescription Benefits</div>

      <div className="quote__table__row">TeleHealth</div>

      <div className="quote__table__row">Generous Wellness Benefits</div>

      <div className="quote__table__row">Medical Evacuation</div>

      <div className="quote__table__row">Life Aware Programme</div>

      <div className="quote__table__row">YourHealth App</div>

      <div className="quote__table__row">
        <CoPay />
      </div>


      <div className="quote__table__row">
        Network:&ensp;{networkOptions[network]}
      </div>

      <div className="quote__table__row">
        Worldwide cover <strong>{coverRegions[quote.geographical_cover]}</strong>
        <Button className="change" variant="light" size="sm"  data-options-visible="geographical_cover" onClick={handleOptionsVisible}>CHANGE</Button>
        <CoverRegion />
      </div>

      <div className="quote__table__row">
        Payment method: <strong>{paymentPeriods[quote.payment_period]}</strong>
        {/*
        <Button className="change" variant="light" size="sm"  data-options-visible="payment_period" onClick={handleOptionsVisible}>CHANGE</Button>
        <PaymentPeriod />
        */}
      </div>
      {/*
      <div className="quote__table__row">
        Add-ons<br />
        <AddOns />
      </div>

      <div className="quote__table__row">Maternity Cover</div>
      <div className="quote__table__row">Dental Cover</div>
      */}
      <div className="quote__table__row">
        <Button className="btn--big" onClick={handleEnrolmentClick}>ENROL</Button>
      </div>

      <div className="text-left mt-2" style={{fontSize: 'x-small'}}>
        <p><i  style={{color: 'rgb(150, 196, 81)', verticalAlign: 'top', textShadow: '0 1px 1px rgba(0, 0, 0, 0.3)'}} className="fa fa-asterisk"></i> These rates are indicative only and based on no medical conditions being declared.
        Any regulatory health chargers applicable to the Emirate of visa issuance will also be applied to the final quotation at the time of acceptance.
        These rates do not include 5% VAT.</p>
      </div>
      {/*
      <div className="quote__table__row">
        <i className="fa fa-envelope" aria-hidden="true"></i>
        <Button className="email-me" variant="link" onClick={() => setShowSendEmail(true)}>EMAIL ME THIS QUOTE</Button>
      </div>
      */}

    </Form>

    <QuoteResultFormSendEmail
      quote={quote}
      handleChange={handleChange}
      show={showSendEmail}
      onHide={() => setShowSendEmail(false)}
    />
    </>
  )
}
