import React, { Component } from "react";
import { BrowserRouter, Switch, Route, NavLink, Link, useRouteMatch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import {Form, Button} from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

import IndividualPlans from "./individual_plans/IndividualPlans"
import IndividualInsuredTable from './individual_insured/IndividualInsuredTable';
//import IndividualEnrolments from './individual_enrolments/IndividualEnrolments';
import IndividualEnrolmentsTable from './individual_enrolments/IndividualEnrolmentsTable';
import Countries from './countries/Countries';
import InOut from "./inout/InOut"


import { logout } from './redux/ilUsersSlice'
import globals from "./globals"
import axios from "axios"



export default function NavBar() {
  const ilUsers = useSelector(state => state.ilUsers)
  const dispatch = useDispatch()

  let { path, url } = useRouteMatch();
  let history = useHistory();

  const handleLogoutClick = (event) => {
  //let handleLogoutClick = (event) => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(host + "/il_ws/il_user/logout", { withCredentials: true })
      .then(response => {
        dispatch(logout());
        //history.push("/ig/admin");
      })
      .catch(error => {

      });
  };


  return (
      <div>
        <Navbar bg="light" variant="light" >
          <Nav>
            <Nav.Link  className="navbar-brand" to="/ig/admin"><span style={{fontVariant: 'small-caps'}}>IG Admin</span> <span style={{fontSize: '42%'}}>{globals.majorVersion}.{globals.minorVersion}</span></Nav.Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
              {/*
              <NavLink  className="nav-link" to={'/ig/admin'} disabled={useRouteMatch(['/ig/admin', '/ig/admin/dashboard'])}>Home</NavLink>


              <li className="nav-item">
                <a className="nav-link" href="/v7/mis/dashboard/plans">Plans</a>
              </li>
              */}

              {/*
              <li className="nav-item">
                <NavLink  className="nav-link" to={`${url}/inout`}>In&amp;Out</NavLink>
              </li>

              <li className="nav-item">
                <NavLink  className="nav-link" to="/v7/mis/dashboard/individual_enrolments">Individual Enrolments</NavLink>
              </li>
              <li className="nav-item">
                <NavLink  className="nav-link" to={`${url}/renewals`}>Renewals</NavLink>
              </li>

              <li className="nav-item">
                <NavLink  className="nav-link" to={`${url}/plans`}>Individual Plans</NavLink>
              </li>
              <li className="nav-item">
                <NavLink  className="nav-link" to={`${url}/individual_insured`}>Individual Insured</NavLink>
              </li>

              <li className="nav-item">
                <NavDropdown title="Users" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Users</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">Groups</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.4">Permissions</NavDropdown.Item>
                </NavDropdown>
              </li>

              <Nav.Item as="li" className="nav-item">
                <NavDropdown title="Admin" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Users</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <NavLink className="nav-link" to={`${url}/countries`}>Countries</NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>

              <Nav.Link as="li" to={`${url}/countries`}>Countries</Nav.Link>

              <Nav.Link as="li" onClick={props.handleLogoutClick} >Logout</Nav.Link>

              <Navbar.Text>User</Navbar.Text>
                              */}
          </Nav>

          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="mr-3" style={{fontSize: 'x-small'}}>{ilUsers.currentUser?.firstname} {ilUsers.currentUser?.lastname}<br />{ilUsers.currentUser?.email_address}</Navbar.Text>
            <Button onClick={handleLogoutClick}>Logout</Button>
          </Navbar.Collapse>
        </Navbar>

        {/*
          <Switch>
            <Route path={`${path}/inout`}>
              <InOut />
            </Route>

            <Route path="/v7/mis/dashboard/individual_enrolments">
              <IndividualEnrolmentsTable />
            </Route>

            <Route path={`${path}/plans`}>
              <IndividualPlans accessToken={accessToken} />
            </Route>
          </Switch>
        */}


        </div>
  );
}