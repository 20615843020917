import React, { forwardRef, useState } from 'react';


export default function IlCurrencySymbol({currency='USD'}) {
  if (currency == 'USD') {
    return (
      <>
        $
      </>
    );
  } else if (currency == 'EUR') {
    return (
      <>
        €
      </>
    );

  } else if (currency == 'GBP') {
    return (
      <>
        £
      </>
    );

  } else if (currency == 'AED') {
    return (
      <>
        AED
      </>
    );
  } else {
    return null;
  }
}
