import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"

import {Container, Form, Row, Col, Card, Breadcrumb } from 'react-bootstrap'
import NavBar from "./NavBar"

export default function IndividualInsured({user, loggedIn}) {

  let history = useHistory();

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Individual Insured</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="mt-5" >
        <Col>
          <Card className="mt-2" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Customers</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Customers.</Card.Subtitle>
              <Link className="stretched-link" to="/ig/admin/persons" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mt-2" style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Individual Policies</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Insurance policies.</Card.Subtitle>
              <Link className="stretched-link" to="/ig/admin/individual_policy_periods" />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}


