import React, { useState, useEffect } from "react"

import axios from "axios"
import { Redirect, useHistory } from "react-router-dom"

import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import CustomerEdit from './CustomerEdit'
import CustomerView from './CustomerView'

export default function CustomerAccount({formAuthenticityToken, accessToken, setAccessToken}) {
  if (! accessToken) {
    return <Redirect to="/ig/customer/login" />
  }


  const [customer, setCustomer] = React.useState({});

  const [editing, setEditing] = React.useState(false);

  useEffect(() => {
    getCustomer(accessToken);
  }, []);



  const getCustomer = (accessToken) => {
    axios
    .get(
      window.location.protocol + '//' + window.location.host + "/ws_customer/customer.json",
      {
        headers: {
          'X-CSRF-Token': formAuthenticityToken,
          'integraglobal-access-token': accessToken  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
        }
      }
    )
    .then(response => {
      if (response.data.success) {
        // setCustomer({
        //   ...customer,
        //   ...response.data.customer
        // });
        setCustomer({...response.data.customer});
      } else if (! response.data.success) {
        setCustomer({});
      }
    })
    .catch(error => {
      setCustomer({});
    });
  };

  const handleLogout = () => {
    history.push("/ig/customer/password_update");
  };

  if (editing) {
    return <CustomerEdit formAuthenticityToken={formAuthenticityToken} accessToken={accessToken} setAccessToken={setAccessToken} setCustomer={setCustomer} customer={customer} setEditing={setEditing} />
  } else {
    return <CustomerView accessToken={accessToken} setAccessToken={setAccessToken} customer={customer} setEditing={setEditing} />
  }
}