import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from "axios";

import Select from 'react-select';

export default function CountryCallingCodeSelect(props) {

  const value = props.value ? {value: props.value} : ''

  const handleChange = (calling_code) => {
    props.onChange(calling_code);
  };


  let controlStyle = props.controlStyle ?? ((styles, state) => ({
    ...styles,
    background: 'none',
    border: '1px solid #e1e1e1',
    borderRadius: 0,
    fontSize: '16px',
    height: '50px',
    boxShadow: 'none',
    '&:hover': {
        border: '3px solid #c8d9f9'
    },
    '&:focus': {
        border: '3px solid #c8d9f9'
    }
  }));

  // https://codesandbox.io/s/oojnrvq4n6?file=/src/select.js
  const SingleValue = ({
    getStyles,
    selectProps,
    data,
    isDisabled,
    className,
    ...props
  }) => {
    return (
      <div className={className}>
        <div>{selectProps.getOptionValue(data)}</div>
      </div>
    );
  };

  return (
    <>
      <Select
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={value}
        className={'calling-code-select ' + props.className}
        options={props.options}
        components={{ SingleValue }}
        styles={{
          menu: (styles, state) => ({
            ...styles,
            width: '40em'
          }),
          control: controlStyle
        }}
      />
    </>
  );

}