import React from "react"

export default function FormatSex({person}) {
  if (person.sex == 'male') {
    return (
      <>♂&nbsp;male</>
    )
  } else if (person.sex == 'female') {
    return (
      <>♀&nbsp;female</>
    )
  } else if (person?.sex == 'inter') {
    return (
      <>⚥&nbsp;inter</>
    )
  }
  return null;
}