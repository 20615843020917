import React, { forwardRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";

import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';


import IndividualInsuredRow from './IndividualInsuredRow';

import SearchIndividualInsuredForm from "./SearchIndividualInsuredForm";
import { format, parseISO} from "date-fns";

import pdfLogo from '../../../images/il_admin/pdf-11x13.png'

export default function IndividualInsuredTable(props) {

  const [currentIndividualPlan, setCurrentIndividualPlan] = useState({});
  const [totalCount, setTotalCount] = React.useState(-1);
  const [rows, setRows] = React.useState([]);




  const formatDate = date_string => (
    date_string ? format(parseISO(date_string), "dd MMM Y") : ''
  );

  const enrollmentFormLinkRenderer = row => {
    if (row.status != 'Contact') {
      return (
        <a href={"/ws/individual_insured/" + row.id + ".pdf"} className="client-pdf-link"><img src={pdfLogo} /></a>
      );
    }
    return '';
  };

  const fullnameRenderer = function (row) {
    // => Inline If with Logical && Operator
    // https://reactjs.org/docs/conditional-rendering.html#inline-if-with-logical--operator
      // row.lastname + (row.firstname || row.middlename ? ', ' : '') +
      // (row.firstname  ? row.firstname + ' ' : '') +
      // (row.middlename  || '') +
      // (row.sex === "male" ? " ♂" : (row.sex === "female" ? " ♀" : (row.sex === "inter" ? ' ⚥': '')))
    return (
      <span className="fullname">
        <span className="lastname">{row.lastname}</span>{row.firstname || row.middlename ? ', ' : ''}
        <span className="firstname">{row.firstname  ? row.firstname + ' ' : ''}</span>
        <span className="middlename">{row.middlename  || ''}</span>
        <span className="sex">{row.sex === "male" ? " ♂" : (row.sex === "female" ? " ♀" : (row.sex === "inter" ? ' ⚥': ''))}</span>
      </span>
    );
  };





  const onClick = event => {

  };




  const doReset = () => {
    setTotalCount(-1);
  }

  const doSearch = (values) => {

    //"sort"=>"[{\"property\":\"created_at\",\"direction\":\"DESC\"}]"
    const sort = [{property: "created_at", direction: "DESC"}]
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/individual_insured.json", {
          params: {
            name: values.searchText,
            sort: JSON.stringify(sort),
            include_individual_policy: true
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setRows(response.data.individual_insured);
        setTotalCount(response.data.total_count);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/v7/mis");
          } else {
            setWsError(error.response.data.message);
          }
        } else {
          throw error;
        }

      });
  }









  return (
    <div className="mt-3">

      <SearchIndividualInsuredForm {...props} doSearch={doSearch} totalCount={totalCount} doReset={doReset} />

      <div className="table-responsive">
        <table id="individual-insured-table" className="table">
          <thead className="">
            <tr>
              <th></th>
              <th>ID</th>
              <th>Status</th>
              <th>Enrolment</th>
              <th>Created</th>
              <th>Name</th>
              <th>Validity Start&nbsp;Date</th>
              <th>Validity End&nbsp;Date</th>
              <th>Commencement Date</th>
              <th>Cancellation Date</th>
              <th>Certificate №</th>
              <th>E-Mail</th>
              <th>Mobile Phone</th>
              <th>Phone</th>
              <th>Date of Birth</th>
              <th>PDF</th>
              <th>Referrer</th>
              <th>Enrolment Date</th>
              <th>Salesforce</th>
              <th>Policy ID</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.id}>
                <td>{/*<EditTwoToneIcon fontSize="small" onClick={onClick} />*/}</td>
                <td>{row.insured_id}</td>
                <td>{row.status}</td>
                <td>{row.online_enrollment_state}</td>
                <td>{formatDate(row.created_at)}</td>
                <td className="fullname">{fullnameRenderer(row)}</td>
                <td>{row.sorted_policies.length > 0 ? formatDate(row.sorted_policies[0].start_date) : ''}</td>
                <td>{row.sorted_policies.length > 0 ? formatDate(row.sorted_policies[0].end_date)   : ''}</td>
                <td>{row.sorted_policies.length > 0 ? formatDate(row.sorted_policies[0].policy_commencement_date) : ''}</td>
                <td>{row.sorted_policies.length > 0 ? formatDate(row.sorted_policies[0].policy_cancellation_date) : ''}</td>
                <td>{row.certificate_number}</td>
                <td><a href="mailto: {row.email_1}">{row.email_1}</a></td>
                <td>{row.mobile_phone}</td>
                <td>{row.phone_1}</td>
                <td>{formatDate(row.date_of_birth)}</td>
                <td>{enrollmentFormLinkRenderer(row)}</td>
                <td>{row.referrer_code}</td>
                <td>{formatDate(row.enrollment_date)}</td>
                <td>{row.salesforce_id}</td>
                <td>{row.sorted_policies.length > 0 ? row.sorted_policies[0].id : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
