// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useEffect } from "react"
import { Switch, Route, Link, NavLink, useHistory } from "react-router-dom"


import {Container, Form, Row, Col, Card } from 'react-bootstrap'

import MasterData from "./MasterData"


export default function Dashboard() {

  let history = useHistory();


  // useEffect(() => {
  //   if (loggedInStatus == "NOT_LOGGED_IN") {
  //     history.push('/ig/admin/login');
  //   }
  // }, []);


  // const handleLogoutClick = (event) => {
  // //let handleLogoutClick = (event) => {
  //   const host = window.location.protocol + '//' + window.location.host
  //   axios
  //     .get(host + "/il_ws/user/logout", { withCredentials: true })
  //     .then(response => {
  //       handleLogout();
  //       // history object is passed by the Router
  //       history.push("/ig/mis");
  //     })
  //     .catch(error => {

  //     });
  // };







  return (
    <Container fluid>

      {/*
      <Row className="mt-3" >
        <Col>
          <h5 style={{fontVariant: 'small-caps'}}>Dashboard</h5>
        </Col>
      </Row>
      */}
      <Row className="mt-3" md="3">


        <Col>
          <Card  className="mt-2" >
            <Card.Body>
              <Card.Title>Renewals</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/renewals">Renewals,</Link>
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/renewal_trigger_durations">Renewal Trigger Durations</Link>
              </Card.Subtitle>

              <Card.Text>
                 Let's get existing customers to renew their policies.
              </Card.Text>
              <Link className="stretched-link" to="/ig/admin/renewals" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card  className="mt-2">
            <Card.Body>
              <Card.Title>Enrolments</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">New business. Grab it!</Card.Subtitle>
              <Card.Text>
                Welcome new customers.
              </Card.Text>
              <Link className="stretched-link" to="/ig/admin/enrolments" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mt-2" >
            <Card.Body>
              <Card.Title>Customers</Card.Title>
              <Card.Text>
                See how customers are setting up accounts.
              </Card.Text>
              <Link className="stretched-link" to="/ig/admin" />
            </Card.Body>
          </Card>
        </Col>


        <Col>
          <Card  className="mt-2" >
            <Card.Body>
              <Card.Title>Renewals</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Old business. Hold on to it!</Card.Subtitle>
              <Card.Text>
                 Let's get existing customers to renew their policies.
              </Card.Text>
              <Link className="stretched-link" to="/ig/admin" />
            </Card.Body>
          </Card>
        </Col>


        <Col>
          <Card className="mt-2">
            <Card.Body>
              <Card.Title>Master Data</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/underwriters">Underwriters,</Link>
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/individual_plans">Plans,</Link>
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/usergroups">User&ensp;Groups,</Link>
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/users">Users,</Link>
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/countries">Countries…</Link>
              </Card.Subtitle>
              <Card.Text>
                Manage all the long living gory details.
              </Card.Text>
              <Link className="stretched-link" to="/ig/admin/masterdata" />
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="mt-2">
            <Card.Body>
              <Card.Title>Individual Insured</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/persons">Customers,</Link>
               <Link className="mr-2 text-nowrap" style={{zIndex: 2, position: 'relative' /* raise over streched-link */}} to="/ig/admin/individual_policy_periods">Policies…</Link>
              </Card.Subtitle>
              <Card.Text>
                Members and their policies.
              </Card.Text>
              <Link className="stretched-link" to="/ig/admin/individual_insured" />
            </Card.Body>
          </Card>
        </Col>

      </Row>

      {/*
      <Switch>
        <Route
          path={['/ig/admin/masterdata']}
        >
          <MasterData
            user={user}
            loggedInStatus={loggedInStatus}
          />
        </Route>
      </Switch>
    */}
    </Container>
  );
}


