import React, { forwardRef, useState } from 'react';
import axios from "axios";
import * as yup from 'yup';
import { useFormik } from 'formik';

import {Form, Col, Button, InputGroup, FormControl, Modal} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'


export default function CountryForm(props) {
  if (! props.show) return null;


  const saveCountry = (values) => {

    const country = {
      ...props.country,
      ...values
    };
    //console.log(primary);
    //const method = primary.id ? 'put' : 'post';
    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/countries/" + country.id,
      data: country
    }).then(response => {
      props.setCountries(props.countries.map(c => c.id != country.id ? c : {...response.data}));
      props.handleClose();
    })
    .catch(error => {
      if (error.response) {
        if (error.response.status == 401) {
          alert('permission denied');
        } else {

        }
      } else {
        throw error;
      }

    });
  };



  const schema = yup.object().shape({
    iso: yup.string().required('Required').matches(/^[A-Z]+$/, "Must be only uppercase characters").min(2, 'Must be exactly 2 characters').max(2, 'Must be exactly 2 characters'),
    name: yup.string().required('Required'),
    calling_code: yup.string().required('Required'),
    plan_region: yup.string().required('Required')
  });

  const formik = useFormik({
    initialValues: {...props.country},
    onReset: (values, formik) => {
      props.doReset();
    },
    onSubmit: values => {
      saveCountry(values);
    },
    validationSchema: schema
  });


  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Form noValidate onSubmit={formik.handleSubmit} >
        <Modal.Header closeButton>
          <Modal.Title><FontAwesomeIcon icon={faEdit} /> Country</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form.Group controlId="formName">
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.touched.name && formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formIso">
              <Form.Control
                type="text"
                name="iso"
                placeholder="ISO-2 Code (2 Characters)"
                value={formik.values.iso}
                onChange={formik.handleChange}
                isInvalid={formik.touched.iso && formik.errors.iso}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.iso}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCallingCode">
              <Form.Control
                type="text"
                name="calling_code"
                placeholder="Calling Code"
                value={formik.values.calling_code}
                onChange={formik.handleChange}
                isInvalid={formik.touched.calling_code && formik.errors.calling_code}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.calling_code}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPlanRegion">
            {/*
              <Form.Control
                type="text"
                name="plan_region"
                placeholder="Plan Region"
                value={formik.values.plan_region}
                onChange={formik.handleChange}
                isInvalid={formik.touched.calling_code && formik.errors.plan_region}
              />
            */}
              <Form.Control
                as="select"
                custom
                name="plan_region"
                value={formik.values.plan_region}
                onChange={formik.handleChange}
              >
                <option value="row">row</option>
                <option value="cs">cs</option>
                <option value="uae">uae</option>
                <option value="hk">hk</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{formik.errors.plan_region}</Form.Control.Feedback>
            </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
            </Form>
      </Modal>

    </>
  );
}