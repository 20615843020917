import React, { useEffect, forwardRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Jumbotron from 'react-bootstrap/Jumbotron'

import SearchForm from './SearchForm'

export default function Users({accessToken}) {
  let history = useHistory();

  // Declare a new state variable, which we'll call "rows"
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(-1);
  const [error, setError] = React.useState('');

  // useEffect(() => {
  //   doSearch();
  // }, []);


  const doSearch = (values) => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_users.json", {
          headers: {
            'il-access-token': accessToken  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          },
          params: {
            sort: 'lastname',
            name: values.searchText
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setRows(response.data.il_users);
        setTotalCount(response.data.total_count);
        setError('');
      })
      .catch(error => {
        setError(error.response.statusText);
        //history.push("/ig/admin");
      });
  }



  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/masterdata' }}>Admin</Breadcrumb.Item>
        <Breadcrumb.Item active>Users</Breadcrumb.Item>
      </Breadcrumb>

      <SearchForm doSearch={doSearch} totalCount={totalCount} count={rows.length} />

      {error &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>😳 This shouldn't have happened.</h3>
          <h1>{error}</h1>
        </Jumbotron>
      }


      <Container fluid>
        <Table striped bordered hover id="il-users-table" className="result-table mt-3">
          <thead className="">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Referrer Code</th>
              <th>Address</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.id}>
                <td><span style={{fontWeight: 400}}>{row.fullname}</span></td>
                <td><span style={{fontWeight: 400}}>{row.email_address}</span></td>
                <td><span style={{fontWeight: 400}}>{row.phone}</span></td>
                <td><span style={{fontWeight: 400}}>{row.referrer_code}</span></td>
                <td>
                {row.address_line_1 &&
                  <div>{row.address_line_1}</div>
                }
                {row.address_line_2 &&
                  <div>{row.address_line_2}</div>
                }
                {row.postal_code || row.city &&
                  <div style={{whiteSpace: 'nowrap'}}>{row.postal_code} {row.city}</div>
                }
                {row.country}
                </td>
                <td>{row.notes}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );

}
