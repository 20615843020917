import { TESTING } from '../exports';
import React, { useState, useEffect, useRef } from "react"
import axios from "axios";
//import { nanoid } from 'nanoid/non-secure'
//import { nanoid } from 'nanoid/nanoid';

//import { nanoid } from 'nanoid/async'


import { nanoid } from 'nanoid';
import { format, parseISO} from "date-fns"
//import { nanoid } from 'nanoid/nanoid';
//import nanoid from 'nanoid';

import { useHistory, useLocation, Redirect } from "react-router-dom";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import GroupEnrolmentPrimaryForm   from './GroupEnrolmentPrimaryForm';
import GroupEnrolmentDependentForm from './GroupEnrolmentDependentForm';
import GroupEnrolmentMainQuestions from './GroupEnrolmentMainQuestions';
import GroupEnrolmentOverview      from './GroupEnrolmentOverview';
import GroupEnrolmentDeclaration   from './GroupEnrolmentDeclaration';
import GroupEnrolmentConfirmation  from './GroupEnrolmentConfirmation';


export default function GroupEnrolment({groupPolicyId}) {


  // const personId = nanoid();
  // let initialPerson = {
  //   id: personId,
  //   primary_id: personId,
  //   relationship: 'primary',
  //   occupation: 'select_occupation',
  //   firstname: '',
  //   lastname: '',
  //   email_address: '',
  //   mobile_phone: '',
  //   country_calling_code: '',
  //   sex: '',
  //   pregnant: false,
  //   date_of_birth: '',
  //   address_line_1: '',
  //   address_line_2: '',
  //   postal_code: '',
  //   city: '',
  //   country_of_residence_outside_US: '',
  //   citizenship: '',
  //   weight: '',
  //   weight_measurement_unit : '',
  //   height: '',
  //   height_measurement_unit: '',
  //   medical_status: 'medically_approved',
  //   bmi: 0,
  //   passport_image_path: null,
  //   passport_image_file_name: null,
  //   privacy_policy_agreement: true,
  //   referrer_code: referrerCode
  // };

  let initialPersons = [];


  if (TESTING) {
    const primaryId = nanoid();
    const testPrimary = {
      id: primaryId,
      primary_id: primaryId,
      group_policy_id: groupPolicyId,
      relationship: 'primary',
      firstname: "Nissim",
      lastname: "@TEST@ Harmon ",// + format(new Date, "d. MMM Y HH:mm"),
      occupation: 'Chief Executive',
      citizenship: "KH",
      city: "Schärding",
      address_line_1: "Schneebauernhöhe 4",
      address_line_2: "Beim Campingplatz",
      postal_code: "11201",
      country_of_residence_outside_US: "OM",
      date_of_birth: new Date ('1985-12-05'),
      mobile_phone: '664 5027659',
      country_calling_code:  "+43",
      email_address: "makivycepu@mailinator.com",
      height: "180",
      height_measurement_unit: "cm",
      mobile_phone: "+1 (695) 517-1012",
      sex: "female",
      pregnant: false,
      weight: "60",
      weight_measurement_unit: 'kg',
      medical_status: 'medical_underwriting',
      bmi: 0,
      passport_image_path: '',
      answer_2: 'I am short sighted in both eyes and require the use of corrective lenses and / or glasses in order to see clearly. In September 2011 I had reconstructive surgery on my left shoulder to repair damage to ligaments and humeral head caused by repeated and habitual dislocations. Since the surgery I have continued to lead an active life with no problems at all. In September 2013 I had surgery to repair a small tear in the meniscus of my left knee. I have made a full recovery.',
      medical_conditions: [
        // {value: 'mh_treated_for_heart',                        label: 'Heart / Blood vessels'},
        // {value: 'mh_treated_for_mental_disorder',              label: 'Mental / Nervous Disorder'},
        {value: 'mh_treated_for_high_blood_pressure',          label: 'Stroke / Blood Pressure / Hypertension'},
        {value: 'mh_treated_for_hiv',                          label: 'HIV/AIDS or Related Diseases'},
        {value: 'mh_treated_for_ortho_problems',               label: 'Ortho Problems & Arthritis (Back, Joints, etc.)'}
      ],
      privacy_policy_agreement: false

    };
    initialPersons.push(testPrimary);
    initialPersons.push({
      id: nanoid(),
      primary_id: testPrimary.id,
      group_policy_id: groupPolicyId,
      citizenship: "",
      country_of_residence_outside_US: "",
      date_of_birth: new Date ('2014-12-11'),
      email_address: "gimimy@mailinator.com",
      errors: {sex: false, relationship: false},
      firstname: "Asher",
      height: "167",
      height_measurement_unit: "cm",
      lastname: "Combs",
      mobile_phone: "",
      relationship: "child",
      sex: "male",
      pregnant: undefined,
      weight: "74",
      weight_measurement_unit: "kg",
      medical_status: 'medical_underwriting',
      bmi: 0
      // medical_conditions: [
      //   {value: 'mh_treated_for_high_blood_pressure',          label: 'Stroke / Blood Pressure / Hypertension'},
      //   {value: 'mh_treated_for_cancer',                       label: 'Cancer'},
      //   {value: 'mh_treated_for_respiratory_problems',         label: 'Respiratory Problems (Asthma)'},
      //   {value: 'mh_treated_for_diabetes',                     label: 'Diabetes (Related Problems)'},
      //   {value: 'mh_treated_for_ortho_problems',               label: 'Ortho Problems & Arthritis (Back, Joints, etc.)'}
      // ]
    });
    initialPersons.push({
      id: nanoid(),
      primary_id: testPrimary.id,
      group_policy_id: groupPolicyId,
      citizenship: "DZ",
      country_of_residence_outside_US: "MY",
      date_of_birth: new Date ('2002-12-07'),
      email_address: "jicagytaja@mailinator.com",
      errors: {sex: false, relationship: false},
      firstname: "Thaddeus",
      height: "170",
      height_measurement_unit: "in",
      lastname: "Burns",
      mobile_phone: "",
      relationship: "partner",
      sex: "female",
      pregnant: false,
      weight: "78",
      weight_measurement_unit: "lbs",
      medical_status: 'medical_underwriting',
      bmi: 0,
      answer_2: "A disease is a particular abnormal condition that negatively affects the structure or function of all or part of an organism, and that is not due to any immediate external injury.[1][2] Diseases are often known to be medical conditions that are associated with specific signs and symptoms. A disease may be caused by external factors such as pathogens or by internal dysfunctions. For example, internal dysfunctions of the immune system can produce a variety of different diseases, including various forms of immunodeficiency, hypersensitivity, allergies and autoimmune disorders.",
      medical_conditions: [
        {value: 'mh_treated_for_heart',                        label: 'Heart / Blood vessels'}
      ]

    });
  }
  // TEST


  const [persons, setPersons] = React.useState(initialPersons);

  // Anwer 1: Medical conditions treated or diagnosed
  // Anwer 2: Planned medical events
  // Anwer 3: Symptoms last 12 months
  // Anwer 4: Medication
  // Anwer 5: Pregnancy
  // Anwer 6: Previous insurance issues


  let initialPositiveAnswers = {
    answer_1: false,
    answer_2: false,
    answer_3: false,
    answer_4: false,
    answer_5: false
  }
  if (TESTING) {
    initialPositiveAnswers.answer_1 = true;
    initialPositiveAnswers.answer_2 = true;
  }

  const [positiveAnswerToMedicalQuestions, setPositiveAnswerToMedicalQuestions] = React.useState(initialPositiveAnswers);

  // @TODO combine, change data structure, integrate into dependents
  // @TODO combine primary and dependents

  const [validated, setValidated] = React.useState({
    primary: false,
    main_questions: false
  });


  var history = useHistory();

  // -- scroll to top --
  //location.href = "#top";
  const topLocation = useRef(null);
  const { pathname } = useLocation();
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (topLocation.current) {
      topLocation.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [pathname]); // empty array => run hook only once


  const numberOfErrors = () => {
    return enrolment.dependents.reduce((total, dep) => {
      return dep.errors.age ? total + 1 : total;
    }, 0)
  };


  // useEffect(() => {
  //   const maternity_cover = dependents.reduce(
  //     (accumulator, dependent) => {
  //       return accumulator || dependent.relationship == 'child'
  //     },
  //     false
  //   );
  //   // Our rule should Life plans if no children.
  //   setCommonPlanOptions({
  //     ...commonPlanOptions,
  //     maternity_cover: maternity_cover
  //   })

  // }, [dependents]); // empty array => run hook only once



  const debug = () => {
    console.log('persons');
    console.log(persons);
    console.log('positiveAnswerToMedicalQuestions');
    console.log(positiveAnswerToMedicalQuestions);
    console.log('validated');
    console.log(validated);
  }



  return (
      <Container className="enrolment-forms">
        <Row>
          <Col>
          <h1 ref={topLocation}>Group Enrolment
          {TESTING &&
            <>
              <Button variant="link" onClick={debug} style={{marginLeft: '1em'}}><i className="fa fa-bug"></i></Button>
            </>
          }
          </h1>
          </Col>
        </Row>

        <Switch>

          <Route
            exact
            path={[
              "/integraglobal/enrolment/group_policy/:group_policy_id",
              "/:site/enrolment/group_policy/:group_policy_id",

              "/integraglobal/enrolment/group/:group_id/policy/:group_policy_id",
              "/:site/enrolment/group/:group_id/policy/:group_policy_id"
            ]}
          >
            <GroupEnrolmentPrimaryForm
              persons={persons}
              setPersons={setPersons}
              groupPolicyId={groupPolicyId}
              validated={validated}
              setValidated={setValidated}
            />
          </Route>

          <Route path="/:site/enrolment/group_policy/:group_policy_id/dependents/:personId?">
            <GroupEnrolmentDependentForm
              persons={persons}
              setPersons={setPersons}
              groupPolicyId={groupPolicyId}
              validated={validated}
              setValidated={setValidated}
            />
          </Route>

          <Route path="/:site/enrolment/group_policy/:group_policy_id/main_questions">
            <GroupEnrolmentMainQuestions
              persons={persons}
              setPersons={setPersons}
              groupPolicyId={groupPolicyId}

              positiveAnswerToMedicalQuestions={positiveAnswerToMedicalQuestions}
              setPositiveAnswerToMedicalQuestions={setPositiveAnswerToMedicalQuestions}

              validated={validated}
              setValidated={setValidated}
            />
          </Route>


          <Route path="/:site/enrolment/group_policy/:group_policy_id/overview">
            <GroupEnrolmentOverview
              persons={persons}
              setPersons={setPersons}
              groupPolicyId={groupPolicyId}
              validated={validated}
            />
          </Route>

          <Route path="/:site/enrolment/group_policy/:group_policy_id/declaration">
            <GroupEnrolmentDeclaration
              persons={persons}
              groupPolicyId={groupPolicyId}
              validated={validated}
            />
          </Route>
          <Route path="/:site/enrolment/group_policy/:group_policy_id/confirmation">
            <GroupEnrolmentConfirmation
              persons={persons}
              groupPolicyId={groupPolicyId}
              validated={validated}
            />
          </Route>


        </Switch>
      </Container>
  )
}