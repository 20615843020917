import React, { useState, useEffect, useRef } from "react"
import axios from "axios";
import { nanoid } from 'nanoid';

// The useHistory hook gives you access to the history instance that you may use to navigate.
import {Redirect, useHistory, useParams } from "react-router-dom";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import Feedback from 'react-bootstrap/Feedback';
import Jumbotron from 'react-bootstrap/Jumbotron'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addYears } from '@progress/kendo-date-math';

import CountrySelect  from '../country/CountrySelect';
import {CITIZENSHIPS} from '../country/countries';

export default function GroupEnrolmentDependentForm({
  persons,
  setPersons,
  groupPolicyId,
  validated}) {

  const topLocation = useRef(null);
  let history = useHistory();


  if (! validated.primary) return <Redirect to={"/ig/enrolment/group_policy/" + groupPolicyId} />

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  // @TODO Do we need this?
  const initialPerson = {
    id: null,
    primary_id: null,
    group_policy_id: groupPolicyId,
    relationship: '',
    firstname: '',
    lastname: '',
    email_address: '',
    sex: '',
    pregnant: false,
    date_of_birth: '',
    country_of_residence_outside_US: '',
    citizenship: '',
    weight: '',
    weight_measurement_unit : '',
    height: '',
    height_measurement_unit: '',
    medical_status: 'medically_approved',
    bmi: 0
  };

  const [person, setPerson] = React.useState(initialPerson);
  const [primary, setPrimary] = React.useState(null);

  let { personId } = useParams();

  // version where an id is generated before navigating to integraglobal/enrolment/dependents/
  useEffect(() => {
    setPrimary(persons.find(person => person.relationship == 'primary'));
  }, []);


  // version where an id is generated before navigating to integraglobal/enrolment/dependents/
  useEffect(() => {
    let personToEdit = persons.find(pers => pers.id == personId);
    let primary = persons.find(person => person.relationship == 'primary');

    // if there is no dependent with the given ID create a new one
    if (personToEdit) {
      setPerson(personToEdit);
    } else {
      const personId = nanoid();
      setPerson({
        ...initialPerson,
        id: personId,
        primary_id: primary.id
      });
    }
  }, [personId]);


  const nameValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    return null;
  };

  const countryValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '' || fieldValue.trim() === 'select_country') {
      return `${fieldName} is required`;
    }
    return null;
  };



  const numberValidation = (fieldName, fieldValue) => {
    if (typeof fieldValue == "string" && fieldValue.trim() === '') {
      return `${fieldName} is required`;
    }
    if (! isNaN(fieldValue)) {
      return null;
    }
    return `${fieldName} needs to be at valid number`;
  };


  const emailValidation = email => {

    if (person.relationship == 'child') return null;

    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return null;
    }
    if (email.trim() === '') {
      return 'Email is required';
    }
    return 'Please enter a valid email';
  };

  const ageValidation = age => {
    if (! age) {
      return 'Age is required';
    }
    return null;
  };

  const validation = {
    relationship:                    name => nameValidation('Relationship', name),
    firstname:                       name => nameValidation('Firstname', name),
    lastname:                        name => nameValidation('Lastname', name),
    email_address:                   emailValidation,
    country_of_residence_outside_US: country => countryValidation('Country of residence', country),
    citizenship:                     country => countryValidation('Citizenship', country),
    date_of_birth:                   ageValidation,
    sex:                             name => nameValidation('Sex', name),
    weight:                          number => numberValidation('Weight', number),
    weight_measurement_unit:         name => nameValidation('Weight measurement unit', name),
    height:                          number => numberValidation('Height', number),
    height_measurement_unit:         name => nameValidation('Height measurement unit', name)
  };





  const handleChange = (event) => {
    const value = event.target.type === 'number' ? +event.target.value : event.target.value;

    setPerson({
      ...person,
      [event.target.name]: value
    });

    setTouched({
      ...touched,
      [event.target.name]: true,
    });

    if (event.target.type == 'radio') {
      const { [event.target.name]: removedError, ...rest } = errors;
      setErrors({
        ...rest
      });
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const { [name]: removedError, ...rest } = errors;
    if (typeof validation[name] === 'function') {
      const error = validation[name](value);
      setErrors({
        ...rest,
        ...(error && { [name]: touched[name] && error })
      });
    }
  };


  // @TODO add validation!
  const handleAddDependent = () => {

    const newPerson = {
      id: nanoid(),
      primary_id: primary.id,
      group_policy_id: groupPolicyId,
      relationship: '',
      firstname: '',
      lastname: '',
      email_address: '',
      mobile_phone: '',
      sex: '',
      date_of_birth: '',
      country_of_residence_outside_US: '',
      citizenship: '',
      weight: '',
      weight_measurement_unit : '',
      height: '',
      height_measurement_unit: '',
      relationship: '',
      medical_status: 'medically_approved',
      bmi: 0,
      errors: {
        sex: false,
        relationship: false
      }
    };

    const formValidation = validateForm();
    if (Object.values(formValidation.errors).length < 1) {// errors object is empty
      // @TODO only do this if validated!
      savePerson(person, "/ig/enrolment/group_policy/" + groupPolicyId + "/dependents/" + newPerson.id);

      // @TODO only do this if validated!
      setPersons([
        ...persons,
        newPerson
      ]);
    }
  };


  const handleProceed = () => {

    const formValidation = validateForm();

    if (Object.values(formValidation.errors).length < 1) {// errors object is empty
      savePerson(person, "/ig/enrolment/group_policy/" + groupPolicyId + "/main_questions");
    }
  }


  const validateForm = () => {

    const formValidation = Object.keys(validation).reduce(
      (acc, key) => {
        const newError = validation[key](person[key]);
        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          }
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      },
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (Object.values(formValidation.errors).length > 0) {// errors object is empty
      if (topLocation.current) {
        topLocation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }

    return formValidation;
  };

  const savePerson = (person, route) => {
    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + person.id,
      data: {
        ...person,
        log: "DEPENDENT FORM"
      }
    }).then(response => {

      let newPersons = persons.filter(function(pers) {
        return pers.id != person.id
      })

      setPersons([
        ...newPersons, // @TODO why ...?
        {
          ...person,
          medical_status: response.data.medical_status,
          bmi: response.data.bmi
        }
      ]);

      history.push(route)

    }).catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            alert('permission denied');
          } else {

          }
        } else {
          throw error;
        }

      });
  };



  return (
    <Form>
      {Object.values(errors).length > 0 &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h1>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h1>
        </Jumbotron>
      }

      <h2 ref={topLocation} className="mt-4">Tell us who you want to include in your health plan</h2>


      <Form.Group as={Row} controlId="formGroupRelationship">
        <Col style={{paddingTop: '2px'}}>
          <Form.Label className="align-middle" style={{marginRight: '1em'}}>Relationship*</Form.Label>
          <FormCheck inline>
            <FormCheck.Input
              name="relationship"
              type="radio"
              checked={person.relationship == 'partner'}
              value="partner"
              isInvalid={errors.relationship}
              onChange={handleChange}
            />
            <FormCheck.Label style={{marginRight: '0.5em'}}>Partner</FormCheck.Label>
            <Form.Check.Input
              name="relationship"
              type="radio"
              label="Child"
              checked={person.relationship == 'child'}
              value="child"
              isInvalid={errors.relationship}
              onChange={handleChange}
            />
            <FormCheck.Label style={{marginRight: '1.5em'}}>Child</FormCheck.Label>
            <Form.Control.Feedback type="invalid">Please choose the relationship to the primary insured.</Form.Control.Feedback>
          </FormCheck>
        </Col>
      </Form.Group>

      <Form.Text className="">Fields marked with an <span>*</span> are required</Form.Text>
      <Form.Group as={Row} controlId="formGroupFirstName">
        <Col sm="10">
          <Form.Control
            type="text"
            name="firstname"
            placeholder="Firstname*"
            required
            value={person.firstname}
            isInvalid={errors.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupLastName">
        <Col sm="10">
          <Form.Control
            type="text"
            name="lastname"
            placeholder="Lastname*"
            required
            value={person.lastname}
            isInvalid={errors.lastname}
            onChange={handleChange}
            onBlur={handleBlur}

          />
          <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupEmail">
        <Col sm="10">
          <Form.Control
            type="text"
            name="email_address"
            placeholder={person.relationship == 'child' ? 'Email' : 'Email*'}
            value={person.email_address}
            isInvalid={errors.email_address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Form.Control.Feedback type="invalid">Please provide an email address.</Form.Control.Feedback>
        </Col>
      </Form.Group>


      <Form.Group as={Row} controlId="formGroupCountryOfResidenceOutsideUS">
        <Col sm="10">
          <CountrySelect
            fieldName="country_of_residence_outside_US"
            handleChange={handleChange}
            validated={validated}
            placeholder="Country of Residence*"
            value={person.country_of_residence_outside_US}
            isInvalid={errors.country_of_residence_outside_US}
            onChange={handleChange}
            onBlur={handleBlur}
            exclude={['HK']}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formGroupCitizenship">
        <Col sm="10">
          <CountrySelect
            countries={CITIZENSHIPS}
            fieldName="citizenship"
            handleChange={handleChange}
            validated={validated}
            placeholder="Citizenship*"
            value={person.citizenship}
            isInvalid={errors.citizenship}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Form.Group>


      <h2 className="mt-4">And now we need to get a little personal</h2>

      <Row className="mt-4">
        <Form.Group as={Col} controlId="formGroupAge">
          <DatePicker
            className={'dependent-insured-birthdate form-control ' + (errors.date_of_birth ? 'is-invalid' : '') }
            selected={person.date_of_birth}
            dateFormat="yyyy-MM-dd"
            minDate={addYears(new Date(), -70)}
            maxDate={new Date()}
            placeholderText="Date of birth*"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onChange={(date) => {
              let localDate = new Date(date - new Date().getTimezoneOffset() * 60000);
              setPerson({
                ...person,
                'date_of_birth': localDate
              });
              setTouched({
                ...touched,
                'date_of_birth': true,
              });

              const { 'date_of_birth': removedError, ...rest } = errors;
              const error = validation['date_of_birth'](localDate);
              setErrors({
                ...rest,
                ...(error && { 'date_of_birth': error })
              });

            }}
          />
          {/* @TODO workaround because I can't make Control.Feedback work with radio groups: */}
          {errors.date_of_birth &&
            <div style={{color: '#A2242F', fontSize: '80%', display: 'block', marginTop: '.25rem'}}>Please provide your date of birth.</div>
          }
          <Form.Text className="">For example 1969-12-31</Form.Text>
          <Form.Control.Feedback type="invalid">Please provide your date of birth.</Form.Control.Feedback>
        </Form.Group>
      </Row>


      <Row className="mt-4">
        <Form.Group as={Col} controlId="formGroupSex">
          <FormCheck inline>
            <FormCheck.Input
              name="sex"
              type="radio"
              checked={person.sex == 'male'}
              value="male"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label style={{marginRight: '0.5em'}}>male</FormCheck.Label>
            <FormCheck.Input
              name="sex"
              type="radio"
              checked={person.sex == 'female'}
              value="female"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label style={{marginRight: '0.5em'}}>female</FormCheck.Label>
            <FormCheck.Input
              name="sex"
              type="radio"
              checked={person.sex == 'intersex'}
              value="intersex"
              isInvalid={errors.sex}
              onChange={handleChange}
            />
            <FormCheck.Label style={{marginRight: '0.5em'}}>inter</FormCheck.Label>
            <Form.Control.Feedback type="invalid">Please state your sex.</Form.Control.Feedback>
          </FormCheck>
        </Form.Group>
      </Row>



      {/* WEIGHT */}
      <Row className="mt-4 mb-0">
        <Col md={3} className="mr-1">
          <Form.Group className="mb-1" controlId="formGroupWeight">
            <Form.Control
              type="number"
              name="weight"
              placeholder="Weight*"
              required
              value={person.weight}
              isInvalid={errors.weight}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your weight.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formGroupWeightMeasurementUnit">
            <FormCheck inline>
              <FormCheck.Input
                name="weight_measurement_unit"
                type="radio"
                checked={person.weight_measurement_unit == 'kg'}
                value="kg"
                isInvalid={errors.weight_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label style={{marginRight: '0.5em'}}>Kilograms</FormCheck.Label>
              <FormCheck.Input
                name="weight_measurement_unit"
                type="radio"
                checked={person.weight_measurement_unit == 'lbs'}
                value="lbs"
                isInvalid={errors.weight_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label>Pounds</FormCheck.Label>
            </FormCheck>
            {errors.weight_measurement_unit &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', width: '100%', marginTop: 0}}>Please choose a weight measurement unit.</div>
            }
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group className="mb-1"  controlId="formGroupHeight">
            <Form.Control
              type="number"
              name="height"
              placeholder="Height*"
              required
              value={person.height}
              isInvalid={errors.height}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">Please provide your height.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formGroupHeightMeasurementUnit">
            <FormCheck inline>
              <FormCheck.Input
                name="height_measurement_unit"
                type="radio"
                checked={person.height_measurement_unit == 'cm'}
                value="cm"
                isInvalid={errors.height_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label style={{marginRight: '0.5em'}}>Centimeters</FormCheck.Label>
              <FormCheck.Input
                name="height_measurement_unit"
                type="radio"
                checked={person.height_measurement_unit == 'in'}
                value="in"
                isInvalid={errors.height_measurement_unit}
                onChange={handleChange}
              />
              <FormCheck.Label style={{marginRight: '0.5em'}}>Inches</FormCheck.Label>
            </FormCheck>
            {errors.height_measurement_unit &&
              <div style={{color: '#A2242F', fontSize: '80%', display: 'block', width: '100%', marginTop: 0}}>Please choose a height measurement unit.</div>
            }
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className="mt-4 mb-5" lg="8">
          <h2>Do you have any additional family members to add to the policy?</h2>
          <div className="text-center">
            <Button type="button" onClick={handleAddDependent} style={{marginRight: '1em', fontSize: 'x-large'}}>YES</Button>
            <Button type="button" onClick={handleProceed}  style={{fontSize: 'x-large'}}>NO</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

