import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import QuoteResultFormAccesshealthPlan from './QuoteResultFormAccesshealthPlan';

import QuoteResultFormCurrencies from '../../quote/QuoteResultFormCurrencies';

//-----------------------------------------------------------------------------
export default function QuoteResultFormAccesshealth({
    quoteId
  }) {

  const defaultValues = {
    default_values: true,
    id: quoteId,
    firstname: '',
    lastname: '',
    email: '',
    age: '',
    country: 'AE',
    dependents: [],
    plan: 'accesshealth_uae_20_dic_r',
    co_pay: 'op80',
    geographical_cover: 'uae_worldwide_sans_us',
    payment_period: 'annual',
    dental_option: false,
    currency: 'USD'
  };

  const [quote, setQuote] = React.useState(defaultValues);

  //---------------------------------------------------------------------------
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    if (localStorage.getItem('quote')) {
      const storedQuote = JSON.parse(localStorage.getItem('quote'));
      setQuote({
        ...defaultValues,
        ...storedQuote,
        default_values: false,
        maternity_cover: false,
        plan: 'accesshealth_uae_20_dic_r',
        id: quoteId,
        payment_period: 'annual'
      });
    } else {
      setQuote(defaultValues);
    }
    if (localStorage.getItem('currency')) {
      setCurrency(localStorage.getItem('currency'));
    }

  }, []); // empty array => run hook only once

  //---------------------------------------------------------------------------
  useEffect(() => {
    if (quote.default_values == false) {
      localStorage.setItem('quote', JSON.stringify(quote));
    }
  }, [quote]); // run hook when quote changes


  // currently there are no rates for AED
  // @TODO add AED rates to the system
  const [currency, setCurrency] = React.useState('USD');

  //---------------------------------------------------------------------------
  const handleCurrencyChange = (currency) => {
    setCurrency(currency);
    localStorage.setItem('currency', currency);
  };


  return (
    <>


      <div className="tabs" style={{background: 'rgb(245, 245, 245)'}}>
        <div className="section__inner">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="tabs__header tabs__header--mob-same js-tabs-header" >
                  <QuoteResultFormCurrencies currency={currency} setCurrency={handleCurrencyChange} currencies={['USD', 'AED']} format="text"  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="section__inner">
        <div className="container-fluid">

          <div className="row">
            <div className="col">
              <div className="tab__inner">
                <div className="quote__tables team__form">

                  <div className="quote__table js-quote-table">
                    <QuoteResultFormAccesshealthPlan
                      network="silver_classic"
                      currency={currency}
                      quote={quote}
                      setQuote={setQuote}
                    />
                  </div>

                  <div className="quote__table quote__table--big js-quote-table">
                    <QuoteResultFormAccesshealthPlan
                      network="silver_premium"
                      currency={currency}
                      quote={quote}
                      setQuote={setQuote}
                    />
                  </div>

                  <div className="quote__table js-quote-table">
                    <QuoteResultFormAccesshealthPlan
                      network="gold"
                      currency={currency}
                      quote={quote}
                      setQuote={setQuote}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
