// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useEffect } from "react"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import {Container, Form, Row, Col, Card } from 'react-bootstrap'

import Dashboard from "./Dashboard";
import MasterData from "./MasterData"
import Underwriters from "./underwriters/Underwriters"
import Users from "./users/Users"
import Usergroups from "./usergroups/Usergroups"
import Renewals from "./renewals/Renewals"
import RenewalTriggerDurations from "./renewal_trigger_durations/RenewalTriggerDurations"
import Countries from "./countries/Countries"
import IndividualPlans from "./individual_plans/IndividualPlans"
import IndividualInsured from "./IndividualInsured"
import IndividualPolicyPeriods from "./individual_policy_periods/IndividualPolicyPeriods"
import IndividualPolicyPeriodView from "./individual_policy_periods/IndividualPolicyPeriodView"
import Persons from "./persons/Persons"

import NavBar from "./NavBar"
import Login from "./Login";

import { login, logout } from './redux/ilUsersSlice'

export default function Home() {
  const ilUsers = useSelector(state => state.ilUsers)
  const dispatch = useDispatch();

  let history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push("/ig/admin");
  };

  const handleLogin = (user) => {
    dispatch(login(user));
    history.push("/ig/admin");
  };




  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('il_user'))
    dispatch( login(user) );
  }, []);


  // useEffect(() => {
  //   if (accessToken) {
  //     setLoggedIn(true);
  //   } else {
  //     setLoggedIn(false);
  //   }
  // }, [accessToken]);

  // const handleLogoutClick = (event) => {
  // //let handleLogoutClick = (event) => {
  //   const host = window.location.protocol + '//' + window.location.host
  //   axios
  //     .get(host + "/il_ws/user/logout", { withCredentials: true })
  //     .then(response => {
  //       handleLogout();
  //       // history object is passed by the Router
  //       history.push("/ig/mis");
  //     })
  //     .catch(error => {

  //     });
  // };

  if (! ilUsers.currentUser) {
    return (
      <Login />
    )
  }



  return (
    <Container fluid>
      <NavBar />
      <Switch>

        <Route exact path={["/ig/admin"]}>
          <Dashboard />
        </Route>

        <Route path={'/ig/admin/renewals'}>
          <Renewals />
        </Route>

        <Route path={'/ig/admin/renewal_trigger_durations'}>
          <RenewalTriggerDurations />
        </Route>

        <Route path={['/ig/admin/masterdata']}>
          <MasterData />
        </Route>

        <Route path={['/ig/admin/underwriters']}>
          <Underwriters />
        </Route>


        <Route path={'/ig/admin/users'}>
          <Users />
        </Route>

        <Route path={'/ig/admin/usergroups'}>
          <Usergroups />
        </Route>

        <Route path={'/ig/admin/countries'}>
          <Countries />
        </Route>

        <Route path={['/ig/admin/persons']}>
          <Persons />
        </Route>

        <Route path={['/ig/admin/individual_plans']}>
          <IndividualPlans />
        </Route>


        <Route exact path={['/ig/admin/individual_insured']}>
          <IndividualInsured />
        </Route>


        <Route exact path={['/ig/admin/individual_policy_periods']}>
          <IndividualPolicyPeriods />
        </Route>

        <Route exact path={['/ig/admin/individual_policy_periods/:id']}>
          <IndividualPolicyPeriodView />
        </Route>


      </Switch>

    </Container>
  );
}


