import React, { forwardRef, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import axios from "axios"
import * as yup from 'yup'

import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import {Form, Col, Button, InputGroup, FormControl, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'

import IndividualEnrolmentsSearchForm from "./IndividualEnrolmentsSearchForm"
//import CountryForm from "./CountryForm"
import { format, parseISO} from "date-fns"

import pdfLogo from '../../../images/il_admin/pdf-11x13.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { FormatSex } from "../Format"
import { countryName } from '../../country/countries'



export default function IndividualEnrolmentsTable(props) {
  let history = useHistory();

  const [totalCount, setTotalCount] = React.useState(-1);
  const [enrolmentInsured, setEnrolmentInsured] = React.useState([]);
  const [showEditEnrolmentInsured, setShowEditEnrolmentInsured] = React.useState(false);
  const [currentEnrolmentInsured, setCurrentEnrolmentInsured] = React.useState({
    iso: '',
    name: '',
    calling_code: '',
    plan_region:  ''
  });

  // useEffect(() => {
  //   doSearch({searchText: ''});
  // }, []); // empty array => run hook only once

  const formatDate = date_string => (
    date_string ? format(parseISO(date_string), "d. MMM Y") : ''
  );


  const doReset = () => {
    setTotalCount(-1);
  }

  const doSearch = (values) => {
    console.log('doSearch');
    //"sort"=>"[{\"property\":\"created_at\",\"direction\":\"DESC\"}]"
    const sort = [{property: "name", direction: "ASC"}]
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get(
        host + "/ws/enrolment_insured.json", {
          params: {
            name: values.searchText,
            sort: JSON.stringify(sort)
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setEnrolmentInsured(response.data.enrolment_insured);
        setTotalCount(response.data.total_count);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401) {
            history.push("/v7/mis");
          } else {
            //setWsError(error.response.data.message);
          }
        } else {
          throw error;
        }

      });
  }


                // {
                //   enrolmentInsuredI.dependents &&
                //   enrolmentInsuredI.dependents.length > 0 &&
                //   enrolmentInsuredI.dependents.map(dependent => (
                //     <Row key={dependent.id} insured={dependent} />
                //   ))
                // }

  const InsuredRow = ({insured}) => {
    return (
      <>
        <Row insured={insured} />
        {insured.dependents && insured.dependents.length > 0 && insured.dependents.map(dependent => (
          <Row key={dependent.id} insured={dependent} />
        ))}
      </>
    )
  };


  const Row = ({insured}) => {
    return (
      <tr key={insured.id} className={insured.id == insured.primary_id ? 'primary' : 'dependent'}>
        <td>{insured.id == insured.primary_id ? (insured.created_at ? format(parseISO(insured.created_at), "d. MMM Y HH:mm") : '') : ''}</td>
        <td>
          <strong>{insured.lastname}, {insured.firstname}</strong><br />
          <span style={{color: 'grey'}}>{insured.relationship}</span><br />
          {formatDate(insured.date_of_birth)}<br />
          <FormatSex insured={insured} /><br />
          {insured.weight}&#8201;{insured.weight_measurement_unit},&#8200;
          {insured.height}&#8201;{insured.height_measurement_unit},&#8200;BMI&#8201;{insured.bmi}<br />
          <span title="Citizenship">{countryName(insured.citizenship)}</span>
          {/*
          <OverlayTrigger overlay={<Tooltip>Citizenship</Tooltip>}>
            <span>{countryName(insured.citizenship)}</span>
          </OverlayTrigger>
          */}
        </td>
        <td>

          <a href={`mailto:${insured.email_address}`} style={{color: '#96c451', filter: 'brightness(80%)'}}>{insured.email_address}</a><br />
          {insured.country_calling_code} {insured.mobile_phone}
        </td>
        <td>
          {insured.address_line_1}<br />
          {insured.address_line_2}<br />
          {insured.postal_code} {insured.city}<br />
          {countryName(insured.country_of_residence_outside_US)}
        </td>
        <td className="health-question">
          {insured.enrolment_medical_conditions && insured.enrolment_medical_conditions.length > 0 &&
          <p><span style={{color: 'grey'}}>Medical conditions treated or diagnosed:</span> <br/>
            {insured.enrolment_medical_conditions.map(emc => (
              <span key={emc.value}>
              {emc.label}<br />
              </span>
            ))}
          </p>
          }
          {insured.answer_2 &&
          <p><span style={{color: 'grey'}}>Planned medical events:</span><br />{insured.answer_2}</p>
          }
          {insured.answer_3 &&
          <p><span style={{color: 'grey'}}>Symptoms last 12 months:</span><br />{insured.answer_3}</p>
          }
          {insured.answer_4 &&
          <p><span style={{color: 'grey'}}>Medication:</span><br />{insured.answer_4}</p>
          }
          {insured.answer_5 &&
          <p><span style={{color: 'grey'}}>Previous insurance issues:</span><br />{insured.answer_5}</p>
          }
          {insured.pregnant &&
          <p>pregnant</p>
          }
        </td>
        <td>
          {insured.medical_status == 'medical_underwriting' ? 'IG Underwriter Pending' : 'Medically Approved' }
        </td>
        <td>{insured.occupation != 'select_occupation' ? insured.occupation : ''}</td>
        <td>{insured.exported}</td>
        <td style={{fontSize: 'small'}}>
          {insured.id}
          {/*
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => {
              setCurrentEnrolmentInsured(enrolmentInsured.find((item) => enrolmentInsured.id == item.id));
              setShowEditCountry(true);
            }}
          />
          */}
          {insured.primary_id && insured.primary_id != insured.id &&
            <>
              <br />
              <strong>Primary's ID:</strong><br />
              {insured.primary_id}
            </>
          }
        </td>
      </tr>
    )
  };



  return (
    <div className="mt-3">

      <IndividualEnrolmentsSearchForm {...props} doSearch={doSearch} totalCount={totalCount} doReset={doReset} />

      <div className="table-responsive">
        <table id="individual-enrolments-table" className="table">
          <thead className="">
            <tr>
              <th>Created</th>
              <th>Name</th>
              <th>Email/Phone</th>
              <th>Address</th>
              <th>Health Questions</th>
              <th>Status</th>
              <th>Occupation</th>
              <th>Exported</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            {enrolmentInsured && enrolmentInsured.map(insured => (
                <InsuredRow key={insured.id} insured={insured} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
