// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import EnrolmentAccesshealthContact                 from './EnrolmentAccesshealthContact';
import EnrolmentAccesshealthMailingAddress          from './EnrolmentAccesshealthMailingAddress';
import EnrolmentAccesshealthNationalityAndResidence from './EnrolmentAccesshealthNationalityAndResidence';
import EnrolmentAccesshealthPersonalDetails         from './EnrolmentAccesshealthPersonalDetails';
import EnrolmentAccesshealthCoverageDetails         from './EnrolmentAccesshealthCoverageDetails';


export default function EnrolmentAccesshealth() {
  const defaulValues = {
    id: undefined,
    title: '',
    job_title: '',
    firstname: '',
    middlename: '',
    lastname: '',
    email_1: '',
    phone_1: '',
    dependents: [],
    marital_status: '',
    sex: ''
    //dependents: [{id: 1, lastname: 'eins', age: 21, relationship: 'partner'}, {id: 2, lastname: 'zwei', age: 22, relationship: 'child'}]
  };
  const [enrolment, setEnrolment] = React.useState(defaulValues);
  const [validated, setValidated] = React.useState(false);
  const [step, setStep] = React.useState(1);

  //const [errors, setErrors] = React.useState({dependents: []});


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    if (localStorage.getItem('enrolment')) {
      const storedEnrolment = JSON.parse(localStorage.getItem('enrolment'));
      setEnrolment({
        ...storedEnrolment
      });
    } else {
      setEnrolment({
        ...defaulValues
      });
    }
  }, []); // empty array => run hook only once


  const handleSubmit = (event) => {

    const form = event.currentTarget;
    if (form.checkValidity() === false || numberOfErrors() > 0) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      // var bodyFormData = new FormData();
      // axios({
      //   method: 'get',
      //   url: '/accesshealth/quote_results',
      //   data: bodyFormData,
      //   headers: {'Content-Type': 'multipart/form-data' }
      // })
      // .then(function (response) {
      //     //handle success
      // })
      // .catch(function (response) {
      //     //handle error
      // });
    }

    setValidated(true);
    localStorage.setItem('enrolment', JSON.stringify(enrolment));
  };


  const numberOfErrors = () => {
    return enrolment.dependents.reduce((total, dep) => {
      return dep.errors.age ? total + 1 : total;
    }, 0)
  };



  const handleChange = (event) => {
    setEnrolment({
      ...enrolment,
      [event.target.name]: event.target.value
    });
  };


  return (
    <Form action="/ws/accesshealth/enrolment" className="enrolment-form" noValidate validated={validated} onSubmit={handleSubmit}>

      <h1>Enrolment</h1>

      <EnrolmentAccesshealthContact
        enrolment={enrolment}
        handleChange={handleChange}
        validated={validated}
        step={step}
        setStep={setStep}
      />
      <EnrolmentAccesshealthMailingAddress
        enrolment={enrolment}
        handleChange={handleChange}
        validated={validated}
        step={step}
        setStep={setStep}
      />
      <EnrolmentAccesshealthNationalityAndResidence
        enrolment={enrolment}
        setEnrolment={setEnrolment}
        handleChange={handleChange}
        validated={validated}
        step={step}
        setStep={setStep}
      />
      <EnrolmentAccesshealthPersonalDetails
        enrolment={enrolment}
        setEnrolment={setEnrolment}
        handleChange={handleChange}
        validated={validated}
        step={step}
        setStep={setStep}
      />
      <EnrolmentAccesshealthCoverageDetails
        enrolment={enrolment}
        setEnrolment={setEnrolment}
        handleChange={handleChange}
        validated={validated}
        step={step}
        setStep={setStep}
      />

    </Form>
  )
}
