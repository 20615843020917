// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import TESTING from "../globals"

// REACT
import React, { useEffect, forwardRef } from 'react'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/ilUsersSlice'

// ROUTER
import { Link, useHistory, useParams, Redirect } from "react-router-dom"

// AXIOS
import axios from "axios";

// BOOTSTRAP
import Container from 'react-bootstrap/Container'
import { Table, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Jumbotron from 'react-bootstrap/Jumbotron'

// UTILITY
import { format } from 'date-fns'

// IL_ADMIN
import SearchForm from './SearchForm'
import IlFormatDate from '../util/IlFormatDate'
import IlFormatCurrency from '../util/IlFormatCurrency'

import IndividualPolicyPeriodUnderwritingNotes from './IndividualPolicyPeriodUnderwritingNotes'
import IndividualPolicyPeriodUnderwritingTerms from './IndividualPolicyPeriodUnderwritingTerms'
import IndividualPolicyPeriodRates from './IndividualPolicyPeriodRates'



export default function IndividualPolicyPeriodView() {

  const ilUsers = useSelector(state => state.ilUsers)
  const dispatch = useDispatch();
  let history = useHistory();

  let { id } = useParams();

  const [error, setError] = React.useState('');
  const [policyPeriod, setPolicyPeriod] = React.useState(null);


  useEffect(() => {
    doRetrieve(id);
  }, []);


  const doRetrieve = (id) => {

    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_individual_policy_periods/" + id + ".json", {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setPolicyPeriod(response.data.il_individual_policy_period);
        if (response.data.il_individual_policy_period) {
          setError(null);
        } else {
          setError('No policy period with ID ' + id + ' found.');
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 401) {
           dispatch(logout());
        }
        setError(error.response.statusText);
        setPolicyPeriod(null);
      });
  }

  const BreadcrumbsAndErrors = () => (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/individual_insured' }}>Individual Insured</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin/individual_policy_periods' }}>Policies</Breadcrumb.Item>
        <Breadcrumb.Item active>Policy</Breadcrumb.Item>
      </Breadcrumb>

      {error &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>😳 This shouldn't have happened.</h3>
          <h1>{error}</h1>
        </Jumbotron>
      }
    </>
  );


  if (! policyPeriod) {
    return (
      <BreadcrumbsAndErrors />
    )
  };

  return (
    <>
      <BreadcrumbsAndErrors />



        <Container>
          <h2>Policy Period</h2>
          <Container fluid className="il-policy-period"  style={{padding: 0}}>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Policy ID
              </Col>
              <Col>
                {policyPeriod.il_individual_policy_id}<span style={{fontSize: 'smaller', color: 'grey'}}>_{policyPeriod.id}<span className='ig_ID'>_{policyPeriod._ig_policy_id}</span></span>
              </Col>
            </Row>

            <Row className="il-policy-period mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Primary
              </Col>
              <Col>
                <Link className="" to={'/ig/admin/persons/' + policyPeriod.primary.id} >
                  {policyPeriod.primary?.fullname}
                </Link>
              </Col>
              <Col xs="6" sm="4" lg="2">
               Email
              </Col>
              <Col>
                <a href={'mailto:' + policyPeriod.primary?.email_address}>{policyPeriod.primary?.email_address}</a>
              </Col>
            </Row>

            <Row className=" mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Certificate Number
              </Col>
              <Col>{policyPeriod.certificate_number}</Col>
            </Row>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Preferred Start Date
              </Col>
              <Col><IlFormatDate date={policyPeriod.preferred_start_date}/></Col>
            </Row>
            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Period Start Date
              </Col>
              <Col><IlFormatDate date={policyPeriod.start_date}/></Col>
              <Col xs="6" sm="4" lg="2">
               Period End Date
              </Col>
              <Col><IlFormatDate date={policyPeriod.end_date}/></Col>
            </Row>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Commencement Date
              </Col>
              <Col><IlFormatDate date={policyPeriod.commencement_date}/></Col>
              <Col xs="6" sm="4" lg="2">
               Cancellation Date
              </Col>
              <Col><IlFormatDate date={policyPeriod.cancellation_date}/></Col>
            </Row>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Reason for Cancellation
              </Col>
              <Col>{policyPeriod.il_individual_policy?.reason_for_cancellation}</Col>
            </Row>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Cover Region
              </Col>
              <Col>{policyPeriod.cover_region == 'worldwide_sans_us' ? 'worldwide excluding US/Canada' : 'worldwide including US/Canada'}</Col>
            </Row>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Currency
              </Col>
              <Col><IlFormatCurrency currency={policyPeriod.currency}/></Col>
            </Row>


            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Deductible
              </Col>
              <Col>{policyPeriod.deductible}</Col>
              <Col xs="6" sm="4" lg="2">
               Network
              </Col>
              <Col>{policyPeriod.network}</Col>
            </Row>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Co-Pay
              </Col>
              <Col>{policyPeriod.co_pay}</Col>
            </Row>


            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Dental Cover
              </Col>
              <Col>{policyPeriod.dental ? 'YES' : 'NO'}</Col>
              <Col xs="6" sm="4" lg="2">
                AD&amp;D Benefit
              </Col>
              <Col>{policyPeriod.add_benefit}</Col>
            </Row>

            <Row className="mt-2 mt-sm-0">
              <Col xs="6" sm="4" lg="2">
               Payment Period
              </Col>
              <Col>{policyPeriod.payment_period}</Col>
            </Row>
          </Container>
        </Container>

        <Container className="mt-5">
          <IndividualPolicyPeriodRates quote={policyPeriod.premium_json} currency={policyPeriod.currency} paymentPeriod={policyPeriod.payment_period} />
        </Container>

        <Container className="mt-5">
          <h2>Underwriting Terms</h2>
          {policyPeriod.il_persons.map(il_person => (
            <Container className="il-underwriting-terms" key={il_person.id} style={{padding: 0}} >
              <IndividualPolicyPeriodUnderwritingTerms person={il_person} setError={setError} />
            </Container>
          ))}
        </Container>


        <Container className="mt-5">
          <h2>Underwriting History</h2>
          {policyPeriod.il_persons.map(il_person => (
            <Container className="il-underwriting-notes" key={il_person.id}  style={{padding: 0}} >
              <IndividualPolicyPeriodUnderwritingNotes person={il_person} setError={setError} />
            </Container>
          ))}
        </Container>

    </>
  );

}
