// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect} from "react"
import axios from "axios";
import { nanoid } from 'nanoid';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';






export default function ContactUsForm({
  authenticity_token,
  site,
  subsite
  }) {

  const [errors, setErrors] = React.useState([]);

  const [message, setMessage] = React.useState({fullname: '', from_address: '', message_text: ''});
  const [validated, setValidated] = React.useState(false);
  const [messageSent, setMessageSent] = React.useState(false);
//  const [positiveServerResponse, setPositiveServerResponse] = React.useState(false);

  // useEffect(() => {
  //   //setErrors(['was soll das', 'keine Ahnung!']);
  // }, []); // empty array => run hook only once

  //---------------------------------------------------------------------------
  const handleChange = (event) => {
    setMessage({
      ...message,
      [event.target.name]: event.target.value
    });
  };


  //---------------------------------------------------------------------------
  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      setMessageSent(true);
      axios.post('/ws/contact_us', {
        message
      })
      .then(function (response) {
        // handle success
        if (! response.data.success) {
          setErrors(response.data.errors);
        } else {
          setErrors([]);
        }
      })
      .catch(function (response) {
        // handle error
        // setPositiveServerResponse(false);
      });

    }
    setValidated(true);
  };



  // const Title = ({errors}) => {
  //   if (errors.length < 1) return null;
  //   // const listItems = errors.map((error) =>
  //   //   <li>{error}</li>
  //   // );

  //   return (
  //     <div>
  //     <h1>Thank You</h1>
  //     <p>We received your message successfully, but there might be something wrong.  Please check the values provided and submit your message again.</p>
  //     <ul className="errors">
  //     {errors.map((error) => <li>{error}</li>)}
  //     </ul>
  //     </div>
  //   )
  // };


  const ErrorMessage = ({errors}) => {
    if (errors.length < 1) {
      return (
        <h4>Email us</h4>
      )
    }

    return (
      <div>
      <h1>Thank You</h1>
      <p>We received your message, but there might be something wrong.  Please check the values provided and submit your message again.</p>
      <ul className="errors">
      {/* key={nanoid()} should not be used because  it should be consistent among renders. */}
      {errors.map((error, index) => <li key={nanoid()}>{error}</li>)}
      </ul>
      </div>
    )
  };



  if (messageSent == true && errors.length < 1) {
    return (
      <div className="mt-5 mb-5">
        <h1>Thank You</h1>
        <p>Your message has been successfully sent to Integra Global Ltd.</p>
      </div>
    )
  } else {
    return (
      <>

      <ErrorMessage errors={errors} />

      <Form action='/ws/contact_us' className="contact-us-form" noValidate validated={validated} onSubmit={handleSubmit} method="POST">

        <Form.Row>
          <Form.Group controlId="formGroupFullname">
            <Form.Label srOnly>
              Name*
            </Form.Label>
            <Form.Control
              type="text"
              name="fullname"
              placeholder="Name*"
              required
              onChange={handleChange}
              value={message.fullname}
            />
            <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group controlId="formGroupFromAddress" className="">
            <Form.Label srOnly>
              Email*
            </Form.Label>
            <Form.Control
              type="email"
              name="from_address"
              placeholder="Your email*"
              required
              onChange={handleChange}
              value={message.from_address}
            />
            <Form.Control.Feedback type="invalid">Please provide an email address.</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group controlId="formGroupMessageText">
            <Form.Label srOnly>
              Message*
            </Form.Label>

            <Form.Control
              as="textarea"
              rows={5}
              name="message_text"
              placeholder="Your message*"
              required
              onChange={handleChange}
              value={message.message_text}
            />
            <Form.Control.Feedback type="invalid">Please provide a message.</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Text className="text-muted">
          By clicking send you’re agreeing to the terms within our <a href="/ig/privacy_policy">privacy policy</a>.
        </Form.Text>
        {/* if the button is not of type submit form.checkValidity() returns true even if all fields are left empty */}
        <Button type="submit">Submit</Button>

      </Form>
      </>
    )
  }
}