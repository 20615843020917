import React from "react"

export function FormatSex({insured}) {
  if (insured.sex == 'male') {
    return (
      <>♂&nbsp;male</>
    )
  } else if (insured.sex == 'female') {
    return (
      <>♀&nbsp;female</>
    )
  }
  return (
    <>⚥&nbsp;inter</>
  )
};


// import { COUNTRIES } from './countries'

// export const countryName = (country_iso) => {
//   let country = COUNTRIES.find(element => element.iso == country_iso);
//   if (country) {
//     return country.name;
//   }
//   return '';
// };

// export const function CountryName({iso}) {
//   let country = COUNTRIES.find(element => element.iso == iso);
//   if (country) {
//     return (country.name);
//   }
//   return null;
// };
