export const TESTING = false;
/*
export const COUNTRIES = ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua &amp; Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas"
    , "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia &amp; Herzegovina", "Botswana", "Brazil", "British Virgin Islands"
    , "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica"
    , "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea"
    , "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana"
    , "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India"
    , "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia"
    , "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania"
    , "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia"
    , "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal"
    , "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre &amp; Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles"
    , "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea", "Spain", "Sri Lanka", "St Kitts &amp; Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan"
    , "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad &amp; Tobago", "Tunisia"
    , "Turkey", "Turkmenistan", "Turks &amp; Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay"
    , "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"];
*/

export const PERSONAL_RELATIONSHIPS = {
  primary: {name: 'Primary', value: 0},
  partner: {name: 'Partner', value: 50},
  spouse:  {name: 'Partner', value: 50},
  child:   {name: 'Child', value: 100}
};

// @TODO retrieve from database
export const PLAN_NAMES = {
  yourincare_19_v:        'V-InCareLife-19',
  premierincare_19_v:     'V-InCarePremier-19',

  yourlife_row_17_v:      'V-yourLife-17',
  yourfamily_row_17_v:    'V-yourFamily-17',
  premierlife_row_17_v:   'V-PremierLife-17',
  premierfamily_row_17_v: 'V-PremierFamily-17',

  yourlife_cs_18_v:       'V-yourLife-CS-18',
  premierlife_cs_18_v:    'V-premierLife-CS-18',
  yourfamily_cs_18_v:     'V-yourFamily-CS-18',
  premierfamily_cs_18_v:  'V-premierFamily-CS-18'
};

export const isInCarePlan = (plan) => (['yourincare_19_v', 'premierincare_19_v'].includes(plan));


export const MEDICAL_CONDITIONS = [
    {value: 'mh_treated_for_heart',                        label: 'Heart / Blood vessels'},
    {value: 'mh_treated_for_mental_disorder',              label: 'Mental / Nervous Disorder'},
    {value: 'mh_treated_for_high_blood_pressure',          label: 'Stroke / Blood Pressure / Hypertension'},
    {value: 'mh_treated_for_cancer',                       label: 'Cancer'},
    {value: 'mh_treated_for_respiratory_problems',         label: 'Respiratory Problems (Asthma)'},
    {value: 'mh_treated_for_diabetes',                     label: 'Diabetes (Related Problems)'},
    {value: 'mh_treated_for_hiv',                          label: 'HIV/AIDS or Related Diseases'},
    {value: 'mh_treated_for_ortho_problems',               label: 'Ortho Problems & Arthritis (Back, Joints, etc.)'},
    {value: 'mh_treated_for_urological_problems_20160615', label: 'Urinary disorders - including bladder, kidney, prostate, urinary infections and incontinence'},
    {value: 'mh_gynaecological_disorders',                 label: 'Gynaecological disorders - including heavy or irregular periods, fibroids, endometriosis, infertility, abnormal smears, polycystic ovaries'},
    {value: 'mh_treated_for_neurological_disorder',        label: 'Neurological Disorder'},
    {value: 'mh_treated_for_stomach_intestines',           label: 'Stomach/Intestines'},
    {value: 'mh_treated_for_hernia',                       label: 'Hernia'},
    {value: 'mh_ears_eyes',                                label: 'Ears/Eyes'},
    {value: 'mh_immune_system_disorders',                  label: 'Immune System Disorders'},
    {value: 'mh_sti',                                      label: 'Sexually Transmitted Infections'},
    {value: 'mh_skin',                                     label: 'Skin'},
    {value: 'mh_thyroid',                                  label: 'Thyroid'}
  ];

export const getBMI = (insured) => {
  if (insured?.weight && ! isNaN(insured.weight) &&
      insured.weight_measurement_unit &&
      insured.height && ! isNaN(insured.height) &&
      insured.height_measurement_unit) {
      ///^\d+$/.test(insured.weight) &&
      ///^\d+$/.test(insured.height)) {

    let height = 0;
    if (insured.height_measurement_unit == 'in') {
      height = (insured.height / 0.39370);
    } else {
      height = insured.height;
    }

    let weight = 0;
    if (insured.weight_measurement_unit == 'lbs') {
      weight = (insured.weight / 2.205);
    } else {
      weight = insured.weight;
    }

    let bmi = Math.round(weight / Math.pow(height / 100, 2));
    return bmi;

  }
  return '';
};

export const isCriticalBMI = (bmi) => (bmi > 30 || bmi < 17)

export const formatHeight = (insured) => {
  if (insured?.height &&
      !isNaN(insured.height) &&
      insured.height_measurement_unit &&
      ['cm', 'in'].includes(insured.height_measurement_unit)) {
    return insured.height + insured.height_measurement_unit;
  }
  return '';
};

export const formatWeight = (insured) => {
  if (insured?.weight &&
      !isNaN(insured.weight) &&
      insured.weight_measurement_unit &&
      ['kg', 'lbs'].includes(insured.weight_measurement_unit)) {
    return insured.weight + insured.weight_measurement_unit;
  }
  return '';
};


export const getAge = (dateOfBirth) => {
    // var today = new Date();
    // var birthDate = new Date(date);
    // var age = today.getFullYear() - birthDate.getFullYear();
    // var m = today.getMonth() - birthDate.getMonth();
    // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //     age--;
    // }
    return Math.floor((new Date() - new Date(dateOfBirth)) / (1000 *60 *60 *24 * 365.25));
};


export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
};


export const ANNUALIZATION_FACTOR = {
  quarterly: 4,
  semiannual: 2,
  annual: 1
};