import React from 'react';
import { useHistory, NavLink, useRouteMatch } from "react-router-dom";


export default function InOut(props) {
  let { path, url } = useRouteMatch();

  return (

    <div className="album py-5 bg-light">
        <div className="container">

          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <h1 className="card-text">
                    <NavLink  className="nav-link" to="/v7/mis/dashboard/individual_enrolments">Individual Enrolments</NavLink>
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <h1 className="card-text">Enrolments in Underwriting</h1>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  )
}
