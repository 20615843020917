// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import { createSlice } from '@reduxjs/toolkit'

export const ilRenewalTriggerDurationsSlice = createSlice({
  name: 'ilRenewalTriggerDurations',
  initialState: {
    rows: [],
    totalCount: 0
  },
  reducers: {
    setIlRenewalTriggerDurations: (state, action) => {
      state.rows = action.payload.rows
      state.totalCount = action.payload.totalCount
    },
    removeIlRenewalTriggerDurations: state => {
      state.rows = null
      state.totalCount = 0
    },
    updateIlRenewalTriggerDuration: (state, action) => {
      state.rows = state.rows.map(rtd => rtd.id != action.payload.item.id ? rtd : {...action.payload.item});
    },
    addIlRenewalTriggerDuration: (state, action) => {
      state.rows = [...state.rows, action.payload.item].sort((a, b) => {
        if (a.il_underwriter.name.localeCompare(b.il_underwriter.name) == 0) {
          return a.il_plan_region.name.localeCompare(b.il_plan_region.name);
        } else {
          return a.il_underwriter.name.localeCompare(b.il_underwriter.name);
        }
      });
    },
    removeIlRenewalTriggerDuration: (state, action) => {
      state.rows = state.rows.filter(function(item) {
        return item.id != action.payload.item.id
      })
      state.totalCount = state.rows.length
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIlRenewalTriggerDurations, removeIlRenewalTriggerDurations, updateIlRenewalTriggerDuration, addIlRenewalTriggerDuration, removeIlRenewalTriggerDuration } = ilRenewalTriggerDurationsSlice.actions

export default ilRenewalTriggerDurationsSlice.reducer