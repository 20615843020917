// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { forwardRef, useState } from 'react';
import { format } from 'date-fns'

export default function IlFormatDate({date}) {

  if (date) {
    return (
      <>
        {format(new Date(date), 'd. LLL yyyy')}
      </>
    );
  } else {
    return null;
  }
}

