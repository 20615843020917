// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';





export default function QuoteResultFormSendEmail(props) {
  // https://reactjs.org/warnings/unknown-prop.html
  // The spread operator can be used to pull variables off props, and put the remaining props into a variable.
  const {handleChange, ...rest } = props;
  const [validated, setValidated] = React.useState(false);

  // const handleSendEmail = (event) => {
  //   props.onHide();
  // };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();
    if (form.checkValidity() === false) {
    } else {
      props.onHide();
    }
    setValidated(true);
  };



  return (

    <Modal
      {...rest}
      show={props.show}
      onShow={() => {console.log('onShow')}}
      onHide={props.onHide}
      size="lg"
      centered
    >
      <Form onSubmit={handleSubmit}  noValidate  validated={validated}>
      <Modal.Header closeButton>
        <Modal.Title>
          Email me this quote
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {/*
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      */}

          <Form.Group as={Row}>
            <Form.Label column sm="2">First name:</Form.Label>
            <Col sm="10">
              <Form.Control plaintext readOnly value={props.quote.firstname} />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">Last name:</Form.Label>
            <Col sm="10">
              <Form.Control plaintext readOnly value={props.quote.lastname} />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">Email*:</Form.Label>
            <Col sm="10">
              <Form.Control
                name='email_address'
                type="email"
                required
                placeholder="Email address"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">Please provide an email address.</Form.Control.Feedback>
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">Phone:</Form.Label>
            <Col sm="10">
              <Form.Control
                name="phone"
                type="text"
                placeholder="Phone number"
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button type="submit">Email me this Quote</Button>
      </Modal.Footer>
      </Form>
    </Modal>

  );
}