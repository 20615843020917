// encoding: UTF-8
//
// (C) Copyright 2021-2022 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import NumberFormat from 'react-number-format'

import { format } from 'date-fns';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { PLAN_NAMES, isInCarePlan, ANNUALIZATION_FACTOR } from '../../exports'

export default function EnrolmentPlanOptionsView({
    primary,
    dependents,
    planOptions,
    premium
  }) {

  const currencyPrefix = {
    USD: '$',
    EUR: '€',
    GBP: '£'
  };

  const networks = {
    silver:   'Silver',
    gold:     'Gold',
    platinum: 'Platinum'
  };

  const paymentPeriods = {
    annual:     'Annual',
    semiannual: 'Semi‑annual',
    quarterly:  'Quarterly'
  };


  let history = useHistory();

  const [csRegion, setCsRegion] = React.useState(['CN', 'MO', 'SG'].includes(primary.country_of_residence_outside_US));

  const [medicalStatusForPolicy, setMedicalStatusForPolicy] = React.useState('medical_underwriting');

  useEffect(() => {
  //   setCsRegion(['CN', 'MO', 'SG'].includes(primary.country_of_residence_outside_US));
    setMedicalStatusForPolicy(calcMedicalStatusForPolicy());
  }, []); // empty array => run hook only once



  const handleChangePlanOptions = (event) => {
    history.push("/ig/enrolment/plan_options")
  };

  const debug = () => {
    console.log('planOptions');
    console.log(planOptions);
    console.log('premium');
    console.log(premium);
  }

  const calcMedicalStatusForPolicy = () => {
    return dependents.reduce(
      (accumulator, dependent) => {
        return dependent.medical_status == 'medical_underwriting' ? 'medical_underwriting' : accumulator
      },
      primary.medical_status
    );
  };





  return (
    <Container className="mt-5" style={{padding: 0, margin: 0}}>
      <Row className="enrolment-summary first-row">
        <Col lg="12">
         <h3 className="m-0 p-0">PLAN OPTIONS <Button variant="link" className="change btn view btn-light btn-sm" style={{display: 'inline-block'}} onClick={handleChangePlanOptions}>CHANGE</Button></h3>
        </Col>
      </Row>
      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
         Plan
        </Col>
        <Col>
          {PLAN_NAMES[planOptions.plan]}
        </Col>
      </Row>
      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Deductible
        </Col>
        <Col>
          <NumberFormat value={planOptions.deductible} prefix={currencyPrefix[planOptions.currency]} displayType={'text'} thousandSeparator={true} decimalScale={0} />
        </Col>
      </Row>

      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          AD&amp;D Option
        </Col>
        <Col>
          {planOptions.add_benefit > 0 ? 'YES' : 'NO'}
        </Col>
      </Row>

      {planOptions.add_benefit > 0 &&
      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          AD&amp;D Benefit
        </Col>
        <Col>
          <NumberFormat value={planOptions.add_benefit} prefix={currencyPrefix[planOptions.currency]} displayType={'text'} thousandSeparator={true} decimalScale={0} />
        </Col>
      </Row>
      }

      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Geogr. Cover
        </Col>
        <Col>
          {planOptions.geographical_cover == 'worldwide_sans_us'
            ? <span>Worldwide <strong>excluding US/Canada</strong></span>
            : <span>Worldwide <strong>including US/Canada</strong></span>
          }
        </Col>
      </Row>

      {isInCarePlan(planOptions.plan) == false &&
      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Maternity Cover
        </Col>
        <Col>
          {planOptions.maternity_cover ? 'YES' : 'NO'}
        </Col>
      </Row>
      }


      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Dental Plan
        </Col>
        <Col>
          {planOptions.dental_option ? 'YES' : 'NO'}
        </Col>
      </Row>

      {csRegion && isInCarePlan(planOptions.plan) == false &&
      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Network
        </Col>
        <Col>
          {networks[planOptions.network]}
        </Col>
      </Row>
      }


      <Row className="enrolment-summary last-row mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Preferred Start
        </Col>
        <Col>
          {planOptions.preferred_start_date ? format(new Date(planOptions.preferred_start_date), 'dd MMM. yyyy') : ''}
        </Col>
      </Row>

      <Row className="enrolment-summary first-row mt-3">
        <Col lg="12">
         <h3 className="m-0 p-0">PREMIUM</h3>
        </Col>
      </Row>

      {medicalStatusForPolicy == 'medically_approved' &&
      <Row className="enrolment-summary mt-2">
        <Col lg="12">
         This is a binding quote if the premium is paid within 30 days.
        </Col>
      </Row>
      }
      {medicalStatusForPolicy == 'medical_underwriting' &&
      <Row className="enrolment-summary">
        <Col lg="12">
         <strong>This quote is for indicative purposes only and the quote may change based on the medical information provided. We will inform you of any change to your premium.</strong>
        </Col>
      </Row>
     }


     <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3" >
          Payment Method
        </Col>
        <Col>
          {paymentPeriods[planOptions.payment_period]}
        </Col>
      </Row>
      <Row className="enrolment-summary mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Currency
        </Col>
        <Col>
           {planOptions.currency} {currencyPrefix[planOptions.currency]}
        </Col>
      </Row>

      {planOptions.payment_period != 'annual' &&
      <Row className="last-row mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3" >
          {paymentPeriods[planOptions.payment_period]}&nbsp;Payments
        </Col>
        <Col>
          <NumberFormat value={premium[planOptions.payment_period]} prefix={currencyPrefix[planOptions.currency]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={2} />
        </Col>
      </Row>
      }
      <Row className="enrolment-summary last-row mt-2 mt-sm-0">
        <Col xs="6" sm="4" lg="3">
          Annual&nbsp;Premium
        </Col>
        <Col>
        {/*
Quartlery payments of #AMOUNT (if)
Semi-annual payments of #AMOUNT (if)
BELOW THAT:
Annual Premium #AMOUNT

          <span className="quote__table__pricing__currency">{planOptions.currency}</span>
          <NumberFormat value={premium} displayType={'text'} thousandSeparator={true} decimalScale={0} />

          <FormatPremium premium={premium} currency={planOptions.currency} />
                  */}
          <strong>
            <NumberFormat value={Math.round(((premium[planOptions.payment_period] * ANNUALIZATION_FACTOR[planOptions.payment_period]) + Number.EPSILON) * 100) / 100} prefix={currencyPrefix[planOptions.currency]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={2} />
          </strong>
        </Col>
      </Row>
    </Container>
  )
}