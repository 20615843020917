// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import { createSlice } from '@reduxjs/toolkit'

export const ilCountriesSlice = createSlice({
  name: 'ilCountries',
  initialState: {
    rows: [],
    totalCount: 0
  },
  reducers: {
    setIlCountries: (state, action) => {
      state.rows = action.payload.rows
      state.totalCount = action.payload.totalCount
    },
    removeIlCountries: state => {
      state.rows = null
      state.totalCount = 0
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIlCountries, removeIlCountries } = ilCountriesSlice.actions

export default ilCountriesSlice.reducer