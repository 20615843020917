// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { useEffect, forwardRef } from 'react'
import { Link, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import axios from "axios";

import { format} from 'date-fns'

import Container from 'react-bootstrap/Container'
import { Table, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Jumbotron from 'react-bootstrap/Jumbotron'

import SearchForm from './SearchForm'

export default function Renewals() {
  let history = useHistory();
  const ilUsers = useSelector(state => state.ilUsers)
  // Declare a new state variable, which we'll call "rows"
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(-1);
  const [error, setError] = React.useState('');

  // useEffect(() => {
  //   doSearch();
  // }, []);

  // useEffect(() => {

  // }, [rows]);


  const doSearch = (values) => {
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
        host + "/il_ws/il_individual_policy_periods/renewals.json", {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          },
          params: {
            sort: 'commencement_date',
            calendar_week: values.calendarWeek
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setError(null);
        setRows(response.data.il_individual_policy_period_renewals);
        setTotalCount(response.data.total_count);
      })
      .catch(error => {
        setError(error.response.statusText);
        //history.push("/ig/admin");
      });
  }



  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ig/admin' }}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Renewals</Breadcrumb.Item>
      </Breadcrumb>

      <SearchForm doSearch={doSearch} totalCount={totalCount} count={rows.length} />

      {error &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h3>😳 This shouldn't have happened.</h3>
          <h1>{error}</h1>
        </Jumbotron>
      }



      <Container fluid>
        {rows.map(row => (
          <div key={row.id} className="il-individual-policy">
            <Row className="mt-3">
              <Col xs="6" sm="4" md="3" lg="2">Policy ID</Col> <Col>{row.id}</Col>
            </Row>
            <Row>
              <Col xs="6" sm="4" md="3" lg="2">Renewal Invitation</Col> <Col>{format(new Date(row.renewal_invitation_sent_on), 'd. LLL yyyy')}</Col>
            </Row>
            <Row>
              <Col xs="6" sm="4" md="3" lg="2">First Reminder</Col> <Col>{format(new Date(row.first_reminder_sent_on), 'd. LLL yyyy')}</Col>
            </Row>
            {/*
            <Table className="il-individual-policy-periods">
              <tbody>
              {row.il_individual_policy_periods.map(policy_period => (
                <tr key={row.id + ' ' + policy_period.id}  className="il-individual-policy-period">
                  <td>{policy_period.id}</td>
                  <td>{format(new Date(policy_period.start_date), 'd. LLL yyyy')}</td>
                  <td>{format(new Date(policy_period.end_date), 'd. LLL yyyy')}</td>
                </tr>
              ))}
              </tbody>
            </Table>
            */}
          </div>
        ))}
      </Container>
    </>
  );

}
