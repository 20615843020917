import { TESTING } from '../../exports';
import React, { useState, useEffect, useRef } from "react"
import axios from "axios";

// import ImageUploader from 'react-images-upload';
// import { FilePond, registerPlugin } from 'react-filepond';
// import 'filepond/dist/filepond.min.css';
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
//
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);



// The useHistory hook gives you access to the history instance that you may use to navigate.
import {Redirect, useHistory } from "react-router-dom";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FormFile from 'react-bootstrap/FormFile'

import Button from 'react-bootstrap/Button';

import bsCustomFileInput from 'bs-custom-file-input'

export default function EnrolmentPrimaryPassport({
  primary,
  setPrimary,
  validated,
  setValidated}) {

  const [error, setError] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploadSuccessful, setUploadSuccessful] = React.useState(false);
  const [uploading, setUploading] = useState(false);

  let history = useHistory();

  if (! TESTING) {
    if (! validated.primary || ! validated.main_questions || ! validated.plan_options)  return <Redirect to="/ig/enrolment/primary" />
  }

  const handleContinue = (event) => {
    setValidated({...validated, passport_image: true});
    history.push("/ig/enrolment/overview");
  }


  useEffect(() => {
    setValidated({...validated, passport_image: false});
    bsCustomFileInput.init()
  }, []); // empty array => run hook only once




  const handleFileChange = (event) => {


    if (! event.target.files.length > 0) {
      setError(null);
      setUploadSuccessful(false);
      setSelectedFile(null);
      setPrimary({
        ...primary,
        'passport_image_path': null,
        'passport_image_file_name': null
      });
      return;
    }

    if (event.target.files[0].size > 10000000) {
      setError('Image is too big.  Maximum size is 10MB.');
      return;
    }

    if (['image/jpeg', 'image/png', 'image/tiff', 'application/pdf'].includes(event.target.files[0].type) == false) {
      setError('We are sorry.  Files of this type are not allowed.');
      return;
    }

    setError(null);
    setUploadSuccessful(false);
    setSelectedFile(event.target.files[0]);
    setPrimary({
      ...primary,
      'passport_image_path': null,
      'passport_image_file_name': null
    });
    //setImage(URL.createObjectURL(event.target.files[0]));

  };

  const handleFileUpload = (event) => {

    setUploading(true);
   // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "passportImage",
      selectedFile,
      selectedFile.name
    );

    axios({
      method: 'put',
      url: window.location.protocol + '//' + window.location.host + "/ws/enrolment_insured/" + primary.id + "/passport_image",
      data: formData
    }).then(response => {
      setUploadSuccessful(true);
      setPrimary({
        ...primary,
        'passport_image_path': window.location.protocol + '//' + window.location.host + '/ws/enrolment_insured/' + primary.id + '/passport_image',
        'passport_image_file_name': selectedFile.name
      });
      setUploading(false);
    })
    .catch(error => {
      setUploading(false);
      setUploadSuccessful(false);
      setPrimary({
        ...primary,
        'passport_image_path': null,
        'passport_image_file_name': null
      });

      if (error.response) {
        if (error.response.status == 401) {
          alert('permission denied');
        } else {

        }
      } else {
        throw error;
      }

    });
  };


  let imgPreview;
  if (selectedFile) {
    imgPreview = <img className="w-50" src={URL.createObjectURL(selectedFile)} alt='' />;
  } else if (primary.passport_image_path) {
    imgPreview = <img className="w-50" src={primary.passport_image_path} alt='' />;
  }

  return (
    <Form>
      <h2 className="mt-4">Passport of primary insured</h2>
      <Form.Group>
        <Form.File
          id="custom-file"
          label="Passport image filename"
          data-browse="Select Passport Image"
          custom
          onChange={handleFileChange}
          isInvalid={error}
        />
        <Form.Text id="passwordHelpBlock" muted>
          Valid file types are JPEG, PNG, TIFF or PDF.
        </Form.Text>
        <div className="text-center" style={{fontSize: 'x-large'}}>
          {error}
        </div>
      </Form.Group>

      <div style={{display: (! uploadSuccessful && selectedFile ? 'block' : 'none')}}>
      <Row className="text-center">
        <Col className="mt-4 mb-5" style={{}} >
          <Button
            type="button"
            disabled={! selectedFile || uploading}
            onClick={handleFileUpload}
            dangerouslySetInnerHTML={{__html: uploading ? '<i class="fa fa-spinner fa-spin" style="font-size:24px"></i>&nbsp;UPLOADING...' : 'UPLOAD'}}
          />

          </Col>
      </Row>
      </div>

      <Row className="text-center" style={{display: uploadSuccessful  == true ? 'block' : 'none'}}>
        <Col className="mt-4 mb-4" style={{fontSize: '1.25rem', flex: 1, flexWrap: 'wrap'}} >
          <div>Passport image<br /><i>{primary.passport_image_file_name}</i><br />has been uploaded successfully</div>
        </Col>
      </Row>


      <Row className="text-center" style={{display: primary.passport_image_path && uploadSuccessful == false ? 'block' : 'none'}}>
        <Col className="mb-4 upload-text" style={{fontSize: 'x-large'}}>
          Current passport image<br /><i>{primary.passport_image_file_name}</i>
        </Col>
      </Row>


      <Row className="text-center" style={{display: primary.passport_image_path ? 'block' : 'none'}}>
        <Col className="mb-5">
          <Button
            variant="secondary"
            type="button"
            disabled={! primary.passport_image_path}
            onClick={handleContinue}
          >CONTINUE</Button>
        </Col>
      </Row>


      <Row className="text-center">
        <Col className="mb-5">
          <div className="form-group preview text-center">
              {imgPreview}
          </div>
        </Col>
      </Row>
    </Form>
  )
}