// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//

import React, { useState, useEffect } from "react"

import axios from "axios"
import { Redirect, useHistory } from "react-router-dom"

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Jumbotron from 'react-bootstrap/Jumbotron'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addYears } from '@progress/kendo-date-math'


import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import valid from 'card-validator'

import CountrySelect            from '../../country/CountrySelect'
import { COUNTRIES }            from '../../country/countries'
import CountryCallingCodeSelect from '../../country/CountryCallingCodeSelect'


export default function PaymentForm({formAuthenticityToken}) {

  const [payment, setPayment] = React.useState({});
  const [payed, setPayed] = React.useState(false);
  const [error, setError] = React.useState('');
  const [exceptionMessage, setExceptionMessage] = React.useState('');
  const [success, setSuccess] = React.useState('');


  useEffect(() => {
  }, []);


  const handleSubmit = (values, { setSubmitting }) => {
    console.log('handleSubmit')

    // Worldpay
    axios({
      method: 'POST',
      url: window.location.protocol + '//' + window.location.host + "/ws/credit_card_payments/worldpay",
      data: {
        credit_card_payment: values
      }
    }).then(response => {
      console.log(response);
      if (response.data.success) {
        setError(null);
        setExceptionMessage(null);
        setSuccess(response.data.message);
      } else {
        setError(response.data.message);
        setExceptionMessage(response.data?.exception_message);
        setSuccess('');
      }
      setSubmitting(false);
    }).catch(error => {
      setError(error.response.data.message);
      setExceptionMessage(error.response.data.exception_message);
      setSubmitting(false);
      setSuccess('');
    });

  }





  const savePaymentResult = (payment, answer) => {
    axios
    .put( // we don't want the password be visible in the query string
      window.location.protocol + '//' + window.location.host + "/ws/credit_card_payments/finish3ds",
      {
        payment: payment,
        answer: answer
      }
    )
    .then(response => {
      if (response.data.success) {
      } else {
      }
    })
    .catch(error => {
    });
  }




  const validationSchema = Yup.object().shape({
    // policy_holder_email:     Yup.string().max(254, 'Only 254 characters are allowed.').email('The email address you provided has an invalid format.').required('Please provide your email address.'),
    policy_holder_firstname: Yup.string().max(254, 'Only 254 characters are allowed.').required('Please provide a firstname.'),
    policy_holder_lastname:  Yup.string().max(254, 'Only 254 characters are allowed.').required('Please provide a lastname.'),
    certificate_number:      Yup.string().matches(/^[a-zA-Z0-9]+$/, {message: 'Only letters and numbers are allowed.'}).required('Please provide an invoice number.'),
    billing_address_street_1: Yup.string().max(50, 'Only 50 characters are allowed.').required('Please provide a street.'),
    billing_address_city: Yup.string().max(40, 'Only 40 characters are allowed.').required('Please provide a city.'),
    billing_address_country_code: Yup.string().required('Please select a country.'),
    billing_address_zip: Yup.string().max(16, 'Only 16 characters are allowed.').required('Please provide your postal code or zip.'),
    amount: Yup.number().min(0).max(1000000).positive('Amount must be a positive number.').required('Please provide an amount.'),

    credit_card_holder:              Yup.string().max(254, 'Only 254 characters are allowed.').required('Please provide a credit card holder name.'),
    credit_card_number_decrypted:    Yup.string().test('test-number', 'Credit card number is invalid', value => valid.number(value).isValid).required(),
    credit_card_expiry_date:         Yup.string().matches(/^[0-9]+\/2[1-9]+$/, {message: 'Valid format MM/YY. For example: 9/23.'}).required('Please provide an expiry date.'),
    credit_card_verification_number: Yup.string().max(4).matches(/^[0-9]+$/, {message: 'Only numbers are allowed.'}).required('Please provide a verification number.'),

  });


  if (payed) {
    return (
      <>
        <h1>Thank you!</h1>
        <p>Your payment has been received. Please kindly allow 1 working day for the payment to be reflected on your invoice.</p>
      </>
    )
  }

  return (
    <Container fluid className="payment-form">
      <h1>PAY PREMIUM</h1>

        <Formik
          enableReinitialize={true}
          initialValues={{
            policy_holder_email:             payment?.policy_holder_email ?? '',
            policy_holder_firstname:         payment?.policy_holder_firstname ?? '',
            policy_holder_lastname:          payment?.policy_holder_lastname ?? '',
            certificate_number:              payment?.certificate_number ?? '',
            billing_address_firstname:       payment?.billing_address_firstname ?? '',
            billing_address_lastname:        payment?.billing_address_lastname ?? '',
            billing_address_company_name:    payment?.billing_address_company_name ?? '',
            billing_address_street_1:        payment?.billing_address_street_1 ?? '',
            billing_address_city:            payment?.billing_address_city ?? '',
            billing_address_state:           payment?.billing_address_state ?? '',
            billing_address_country_code:    payment?.billing_address_country_code ?? '',
            billing_address_zip:             payment?.billing_address_zip ?? '',
            amount:                          payment?.amount ?? '',
            currency:                        payment?.currency ?? 'USD',
            description:                     payment?.description ?? '',

            credit_card_holder:              payment?.credit_card_holder ?? '',
            credit_card_number_decrypted:    payment?.credit_card_number_decrypted ?? '',
            credit_card_type:                payment?.credit_card_type ?? '',
            // credit_card_expiration_year:     payment?.credit_card_expiration_year ?? '',
            // credit_card_expiration_month:    payment?.credit_card_expiration_month ?? '',
            credit_card_expiry_date:         payment?.credit_card_expiry_date ?? '',
            credit_card_verification_number: payment?.credit_card_verification_number ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            submitCount,
            getFieldProps,
            validateField,
            setFieldError,
            setFieldTouched,

            handleChange,
            handleBlur
          }) => (

            <Form onSubmit={handleSubmit} noValidate id="card-form">

              <Form.Control type="text" hidden {...getFieldProps('id')} />

              {error &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h3>We are sorry.</h3>
                  <h1>{error}</h1>
                  <p>{exceptionMessage}</p>
                </Jumbotron>
              }
              {success &&
                <Jumbotron style={{color: 'white', backgroundColor: '#96c451'}}>
                  <h3>Congratulations!</h3>
                  <h1>{success}</h1>
                </Jumbotron>
              }


              <h3>Policy Holder</h3>
              <Form.Row>
                <Form.Group as={Col}  md="4">
                    <Form.Control
                      type="text"
                      placeholder="Firstname*"
                      required
                      isInvalid={errors.policy_holder_firstname && touched.policy_holder_firstname}
                      {...getFieldProps('policy_holder_firstname')}
                    />
                  <Form.Control.Feedback type="invalid">{errors.policy_holder_firstname}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <Form.Control
                    type="text"
                    placeholder="Lastname*"
                    required
                    isInvalid={errors.policy_holder_lastname && touched.policy_holder_lastname}
                    {...getFieldProps('policy_holder_lastname')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.policy_holder_lastname}</Form.Control.Feedback>
                </Form.Group>

              </Form.Row>


              <Form.Row>


                <Form.Group as={Col} md="4">
                  <Form.Control
                    type="text"
                    placeholder="Email*"
                    required
                    isInvalid={errors.policy_holder_email && touched.policy_holder_email}
                    {...getFieldProps('policy_holder_email')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.policy_holder_email}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <Form.Control
                    type="text"
                    placeholder="Invoice Number*"
                    required
                    isInvalid={errors.certificate_number && touched.certificate_number}
                    {...getFieldProps('certificate_number')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.certificate_number}</Form.Control.Feedback>
                </Form.Group>

              </Form.Row>

              <h3>Billing Address</h3>
              <Form.Row className="row-with-form-text">
                <Form.Group as={Col}  md="4">
                    <Form.Control
                      type="text"
                      placeholder="Firstname"
                      isInvalid={errors.billing_address_firstname && touched.billing_address_firstname}
                      {...getFieldProps('billing_address_firstname')}
                    />
                  <Form.Control.Feedback type="invalid">{errors.billing_address_firstname}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <Form.Control
                    type="text"
                    placeholder="Lastname"
                    isInvalid={errors.billing_address_lastname && touched.billing_address_lastname}
                    {...getFieldProps('billing_address_lastname')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.billing_address_lastname}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <Form.Control
                    type="text"
                    placeholder="Company"
                    isInvalid={errors.billing_address_company_name && touched.billing_address_company_name}
                    {...getFieldProps('billing_address_company_name')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.billing_address_company_name}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="8">
                  <Form.Control
                    type="text"
                    placeholder="Street*"
                    required
                    isInvalid={errors.billing_address_street_1 && touched.billing_address_street_1}
                    {...getFieldProps('billing_address_street_1')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.billing_address_street_1}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Control
                    type="text"
                    placeholder="City*"
                    required
                    isInvalid={errors.billing_address_city && touched.billing_address_city}
                    {...getFieldProps('billing_address_city')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.billing_address_city}</Form.Control.Feedback>
                </Form.Group>

              </Form.Row>



              <Form.Row>
                <Form.Group as={Col}  md="4">
                    <Form.Control
                      type="text"
                      placeholder="State/Province"
                      isInvalid={errors.billing_address_state && touched.billing_address_state}
                      {...getFieldProps('billing_address_state')}
                    />
                  <Form.Control.Feedback type="invalid">{errors.billing_address_state}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4">

                  <CountrySelect
                    className={'form-control ' + (errors.billing_address_country_code ? 'is-invalid' : '') }
                    fieldName="billing_address_country_code"
                    placeholder="Country*"
                    value={values.billing_address_country_code}
                    isInvalid={errors.billing_address_country_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <Form.Control.Feedback type="invalid">Please provide your country of residence.</Form.Control.Feedback>
                  </CountrySelect>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <Form.Control
                    type="text"
                    placeholder="Postal code/Zip*"
                    required
                    isInvalid={errors.billing_address_zip && touched.billing_address_zip}
                    {...getFieldProps('billing_address_zip')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.billing_address_zip}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>



              <h3>Payment</h3>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Control
                    type="number"
                    placeholder="Amount*"
                    required
                    isInvalid={errors.amount && touched.amount}
                    {...getFieldProps('amount')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Control
                    as="select"
                    custom
                    {...getFieldProps('currency')}
                  >
                    <option value="USD">$ US Dollar</option>
                    <option value="EUR">€ Euro</option>
                    <option value="GBP">£ Pound Sterling</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.currency}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Control
                    className="description"
                    as="textarea"
                    rows={5}
                    placeholder="Please provide details if applicable"
                    {...getFieldProps('description')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>



              <h3>Credit Card</h3>
              <Form.Row>
                <Form.Group as={Col} sm="10" lg="6">
                  <Form.Control
                    type="text"
                    placeholder="Name as written on card*"
                    required
                    isInvalid={errors.credit_card_holder && touched.credit_card_holder}
                    {...getFieldProps('credit_card_holder')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.credit_card_holder}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>


              <Form.Row>

                <Form.Group as={Col} sm="4" lg="3">
                  <Form.Control
                    id="card-pan"
                    type="text"
                    placeholder="Card number*"
                    required
                    isInvalid={errors.credit_card_number_decrypted && touched.credit_card_number_decrypted}
                    {...getFieldProps('credit_card_number_decrypted')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.credit_card_number_decrypted}</Form.Control.Feedback>
                </Form.Group>


                <Form.Group as={Col} sm="2" lg="1">
                  <Form.Control
                    as="select"
                    custom
                    {...getFieldProps('credit_card_type')}
                  >
                    <option value="VISA">Visa</option>
                    <option value="MC">Mastercard</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.credit_card_type}</Form.Control.Feedback>
                </Form.Group>


                <Form.Group as={Col} sm="2" lg="1">
                  <Form.Control
                    id="card-expiry"
                    type="text"
                    placeholder="MM/YY"
                    required
                    isInvalid={errors.credit_card_expiry_date && touched.credit_card_expiry_date}
                    {...getFieldProps('credit_card_expiry_date')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.credit_card_expiry_date}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} sm="2" lg="1">
                  <Form.Control
                    id="card-cvv"
                    type="text"
                    placeholder="CVV*"
                    required
                    isInvalid={errors.credit_card_verification_number && touched.credit_card_verification_number}
                    {...getFieldProps('credit_card_verification_number')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.credit_card_verification_number}</Form.Control.Feedback>
                </Form.Group>



                {/*
                <Form.Group as={Col} sm="2">
                  <Form.Control
                    as="select"
                    custom
                    {...getFieldProps('credit_card_expiration_month')}
                  >
                    <option value="1"> January</option>
                    <option value="2"> February</option>
                    <option value="3"> March</option>
                    <option value="4"> April</option>
                    <option value="5"> May</option>
                    <option value="6"> June</option>
                    <option value="7"> July</option>
                    <option value="8"> August</option>
                    <option value="9"> September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.credit_card_expiration_month}</Form.Control.Feedback>
                </Form.Group>



                <Form.Group as={Col} sm="2">
                  <Form.Control
                    as="select"
                    custom
                    {...getFieldProps('credit_card_expiration_year')}
                  >
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.credit_card_expiration_year}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} sm="2">
                  <DatePicker
                    selected={new Date()}
                    onChange={(date) => console.log(date)}
                    dateFormat="MM/yy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    showTwoColumnMonthYearPicker
                  />
                  <Form.Control.Feedback type="invalid">{errors.credit_card_expiration_date}</Form.Control.Feedback>
                </Form.Group>
              */}

              </Form.Row>



              <Form.Row className="mb-3">
                <Col>
                  <Button id="clear" className="" variant="primary" >CLEAR</Button>
                </Col>
                <Col>
                  <Button id="pay" className="" type="submit" variant="primary" >PAY</Button>
                </Col>
              </Form.Row>


              {Object.values(errors).length > 0 && submitCount > 0 &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h1>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h1>
                </Jumbotron>
              }
            </Form>
            )}
        </Formik>


    </Container>
  );
}