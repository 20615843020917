import React from "react"
import { Field, Formik, useFormik } from 'formik'


import {Container, Form, Row, Col, Button } from 'react-bootstrap'

import TotalCount from "../util/TotalCount";


export default function SearchPlanForm(props) { // parameter destructuring

  const [value, setValue] = React.useState('');

  // We pass our form's initialValues and a submission function (onSubmit) to the useFormik() hook. The hook then
  // returns to us a goodie bag of form state and helpers in a variable we are calling formik.
  const formik = useFormik({
    initialValues: {
      searchText: '',
      underwriter: '',
      region: ''
    },
    onReset: (values, formik) => {
      props.doReset();
    },
    onSubmit: (values, formik) => {
      props.doSearch(values);
    }
  });

  return (
    <Container fluid className="">
      <Form inline noValidate onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        {/*
        <Form.Label className="my-1 mr-2"><h3>Plans</h3></Form.Label>
        */}
        <Form.Control
          className="mr-sm-2"
          size="sm"
          name="searchText"
          placeholder="Search Text"
          value={formik.values.searchText || ''}
          onChange={formik.handleChange}
          isInvalid={formik.touched.searchText && formik.errors.searchText}
        />
        {/*
        <Form.Control.Feedback type="invalid">
          {formik.errors.searchText}
        </Form.Control.Feedback>
        */}

        <Form.Control
          className="mr-sm-2"
          as="select"
          size="sm"
          name="underwriter"
          onChange={formik.handleChange}
          value={formik.values.underwriter}
          >
          <option value="">Underwriter...</option>
          <option value="RGA">RGA</option>
          <option value="Generali">Generali</option>
          <option value="LAMP">LAMP</option>
          <option value="MGEN">MGEN</option>
          <option value="@Test@">@Test@</option>
        </Form.Control>

        {/*<Form.Label>Disabled select menu</Form.Label>*/}
        <Form.Control
          className="mr-sm-2"
          as="select"
          size="sm"
          name="region"
          onChange={formik.handleChange}
          value={formik.values.region}
          >
          <option value="">Region...</option>
          <option value="chks">CHKS</option>
          <option value="cs">CS</option>
          <option value="hk">HK</option>
          <option value="ms">MS</option>
          <option value="row">ROW</option>
          <option value="uae">UAE</option>
          <option value="gcc">GCC</option>
        </Form.Control>

        <Button type="submit" size="sm" className="mr-1" name="search">Search</Button>
        <Button type="reset" variant="outline-secondary" size="sm" className="" name="clear">Clear</Button>
        {/*<Button type="button" variant="outline-secondary" size="sm" name="test" onClick={(event) => onTest(formik, event)}>Test</Button>*/}

        <TotalCount totalCount={props.totalCount} singularName="Plan" />
      </Form>
    </Container>
  );

}