//
// @TODO use AJAX only?
//
export const countryName = (country_iso) => {
  let country = COUNTRIES.find(element => element.iso == country_iso);
  if (country) {
    return country.name;
  }
  return '';
};

export const countryPlanRegion = (country_iso) => {
  let country = COUNTRIES.find(element => element.iso == country_iso);
  if (country) {
    return country.plan_region;
  }
  return '';
};


export const TOP_COUNTRIES_OF_RESIDENCE = [
  {"id": 13,  "name": "Australia", "numcode": "36", "iso": "AU", "calling_code": "+61", "plan_region": "row", "ranking": 1},
  {"id": 17,  "name": "Bahrain", "numcode": "48", "iso": "BH", "calling_code": "+973", "plan_region": "gcc", "ranking": 1},
  {"id": 36,  "name": "Cambodia", "numcode": "116", "iso": "KH", "calling_code": "+855", "plan_region": "row", "ranking": 1},
  {"id": 100, "name": "Indonesia", "numcode": "360", "iso": "ID", "calling_code": "+62", "plan_region": "row", "ranking": 1},
  {"id": 129, "name": "Malaysia", "numcode": "458", "iso": "MY", "calling_code": "+60", "plan_region": "row", "ranking": 1},
  {"id": 169, "name": "Philippines", "numcode": "608", "iso": "PH", "calling_code": "+63", "plan_region": "row", "ranking": 1},
  {"id": 177, "name": "Russian Federation", "numcode": "643", "iso": "RU", "calling_code": "+7", "plan_region": "row", "ranking": 1},
  {"id": 208, "name": "Taiwan, Province of China", "numcode": "158", "iso": "TW", "calling_code": "+886", "plan_region": "row", "ranking": 1},
  {"id": 211, "name": "Thailand", "numcode": "764", "iso": "TH", "calling_code": "+66", "plan_region": "row", "ranking": 1},
  {"id": 225, "name": "United Kingdom", "numcode": "826", "iso": "GB", "calling_code": "+44", "plan_region": "row", "ranking": 1},
  {"id": 232, "name": "Viet Nam", "numcode": "704", "iso": "VN", "calling_code": "+84", "plan_region": "row",  "ranking": 1}
];

export const TOP_CITIZENSHIPS = [
  {"id": 13,  "name": "Australia", "numcode": "36", "iso": "AU", "calling_code": "+61", "plan_region": "row"},
  {"id": 38,  "name": "Canada", "numcode": "124", "iso": "CA", "calling_code": "+1", "plan_region": "row"},
  {"id": 80,  "name": "Germany", "numcode": "276", "iso": "DE", "calling_code": "+49", "plan_region": "row"},
  {"id": 99,  "name": "India", "numcode": "356", "iso": "IN", "calling_code": "+91", "plan_region": "row"},
  {"id": 225, "name": "United Kingdom", "numcode": "826", "iso": "GB", "calling_code": "+44", "plan_region": "row"},
  {"id": 226, "name": "United States of America", "numcode": "840", "iso": "US", "calling_code": "+1", "plan_region": "row"}
];
export const SEPARATOR = [
  {"id": 0,   "name": "—", "numcode": "-1", "iso": "separator"}
];

export const COUNTRIES = [
  {"id": 1,   "name": "Afghanistan", "numcode": "4", "iso": "AF", "calling_code": "+93", "plan_region": "row"},
  {"id": 2,   "name": "Albania", "numcode": "8", "iso": "AL", "calling_code": "+355", "plan_region": "row"},
  {"id": 3,   "name": "Algeria", "numcode": "12", "iso": "DZ", "calling_code": "+213", "plan_region": "row"},
  {"id": 4,   "name": "American Samoa", "numcode": "16", "iso": "AS", "calling_code": "+684", "plan_region": "row"},
  {"id": 5,   "name": "Andorra", "numcode": "20", "iso": "AD", "calling_code": "+376", "plan_region": "row"},
  {"id": 6,   "name": "Angola", "numcode": "24", "iso": "AO", "calling_code": "+244", "plan_region": "row"},
  {"id": 7,   "name": "Anguilla", "numcode": "660", "iso": "AI", "calling_code": "+264", "plan_region": "row"},
  {"id": 8,   "name": "Antarctica", "numcode": "10", "iso": "AQ", "calling_code": "+672", "plan_region": "row"},
  {"id": 9,   "name": "Antigua and Barbuda", "numcode": "28", "iso": "AG", "calling_code": "+268", "plan_region": "row"},
  {"id": 10,  "name": "Argentina", "numcode": "32", "iso": "AR", "calling_code": "+54", "plan_region": "row"},
  {"id": 11,  "name": "Armenia", "numcode": "51", "iso": "AM", "calling_code": "+374", "plan_region": "row"},
  {"id": 12,  "name": "Aruba", "numcode": "533", "iso": "AW", "calling_code": "+297", "plan_region": "row"},
  {"id": 13,  "name": "Australia", "numcode": "36", "iso": "AU", "calling_code": "+61", "plan_region": "row"},
  {"id": 14,  "name": "Austria", "numcode": "40", "iso": "AT", "calling_code": "+43", "plan_region": "row"},
  {"id": 15,  "name": "Azerbaijan", "numcode": "31", "iso": "AZ", "calling_code": "+994", "plan_region": "row"},
  {"id": 16,  "name": "Bahamas", "numcode": "44", "iso": "BS", "calling_code": "+242", "plan_region": "row"},
  {"id": 17,  "name": "Bahrain", "numcode": "48", "iso": "BH", "calling_code": "+973", "plan_region": "gcc"},
  {"id": 18,  "name": "Bangladesh", "numcode": "50", "iso": "BD", "calling_code": "+880", "plan_region": "row"},
  {"id": 19,  "name": "Barbados", "numcode": "52", "iso": "BB", "calling_code": "+246", "plan_region": "row"},
  {"id": 20,  "name": "Belarus", "numcode": "112", "iso": "BY", "calling_code": "+375", "plan_region": "row"},
  {"id": 21,  "name": "Belgium", "numcode": "56", "iso": "BE", "calling_code": "+32", "plan_region": "row"},
  {"id": 22,  "name": "Belize", "numcode": "84", "iso": "BZ", "calling_code": "+501", "plan_region": "row"},
  {"id": 23,  "name": "Benin", "numcode": "204", "iso": "BJ", "calling_code": "+229", "plan_region": "row"},
  {"id": 24,  "name": "Bermuda", "numcode": "60", "iso": "BM", "calling_code": "+441", "plan_region": "row"},
  {"id": 25,  "name": "Bhutan", "numcode": "64", "iso": "BT", "calling_code": "+975", "plan_region": "row"},
  {"id": 26,  "name": "Bolivia", "numcode": "68", "iso": "BO", "calling_code": "+591", "plan_region": "row"},
  {"id": 27,  "name": "Bosnia and Herzegovina", "numcode": "70", "iso": "BA", "calling_code": "+387", "plan_region": "row"},
  {"id": 28,  "name": "Botswana", "numcode": "72", "iso": "BW", "calling_code": "+267", "plan_region": "row"},
  {"id": 29,  "name": "Bouvet Island", "numcode": "74", "iso": "BV", "calling_code": "+47", "plan_region": "row"},
  {"id": 30,  "name": "Brazil", "numcode": "76", "iso": "BR", "calling_code": "+55", "plan_region": "row"},
  {"id": 31,  "name": "British Indian Ocean Territory", "numcode": "86", "iso": "IO", "calling_code": "+246", "plan_region": "row"},
  {"id": 32,  "name": "Brunei Darussalam", "numcode": "96", "iso": "BN", "calling_code": "+673", "plan_region": "row"},
  {"id": 33,  "name": "Bulgaria", "numcode": "100", "iso": "BG", "calling_code": "+359", "plan_region": "row"},
  {"id": 34,  "name": "Burkina Faso", "numcode": "854", "iso": "BF", "calling_code": "+226", "plan_region": "row"},
  {"id": 35,  "name": "Burundi", "numcode": "108", "iso": "BI", "calling_code": "+257", "plan_region": "row"},
  {"id": 36,  "name": "Cambodia", "numcode": "116", "iso": "KH", "calling_code": "+855", "plan_region": "row"},
  {"id": 37,  "name": "Cameroon", "numcode": "120", "iso": "CM", "calling_code": "+237", "plan_region": "row"},
  {"id": 38,  "name": "Canada", "numcode": "124", "iso": "CA", "calling_code": "+1", "plan_region": "row"},
  {"id": 39,  "name": "Cape Verde", "numcode": "132", "iso": "CV", "calling_code": "+238", "plan_region": "row"},
  {"id": 40,  "name": "Cayman Islands", "numcode": "136", "iso": "KY", "calling_code": "+345", "plan_region": "row"},
  {"id": 41,  "name": "Central African Republic", "numcode": "140", "iso": "CF", "calling_code": "+236", "plan_region": "row"},
  {"id": 42,  "name": "Chad", "numcode": "148", "iso": "TD", "calling_code": "+235", "plan_region": "row"},
  {"id": 43,  "name": "Chile", "numcode": "152", "iso": "CL", "calling_code": "+56", "plan_region": "row"},
  {"id": 44,  "name": "China", "numcode": "156", "iso": "CN", "calling_code": "+86", "plan_region": "cs"},
  {"id": 45,  "name": "Christmas Island", "numcode": "162", "iso": "CX", "calling_code": "+61", "plan_region": "row"},
  {"id": 46,  "name": "Cocos (Keeling) Islands", "numcode": "166", "iso": "CC", "calling_code": "+891", "plan_region": "row"},
  {"id": 47,  "name": "Colombia", "numcode": "170", "iso": "CO", "calling_code": "+57", "plan_region": "row"},
  {"id": 48,  "name": "Comoros", "numcode": "174", "iso": "KM", "calling_code": "+269", "plan_region": "row"},
  {"id": 49,  "name": "Congo", "numcode": "178", "iso": "CG", "calling_code": "+242", "plan_region": "row"},
  {"id": 50,  "name": "Congo, the Democratic Republic of the", "numcode": "180", "iso": "CD", "calling_code": "+243", "plan_region": "row"},
  {"id": 51,  "name": "Cook Islands", "numcode": "184", "iso": "CK", "calling_code": "+682", "plan_region": "row"},
  {"id": 52,  "name": "Costa Rica", "numcode": "188", "iso": "CR", "calling_code": "+506", "plan_region": "row"},
  {"id": 53,  "name": "Cote D'Ivoire", "numcode": "384", "iso": "CI", "calling_code": "+225", "plan_region": "row"},
  {"id": 54,  "name": "Croatia", "numcode": "191", "iso": "HR", "calling_code": "+385", "plan_region": "row"},
  {"id": 55,  "name": "Cuba", "numcode": "192", "iso": "CU", "calling_code": "+53", "plan_region": "row"},
  {"id": 244, "name": "Curaçao", "numcode": "531", "iso": "CW", "calling_code": "+599", "plan_region": "row"},
  {"id": 56,  "name": "Cyprus", "numcode": "196", "iso": "CY", "calling_code": "+357", "plan_region": "row"},
  {"id": 57,  "name": "Czech Republic", "numcode": "203", "iso": "CZ", "calling_code": "+420", "plan_region": "row"},
  {"id": 58,  "name": "Denmark", "numcode": "208", "iso": "DK", "calling_code": "+45", "plan_region": "row"},
  {"id": 59,  "name": "Djibouti", "numcode": "262", "iso": "DJ", "calling_code": "+253", "plan_region": "row"},
  {"id": 60,  "name": "Dominica", "numcode": "212", "iso": "DM", "calling_code": "+767", "plan_region": "row"},
  {"id": 61,  "name": "Dominican Republic", "numcode": "214", "iso": "DO", "calling_code": "+809", "plan_region": "row"},
  {"id": 62,  "name": "Ecuador", "numcode": "218", "iso": "EC", "calling_code": "+593", "plan_region": "row"},
  {"id": 63,  "name": "Egypt", "numcode": "818", "iso": "EG", "calling_code": "+20", "plan_region": "row"},
  {"id": 64,  "name": "El Salvador", "numcode": "222", "iso": "SV", "calling_code": "+503", "plan_region": "row"},
  {"id": 65,  "name": "Equatorial Guinea", "numcode": "226", "iso": "GQ", "calling_code": "+240", "plan_region": "row"},
  {"id": 66,  "name": "Eritrea", "numcode": "232", "iso": "ER", "calling_code": "+291", "plan_region": "row"},
  {"id": 67,  "name": "Estonia", "numcode": "233", "iso": "EE", "calling_code": "+372", "plan_region": "row"},
  {"id": 68,  "name": "Ethiopia", "numcode": "231", "iso": "ET", "calling_code": "+251", "plan_region": "row"},
  {"id": 69,  "name": "Falkland Islands (Malvinas)", "numcode": "238", "iso": "FK", "calling_code": "+500", "plan_region": "row"},
  {"id": 70,  "name": "Faroe Islands", "numcode": "234", "iso": "FO", "calling_code": "+298", "plan_region": "row"},
  {"id": 71,  "name": "Fiji", "numcode": "242", "iso": "FJ", "calling_code": "+679", "plan_region": "row"},
  {"id": 72,  "name": "Finland", "numcode": "246", "iso": "FI", "calling_code": "+358", "plan_region": "row"},
  {"id": 73,  "name": "France", "numcode": "250", "iso": "FR", "calling_code": "+33", "plan_region": "row"},
  {"id": 74,  "name": "French Guiana", "numcode": "254", "iso": "GF", "calling_code": "+594", "plan_region": "row"},
  {"id": 75,  "name": "French Polynesia", "numcode": "258", "iso": "PF", "calling_code": "+689", "plan_region": "row"},
  {"id": 76,  "name": "French Southern Territories", "numcode": "260", "iso": "TF", "calling_code": "+689", "plan_region": "row"},
  {"id": 77,  "name": "Gabon", "numcode": "266", "iso": "GA", "calling_code": "+241", "plan_region": "row"},
  {"id": 78,  "name": "Gambia", "numcode": "270", "iso": "GM", "calling_code": "+220", "plan_region": "row"},
  {"id": 79,  "name": "Georgia", "numcode": "268", "iso": "GE", "calling_code": "+995", "plan_region": "row"},
  {"id": 80,  "name": "Germany", "numcode": "276", "iso": "DE", "calling_code": "+49", "plan_region": "row"},
  {"id": 81,  "name": "Ghana", "numcode": "288", "iso": "GH", "calling_code": "+233", "plan_region": "row"},
  {"id": 82,  "name": "Gibraltar", "numcode": "292", "iso": "GI", "calling_code": "+350", "plan_region": "row"},
  {"id": 83,  "name": "Greece", "numcode": "300", "iso": "GR", "calling_code": "+30", "plan_region": "row"},
  {"id": 84,  "name": "Greenland", "numcode": "304", "iso": "GL", "calling_code": "+299", "plan_region": "row"},
  {"id": 85,  "name": "Grenada", "numcode": "308", "iso": "GD", "calling_code": "+473", "plan_region": "row"},
  {"id": 86,  "name": "Guadeloupe", "numcode": "312", "iso": "GP", "calling_code": "+590", "plan_region": "row"},
  {"id": 87,  "name": "Guam", "numcode": "316", "iso": "GU", "calling_code": "+671", "plan_region": "row"},
  {"id": 88,  "name": "Guatemala", "numcode": "320", "iso": "GT", "calling_code": "+502", "plan_region": "row"},
  {"id": 241, "name": "Guernsey", "numcode": "831", "iso": "GG", "calling_code": "+1481", "plan_region": "row"},
  {"id": 89,  "name": "Guinea", "numcode": "324", "iso": "GN", "calling_code": "+225", "plan_region": "row"},
  {"id": 90,  "name": "Guinea-Bissau", "numcode": "624", "iso": "GW", "calling_code": "+245", "plan_region": "row"},
  {"id": 91,  "name": "Guyana", "numcode": "328", "iso": "GY", "calling_code": "+592", "plan_region": "row"},
  {"id": 92,  "name": "Haiti", "numcode": "332", "iso": "HT", "calling_code": "+509", "plan_region": "row"},
  {"id": 93,  "name": "Heard Island and Mcdonald Islands", "numcode": "334", "iso": "HM", "calling_code": "+61", "plan_region": "row"},
  {"id": 94,  "name": "Holy See (Vatican City State)", "numcode": "336", "iso": "VA", "calling_code": "+379", "plan_region": "row"},
  {"id": 95,  "name": "Honduras", "numcode": "340", "iso": "HN", "calling_code": "+504", "plan_region": "row"},
  {"id": 96,  "name": "Hong Kong", "numcode": "344", "iso": "HK", "calling_code": "+852", "plan_region": "row"},
  {"id": 97,  "name": "Hungary", "numcode": "348", "iso": "HU", "calling_code": "+36", "plan_region": "row"},
  {"id": 98,  "name": "Iceland", "numcode": "352", "iso": "IS", "calling_code": "+354", "plan_region": "row"},
  {"id": 99,  "name": "India", "numcode": "356", "iso": "IN", "calling_code": "+91", "plan_region": "row"},
  {"id": 100, "name": "Indonesia", "numcode": "360", "iso": "ID", "calling_code": "+62", "plan_region": "row"},
  {"id": 101, "name": "Iran, Islamic Republic of", "numcode": "364", "iso": "IR", "calling_code": "+98", "plan_region": "row"},
  {"id": 102, "name": "Iraq", "numcode": "368", "iso": "IQ", "calling_code": "+964", "plan_region": "row"},
  {"id": 103, "name": "Ireland", "numcode": "372", "iso": "IE", "calling_code": "+353", "plan_region": "row"},
  {"id": 104, "name": "Israel", "numcode": "376", "iso": "IL", "calling_code": "+972", "plan_region": "row"},
  {"id": 105, "name": "Italy", "numcode": "380", "iso": "IT", "calling_code": "+39", "plan_region": "row"},
  {"id": 106, "name": "Jamaica", "numcode": "388", "iso": "JM", "calling_code": "+876", "plan_region": "row"},
  {"id": 107, "name": "Japan", "numcode": "392", "iso": "JP", "calling_code": "+81", "plan_region": "row"},
  {"id": 240, "name": "Jersey", "numcode": "839", "iso": "JE", "calling_code": "+44", "plan_region": "row"},
  {"id": 108, "name": "Jordan", "numcode": "400", "iso": "JO", "calling_code": "+962", "plan_region": "row"},
  {"id": 109, "name": "Kazakhstan", "numcode": "398", "iso": "KZ", "calling_code": "+7", "plan_region": "row"},
  {"id": 110, "name": "Kenya", "numcode": "404", "iso": "KE", "calling_code": "+254", "plan_region": "row"},
  {"id": 111, "name": "Kiribati", "numcode": "296", "iso": "KI", "calling_code": "+686", "plan_region": "row"},
  {"id": 113, "name": "Korea, Republic of", "numcode": "410", "iso": "KR", "calling_code": "+82", "plan_region": "row"},
  {"id": 112, "name": "Korea, the Democratic People's Republic of", "numcode": "408", "iso": "KP", "calling_code": "+850", "plan_region": "row"},
  {"id": 114, "name": "Kuwait", "numcode": "414", "iso": "KW", "calling_code": "+965", "plan_region": "gcc"},
  {"id": 115, "name": "Kyrgyzstan", "numcode": "417", "iso": "KG", "calling_code": "+996", "plan_region": "row"},
  {"id": 116, "name": "Lao People's Democratic Republic", "numcode": "418", "iso": "LA", "calling_code": "+856", "plan_region": "row"},
  {"id": 117, "name": "Latvia", "numcode": "428", "iso": "LV", "calling_code": "+371", "plan_region": "row"},
  {"id": 118, "name": "Lebanon", "numcode": "422", "iso": "LB", "calling_code": "+961", "plan_region": "row"},
  {"id": 119, "name": "Lesotho", "numcode": "426", "iso": "LS", "calling_code": "+266", "plan_region": "row"},
  {"id": 120, "name": "Liberia", "numcode": "430", "iso": "LR", "calling_code": "+231", "plan_region": "row"},
  {"id": 121, "name": "Libya", "numcode": "434", "iso": "LY", "calling_code": "+218", "plan_region": "row"},
  {"id": 122, "name": "Liechtenstein", "numcode": "438", "iso": "LI", "calling_code": "+423", "plan_region": "row"},
  {"id": 123, "name": "Lithuania", "numcode": "440", "iso": "LT", "calling_code": "+370", "plan_region": "row"},
  {"id": 124, "name": "Luxembourg", "numcode": "442", "iso": "LU", "calling_code": "+352", "plan_region": "row"},
  {"id": 125, "name": "Macao", "numcode": "446", "iso": "MO", "calling_code": "+853", "plan_region": "row"},
  {"id": 127, "name": "Madagascar", "numcode": "450", "iso": "MG", "calling_code": "+261", "plan_region": "row"},
  {"id": 128, "name": "Malawi", "numcode": "454", "iso": "MW", "calling_code": "+265", "plan_region": "row"},
  {"id": 129, "name": "Malaysia", "numcode": "458", "iso": "MY", "calling_code": "+60", "plan_region": "row"},
  {"id": 130, "name": "Maldives", "numcode": "462", "iso": "MV", "calling_code": "+960", "plan_region": "row"},
  {"id": 131, "name": "Mali", "numcode": "466", "iso": "ML", "calling_code": "+223", "plan_region": "row"},
  {"id": 132, "name": "Malta", "numcode": "470", "iso": "MT", "calling_code": "+356", "plan_region": "row"},
  {"id": 133, "name": "Marshall Islands", "numcode": "584", "iso": "MH", "calling_code": "+692", "plan_region": "row"},
  {"id": 134, "name": "Martinique", "numcode": "474", "iso": "MQ", "calling_code": "+596", "plan_region": "row"},
  {"id": 135, "name": "Mauritania", "numcode": "478", "iso": "MR", "calling_code": "+222", "plan_region": "row"},
  {"id": 136, "name": "Mauritius", "numcode": "480", "iso": "MU", "calling_code": "+230", "plan_region": "row"},
  {"id": 137, "name": "Mayotte", "numcode": "175", "iso": "YT", "calling_code": "+262", "plan_region": "row"},
  {"id": 138, "name": "Mexico", "numcode": "484", "iso": "MX", "calling_code": "+52", "plan_region": "row"},
  {"id": 139, "name": "Micronesia, Federated States of", "numcode": "583", "iso": "FM", "calling_code": "+691", "plan_region": "row"},
  {"id": 140, "name": "Moldova, Republic of", "numcode": "498", "iso": "MD", "calling_code": "+373", "plan_region": "row"},
  {"id": 141, "name": "Monaco", "numcode": "492", "iso": "MC", "calling_code": "+377", "plan_region": "row"},
  {"id": 142, "name": "Mongolia", "numcode": "496", "iso": "MN", "calling_code": "+976", "plan_region": "row"},
  {"id": 242, "name": "Montenegro", "numcode": "499", "iso": "ME", "calling_code": "+382", "plan_region": "row"},
  {"id": 143, "name": "Montserrat", "numcode": "500", "iso": "MS", "calling_code": "+664", "plan_region": "row"},
  {"id": 144, "name": "Morocco", "numcode": "504", "iso": "MA", "calling_code": "+212", "plan_region": "row"},
  {"id": 145, "name": "Mozambique", "numcode": "508", "iso": "MZ", "calling_code": "+258", "plan_region": "row"},
  {"id": 146, "name": "Myanmar", "numcode": "104", "iso": "MM", "calling_code": "+95", "plan_region": "row"},
  {"id": 147, "name": "Namibia", "numcode": "516", "iso": "NA", "calling_code": "+264", "plan_region": "row"},
  {"id": 148, "name": "Nauru", "numcode": "520", "iso": "NR", "calling_code": "+674", "plan_region": "row"},
  {"id": 149, "name": "Nepal", "numcode": "524", "iso": "NP", "calling_code": "+977", "plan_region": "row"},
  {"id": 151, "name": "Netherlands Antilles", "numcode": "530", "iso": "AN", "calling_code": "", "plan_region": "row"},
  {"id": 150, "name": "Netherlands, the", "numcode": "528", "iso": "NL", "calling_code": "+31", "plan_region": "row"},
  {"id": 152, "name": "New Caledonia", "numcode": "540", "iso": "NC", "calling_code": "+687", "plan_region": "row"},
  {"id": 153, "name": "New Zealand", "numcode": "554", "iso": "NZ", "calling_code": "+64", "plan_region": "row"},
  {"id": 154, "name": "Nicaragua", "numcode": "558", "iso": "NI", "calling_code": "+505", "plan_region": "row"},
  {"id": 155, "name": "Niger", "numcode": "562", "iso": "NE", "calling_code": "+277", "plan_region": "row"},
  {"id": 156, "name": "Nigeria", "numcode": "566", "iso": "NG", "calling_code": "+234", "plan_region": "row"},
  {"id": 157, "name": "Niue", "numcode": "570", "iso": "NU", "calling_code": "+683", "plan_region": "row"},
  {"id": 158, "name": "Norfolk Island", "numcode": "574", "iso": "NF", "calling_code": "+672", "plan_region": "row"},
  {"id": 126, "name": "North Macedonia", "numcode": "807", "iso": "MK", "calling_code": "+389", "plan_region": "row"},
  {"id": 159, "name": "Northern Mariana Islands", "numcode": "580", "iso": "MP", "calling_code": "+670", "plan_region": "row"},
  {"id": 160, "name": "Norway", "numcode": "578", "iso": "NO", "calling_code": "+47", "plan_region": "row"},
  {"id": 161, "name": "Oman", "numcode": "512", "iso": "OM", "calling_code": "+968", "plan_region": "gcc"},
  {"id": 162, "name": "Pakistan", "numcode": "586", "iso": "PK", "calling_code": "+92", "plan_region": "row"},
  {"id": 163, "name": "Palau", "numcode": "585", "iso": "PW", "calling_code": "+680", "plan_region": "row"},
  {"id": 164, "name": "Palestine, State of", "numcode": "275", "iso": "PS", "calling_code": "+970", "plan_region": "row"},
  {"id": 165, "name": "Panama", "numcode": "591", "iso": "PA", "calling_code": "+507", "plan_region": "row"},
  {"id": 166, "name": "Papua New Guinea", "numcode": "598", "iso": "PG", "calling_code": "+675", "plan_region": "row"},
  {"id": 167, "name": "Paraguay", "numcode": "600", "iso": "PY", "calling_code": "+595", "plan_region": "row"},
  {"id": 168, "name": "Peru", "numcode": "604", "iso": "PE", "calling_code": "+51", "plan_region": "row"},
  {"id": 169, "name": "Philippines", "numcode": "608", "iso": "PH", "calling_code": "+63", "plan_region": "row"},
  {"id": 170, "name": "Pitcairn", "numcode": "612", "iso": "PN", "calling_code": "+872", "plan_region": "row"},
  {"id": 171, "name": "Poland", "numcode": "616", "iso": "PL", "calling_code": "+48", "plan_region": "row"},
  {"id": 172, "name": "Portugal", "numcode": "620", "iso": "PT", "calling_code": "+351", "plan_region": "row"},
  {"id": 173, "name": "Puerto Rico", "numcode": "630", "iso": "PR", "calling_code": "+787", "plan_region": "row"},
  {"id": 174, "name": "Qatar", "numcode": "634", "iso": "QA", "calling_code": "+974", "plan_region": "gcc"},
  {"id": 175, "name": "Reunion", "numcode": "638", "iso": "RE", "calling_code": "+262", "plan_region": "row"},
  {"id": 176, "name": "Romania", "numcode": "642", "iso": "RO", "calling_code": "+40", "plan_region": "row"},
  {"id": 177, "name": "Russian Federation", "numcode": "643", "iso": "RU", "calling_code": "+7", "plan_region": "row"},
  {"id": 178, "name": "Rwanda", "numcode": "646", "iso": "RW", "calling_code": "+250", "plan_region": "row"},
  {"id": 179, "name": "Saint Helena", "numcode": "654", "iso": "SH", "calling_code": "+290", "plan_region": "row"},
  {"id": 180, "name": "Saint Kitts and Nevis", "numcode": "659", "iso": "KN", "calling_code": "+869", "plan_region": "row"},
  {"id": 181, "name": "Saint Lucia", "numcode": "662", "iso": "LC", "calling_code": "+758", "plan_region": "row"},
  {"id": 182, "name": "Saint Pierre and Miquelon", "numcode": "666", "iso": "PM", "calling_code": "+508", "plan_region": "row"},
  {"id": 183, "name": "Saint Vincent and the Grenadines", "numcode": "670", "iso": "VC", "calling_code": "+784", "plan_region": "row"},
  {"id": 184, "name": "Samoa", "numcode": "882", "iso": "WS", "calling_code": "+685", "plan_region": "row"},
  {"id": 185, "name": "San Marino", "numcode": "674", "iso": "SM", "calling_code": "+378", "plan_region": "row"},
  {"id": 186, "name": "Sao Tome and Principe", "numcode": "678", "iso": "ST", "calling_code": "+239", "plan_region": "row"},
  {"id": 187, "name": "Saudi Arabia", "numcode": "682", "iso": "SA", "calling_code": "+966", "plan_region": "row"},
  {"id": 188, "name": "Senegal", "numcode": "686", "iso": "SN", "calling_code": "+221", "plan_region": "row"},
  {"id": 189, "name": "Serbia", "numcode": "688", "iso": "CS", "calling_code": "", "plan_region": "row"},
  {"id": 190, "name": "Seychelles", "numcode": "690", "iso": "SC", "calling_code": "+248", "plan_region": "row"},
  {"id": 191, "name": "Sierra Leone", "numcode": "694", "iso": "SL", "calling_code": "+232", "plan_region": "row"},
  {"id": 192, "name": "Singapore", "numcode": "702", "iso": "SG", "calling_code": "+65", "plan_region": "cs"},
  {"id": 243, "name": "Sint Maarten", "numcode": "534", "iso": "SX", "calling_code": "+599", "plan_region": "row"},
  {"id": 193, "name": "Slovakia", "numcode": "703", "iso": "SK", "calling_code": "+421", "plan_region": "row"},
  {"id": 194, "name": "Slovenia", "numcode": "705", "iso": "SI", "calling_code": "+386", "plan_region": "row"},
  {"id": 195, "name": "Solomon Islands", "numcode": "90", "iso": "SB", "calling_code": "+677", "plan_region": "row"},
  {"id": 196, "name": "Somalia", "numcode": "706", "iso": "SO", "calling_code": "+252", "plan_region": "row"},
  {"id": 197, "name": "South Africa", "numcode": "710", "iso": "ZA", "calling_code": "+27", "plan_region": "row"},
  {"id": 198, "name": "South Georgia and the South Sandwich Islands", "numcode": "239", "iso": "GS", "calling_code": "+500", "plan_region": "row"},
  {"id": 199, "name": "Spain", "numcode": "724", "iso": "ES", "calling_code": "+34", "plan_region": "row"},
  {"id": 200, "name": "Sri Lanka", "numcode": "144", "iso": "LK", "calling_code": "+94", "plan_region": "row"},
  {"id": 201, "name": "Sudan", "numcode": "736", "iso": "SD", "calling_code": "+249", "plan_region": "row"},
  {"id": 202, "name": "Suriname", "numcode": "740", "iso": "SR", "calling_code": "+597", "plan_region": "row"},
  {"id": 203, "name": "Svalbard and Jan Mayen", "numcode": "744", "iso": "SJ", "calling_code": "+47", "plan_region": "row"},
  {"id": 204, "name": "Swaziland", "numcode": "748", "iso": "SZ", "calling_code": "+268", "plan_region": "row"},
  {"id": 205, "name": "Sweden", "numcode": "752", "iso": "SE", "calling_code": "+46", "plan_region": "row"},
  {"id": 206, "name": "Switzerland", "numcode": "756", "iso": "CH", "calling_code": "+41", "plan_region": "row"},
  {"id": 207, "name": "Syrian Arab Republic", "numcode": "760", "iso": "SY", "calling_code": "+963", "plan_region": "row"},
  {"id": 208, "name": "Taiwan, Province of China", "numcode": "158", "iso": "TW", "calling_code": "+886", "plan_region": "row"},
  {"id": 209, "name": "Tajikistan", "numcode": "762", "iso": "TJ", "calling_code": "+992", "plan_region": "row"},
  {"id": 210, "name": "Tanzania, United Republic of", "numcode": "834", "iso": "TZ", "calling_code": "+255", "plan_region": "row"},
  {"id": 211, "name": "Thailand", "numcode": "764", "iso": "TH", "calling_code": "+66", "plan_region": "row"},
  {"id": 212, "name": "Timor-Leste", "numcode": "626", "iso": "TL", "calling_code": "+670", "plan_region": "row"},
  {"id": 213, "name": "Togo", "numcode": "768", "iso": "TG", "calling_code": "+228", "plan_region": "row"},
  {"id": 214, "name": "Tokelau", "numcode": "772", "iso": "TK", "calling_code": "+690", "plan_region": "row"},
  {"id": 215, "name": "Tonga", "numcode": "776", "iso": "TO", "calling_code": "+676", "plan_region": "row"},
  {"id": 216, "name": "Trinidad and Tobago", "numcode": "780", "iso": "TT", "calling_code": "+868", "plan_region": "row"},
  {"id": 217, "name": "Tunisia", "numcode": "788", "iso": "TN", "calling_code": "+216", "plan_region": "row"},
  {"id": 218, "name": "Turkey", "numcode": "792", "iso": "TR", "calling_code": "+90", "plan_region": "row"},
  {"id": 219, "name": "Turkmenistan", "numcode": "795", "iso": "TM", "calling_code": "+993", "plan_region": "row"},
  {"id": 220, "name": "Turks and Caicos Islands", "numcode": "796", "iso": "TC", "calling_code": "+649", "plan_region": "row"},
  {"id": 221, "name": "Tuvalu", "numcode": "798", "iso": "TV", "calling_code": "+688", "plan_region": "row"},
  {"id": 222, "name": "Uganda", "numcode": "800", "iso": "UG", "calling_code": "+256", "plan_region": "row"},
  {"id": 223, "name": "Ukraine", "numcode": "804", "iso": "UA", "calling_code": "+380", "plan_region": "row"},
  {"id": 224, "name": "United Arab Emirates", "numcode": "784", "iso": "AE", "calling_code": "+971", "plan_region": "uae"},
  {"id": 225, "name": "United Kingdom", "numcode": "826", "iso": "GB", "calling_code": "+44", "plan_region": "row"},
  {"id": 227, "name": "United States Minor Outlying Islands", "numcode": "581", "iso": "UM", "calling_code": "+1", "plan_region": "row"},
  {"id": 226, "name": "United States of America", "numcode": "840", "iso": "US", "calling_code": "+1", "plan_region": "row"},
  {"id": 228, "name": "Uruguay", "numcode": "858", "iso": "UY", "calling_code": "+598", "plan_region": "row"},
  {"id": 229, "name": "Uzbekistan", "numcode": "860", "iso": "UZ", "calling_code": "+998", "plan_region": "row"},
  {"id": 230, "name": "Vanuatu", "numcode": "548", "iso": "VU", "calling_code": "+678", "plan_region": "row"},
  {"id": 231, "name": "Venezuela", "numcode": "862", "iso": "VE", "calling_code": "+58", "plan_region": "row"},
  {"id": 232, "name": "Viet Nam", "numcode": "704", "iso": "VN", "calling_code": "+84", "plan_region": "row"},
  {"id": 233, "name": "Virgin Islands, British", "numcode": "92", "iso": "VG", "calling_code": "+284", "plan_region": "row"},
  {"id": 234, "name": "Virgin Islands, U.S.", "numcode": "850", "iso": "VI", "calling_code": "+340", "plan_region": "row"},
  {"id": 235, "name": "Wallis and Futuna", "numcode": "876", "iso": "WF", "calling_code": "+681", "plan_region": "row"},
  {"id": 236, "name": "Western Sahara", "numcode": "732", "iso": "EH", "calling_code": "+212", "plan_region": "row"},
  {"id": 237, "name": "Yemen", "numcode": "887", "iso": "YE", "calling_code": "+967", "plan_region": "row"},
  {"id": 238, "name": "Zambia", "numcode": "894", "iso": "ZM", "calling_code": "+260", "plan_region": "row"},
  {"id": 239, "name": "Zimbabwe", "numcode": "716", "iso": "ZW", "calling_code": "+263", "plan_region": "row"}
];


export const CITIZENSHIPS = TOP_CITIZENSHIPS.concat(SEPARATOR).concat(COUNTRIES)
export const COUNTRIES_OF_RESIDENCE = TOP_COUNTRIES_OF_RESIDENCE.concat(SEPARATOR).concat(COUNTRIES)


