// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import TESTING from "../globals"

import React, { useEffect, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import axios from "axios";

import { format } from 'date-fns'

import Container from 'react-bootstrap/Container'
import { Table, Row, Col } from 'react-bootstrap'

import IlFormatDate from '../util/IlFormatDate'
import IlFormatCurrency from '../util/IlFormatCurrency'


export default function IndividualPolicyPeriodUnderwritingNotes({person, setError}) {

  const ilUsers = useSelector(state => state.ilUsers)

  const [underwritingNotes, setUnderwritingNotes] = React.useState([]);



  useEffect(() => {
    doRetrieve(person.id);
  }, []);


  const doRetrieve = (id) => {
    console.log(id);
    console.log('not retrieving')
    if (! id) return;
    console.log('retrieving')
    const host = window.location.protocol + '//' + window.location.host
    axios
      .get( // zwecks visibility of query string in URL
//                                            /underwriting_notes.json?_dc=1623868138338&uw_notable_id=122&uw_notable_type=individual-insured
        host + "/il_ws/il_person/il_underwriting_notes?uw_notable_id=" + id, {
          headers: {
            'il-access-token': ilUsers?.currentUser?.access_token  // Underscores are dangerous! https://api.rubyonrails.org/classes/ActionDispatch/Http/Headers.html
          }
        },
        { withCredentials: true }
      )
      .then(response => {
        setError(null);
        console.log(response);
        setUnderwritingNotes(response.data.il_underwriting_notes);
      })
      .catch(error => {
        setError(error.response.statusText);
        setPolicyPeriod(null);
      });
  }

  if (! person) return null;

  return (
    <>
      <Row className="mt-2 mt-sm-0">
        <Col xs="8">
          Person
        </Col>
        <Col xs="8">
          Notes
        </Col>
        <Col xs="2">
          Created at
        </Col>
        <Col xs="2">
          Author
        </Col>
      </Row>
      {underwritingNotes.map(row => (
        <Row key={row.id} className="mt-2 mt-sm-0">
          <Col xs="8">
           {person.fullname}
          </Col>
          <Col xs="8">
           {row.notes}
          </Col>
          <Col xs="2">
            <IlFormatDate date={row.created_at} />
          </Col>
          <Col xs="2">
           {row.il_user.fullname}
          </Col>
        </Row>
      ))}
    </>
  );

}
