// encoding: UTF-8
//
// (C) Copyright 2021 Horst Tellioğlu, All Rights Reserved
// Author: Horst Tellioğlu <horst@tellioglu.at>
//
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from "axios";

// @TODO rename IgCountrySelect and abstract

export default function QuoteFormCountrySelect(props) {

  const [countries, setCountries] = React.useState([]);
  const fieldName = props.fieldName ? props.fieldName : 'country';

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const host = window.location.protocol + '//' + window.location.host

      const response = await axios.get(
        host + "/ws/countries/codes.json",
        {
          withCredentials: false,
          params: {
            show_top_countries: true,
            exclude: JSON.stringify(['HK', 'US'])
          }
        }
      )
      if (isMounted) {
        setCountries(response.data);
      }
    };

    fetchData();

    return () => {
      // clean up
      isMounted = false;
    };

  }, []);

  const handleChange = (event) => {
    if (event.target.value == 'separator') {
      event.target.value = 'select_country'
    }
    props.onChange(event);
  };

  return (
    <Form.Control
      className={props.className}
      custom={true}
      as="select"
      name={props.fieldName ?? 'country'}
      value={props.value}
      isInvalid={props.isInvalid}
      onChange={handleChange}
      onBlur={props.onBlur}
    >
      <option key="-1" value="select_country">{props.placeholder ? props.placeholder : '— Select Country —'}</option>
      {
        // https://reactjs.org/docs/lists-and-keys.html#keys
        // using the indexs is ok if
        // 1. the list and items are static–they are not computed and do not change;
        // 2. the items in the list have no ids;
        // 3. the list is never reordered or filtered.
        countries.map( (country, index) =>
          <option key={index} value={country.iso}>{country.name}</option>
        )
      }
    </Form.Control>
  );
}