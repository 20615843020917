import React, { useState, useEffect, useRef } from "react"
import {Redirect} from "react-router-dom"

import axios from "axios";

// The useHistory hook gives you access to the history instance that you may use to navigate.
import { useHistory } from "react-router-dom"

import { MEDICAL_CONDITIONS, PERSONAL_RELATIONSHIPS, isCriticalBMI } from '../exports'
import { saveEnrolmentInsured } from '../insured'

import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { OverlayTrigger, Popover, Button, Switch } from 'react-bootstrap'
import Jumbotron from 'react-bootstrap/Jumbotron'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { addDays } from '@progress/kendo-date-math'

import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'

import GroupEnrolmentInsuredConditions          from './GroupEnrolmentInsuredConditions'
import GroupEnrolmentInsuredConditionsText      from './GroupEnrolmentInsuredConditionsText'
import GroupEnrolmentInsuredConditionsPregnancy from './GroupEnrolmentInsuredConditionsPregnancy'

export default function EnrolmentMainQuestions({
  persons,
  setPersons,
  groupPolicyId,
  positiveAnswerToMedicalQuestions,
  setPositiveAnswerToMedicalQuestions,
  medicalConditions,
  setMedicalConditions,
  commonPlanOptions,
  setCommonPlanOptions,
  validated,
  setValidated
}) {

  useEffect(() => {
    let sortedPersons = persons.sort((a, b) => (PERSONAL_RELATIONSHIPS[a.relationship].value - PERSONAL_RELATIONSHIPS[b.relationship].value))
    setPersons([...sortedPersons]);
  }, []);


  const topLocation = useRef(null);
  let history = useHistory();

  const [errors, setErrors] = React.useState({});

  if (! validated.primary) return <Redirect to={"/ig/enrolment/group_policy/" + groupPolicyId} />

  const numberOfPossiblePregnancies = () => {
    let number = persons.reduce( (accumulator, person) => {
      return accumulator + ((person.relationship == 'primary' || person.relationship == 'partner') && person.sex != 'male' ? 1 : 0);
    }, 0);
    return number;
  };


  const handleChange = (event) => {
    const value = event.target.checked;
    const name = event.target.name;

    setPositiveAnswerToMedicalQuestions({
      ...positiveAnswerToMedicalQuestions,
      [name]: value
    });

    if (value == false) {
      if (name == 'answer_1') {
        persons.forEach(pers => pers.medical_conditions = [])
      } else {
        persons.forEach(pers => pers[name] = '')
      }
    }
  };



  const handleContinue = (event) => {
    let newErrors = {};
    let invalid = false;

    if (positiveAnswerToMedicalQuestions.answer_1 == true) {
      newErrors.answer_1 = persons.reduce(  // no value for all persons
                              (accu, dependent) => {
                                return accu && (! dependent.medical_conditions || Object.keys(dependent.medical_conditions).length < 1)
                              },
                              true
                            );
      invalid = invalid || newErrors.answer_1;
    }

    invalid = [2, 3, 4, 5].map(i => 'answer_' + i).reduce(
      (accu, answer) => {
        newErrors[answer] = false;
        if (positiveAnswerToMedicalQuestions[answer] == true) {
          if (! persons.reduce(  // is there an answer text for at least dependent?
                 (accu, dependent) => {
                   return accu || (dependent[answer] && dependent[answer].trim() !== '')
                 },
                 false
               )
             ) {
            newErrors[answer] = true;
          }
        }
        return accu || newErrors[answer]
      },
      invalid
    );

    newErrors.answer_6 = false;
    if (persons.reduce(  // no value for all persons
           (accu, person) => {
             return accu || (person.pregnant === undefined && person.relationship == 'partner' && person.sex != 'male')
           },
           false
         )
       ) {
      newErrors.answer_6 = true;
    }
    invalid = invalid || newErrors.answer_6;

    setErrors(newErrors);

    setValidated({...validated, main_questions: !invalid});

    if (! invalid) {
      setMedicalStatus();
      //save
      persons.forEach(person => {
        saveEnrolmentInsured({
          ...person,
          log: "EASY QUESTIONS\nSaving answers to group enrolment main questions."
        });
      })
      history.push("/ig/enrolment/group_policy/" + groupPolicyId + "/overview");
    } else {
      if (topLocation.current) {
        topLocation.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }

    }
  };



  const setMedicalStatus = () => {

    // cleanup, just to make sure and for inconsistent test data
    if (positiveAnswerToMedicalQuestions.answer_1) {
      persons.forEach(pers => {
        if (pers.medical_conditions && pers.medical_conditions.length > 0) {
          pers.medical_status = 'medical_underwriting';
        } else {
          pers.medical_status = 'medically_approved';
        }
      })
    } else {
      persons.forEach(pers => {
        pers.medical_conditions = [];
        pers.medical_status = 'medically_approved';
      })
    }
    ['answer_2', 'answer_3', 'answer_4','answer_5'].forEach((answer) => {
      if (positiveAnswerToMedicalQuestions[answer]) {
        persons.forEach(pers => {
          if (pers[answer] && pers[answer].trim() !== '') {
            pers.medical_status = 'medical_underwriting';
          }
        })
      }
    })

    persons.forEach(pers => {
      if (pers.pregnant || isCriticalBMI(pers.bmi)) {
        pers.medical_status = 'medical_underwriting';
      }
    })
  }


  const popover = (
    <Popover>
      <Popover.Title as="h3">Routine Check-Up</Popover.Title>
      <Popover.Content>
        Routine check-up is defined as a
        physical exam and/or blood tests that are of a routine nature
        and not used for the purpose of treating a medical condition and
        nor which any medical diagnosis has been made.
      </Popover.Content>
    </Popover>
  );

  // Medical conditions treated or diagnosed
  // Planned medical events
  // Symptoms last 12 months
  // Medication
  // Pregnancy
  // Previous insurance issues

  return (
    <Form>
      {Object.values(errors).length > 0 &&
        <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
          <h1>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h1>
        </Jumbotron>
      }

      <h2 ref={topLocation} className="mt-4">And now {numberOfPossiblePregnancies() > 0 ? 6 : 5} easy questions about your health</h2>

      <Container style={{padding: 0}}>


        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)'}}>
          <Row noGutters>
            <Col>
              <h3>1
                {errors.answer_1 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
                }
              </h3>
              <div>
              Have you or any of your dependants to be covered
              on this policy been treated or diagnosed for a medical condition
              in the last 15 years for anything other than colds, flu,
              or <OverlayTrigger overlay={popover}><span style={{textDecoration: 'underline'}}>routine check-up</span></OverlayTrigger>? </div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3">
              <Form.Group as={Col} className="">
                <Form.Check
                  style={{fontSize: 'large'}}
                  inline
                  type="switch"
                  name="answer_1"
                  checked={positiveAnswerToMedicalQuestions.answer_1}
                  onChange={handleChange}
                  id="medical-question-one-switch"
                  label={positiveAnswerToMedicalQuestions.answer_1 ? 'YES' : 'NO'}
                />
              </Form.Group>



          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_1 == true &&
                <GroupEnrolmentInsuredConditions
                  persons={persons}
                  setPersons={setPersons}
                />
              }
            </Col>
          </Row>
        </div>





        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
              <h3>2
                {errors.answer_2 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
                }
                </h3>
              <div>Are any inpatient or outpatient medical/surgical
              or dental procedures or oral surgery (including diagnostic
              testing) recommended/contemplated for you or your dependants
              to be covered on this policy?</div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3" style={{fontSize: 'large'}}>
            <Form.Group as={Col}>
              <Form.Check
                style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                inline
                type="switch"
                name="answer_2"
                checked={positiveAnswerToMedicalQuestions.answer_2}
                onChange={handleChange}
                id="medical-question-two-switch"
                label={positiveAnswerToMedicalQuestions.answer_2 ? 'YES' : 'NO'}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_2 == true &&
                <GroupEnrolmentInsuredConditionsText
                  answer_name='answer_2'
                  persons={persons}
                  setPersons={setPersons}
                />
              }
            </Col>
          </Row>

        </div>


        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
              <h3>3
                {errors.answer_3 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
                }
              </h3>
              <div>Have you or any of your dependants to be covered
              on this policy experienced any symptoms of any medical problem
              in the last 12 months, regardless of whether a healthcare
              professional has been consulted?</div>
            </Col>
          </Row>
          <Row noGutters className="text-center mt-3" style={{fontSize: 'large'}}>
              <Form.Group as={Col}>
                <Form.Check
                  style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                  inline
                  type="switch"
                  name="answer_3"
                  checked={positiveAnswerToMedicalQuestions.answer_3}
                  onChange={handleChange}
                  id="medical-question-three-switch"
                  label={positiveAnswerToMedicalQuestions.answer_3 ? 'YES' : 'NO'}
                />
              </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_3 == true &&
                <GroupEnrolmentInsuredConditionsText
                  answer_name='answer_3'
                  persons={persons}
                  setPersons={setPersons}
                />
              }
            </Col>
          </Row>


        </div>


        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
            <h3>4
              {errors.answer_4 &&
                <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
              }
            </h3>

            <div>Are you or any of your dependants to be covered
            on this policy currently taking any medication?</div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3" style={{fontSize: 'large'}}>
              <Form.Group as={Col}>
                <Form.Check
                  style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                  inline
                  type="switch"
                  name="answer_4"
                  checked={positiveAnswerToMedicalQuestions.answer_4}
                  onChange={handleChange}
                  id="medical-question-four-switch"
                  label={positiveAnswerToMedicalQuestions.answer_4 ? 'YES' : 'NO'}
                />

              </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_4 == true &&
                <GroupEnrolmentInsuredConditionsText
                  answer_name='answer_4'
                  persons={persons}
                  setPersons={setPersons}
                />
              }
            </Col>
          </Row>

        </div>

        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
            <h3>5
              {errors.answer_5 &&
                <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please provide details</span>
              }
            </h3>
            <div>Have you ever or any of your dependants to be
            covered on this policy had an application or an application for
            reinstatement of Life, Accident, or Health Insurance declined,
            postponed, rated or modified?</div>
            </Col>
          </Row>

          <Row noGutters className="text-center mt-3" style={{fontSize: 'large'}}>
              <Form.Group as={Col}>
                <Form.Check
                  style={{zIndex: 0 /* otherwise conflict with dropped down react select */}}
                  inline
                  type="switch"
                  name="answer_5"
                  checked={positiveAnswerToMedicalQuestions.answer_5}
                  onChange={handleChange}
                  id="medical-question-five-switch"
                  label={positiveAnswerToMedicalQuestions.answer_5 ? 'YES' : 'NO'}
                />
              </Form.Group>
          </Row>
          <Row>
            <Col>
              {positiveAnswerToMedicalQuestions.answer_5 == true &&
                <GroupEnrolmentInsuredConditionsText
                  answer_name='answer_5'
                  persons={persons}
                  setPersons={setPersons}
                />
              }
            </Col>
          </Row>

        </div>




        {numberOfPossiblePregnancies() > 0 &&
        <div className="p-4" style={{backgroundColor: '#ffffff', border: '1px solid rgba(0, 0, 0, 0.125)', borderTopWidth: 0}}>
          <Row noGutters>
            <Col>
              <h3>6
                {errors.answer_6 &&
                  <span style={{color: '#dc3545', fontSize: '80%', marginLeft: '0.5em'}}>Please specify if pregnant.</span>
                }
              </h3>
              <GroupEnrolmentInsuredConditionsPregnancy
                persons={persons}
                setPersons={setPersons}
              />
          </Col>
          </Row>
        </div>
        }
      </Container>

      <Row className="text-center">
        <Col className="mt-4 mb-5" style={{}} >
          <Button type="button" onClick={handleContinue} style={{marginRight: '1em', fontSize: 'x-large', padding: '1em'}}>CONTINUE</Button>
          {/*
          <Button type="button" onClick={() => history.push("/integraglobal/enrolment/insured_conditions")} style={{marginRight: '1em'}}>YES</Button>
          <Button type="button" onClick={() => history.push("/integraglobal/enrolment/plan_options")}>NO</Button>
          */}
        </Col>
      </Row>
    </Form>
  )
}