import React, { Component } from "react"

import {Redirect, useHistory } from "react-router-dom"

import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import Jumbotron from 'react-bootstrap/Jumbotron'

import { Formik } from 'formik'
import * as Yup from 'yup'

import axios from "axios"

export default function CreateAccount({formAuthenticityToken}) {

  let history = useHistory();

  //const [customer, setCustomer] = React.useState({});
  const [accountCreated, setAccountCreated] = React.useState(false);
  const [loginError, setLoginError] = React.useState('');

  // const handleChange = event => {
  //   console.log('handleChange');
  //   let { name, value, type } = event.currentTarget;
  //   setCustomer({
  //     ...customer,
  //     [name]: value
  //   });
  // }


  const handleSubmit = (values, { setSubmitting }) => {
    axios
      .post( // we don't want the password be visible in the query string
        window.location.protocol + '//' + window.location.host + "/ws_customer/customer",
        {
          customer: values
        },
        {
          headers: {
            'X-CSRF-Token': formAuthenticityToken
          }
        }
      )
      .then(response => {
        setLoginError(null);
        setAccountCreated(true);
        //setSubmitting(false);
      })
      .catch(error => {
        setLoginError(error.response.data.message);
        setAccountCreated(false);
        setSubmitting(false);
      });
  };


  const SignupSchema = Yup.object().shape({
    firstname: Yup.string().required('Please provide your firstname.'),
    lastname: Yup.string().required('Please provide your lastname.'),
    email_address: Yup.string().email('The email address you provided has an invalid format.').required('Please provide your email address.'),
    password: Yup.string().required('Please provide a password.')
  });


  if (accountCreated) {
    return (
      <Jumbotron className="" style={{color: '#505050', backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
        <h3>Success!</h3>
        <h1>Your account has been created and a confirmation email sent to your address.  Please click on the link in that email to proceed.</h1>
        <div>
          <a className="btn btn-link" href="/ig/customer/login" style={{textTransform: 'uppercase'}}>Login</a>
        </div>
      </Jumbotron>
    )
  }

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="customer-forms mt-5 mb-5 col-12 col-md-8 col-lg-6">

          <Formik
           initialValues={{firstname: '', lastname: '', email_address: '', password: ''}}
           validationSchema={SignupSchema}
           onSubmit={handleSubmit}
          >
           {({
             values,
             errors,
             touched,
             handleChange,
             handleBlur,
             handleSubmit,
             isSubmitting,
             submitCount,
             getFieldProps,
             /* and other goodies */
           }) => (

              <Form
                noValidate
                onSubmit={handleSubmit}
              >
              <h1 className="">CREATE ACCOUNT</h1>


              {loginError &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h3>We are sorry.</h3>
                  <h1>{loginError}</h1>
                </Jumbotron>
              }

              <p>Enter your name, email address and password to create an account. If you already have an account, you can <a href="/ig/customer/login">login</a> to change your details.</p>


              <Form.Group as={Row} controlId="formGroupFirstName">
                <Col sm="10" md="12">
                  <Form.Control
                    type="text"
                    placeholder="Firstname*"
                    required
                    isInvalid={errors.firstname && touched.firstname}
                    {...getFieldProps('firstname')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                </Col>
              </Form.Group>


              <Form.Group as={Row} controlId="formGroupLastName">
                <Col sm="10" md="12">
                  <Form.Control
                    type="text"
                    placeholder="Lastname*"
                    required
                    isInvalid={errors.lastname && touched.lastname}
                    {...getFieldProps('lastname')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formGroupEmail">
                <Col sm="10" md="12">
                  <Form.Control
                    type="text"
                    placeholder="Email*"
                    required
                    isInvalid={errors.email_address && touched.email_address}
                    {...getFieldProps('email_address')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email_address}</Form.Control.Feedback>
                </Col>
              </Form.Group>


              <Form.Group as={Row} controlId="formGroupPassword">
                <Col sm="10" md="12">
                  <Form.Control
                    type="password"
                    placeholder="Password*"
                    required
                    isInvalid={errors.password && touched.password}
                    {...getFieldProps('password')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Col>
              </Form.Group>


              <Form.Row className="mb-3">
                  <Col sm="12" md="4" className="mb-3">
                    <Button type="submit" variant="primary">Create</Button>
                  </Col>
                  <Col sm="12" md="4">
                    <Button variant="link" onClick={() => history.push("/ig/customer/login")} >Login</Button>
                  </Col>
                  <Col sm="12" md="4">
                    <Button variant="link" onClick={() => history.push("/ig/customer/password_update")}>Forgot Password?</Button>
                  </Col>
              </Form.Row>


              {Object.values(errors).length > 0 && submitCount > 0 &&
                <Jumbotron className="" style={{color: 'rgba(162, 36, 47, 1.0)',/* border: '//2px solid #A2242F',*/ backgroundColor: 'rgba(150, 196, 81, 0.1)'}}>
                  <h1>Oops! You've missed something. Please fill in the field(s) highlighted in red.</h1>
                </Jumbotron>
              }


              </Form>
              )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
