import React, { useState, useEffect } from "react"
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';


export default function EnrolmentAssignmentAddress({
  enrolment,
  handleChange,
  assignmentAddressHidden
  }) {

  if (assignmentAddressHidden) return null;

  return (
    <>
      <Form.Group as={Row} controlId="formGroupCompany">
         <Form.Label column sm="2">Company</Form.Label>
         <Col sm="10">
           <Form.Control
             type="text"
             name="corr_company"
             required
             onChange={handleChange}
             value={enrolment.corr_company}
           />
         </Col>
      </Form.Group>


        <Form.Group as={Row} controlId="formGroupCorrStreet1">
          <Form.Label column sm="2">Street*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="corr_street_1"
              required
              onChange={handleChange}
              value={enrolment.corr_street_1}
            />
            <Form.Control.Feedback type="invalid">Please provide a street name.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupCorrStreet2">
          <Form.Label column sm="2">Additional details</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="corr_street_2"
              onChange={handleChange}
              value={enrolment.corr_street_2}
            />
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupZip1">
          <Form.Label column sm="2">Zip/postcode*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="corr_zip_1"
              required
              onChange={handleChange}
              value={enrolment.corr_zip_1}
            />
            <Form.Control.Feedback type="invalid">Please provide a zip/postcode.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupCity">
          <Form.Label column sm="2">City*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="corr_city_1"
              required
              onChange={handleChange}
              value={enrolment.corr_city_1}
            />
            <Form.Control.Feedback type="invalid">Please provide the name of the city you live in.</Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={Row} controlId="formGroupState1">
          <Form.Label column sm="2">State (if applicable)l*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="corr_state_1"
              required
              onChange={handleChange}
              value={enrolment.corr_state_1}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGroupCountry">
          <Form.Label column sm="2">Country*</Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              name="corr_country_1_code"
              required
              onChange={handleChange}
              value={enrolment.corr_country_1_code}
            />
            <Form.Control.Feedback type="invalid">Please provide a country name.</Form.Control.Feedback>
          </Col>
        </Form.Group>
    </>
  )
}
